import * as React from 'react';
import styles from './Box.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

export interface BoxProps {
  children: any;
  border?: boolean;
  className?: string;
}

export default class Box extends React.Component<BoxProps, {}> {
  public render(): React.ReactNode {
    const cssClass = this.props.border ? styles.boxBorder : styles.boxShadow;
    return (
      <div
        className={combineClassNames(styles.box, cssClass, this.props.className)}
      >
        {this.props.children}
      </div>
    );
  }
}
