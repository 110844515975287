import { observer } from 'mobx-react';
import ValidatableObject from '../../../validation/ValidatableObject';
import FormSelectorBase from '../FormSelectorBase';
import { FormSelectOption } from '../FormSelect';
import GetStaticOptionsHelper from '../../../../helpers/getStaticOptionsHelper';
import { Gender, GenderDisplayName } from '../../../../helpers/enums/gender';

@observer
export default class FormGenderSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, Gender> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && s.value === storeRefValue) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return GetStaticOptionsHelper.buildOptions(Gender, GenderDisplayName);
  }

  protected prepareFetchedOptions(data: FormSelectOption[]): FormSelectOption[] {
    return data;
  }
}
