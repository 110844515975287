import { observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';

export class FileUploadHistoryPageStore extends ValidatableObject {
  @observable
  public searchPhrase = '';

  @observable
  public organizationId?: number;
}
