import * as React from 'react';
import error from '../../../../assets/images/icons/error.svg';
import success from '../../../../assets/images/icons/success icon for banner message.svg';
import successCircle from '../../../../assets/images/icons/success_circle.svg';
import approve from '../../../../assets/images/icons/approve.svg';
import reject from '../../../../assets/images/icons/reject.svg';
import destroy from '../../../../assets/images/icons/delete.svg';
import selectionCheck from '../../../../static/images/icons/selection check mark.svg';

interface IconForDisplay {
  name: string;
  url: string;
}

interface IconProps {
  className?: string;
  name: string;
  size: string;
}

export default class Icon extends React.Component<IconProps> {
  private icons: IconForDisplay[];

  constructor(props: IconProps) {
    super(props);

    this.icons = [
      { name: 'success-circle', url: successCircle },
      { name: 'success', url: success },
      { name: 'error', url: error },
      { name: 'approve', url: approve },
      { name: 'reject', url: reject },
      { name: 'delete', url: destroy },
      { name: 'selectionCheck', url: selectionCheck }
    ];
  }

  public render(): React.ReactNode {
    const icon = this.icons.find(i => i.name === this.props.name);

    const style = {
      width: this.props.size,
      height: this.props.size
    };

    return (
      <>
        {icon ? <img style={style} src={icon.url} alt={icon.name} className={this.props.className} />
          : <span className={this.props.className}>No icon found</span>}
      </>
    );
  }
}
