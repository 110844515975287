import { observable } from 'mobx';
import GridStore from '../../../shared/grid/GridStore';
import UserForGrid from '../../../model/grid/user/UserForGrid';
import GridPageRequest from '../../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../../model/grid/GridPageResponse';
import { UsersGridPageRequest } from '../../../model/grid/user/UsersGridPageRequestAggregate';
import { AccountStatus } from '../../../model/user/AccountStatus';
import apiService from '../../../services/ApiService';

export abstract class UsersGridStore<TRowModel extends UserForGrid> extends GridStore<TRowModel, string> {
  @observable
  public organizationId?: number;

  protected get shouldFetchData(): boolean {
    return this.organizationId !== undefined && this.organizationId > 0;
  }

  protected get shouldFetchOnMount(): boolean {
    return false;
  }

  protected get shouldDebounceFetch(): boolean {
    return false;
  }

  protected async apiCall(gridPageRequest: GridPageRequest<string>): Promise<GridPageResponse<TRowModel>> {
    try {
      const params = { gridPageRequest };
      const gridData = await apiService.get<GridPageResponse<TRowModel>>('user/listForGrid', { params });
      return gridData.data;
    } catch {
      return {
        rows: [],
        totalCount: 0
      };
    }
  }

  protected abstract get accountStatus(): AccountStatus;

  public getGridPageRequest(): UsersGridPageRequest {
    return {
      ...super.getGridPageRequest(),
      organizationId: this.organizationId!,
      userStatus: this.accountStatus
    };
  }
}
