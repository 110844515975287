import { AccountStatus } from '../../../../model/user/AccountStatus';
import { UsersGridStore } from '../UsersGridStore';
import { ActiveUserForGrid } from '../../../../model/grid/user/UserForGrid';

export class ActiveUsersGridStore extends UsersGridStore<ActiveUserForGrid> {
  protected get accountStatus(): AccountStatus {
    return AccountStatus.Active;
  }
}

export default new ActiveUsersGridStore();
