import { observable } from 'mobx';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../shared/validation/helpers';
import NotDaysAhead from '../../../../shared/validation/validators/NotDaysAhead';
import Conditional from '../../../../shared/validation/validators/Conditional';
import FieldValidationContext from '../../../../shared/validation/FieldValidationContext';
import Validator, { ValidationResult } from '../../../../shared/validation/validators/Validator';

export class NaloxoneManagementPageStore extends ValidatableObject {
  @observable
  public firstName = '';

  @observable
  public lastName = '';

  @validate(() => new NotDaysAhead(1))
  @observable
  public dateOfBirth = '';

  @observable
  public dataSubmissionType = 0;

  @observable
  public organizationId = 0;

  @validate(
    () => new NotDaysAhead(1),
    () => new Conditional(NaloxoneManagementPageStore.shouldValidateToDateField, new DatesNotBeforeOrAfter('Start Date cannot be after End Date'))
  )
  @observable
  public fromDate = '';

  @validate(
    () => new Conditional(NaloxoneManagementPageStore.shouldValidateToDateField, new DatesNotBeforeOrAfter('End Date cannot be before Start Date'))
  )
  @observable
  public toDate = '';

  private static shouldValidateToDateField(value: any, context: FieldValidationContext): boolean {
    return (context.target as NaloxoneManagementPageStore).fromDate !== '';
  }
}

class DatesNotBeforeOrAfter implements Validator<string> {
  private message: string;

  public constructor(message: string) {
    this.message = message;
  }
  public async validate(value: any, context: FieldValidationContext): Promise<ValidationResult> {
    const fromDate = new Date((context.target as NaloxoneManagementPageStore).fromDate);
    const toDate = new Date((context.target as NaloxoneManagementPageStore).toDate);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const dateDiff = fromDate - toDate;

    if (dateDiff > 0) {
      return { isValid: false, message: this.message };
    }

    return { isValid: true };
  }
}
