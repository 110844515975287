import { User as OidcUser } from 'oidc-client';
import Role from './Role';
import { AccountStatus } from './AccountStatus';

export default class Session {
  private _firstName?: string;
  private _lastName?: string;
  private _email?: string;
  private _emailVerified?: boolean;
  private _phoneNumber?: string;
  private _phoneNumberVerified?: boolean;
  private _role?: Role;
  private _status?: AccountStatus;

  public get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  public get firstName(): string | undefined {
    return this._firstName;
  }

  public get lastName(): string | undefined {
    return this._lastName;
  }

  public get email(): string | undefined {
    return this._email;
  }

  public get emailVerified(): boolean | undefined {
    return this._emailVerified;
  }

  public get phoneNumber(): string | undefined {
    return this._phoneNumber;
  }

  public get phoneNumberVerified(): boolean | undefined {
    return this._phoneNumberVerified;
  }

  public get role(): Role | undefined {
    return this._role;
  }

  public get status(): AccountStatus | undefined {
    return this._status;
  }

  public static fromOidcUser(user: OidcUser): Session {
    const result = new Session();
    result._firstName = user.profile.given_name;
    result._lastName = user.profile.family_name;
    result._email = user.profile.email;
    result._emailVerified = user.profile.email_verified;
    result._phoneNumber = user.profile.phone_number;
    result._phoneNumberVerified = user.profile.phone_number_verified;
    result._role = Role.fromOidcUser(user);
    result._status = user.profile.status;
    return result;
  }
}
