import * as React from 'react';
import { matchPath } from 'react-router';
import devHelper, { LogLevel } from '../../helpers/devHelper';
import Role from '../../model/user/Role';

export interface RouteSettings {
  path: string;
  needsLayout?: boolean;
  needsAuthentication?: boolean;
  permitActiveUsersOnly?: boolean;
  hasPermission?: string;
  exact?: boolean;
}

export interface RouteEntry {
  component: React.ComponentClass | React.FC;
  settings: Required<RouteSettings>;
}

export interface RedirectEntry {
  from: string;
  to: string;
  exact: boolean;
}

export default class RouteManager {
  private static readonly routeMap: { [key: string]: RouteEntry } = {};
  private static readonly redirectMap: {[key: string]: RedirectEntry } = {};

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    // intentionally
  }

  public static registerPage(settings: Required<RouteSettings>, component: React.ComponentClass<any, any>| React.FC<any>) {
    const currentComponent = this.routeMap[settings.path];
    if (currentComponent && currentComponent.component.name !== component.name) {
      devHelper.logForNonProdEnv(
        LogLevel.Warn,
        `Component with route ${settings.path} already exists: ${currentComponent.component.name}`
        + `, registered component: ${component.name}`
      );
      return;
    }
    const redirect = this.redirectMap[settings.path];
    if (redirect) {
      devHelper.logForNonProdEnv(
        LogLevel.Warn,
        `Redirect with route ${settings.path} already exists and redirects to ${redirect.to}.`
        + ` Attempt to override with component ${component.name}`
      );
      return;
    }

    this.routeMap[settings.path] = {
      component,
      settings
    };
  }

  public static registerRedirect(from: string, to: string, exact = true) {
    const currentEntry = this.redirectMap[from];
    if (currentEntry) {
      devHelper.logForNonProdEnv(
        LogLevel.Warn,
        `Redirect for path ${from} already exists and redirects to ${currentEntry.to}. Attempt to override with ${to}`
      );
      return;
    }

    const currentComponent = this.routeMap[from];
    if (currentComponent) {
      devHelper.logForNonProdEnv(
        LogLevel.Warn,
        `Component with route ${from} already exists: ${currentComponent.component.name}. Attempt to override with redirect to ${to}`
      );
      return;
    }

    this.redirectMap[from] = {
      from,
      to,
      exact
    };
  }

  public static getEntries(): RouteEntry[] {
    return Object.values(this.routeMap);
  }

  public static getRedirects(): RedirectEntry[] {
    return Object.values(this.redirectMap);
  }

  public static isPublicPath(path: string) {
    for (const route in this.routeMap) {
      if (matchPath(path, { path: route, exact: this.routeMap[route].settings.exact })) {
        return !this.routeMap[route].settings.needsAuthentication;
      }
    }

    devHelper.logForNonProdEnv(LogLevel.Warn, `Unknown path ${path}`);
    return true;
  }

  public static isActiveUsersOnlyPath(path: string) {
    for (const route in this.routeMap) {
      if (matchPath(path, { path: route, exact: this.routeMap[route].settings.exact })) {
        return this.routeMap[route].settings.permitActiveUsersOnly;
      }
    }

    devHelper.logForNonProdEnv(LogLevel.Warn, `Unknown path ${path}`);
    return true;
  }

  public static hasPermissionForPath(path: string, role?: Role) {
    if (!role) {
      return false;
    }

    for (const route in this.routeMap) {
      if (matchPath(path, { path: route, exact: this.routeMap[route].settings.exact })) {
        const permission = this.routeMap[route].settings.hasPermission;

        if (!permission) {
          // so as not to require setting explicit permissions on all routes
          return true;
        }

        return role.hasPermission(this.routeMap[route].settings.hasPermission);
      }
    }

    devHelper.logForNonProdEnv(LogLevel.Warn, `Unknown path ${path}`);
    return true;
  }

  public static needsLayout(path: string) {
    for (const route in this.routeMap) {
      if (matchPath(path, { path: route, exact: this.routeMap[route].settings.exact })) {
        return this.routeMap[route].settings.needsLayout;
      }
    }

    devHelper.logForNonProdEnv(LogLevel.Warn, `Unknown path ${path}`);
    return true;
  }
}
