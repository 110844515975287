import { observable } from 'mobx';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import NotEmpty from '../../../../shared/validation/validators/NotEmpty';
import ValidPhoneNumber from '../../../../shared/validation/validators/ValidPhoneNumber';
import { validate } from '../../../../shared/validation/helpers';
import ValidEmail from '../../../../shared/validation/validators/ValidEmail';
import { State } from '../../../../model/address/State';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import apiService from '../../../../services/ApiService';

export class OrganizationInfoSectionStore extends ValidatableObject {
  @validate(() => new NotEmpty())
  @observable
  public organizationName = '';

  @validate(() => new NotEmpty())
  @observable
  public streetAddress = '';

  @validate(() => new NotEmpty())
  @observable
  public city = '';

  @validate(() => new NotEmpty())
  @observable
  public state?: State;

  @validate(() => new NotEmpty())
  @observable
  public zip = '';

  @validate(() => new NotEmpty(), () => new ValidPhoneNumber())
  @observable
  public phone = '';

  @validate(
    () => new NotEmpty(),
    () => new ValidEmail()
  )
  @observable
  public email = '';

  @validate(() => new NotEmpty())
  @observable
  public dataSubmissionType?: DataSubmissionType;

  public fullAddress() {
    return `${this.streetAddress}, ${this.city}, ${State[this.state!]} ${this.zip}`;
  }

  public async fetchDataTypes(state: State): Promise<DataSubmissionType[]> {
    const response = await apiService.get('license/dataTypes', {
      params: {
        state
      }
    });
    return response.data;
  }
}

export default new OrganizationInfoSectionStore();
