import { AxiosResponse } from 'axios';
import { NaloxoneRecord } from '../../pages/data/naloxone/details/NaloxoneRecord';
import apiService from '../ApiService';
import UpdateNaloxoneRequest from '../../model/naloxone/UpdateNaloxoneRequest';
import { DataSubmissionType } from '../../model/dataSubmission/DataSubmissionType';

const fetchRecord = async (id: string | undefined): Promise<NaloxoneRecord> => {
  const record = await apiService.get<NaloxoneRecord>(`/naloxoneManagement/${id}`);
  return record.data;
};
const updateIanaRecord = (request: UpdateNaloxoneRequest, id: string): Promise<AxiosResponse> => apiService.patch<AxiosResponse>('/dataSubmission/iana/', { ...request, id });
const updateScnaRecord = (request: UpdateNaloxoneRequest, id: string): Promise<AxiosResponse> => apiService.patch<AxiosResponse>('/dataSubmission/scna/', { ...request, id });

const updateRecord = (request: UpdateNaloxoneRequest, id: string, submissionType: DataSubmissionType): Promise<AxiosResponse> => {
  switch (submissionType) {
    case DataSubmissionType.IANA:
      return updateIanaRecord(request, id);
    case DataSubmissionType.SCNA:
      return updateScnaRecord(request, id);
    default:
      throw new Error(`Invalid data submission, supports only naloxone records, ${submissionType}`);
  }
};

export const naloxoneService = {
  fetchRecord,
  updateRecord
};
