import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import Organization from '../../../../../../model/organization/Organization';
import apiService from '../../../../../../services/ApiService';
import { MedicationDosageUnit } from '../../../../../../helpers/enums/medicationDosageUnit';
import { DataSubmissionType } from '../../../../../../model/dataSubmission/DataSubmissionType';
import ZonedDateTime from '../../../../../../model/naloxone/DateTime/ZonedDateTime';
import RequiredZonedDate from '../../../../../../shared/validation/validators/RequiredZonedDate';
import GreaterThan from '../../../../../../shared/validation/validators/GreaterThan';
import Conditional from '../../../../../../shared/validation/validators/Conditional';
import NotDaysAheadZonedDateTime from '../../../../../../shared/validation/validators/NotDaysAheadZonedDateTime';
import FieldValidationContext from '../../../../../../shared/validation/FieldValidationContext';

export class IanaAdministerInformationSectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @validate(() => new NotEmpty())
  @observable
  public incidentAdministerFirstName = '';

  @validate(() => new NotEmpty())
  @observable
  public incidentAdministerLastName = '';

  @observable
  public incidentOrganizationName = '';

  @validate(() => new NotEmpty())
  @observable
  public submissionId = 0;

  @validate(() => new NotEmpty())
  @observable
  public submissionType: DataSubmissionType = 0;

  public set incidentMedicationAdministerId(value: string) {
    if (!value) {
      return;
    }
    const [first, second] = value.split('_', 2);
    this.incidentAdministerFirstName = first;
    this.incidentAdministerLastName = second;
  }
  public get incidentMedicationAdministerId(): string {
    if (!this.incidentAdministerFirstName && !this.incidentAdministerLastName) {
      return '';
    }
    return `${this.incidentAdministerFirstName} ${this.incidentAdministerLastName}`;
  }

  @observable
  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateIncidentNotifiedDate, new RequiredZonedDate('Notified date and time are required if provided.')),
    () => new Conditional(IanaAdministerInformationSectionStore.shouldValidateIncidentNotifiedDate, new NotDaysAheadZonedDateTime(1)))
  public incidentNotified: ZonedDateTime = ZonedDateTime.default();

  @observable
  @validate(() => new RequiredZonedDate('Medication administered date and time are required.'), () => new NotDaysAheadZonedDateTime(1))
  public medicationAdministered: ZonedDateTime = ZonedDateTime.default();

  @validate(() => new NotEmpty())
  @observable
  public incidentMedicationName = '';

  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateMedicationDosage, new NotEmpty()), () => new Conditional(IanaAdministerInformationSectionStore.shouldValidateMedicationDosage, new GreaterThan(0)))
  @observable
  public incidentMedicationDosage = '';

  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateMedicationDosage, new NotEmpty()))
  @observable
  public incidentMedicationDosageUnit: MedicationDosageUnit = 0;

  // ////////////
  // NON IANA FIELDS
  // ////////////
  public submitterType = 0;
  public firstResponder_FirstAndLastName = '';
  public firstResponder_Type = '';
  public incidentCounty = '';
  public incidentZipCode = '';
  // ////////////
  // ////////////

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }

  private static shouldValidateMedicationDosage(value: any, context: FieldValidationContext) {
    const { submissionType } = context.target as IanaAdministerInformationSectionStore;
    return submissionType !== DataSubmissionType.SCNA;
  }

  private static shouldValidateIncidentNotifiedDate(value: any, context: FieldValidationContext): boolean {
    const { submissionType } = context.target as IanaAdministerInformationSectionStore;
    if (submissionType === DataSubmissionType.SCNA) {
      return false;
    }
    const { date, time } = (context.target as IanaAdministerInformationSectionStore).incidentNotified;
    if (date === '' && time === '') {
      return false;
    }
    return true;
  }
}
