import { observer } from 'mobx-react';
import ValidatableObject from '../../../validation/ValidatableObject';
import { FormSelectOption } from '../FormSelect';
import { Role } from '../../../../model/role/RoleList';
import FormSelectorBase from '../FormSelectorBase';

@observer
export default class FormRoleSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, Role> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && Number(s.value) === storeRefValue.id) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return [];
  }

  protected prepareFetchedOptions(data: Role[]): FormSelectOption[] {
    return data.map<FormSelectOption>(role => ({
      value: role.id,
      label: role.name
    }));
  }
}
