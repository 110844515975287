import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';

import styles from './InfoSection.scss';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import BusyOverlay from '../../../../../../shared/layout/busyOverlay/BusyOverlay';
import { Summary } from '../Summary/Summary';
import { EditableSection } from '../../../EditableSection';
import { LicenseeInformationSection } from './_components/LicenseeInformationSection/LicenseeInformationSection';
import { LicenseeContext } from '../../../../LicenseeContext';
import { ConfigurationsSection } from './_components/ConfigurationsSection/ConfigurationsSection';
import { ManageUsersSection } from './_components/ManageUsersSection/ManageUsersSection';
import { ViewMode } from '../../../../ViewMode';
import { ManageOrganizationsSection } from './_components/ManageOrganizationsSection/ManageOrganizationsSection';

export const InfoSection: React.FC = () => {
  const store = useContext(LicenseeContext);
  const [mode] = useState<ViewMode>(ViewMode.preview);

  return (
    <div className={styles.infoSectionContainer}>
      <h1> License Overview </h1>
      <BusyOverlay isBusy={!store.loaded}>
        <FormSectionContainer headerText="License Information">
          <EditableSection
            key="licenseeInformation"
            isEditable={mode}
            actions={[
              <Button color="primary" block key="licenseeInformationUpdate">Update</Button>,
              <Button color="primary" block key="licenseeInformationDeactivate">Deactivate</Button>,
            ]}
            renderSection={<LicenseeInformationSection />}
          />
        </FormSectionContainer>
        <FormSectionContainer headerText="Configurations">
          <EditableSection
            key="configurations"
            isEditable={mode}
            actions={[
              <Button color="primary" block key="configurationsEdit">Edit</Button>,
            ]}
            renderSection={<ConfigurationsSection />}
          />
        </FormSectionContainer>
        <FormSectionContainer headerText="License Users">
          <EditableSection
            key="manageUsers"
            isEditable={mode}
            actions={[
              <Button color="primary" block key="licenseUsersEdit">License Users</Button>,
            ]}
            renderSection={<ManageUsersSection />}
          />
        </FormSectionContainer>
        <FormSectionContainer headerText="License Organizations">
          <EditableSection
            key="manageOrganizations"
            isEditable={mode}
            actions={[
              <Button color="primary" block key="licenseOrganizationsEdit">License Organizations</Button>,
            ]}
            renderSection={<ManageOrganizationsSection />}
          />
        </FormSectionContainer>
        <Summary />
      </BusyOverlay>
    </div>
  );
};
