export default class Ref<TObject, TKey extends keyof TObject, TValue> {
  private readonly _ref: HasTypedProperty<TObject, TKey, TValue>;
  private readonly _field: keyof TObject;

  public get ref() {
    return this._ref;
  }

  public get field() {
    return this._field;
  }

  public get value(): TValue {
    // COMMENT (tomek): I think I'm pissing typescript off, but not sure
    return this.ref[this.field] as any as TValue;
  }

  public set value(value: TValue) {
    this.ref[this.field] = value as any;
  }

  public constructor(ref: HasTypedProperty<TObject, TKey, TValue>, field: TKey) {
    this._ref = ref;
    this._field = field;
  }
}

export function makeRef<TObject,
  TKey extends keyof TObject,
  TValue>(obj: HasTypedProperty<TObject, TKey, TValue>, field: TKey): Ref<TObject, TKey, TValue> {
  return new Ref(obj, field);
}
