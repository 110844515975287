import { FormSelectOption } from '../shared/controls/formSelect/FormSelect';

export default class GetStaticOptionsHelper {
  public static buildOptions(valuesArray: any, labelsArray: {[index: number]: string}): FormSelectOption[] {
    return Object.keys(valuesArray).filter(key => !isNaN(Number(valuesArray[key]))).map((key, index) => ({
      value: (index + 1),
      label: labelsArray[valuesArray[key]]
    }));
  }
}
