import { FileProcessStatus } from './fileProcessStatus';

const PENDING = 'Pending';
const IN_PROGRESS = 'Uploading';
const SUCCESS = 'Complete';
const ERROR = 'Error';
const COMPLETED_WITH_ERRORS = 'Complete with errors';

export const FileProcessStatusDisplayName: { [index: number]: string } = [];
FileProcessStatusDisplayName[FileProcessStatus.Pending] = PENDING;
FileProcessStatusDisplayName[FileProcessStatus.InProgress] = IN_PROGRESS;
FileProcessStatusDisplayName[FileProcessStatus.Success] = SUCCESS;
FileProcessStatusDisplayName[FileProcessStatus.Error] = ERROR;
FileProcessStatusDisplayName[FileProcessStatus.CompletedWithErrors] = COMPLETED_WITH_ERRORS;
