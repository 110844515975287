import { observable } from 'mobx';
import NotEmpty from '../../../../shared/validation/validators/NotEmpty';
import PasswordStrength from '../../../../shared/validation/validators/PasswordStrength';
import MatchPassword from '../../../../shared/validation/validators/MatchPassword';
import { validate } from '../../../../shared/validation/helpers';
import { CreateUserPersonalInfoSectionStore } from '../../../../shared/user/create/personalInfoSection/CreateUserPersonalInfoSectionStore';

export class RegisterUserPersonalInfoSectionStore extends CreateUserPersonalInfoSectionStore {
  @validate(() => new NotEmpty(), () => new PasswordStrength())
  @observable
  public password = '';

  @validate(() => new NotEmpty(), () => new MatchPassword('password'))
  @observable
  public confirmPassword = '';

  public async validate<TKey extends keyof this>(field?: TKey): Promise<boolean> {
    if (field === 'password' && Boolean(this.confirmPassword)) {
      await super.validate('confirmPassword');
    }
    return super.validate(field);
  }
}
