import React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';
import FormInput from '../../../../shared/controls/formInput/FormInput';
import { NaloxoneManagementPageStore } from './NaloxoneManagementPageStore';
import { makeRef } from '../../../../helpers/Ref';
import routePaths from '../../../../constants/routePaths';
import { page } from '../../../../services/route/decorators';
import NaloxoneRecordsGrid from './naloxoneRecordsGrid/NaloxoneRecordsGrid';
import { NaloxoneRecordsGridStore } from './naloxoneRecordsGrid/NaloxoneRecordsGridStore';
import NaloxoneRecordForGrid from '../../../../model/grid/naloxone/NaloxoneRecordForGrid';
import { GridType } from '../../../../shared/grid/Grid';
import { OpenModal } from '../../../../shared/layout/modal/OpenModal';
import SubmissionRecordDeletionModal from '../../deletionModal/SubmissionRecordDeletionModal';
import { DynamoSearch } from '../../../../model/grid/dynamo/DynamoSearch';
import permissions from '../../../../model/user/permissions';
import { SearchForm } from '../../../../shared/layout/searchForm/SearchForm';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import apiService from '../../../../services/ApiService';
import FormDataTypeSelector from '../../../../shared/controls/formSelect/dataTypeSelector/FormDataTypeSelector';
import userStore from '../../../../data/UserStore';
import OrganizationFormSelector from '../../../management/users/organizationSelector/OrganizationFormSelector';
import Organization from '../../../../model/organization/Organization';
import NotificationToast from '../../../../shared/layout/notificationToast/NotificationToast';

interface NaloxoneManagementPageState {
  openModal: OpenModal;
  shouldFetchOrganizations: boolean;
  id?: string;
}
@page({
  path: routePaths.data.naloxone.manage,
  hasPermission: permissions.data.naloxoneList
})
@observer
export class NaloxoneManagementPage extends React.Component<{}, NaloxoneManagementPageState> {
  private _store: NaloxoneManagementPageStore = new NaloxoneManagementPageStore();
  private _gridStore: NaloxoneRecordsGridStore<NaloxoneRecordForGrid> = new NaloxoneRecordsGridStore();

  constructor(props: {}) {
    super(props);
    this.state = {
      openModal: OpenModal.None,
      shouldFetchOrganizations: false,
      id: ''
    };
  }

  public render() {
    const isLastNameRequired = userStore.session?.role?.isApprissAdmin ? false : !userStore.session?.role?.hasPermission(permissions.license.overview);

    return (
      <Container>
        <div className="mt-5 mb-5">
          <h1>Manage Opioid Antidote Records</h1>
        </div>
        <SearchForm searchFn={e => this.search(e)} clearFn={e => this.clear(e)} disabled={this.searchDisabled()}>
          <Col xs={12}>
            <Row>
              <Col md={3}>
                <FormDataTypeSelector
                  id="dataTypeSearch"
                  storeRef={makeRef(this._store, 'dataSubmissionType')}
                  labelText="Select a Data Type"
                  onValueChangedFunc={(dataType: number) => this.onDataTypeChanged(dataType)}
                  fetchOptionsFunc={() => this.fetchDataTypes()}
                  invalid={!this._store.isValid('dataSubmissionType')}
                  shouldFetchOptions
                  required
                />
              </Col>
              <Col md={3}>
                <OrganizationFormSelector
                  id="organization"
                  storeRef={makeRef(this._store, 'organizationId')}
                  labelText="Select an Organization"
                  onValueChangedFunc={(organizationId: number) => this.handleOrganizationChange(organizationId)}
                  fetchOptionsFunc={() => this.fetchOrganizations()}
                  invalid={!this._store.isValid('organizationId')}
                  shouldFetchOptions={this.state.shouldFetchOrganizations}
                  addAllOption
                />
              </Col>
              <Col md={3}>
                <FormInput
                  id="fromDate"
                  labelText="Start Date"
                  type="date"
                  storeRef={makeRef(this._store, 'fromDate')}
                  required={!!this._store.toDate}
                />
              </Col>
              <Col md={3}>
                <FormInput
                  id="fromDate"
                  labelText="End Date"
                  type="date"
                  storeRef={makeRef(this._store, 'toDate')}
                  required={!!this._store.fromDate}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              id="searchFirstName"
              labelText="First Name"
              storeRef={makeRef(this._store, 'firstName')}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              id="searchLastName"
              labelText="Last Name"
              storeRef={makeRef(this._store, 'lastName')}
              required={isLastNameRequired}
            />
          </Col>
          <Col xs={12} md={3}>
            <FormInput
              id="searchDateOfBirth"
              labelText="Date of Birth"
              type="date"
              storeRef={makeRef(this._store, 'dateOfBirth')}
            />
          </Col>
        </SearchForm>
        <Row>
          <Col>
            <NaloxoneRecordsGrid
              store={this._gridStore}
              gridType={GridType.Dynamo}
              openSubmissionDeletionModalFunc={(id: string) => this.openModal(OpenModal.SubmissionDelete, id)}
            />
          </Col>
        </Row>
        {this.state.openModal === OpenModal.SubmissionDelete && (
          <SubmissionRecordDeletionModal
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.state.id || ''}
            onSubmitFunc={() => this._gridStore.dynamoDelete(this.buildSearch())}
          />
        )}
      </Container>
    );
  }

  private async handleOrganizationChange(organizationId: number): Promise<void> {
    this._store.organizationId = organizationId;
  }

  private async fetchOrganizations(): Promise<Organization[]> {
    if (this._store.dataSubmissionType === 0) {
      return [];
    }

    try {
      const response = await apiService.get<Organization[]>(`/organization/listActive?submissionType=${this._store.dataSubmissionType}`);
      this.setState({ shouldFetchOrganizations: false });
      return response.data;
    } catch (e) {
      NotificationToast.showNetworkError();
      this.setState({ shouldFetchOrganizations: false });
      return [];
    }
  }

  private searchDisabled(): boolean {
    if (userStore.session?.role?.hasPermission(permissions.license.overview) || userStore.session?.role?.isApprissAdmin) {
      return this._store.dataSubmissionType === 0
        || (this._store.fromDate === '' && this._store.toDate !== '')
        || (this._store.fromDate !== '' && this._store.toDate === '')
        || this.datesNotBeforeOrAfter();
    }

    return this._store.dataSubmissionType === 0
      || !this._store.lastName
      || (this._store.fromDate === '' && this._store.toDate !== '')
      || (this._store.fromDate !== '' && this._store.toDate === '')
      || this.datesNotBeforeOrAfter();
  }

  private datesNotBeforeOrAfter(): boolean {
    const fromDate = new Date(this._store.fromDate);
    const toDate = new Date(this._store.toDate);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const dateDiff = fromDate - toDate;

    return dateDiff > 0;
  }

  private async fetchDataTypes(): Promise<DataSubmissionType[]> {
    try {
      const response = await apiService.get('naloxoneManagement/dataTypes');
      return response.data;
    } catch (e) {
      return [];
    }
  }

  private onDataTypeChanged(submissionType: DataSubmissionType): void {
    this._store.dataSubmissionType = submissionType;
    this._store.resetValidationState('dataSubmissionType');
    this.setState({ shouldFetchOrganizations: true });
  }

  private async search(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    await this._gridStore.dynamoSearch(
      this.buildSearch(),
      this._store.dataSubmissionType,
      this._store.organizationId,
      this._store.fromDate,
      this._store.toDate
    );
  }

  public clear(e: React.MouseEvent<HTMLButtonElement>) {
    this._store.firstName = '';
    this._store.lastName = '';
    this._store.dateOfBirth = '';
    this._store.fromDate = '';
    this._store.toDate = '';
    this._store.organizationId = 0;
    this._store.resetValidationState();
  }

  private buildSearch(): DynamoSearch {
    return {
      firstName: this._store.firstName.trim(),
      lastName: this._store.lastName.trim(),
      dateOfBirth: this._store.dateOfBirth.trim()
    };
  }

  private openModal(modalToOpen: OpenModal, id?: string) {
    this.setState({ openModal: modalToOpen, id });
  }

  private closeModal(): void {
    this.setState({ openModal: OpenModal.None });
  }
}
