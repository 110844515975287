import { observer } from 'mobx-react';
import { Column, SortingRule } from 'react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import Grid, { GridProps } from '../../../../shared/grid/Grid';
import {
  RejectedOrganizationForGrid
} from '../../../../model/grid/organization/OrganizationForGrid';
import { formatUsDateTime } from '../../../../helpers/displayHelper';
import Icon from '../../../../shared/controls/icons/Icon';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';
import userStore from '../../../../data/UserStore';

interface RejectedOrganizationsGridProps extends GridProps<RejectedOrganizationForGrid, string> {
  openOrgApprovalModalFunc: (organizationId: number) => void;
  openOrgDeleteModalFunc: (organizationId: number) => void;
}
@observer
export default class RejectedOrganizationsGrid
  extends Grid<RejectedOrganizationsGridProps, RejectedOrganizationForGrid, string> {
  protected defineColumns(): Array<Column<RejectedOrganizationForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        id: 'name',
        Header: 'Organization',
        accessor: d => d.name,
        Cell: ({ row }) => <Link to={{ pathname: `/organization/${row.id}/details` }}>{row.name}</Link>
      },
      {
        id: 'address.city', Header: 'City', accessor: d => d.address.city, show: true
      },
      {
        id: 'address.state', Header: 'State', accessor: d => StateDisplayName[d.address.state], show: !!userStore.session?.role?.isApprissAdmin
      },
      {
        id: 'email', Header: 'Email', accessor: d => d.email, show: true
      },
      {
        id: 'deactivation_reason', Header: 'Deactivation Reason', accessor: d => d.deactivationReason, show: true
      },
      {
        id: 'registered_on', Header: 'Registered On', accessor: d => formatUsDateTime(d.createdOn), show: true
      },
      {
        Header: 'Actions',
        width: 100,
        sortable: false,
        Cell: ({ row }) => (
          <>
            <button className="drop-styles" type="button" onClick={() => this.props.openOrgApprovalModalFunc(row.id)}>
              <Icon name="approve" size="4rem" className="actionButton" />
            </button>
            <button className="drop-styles" type="button" onClick={() => this.props.openOrgDeleteModalFunc(row.id)}>
              <Icon name="delete" size="4rem" className="actionButton" />
            </button>
          </>
        )
      }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No rejected organizations';
  }
}
