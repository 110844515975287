import Validator, { ValidationResult } from './Validator';

type SupportedTypes = any;

export default class NotEmpty implements Validator<SupportedTypes> {
  private readonly _message: string;

  public constructor(message = 'Field is required') {
    this._message = message;
  }

  public validate(value: SupportedTypes): Promise<ValidationResult> {
    if (Boolean(value) || value === false) {
      return Promise.resolve({ isValid: true });
    }
    return Promise.resolve({ isValid: false, message: this._message });
  }
}
