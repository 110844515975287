export enum Gender {
  Male = 1,
  Female,
  NonBinary
}

const Male = 'Male';
const Female = 'Female';
const NonBinary = 'NonBinary';

export const GenderDisplayName: { [index: number]: string } = [];
GenderDisplayName[0] = 'Not specified';
GenderDisplayName[Gender.Male] = Male;
GenderDisplayName[Gender.Female] = Female;
GenderDisplayName[Gender.NonBinary] = NonBinary;

export const GenderSymbol: { [index: number]: string } = [];
GenderSymbol[Gender.Male] = 'M';
GenderSymbol[Gender.Female] = 'F';
GenderSymbol[Gender.NonBinary] = 'NB';
