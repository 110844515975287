import { DataSubmissionType } from '../model/dataSubmission/DataSubmissionType';

export const SupportedFileExtensions: { [submissionType in DataSubmissionType]: string[]} = {
  [DataSubmissionType.AZMM]: ['txt'],
  [DataSubmissionType.NDMM]: ['csv', 'tsv'],
  [DataSubmissionType.IANA]: ['tsv', 'xls', 'xlsx'],
  [DataSubmissionType.OHDC]: ['csv', 'xls', 'xlsx'],
  [DataSubmissionType.SCNA]: ['xlsx', 'xls', 'csv', 'tsv'],
  [DataSubmissionType.OHOTP]: ['csv']
};
