import * as React from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './ForgotPasswordPage.scss';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import FormSectionContainer from '../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../helpers/Ref';
import forgotPasswordStore, { ForgotPasswordStore } from './ForgotPasswordStore';
import ButtonWithSpinner from '../../../shared/controls/buttons/ButtonWithSpinner';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import Icon from '../../../shared/controls/icons/Icon';
import { combineClassNames } from '../../../helpers/reactHelpers';

@page({
  path: routePaths.user.forgotPassword,
  needsAuthentication: false
})
@observer
export default class ForgotPasswordPage extends React.Component<{}, {}> {
  private _store: ForgotPasswordStore = forgotPasswordStore;

  public render(): React.ReactNode {
    return (
      <FormContainer className={combineClassNames(...this.getClassNames())}>
        {this.getContent()}
      </FormContainer>
    );
  }

  private *getClassNames(): Iterable<string> {
    yield styles.forgotPasswordPage;
    if (this._store.success) {
      yield 'success';
    }
  }

  private getContent(): React.ReactNode {
    if (this._store.success) {
      return (
        <div>
          <Icon name="success" size="5rem" />
          <h1>Email sent</h1>
          <p>If the account exists, you should receive an email. Please check your inbox and follow instructions from the email</p>
          <Link to={routePaths.general.root}>Back to login page</Link>
        </div>
      );
    }

    return (
      <Form>
        <FormSectionContainer>
          <p>
            Please enter the email address or phone number associated with your account.
            We&apos;ll send you a verification code to set up new password.
          </p>
          <FormInput storeRef={makeRef(this._store, 'email')} id="email" labelText="Email address" />
        </FormSectionContainer>
        <Row>
          <Col lg={3} md={4} sm={6}>
            <ButtonWithSpinner
              id="submit"
              type="submit"
              color="primary"
              buttonText="Continue"
              isBusy={this._store.isBusy}
              onClick={(e: React.MouseEvent) => this.onSubmitClick(e)}
              block
            />
          </Col>
          <Col lg={3} md={4} sm={6}>
            <Link to={routePaths.general.root} className="btn btn-link btn-block">Back to login page</Link>
          </Col>
        </Row>
      </Form>
    );
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private async onSubmitClick(e: React.MouseEvent): Promise<void> {
    e.preventDefault();
    if (await this._store.validate()) {
      await this._store.resetPassword();
    }
  }
}
