import * as React from 'react';
import styles from './InlineEntry.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

interface InlineEntryProps {
  labelText?: string;
  value: any;
  className?: string;
  id: string;
}

export default class InlineEntry extends React.Component<InlineEntryProps> {
  public render(): React.ReactNode {
    const label = this.props.labelText
      ? (
        <>
          <h5>{`${this.props.labelText}:`}</h5>{' '}
        </>
      )
      : '';

    return (
      <div id={this.props.id} className={combineClassNames(styles.inlineEntry, this.props.className)}>
        {label}<span>{this.props.value}</span>
      </div>
    );
  }
}
