import React, { useContext } from 'react';

import InlineEntry from '../../../../../../../../shared/layout/inlineEntry/InlineEntry';
import { LicenseeContext } from '../../../../../../LicenseeContext';

export const ManageUsersSection: React.FC = () => {
  const context = useContext(LicenseeContext);
  if (!context.loaded) {
    return null;
  }
  const { manageUsers: { awaitingApproval, activeUsers, inactiveUsers } } = context;
  return (
    <>
      <InlineEntry id="awaitingApproval" labelText="Awaiting Approval" value={awaitingApproval} />
      <InlineEntry id="activeUsers" labelText="Active" value={activeUsers} />
      <InlineEntry id="inactiveUsers" labelText="Inactive" value={inactiveUsers} />
    </>
  );
};
