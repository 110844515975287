import ReactTable, { Column, SortingRule, TableProps } from 'react-table';
import { Spinner } from 'reactstrap';
import { Omit } from 'react-router';
import React from 'react';
import styles from './Grid.scss';
import GridPagination, { GridPaginationProps } from './pagination/Pagination';
import GridStore from './GridStore';
import LegacyDynamoGridPagination from './dynamoPagination/LegacyDynamoPagination';

type InnerProps<TRowModel> = Omit<Partial<TableProps<TRowModel>>, 'page' | 'pageSize' | 'data' | 'PaginationComponent' |
  'loading' | 'onFetchData' | 'multiSort' | 'defaultSorted' | 'loadingText' | 'columns' | 'manual'>;

export type GridProps<TRowModel, TSearch> = InnerProps<TRowModel> & {
  store: GridStore<TRowModel, TSearch>;
  gridType?: GridType;
};

export default abstract class Grid<TGridProps extends GridProps<TRowModel, TSearch>, TRowModel, TSearch>
  extends React.Component<TGridProps> {
  public render(): React.ReactNode {
    const { store, ...innerProps } = this.props;
    const {
      page, pageSize, pageSizes, isLoading, rows
    } = store;

    const sortingRule = this.setDefaultSortedColumn();
    const defaultSortingRule = sortingRule ? [sortingRule] : [];

    return (
      <div className={styles.gridContent}>
        <ReactTable
          manual
          data={rows}
          onFetchData={state => store.fetchData(state)}
          loading={isLoading}
          className="-striped -highlight"
          PaginationComponent={this.getPaginationComponent()}
          columns={this.defineColumns()}
          page={page}
          minRows={pageSizes[0]}
          pageSize={pageSize}
          defaultSorted={defaultSortingRule}
          multiSort={false}
          loadingText={<Spinner color="primary" />}
          noDataText={this.setNoDataText()}
          {...innerProps}
        />
      </div>
    );
  }

  protected abstract defineColumns(): Array<Column<TRowModel>>;

  protected setDefaultSortedColumn(): SortingRule | undefined {
    return undefined;
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No records found';
  }

  protected getPaginationComponent(): React.ElementType {
    const paginationProps: GridPaginationProps = {
      page: this.props.store.page,
      pageSize: this.props.store.pageSize,
      pageSizes: this.props.store.pageSizes,
      totalCount: this.props.store.totalCount,
      onPageSizeChangeHandler: newPageSize => this.props.store.onPageSizeChangedHandler(newPageSize),
      onPageChangeHandler: newPage => this.props.store.onPageChangedHandler(newPage)
    };
    // TODO
    // Rewrite this so it looks like: return () => <GridPagination {...paginationProps} />;
    // Overrides of behaviors should be provided by class/method overrides, not here
    // Needs to stay to because of dependency on DataSubmissionErrorsGrid
    return () => (this.props.gridType === GridType.Dynamo ? <LegacyDynamoGridPagination {...paginationProps} /> : <GridPagination {...paginationProps} />);
  }
}

export enum GridType {
  Sql,
  Dynamo
}
