import { reaction } from 'mobx';
import { AdminCreateUserPersonalInfoSectionStore } from './personalInfoSection/AdminCreateUserPersonalInfoSectionStore';
import CreateUserStore from '../../../shared/user/create/CreateUserStore';
import apiService from '../../../services/ApiService';
import CreateUserRequest, { UserForCreateUser } from '../../../model/account/create/CreateUserRequest';
import { AdminCreateUserOrganizationSectionStore } from './organizationSection/AdminCreateUserOrganizationSectionStore';

export class AdminCreateUserStore
  extends CreateUserStore<AdminCreateUserPersonalInfoSectionStore, AdminCreateUserOrganizationSectionStore> {
  constructor(personalInfoStore: AdminCreateUserPersonalInfoSectionStore,
    organizationStore: AdminCreateUserOrganizationSectionStore) {
    super(personalInfoStore, organizationStore);

    reaction(
      () => this.personalInfoStore.selectedUserId,
      id => { this.organizationStore.existingUserId = id; }
    );
  }

  public async createAccount(): Promise<void> {
    const request = this.prepareRequest();
    await apiService.post('/user/create', request);
  }

  protected getUser(): UserForCreateUser {
    const user = super.getUser();
    user.id = this.personalInfoStore.selectedUserId;

    if (user.id > 0) {
      delete user.firstName;
      delete user.lastName;
      delete user.email;
      delete user.phone;
      delete user.password;
    }

    return user;
  }

  private prepareRequest(): CreateUserRequest {
    const user = this.getUser();
    const organization = this.getOrganization();

    return {
      user,
      organization
    };
  }
}
