import React from 'react';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import ScnaPreviewPatientInformationSection from './ScnaPatientInformationSection/ScnaPreviewPatientInformationSection';
import IanaPreviewPatientInformationSection from './IanaPatientInformationSection/IanaPreviewPatientInformationSection';

export interface PreviewPatientSectionProps {
  submissionType: DataSubmissionType;
  onUpdateButtonHandler: (e: React.MouseEvent<any>) => void;
}

export default class PreviewPatientInformationSection extends React.Component<PreviewPatientSectionProps> {
  public constructor(props: any) {
    super(props);
  }

  public render() {
    switch (this.props.submissionType) {
      case DataSubmissionType.IANA:
        return (
          <IanaPreviewPatientInformationSection
            onUpdateButtonHandler={this.props.onUpdateButtonHandler}
          />
        );
        break;
      case DataSubmissionType.SCNA:
        return (
          <ScnaPreviewPatientInformationSection
            onUpdateButtonHandler={this.props.onUpdateButtonHandler}
          />
        );
        break;
      default:
        return <></>;
    }
  }
}
