import OrganizationForGrid from '../../../model/grid/organization/OrganizationForGrid';
import GridStore from '../../../shared/grid/GridStore';
import GridPageRequest from '../../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../../model/grid/GridPageResponse';
import apiService from '../../../services/ApiService';
import { OrganizationsGridPageRequest } from '../../../model/grid/organization/OrganizationsGridPageRequestAggregate';
import { OrganizationStatus } from '../../../model/organization/OrganizationStatus';

export abstract class OrganizationsGridStore<TRowModel extends OrganizationForGrid> extends GridStore<TRowModel, string> {
  protected get shouldFetchOnMount(): boolean {
    return false;
  }

  protected get shouldDebounceFetch(): boolean {
    return false;
  }

  protected get shouldFetchData(): boolean {
    return true;
  }

  protected async apiCall(gridPageRequest: GridPageRequest<string>): Promise<GridPageResponse<TRowModel>> {
    try {
      const params = { gridPageRequest };
      const gridData = await apiService.get<GridPageResponse<TRowModel>>('organization/listForGrid', { params });
      return gridData.data;
    } catch {
      return {
        rows: [],
        totalCount: 0
      };
    }
  }

  protected abstract get organizationStatus(): OrganizationStatus;

  public getGridPageRequest(): OrganizationsGridPageRequest {
    return {
      ...super.getGridPageRequest(),
      organizationStatus: this.organizationStatus
    };
  }
}
