import { Column, SortingRule } from 'react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatUsDateTime } from '../../../../helpers/displayHelper';
import { AwaitingApprovalOrganizationForGrid } from '../../../../model/grid/organization/OrganizationForGrid';
import Icon from '../../../../shared/controls/icons/Icon';
import styles from './AwaitingApprovalOrganizationsGrid.scss';
import Grid, { GridProps } from '../../../../shared/grid/Grid';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';
import userStore from '../../../../data/UserStore';

interface AwaitingApprovalOrganizationsGridProps extends GridProps<AwaitingApprovalOrganizationForGrid, string> {
  openOrgApprovalModalFunc: (organizationId: number) => void;
  openOrgRejectModalFunc: (organizationId: number) => void;
}

@observer
export default class AwaitingApprovalOrganizationsGrid
  extends Grid<AwaitingApprovalOrganizationsGridProps, AwaitingApprovalOrganizationForGrid, string> {
  public render(): React.ReactNode {
    return (
      <div className={styles.awaitingApprovalOrganizationsGrid}>
        {super.render()}
      </div>
    );
  }

  protected defineColumns(): Array<Column<AwaitingApprovalOrganizationForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        Header: 'Name',
        id: 'name',
        accessor: d => d.name,
        Cell: ({ row }) => <Link to={{ pathname: `/organization/${row.id}/details` }}>{row.name}</Link>
      },
      { Header: 'City', id: 'address.city', accessor: d => d.address?.city },
      {
        id: 'address.state', Header: 'State', accessor: d => StateDisplayName[d.address.state], show: !!userStore.session?.role?.isApprissAdmin
      },
      { Header: 'Email', id: 'email', accessor: d => d.email },
      { Header: 'Registered On', id: 'createdOn', accessor: d => formatUsDateTime(d.createdOn) },
      {
        Header: 'Actions',
        width: 100,
        sortable: false,
        Cell: ({ row }) => (
          <>
            <button className="drop-styles" type="button" onClick={() => this.props.openOrgApprovalModalFunc(row.id)}>
              <Icon name="approve" size="4rem" className="actionButton" />
            </button>
            <button className="drop-styles" type="button" onClick={() => this.props.openOrgRejectModalFunc(row.id)}>
              <Icon name="reject" size="4rem" className="actionButton" />
            </button>
          </>
        )
      }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No Organizations awaiting approval';
  }
}
