import * as React from 'react';
import * as DOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './styles/index.scss';
import App from './App';

const app = (
  <HashRouter>
    <App />
  </HashRouter>
);

const main = document.createElement('div');
main.id = 'main';
document.body.appendChild(main);
DOM.render(app, main);
