import { observable } from 'mobx';
import EditProfileStore from '../../../shared/user/profile/editProfile/EditProfileStore';
import apiService from '../../../services/ApiService';
import userStore from '../../../data/UserStore';
import permissions from '../../../model/user/permissions';

export class EditDetailsUserNameStore extends EditProfileStore {
  @observable
  public userId = '';

  public async updateProfile(): Promise<void> {
    this._isBusy = true;

    if (userStore.session?.role?.hasPermission(permissions.user.adminUserAssignRole)) {
      try {
        await apiService.post(`/user/${this.userId}/updateNameAndRole`, {
          firstName: this.firstName,
          lastName: this.lastName,
          role: this.role
        });
      } finally {
        this._isBusy = false;
      }
    } else {
      try {
        await apiService.post(`/user/${this.userId}/updateName`, {
          firstName: this.firstName,
          lastName: this.lastName
        });
      } finally {
        this._isBusy = false;
      }
    }
  }
}
