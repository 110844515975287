import * as React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Row
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from './ManagementTabbedPage.scss';

export interface ComponentForTabs {
  component: React.ComponentClass<any> | React.StatelessComponent<any>;
  tabName: string;
  path: string;
}

export interface ManagementTabbedPageProps extends RouteComponentProps<any> {
  headerName: string;
  componentsForTabs: ComponentForTabs[];
}

export interface TabbedPageState {
  loadedTabPaths: string[];
  currentUrl: string;
}

class ManagementTabbedPage extends React.Component<ManagementTabbedPageProps, TabbedPageState> {
  constructor(props: any) {
    super(props);

    const currentUrl: string = this.props.location.pathname;
    this.state = {
      currentUrl,
      loadedTabPaths: [currentUrl]
    };
  }

  public render(): React.ReactNode {
    return (
      <div className={`${styles.manageTabbedPage} container pl-0 pr-0`}>
        <div className="row pb-3 ml-0 mr-0">
          <h2>{this.props.headerName}</h2>
        </div>
        <Nav tabs className="row ml-0">
          {this.props.componentsForTabs.map(component => (
            <NavItem key={component.tabName}>
              <NavLink
                className={`${this.isTabActive(component.path) ? 'active' : ''}`}
                onClick={() => this.toggle(component.path)}
              >
                {component.tabName}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.currentUrl} className="pt-4">
          {this.props.componentsForTabs.map(component => {
            const Component = component.component; // tslint:disable-line:variable-name
            return (
              <TabPane tabId={component.path} key={component.tabName}>
                <Row>
                  {this.isTabLoaded(component.path) && <Component /> }
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    );
  }

  private toggle(path: string) {
    this.setState(prevState => ({
      currentUrl: path,
      loadedTabPaths: prevState.loadedTabPaths.indexOf(path) === -1
        ? [...prevState.loadedTabPaths, path]
        : [...prevState.loadedTabPaths]
    }));

    this.props.history.push(path);
  }
  private isTabActive(path: string) {
    return this.state.currentUrl === path;
  }

  private isTabLoaded(path: string) {
    return this.state.loadedTabPaths.indexOf(path) >= 0;
  }
}

export default withRouter(ManagementTabbedPage);
