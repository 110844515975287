import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { PreviewSectionProps } from '../../PreviewSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';
import { SubmitterType, SubmitterTypeDisplayName } from '../../../../../../helpers/enums/submitterType';

export default class ScnaPreviewAdministerInformationSection extends React.Component<PreviewSectionProps> {
  public constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Incident Information">
              <Row>
                <Col sm={9} xs={12}>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Submission Id" value={context.administerInformationSectionStore!.submissionId} id="submissionId" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Submitter Type" value={SubmitterTypeDisplayName[context.administerInformationSectionStore!.submitterType]} id="submitterType" />
                    </Col>
                  </Row>
                  {context.administerInformationSectionStore!.submitterType === SubmitterType.FirstResponder && (
                    <>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="First Responder Type" value={context.administerInformationSectionStore!.firstResponder_Type} id="firstResponderType" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="First Responder Name" value={context.administerInformationSectionStore!.firstResponder_FirstAndLastName} id="firstResponderName" />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */ }
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Administered Date" value={context.administerInformationSectionStore!.medicationAdministeredForPreview} id="incidentMedicationAdministeredDate" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Incident County" value={context.administerInformationSectionStore!.incidentCounty} id="incidentCounty" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Incident Zip Code" value={context.administerInformationSectionStore!.incidentZipCode} id="incidentZipCode" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onUpdateButtonHandler(e)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
