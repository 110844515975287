import axios from 'axios';
import { getBaseApiUrl } from './baseUrl';
import userStore, { UserStoreState } from '../data/UserStore';
import routePaths from '../constants/routePaths';

const apiService = axios.create({
  baseURL: getBaseApiUrl(),
  headers: {
    'api-version': '1.0'
  },
  withCredentials: true
});

apiService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 404) {
        window.location.href = `/#${routePaths.error.notFound}`;
        window.location.reload();
      }

      if (error.response.status === 403) {
        userStore.logout();
      }

      if (error.response.status === 400) {
        window.location.href = `/#${routePaths.error.badRequest}`;
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

apiService.interceptors.request.use(async config => {
  if (config.url && config.url.startsWith('authentication')) {
    return config;
  }

  if (userStore.loadingState === UserStoreState.NotInitialized || userStore.loadingState === UserStoreState.Initializing) {
    await userStore.initialize();
  }

  const { accessToken } = userStore;
  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  return config;
});

export default apiService;
