import * as React from 'react';
import {
  CustomInput, FormFeedback, FormGroup, Label
} from 'reactstrap';
import { ValidatableInputProps } from '../SharedProps';
import styles from './SelectableInputGroup.scss';
import Icon from '../icons/Icon';

export interface SelectableGroupInputProps extends ValidatableInputProps {
  groupDisplayName?: string;
  items: SelectableInputProps[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface SelectableInputProps extends ValidatableInputProps {
  displayName: string;
  propertyName: string;
  checked: boolean;
}

export abstract class SelectableInputGroup<P extends SelectableGroupInputProps> extends React.Component<P, {}> {
  public render(): React.ReactNode {
    const labelNode = this.props.groupDisplayName
      ? <Label>{this.props.groupDisplayName}</Label>
      : null;
    const feedbackVisibleProp = !this.props.isValid ? { display: 'block' } : {};

    return (
      <FormGroup tag="fieldset" className={styles.selectableInputGroup}>
        {labelNode}
        {this.props.items.map((item, index) => this.getInput(item, index))}
        {this.props.children}
        <FormFeedback style={feedbackVisibleProp}>
          <Icon name="error" size="1.2rem" /><span> {this.props.errorMessage}</span>
        </FormFeedback>
      </FormGroup>
    );
  }

  protected abstract getInput(item: SelectableInputProps, index: number): React.ReactNode;
}

export interface CheckboxProps extends SelectableInputProps {
  checkboxValue: any;
}

export interface CheckboxGroupProps extends SelectableGroupInputProps {
  items: CheckboxProps[];
}

export class CheckboxGroup extends SelectableInputGroup<CheckboxGroupProps> {
  protected getInput(item: CheckboxProps, index: number): React.ReactNode {
    return (
      <CustomInput
        key={index}
        id={item.id}
        name={item.propertyName}
        type="checkbox"
        label={item.displayName}
        checked={item.checked}
        value={item.checkboxValue}
        onChange={event => this.props.onChange(event)}
        invalid={!this.props.isValid}
      />
    );
  }
}

export interface RadioButtonProps extends SelectableInputProps {
  radioButtonValue: any;
}

export interface RadioButtonGroupProps extends SelectableGroupInputProps {
  items: RadioButtonProps[];
}

export class RadioButtonGroup extends SelectableInputGroup<RadioButtonGroupProps> {
  protected getInput(item: RadioButtonProps, index: number): React.ReactNode {
    return (
      <CustomInput
        key={index}
        type="radio"
        id={item.id}
        name={item.propertyName}
        label={item.displayName}
        checked={item.checked}
        value={item.radioButtonValue}
        onChange={event => this.props.onChange(event)}
        invalid={!this.props.isValid}
      />
    );
  }
}
