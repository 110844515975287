import { CreateUserOrganizationSectionStore } from '../../../../shared/user/create/organizationSection/CreateUserOrganizationSectionStore';

export class AdminCreateUserOrganizationSectionStore extends CreateUserOrganizationSectionStore {
  public existingUserId = 0;

  protected getFetchOrganizationParams() {
    let params = super.getFetchOrganizationParams();
    if (this.existingUserId > 0) {
      params = {
        ...params,
        existingUserId: this.existingUserId
      };
    }

    return params;
  }
}
