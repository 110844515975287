import { action, computed, observable } from 'mobx';
import apiService from '../../../services/ApiService';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import { OrganizationDetails } from '../../../model/organization/Organization';
import { OrganizationAdmin } from '../../../model/organization/admin/OrganizationAdmin';

export class OrganizationDetailsStore extends ValidatableObject {
  public organizationId = 0;

  @observable
  public orgDetails?: OrganizationDetails = undefined;

  @observable
  public addOrgAdminDetails: OrganizationAdmin = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  };

  @observable
  private _isGetOrgDetailsBusy = true;
  @computed
  public get isGetOrgDetailsBusy() {
    return this._isGetOrgDetailsBusy;
  }

  @observable
  private _error = '';
  @computed
  public get error() {
    return this._error;
  }
  // @observable
  // @notEmpty()
  // public login: string = '';
  //
  // @observable
  // @notEmpty()
  // public password: string = '';
  //
  // @observable
  // private _isBusy: boolean = false;
  // @computed
  // public get isBusy() {
  //   return this._isBusy;
  // }
  //
  // @observable
  // private _error: string = '';
  // @computed
  // public get error() {
  //   return this._error;
  // }
  //
  // @observable
  // private _loginSuccessful: boolean = false;
  // @computed
  // public get loginSuccessful() {
  //   return this._loginSuccessful;
  // }

  @action
  public async getOrganizationDetails() {
    this._isGetOrgDetailsBusy = true;

    try {
      const response = await apiService.get<OrganizationDetails>(`organization/${this.organizationId}`);
      this.orgDetails = response.data;
    } finally {
      this._isGetOrgDetailsBusy = false;
    }
  }

  // @action
  // public async doLogin() {
  //   this._isBusy = true;
  //   if (!this.validate()) {
  //     return;
  //   }
  //
  //   try {
  //     let response = null;
  //     try {
  //       response = await apiService
  //         .get('/user/signin', {
  //           params: {
  //             username: this.login,
  //             password: this.password
  //           }
  //         });
  //     } catch (e) {
  //       if (e.response) {
  //         switch ((e.response.data as AuthenticationResult).resultCode) {
  //           case AuthenticationResultCode.Authorized:
  //             throw new Error('Error in response but authorized? Something\'s wrong');
  //           case AuthenticationResultCode.InvalidCredentials:
  //             this._error = 'Invalid login or password';
  //             break;
  //           case AuthenticationResultCode.PasswordResetRequired:
  //             // TODO
  //             break;
  //         }
  //       } else if (e.request instanceof XMLHttpRequest) {
  //         if (e.request.status === 0) {
  //           this._error = 'Connection to server lost. Check your internet connection.';
  //         } else {
  //           this._error = 'Unknown error while making the request';
  //         }
  //       } else {
  //         this._error = 'Unknown error while making the request';
  //       }
  //     }
  //
  //     if (response && response.data) {
  //       if ((response.data as AuthenticationResult).resultCode === AuthenticationResultCode.Authorized) {
  //         this._error = '';
  //         this._loginSuccessful = true;
  //       } else {
  //         throw new Error('Inconsistent server response');
  //       }
  //     }
  //   } finally {
  //     this._isBusy = false;
  //   }
  //
  // }
}

export default new OrganizationDetailsStore();
