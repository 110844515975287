/* eslint-disable indent */
import React, { createContext } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { Form } from 'reactstrap';
import { page } from '../../../../services/route/decorators';
import routePaths from '../../../../constants/routePaths';
import { WithIdProps } from '../../../../shared/props/WithIdProps';
import BusyOverlay from '../../../../shared/layout/busyOverlay/BusyOverlay';
import { FormContainer } from '../../../../shared/layout/formContainer/FormContainer';
import { editPatientContactInformationSection } from './contactInformationSection/EditPatientContactInformation';
import { EditPatientInformationSection } from './patientInformationSection/EditPatientInformationSection';
import { EditAdministerInformationSection } from './administerInformationSection/EditAdministerInformationSection';
import PreviewAdministerInformationSection from './administerInformationSection/PreviewAdministerInformationSection';
import PreviewPatientContactInformation from './contactInformationSection/PreviewPatientContactInformation';
import PreviewPatientInformationSection from './patientInformationSection/PreviewPatientInformationSection';
import notificationToast from '../../../../shared/layout/notificationToast/NotificationToast';
import { Section } from './Section';
import { NaloxoneStore } from './NaloxoneStoreContext';
import DetailsNaloxoneEventStore from './DetailsNaloxoneEventStore';
import { sectionValidationStrategy } from './SectionValidationStrategy';

interface UpdateNaloxoneEventPageState {
  isBusy: boolean;
  editPatientInformation: boolean;
  editContactInformation: boolean;
  editIncidentInformation: boolean;
}

export const NaloxoneEventPageContext = createContext<UpdateNaloxoneEventPageState>({
  isBusy: false,
  editContactInformation: false,
  editPatientInformation: false,
  editIncidentInformation: false,
});
@page(routePaths.data.naloxone.details)
@observer
export default class NaloxoneRecordDetailsPage extends React.Component<RouteComponentProps<WithIdProps>, UpdateNaloxoneEventPageState> {
  private store: DetailsNaloxoneEventStore = new DetailsNaloxoneEventStore();

  public constructor(props: RouteComponentProps<WithIdProps>) {
    super(props);
    this.state = {
      isBusy: true,
      editPatientInformation: false,
      editContactInformation: false,
      editIncidentInformation: false,
    };
  }

  public async componentDidMount() {
    await this.getRecordDetails();
  }

  public render() {
    return (
      <BusyOverlay isBusy={this.state.isBusy}>
        {this.store.administerInformationSectionStore! && (
        <NaloxoneStore.Provider value={this.store}>
          <NaloxoneEventPageContext.Provider value={this.state}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Form>
              <FormContainer headerText="Opioid Antidote record details">
                {
                 this.state.editPatientInformation
                   ? EditPatientInformationSection(
                     this.store.administerInformationSectionStore!.submissionType,
                     async () => this.handleSaveButton({
                       editPatientInformation: false,
                     }, 'patientInformation'),
                     async () => this.handleCancelButton({
                       editPatientInformation: false,
                     })
                   ) : (
                     <PreviewPatientInformationSection
                       submissionType={this.store.administerInformationSectionStore!.submissionType}
                       onUpdateButtonHandler={() => this.setState({
                     editPatientInformation: true
                   })}
                     />
                 )
               }
                {
                 this.state.editContactInformation ? (
                   editPatientContactInformationSection(
                     this.store.administerInformationSectionStore!.submissionType,
                     async () => this.handleSaveButton({
                       editContactInformation: false,
                     }, 'contactInformation'),
                     async () => this.handleCancelButton({
                       editContactInformation: false,
                     })
                   )
                 ) : (
                   <PreviewPatientContactInformation onUpdateButtonHandler={() => this.setState({
                     editContactInformation: true
                   })}
                   />
                 )
               }
                {
                 this.state.editIncidentInformation
                   ? EditAdministerInformationSection(
                     this.store.administerInformationSectionStore!.submissionType,
                     async () => this.handleSaveButton({
                       editIncidentInformation: false,
                     }, 'incidentInformation'),
                     async () => this.handleCancelButton({
                       editIncidentInformation: false,
                     })
                 ) : (
                   <PreviewAdministerInformationSection
                     submissionType={this.store.administerInformationSectionStore!.submissionType}
                     onUpdateButtonHandler={() => this.setState({
                     editIncidentInformation: true
                   })}
                   />
                 )
               }
              </FormContainer>
            </Form>
          </NaloxoneEventPageContext.Provider>
        </NaloxoneStore.Provider>
        )}
      </BusyOverlay>
    );
  }

  private async handleCancelButton(state: any) {
    await this.getRecordDetails();
    this.setState({
      ...state,
    });
  }

  private async getRecordDetails() {
    await this.store.fetchRecord(this.props.match.params.id);
    this.setState({
      isBusy: false,
    });
  }

  private async handleSaveButton(state: any, sectionName: Section) {
    const validationResult = await sectionValidationStrategy.validate(sectionName, this.store);
    if (!validationResult) return;

    if (this.props.match.params.id != null) {
      const result = await this.store.save(this.props.match.params.id, sectionName);

      if (!result.isSuccess) {
        notificationToast.showError(result.error);
        return;
      }
      this.setState({
        ...state,
      });
      notificationToast.showSuccess('Record has been updated');
    }
  }
}
