import * as React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import {
  Badge, Button, Col, Form, Input, InputGroup, InputGroupAddon, Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import RouteManager from '../../../services/route/RouteManager';
import manageOrganizationsTabsPageStore, { ManageOrganizationsTabsPageStore } from './ManageOrganizationsTabsPageStore';
import { combineClassNames } from '../../../helpers/reactHelpers';
import Tabs, { TabForPage } from '../../../shared/layout/tabs/Tabs';
import awaitingApprovalOrganizationsGridStore, { AwaitingApprovalOrganizationsGridStore } from './awaitingApproval/AwaitingApprovalOrganizationsGridStore';
import activeOrganizationsGridStore, { ActiveOrganizationsGridStore } from './active/ActiveOrganizationsGridStore';
import inactiveOrganizationsGridStore, { InactiveOrganizationsGridStore } from './inactive/InactiveOrganizationsGridStore';
import ActiveOrganizationsGrid from './active/ActiveOrganizationsGrid';
import styles from './ManageOrganizationsTabsPage.scss';
import AwaitingApprovalOrganizationsGrid from './awaitingApproval/AwaitingApprovalOrganizationsGrid';
import InactiveOrganizationsGrid from './inactive/InactiveOrganizationsGrid';
import { makeRef } from '../../../helpers/Ref';
import FormInput from '../../../shared/controls/formInput/FormInput';
import OrganizationDeactivationRejectionModal
  from '../../organization/details/rejectionModal/OrganizationDeactivationRejectionModal';
import OrganizationDeactivationRejectionStore
  from '../../organization/details/rejectionModal/OrganizationDeactivationRejectionStore';
import OrganizationApprovalModal from '../../organization/details/approvalModal/OrganizationApprovalModal';
import rejectedOrganizationsGridStore, { RejectedOrganizationsGridStore } from './rejected/RejectedOrganizationsGridStore';
import RejectedOrganizationsGrid from './rejected/RejectedOrganizationsGrid';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';
import OrganizationDeletionModal from '../../organization/details/deletionModal/OrganizationDeletionModal';
import permissions from '../../../model/user/permissions';

RouteManager.registerRedirect(routePaths.organization.root, routePaths.organization.activeList);

interface ManageOrganizationsTabsPageState {
  organizationId?: number;
  openModal: OpenModal;
}

@page({
  path: routePaths.organization.manage,
  hasPermission: permissions.organization.manage
})
@observer
export default class ManageOrganizationsTabsPage extends React.Component<RouteComponentProps, ManageOrganizationsTabsPageState> {
  private _store: ManageOrganizationsTabsPageStore = manageOrganizationsTabsPageStore;
  private _awaitingApprovalOrganizationsGridStore: AwaitingApprovalOrganizationsGridStore = awaitingApprovalOrganizationsGridStore;
  private _activeOrganizationsGridStore: ActiveOrganizationsGridStore = activeOrganizationsGridStore;
  private _inactiveOrganizationsGridStore: InactiveOrganizationsGridStore = inactiveOrganizationsGridStore;
  private _rejectedOrganizationsGridStore: RejectedOrganizationsGridStore = rejectedOrganizationsGridStore;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      openModal: OpenModal.None,
      organizationId: 0
    };
  }

  public render(): React.ReactNode {
    const searchInput = (
      <InputGroup>
        <Input id="searchPhraseInput" onChange={e => this.handleInputValueChange(e.target.value)} />
        <InputGroupAddon addonType="append">
          <Button
            id="searchButton"
            color="primary"
            block
            className="searchButton"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleSearch(e)}
            style={{ width: '50px' }}
            type="submit"
            disabled={!this._store.searchPhrase}
          >
            <FontAwesomeIcon icon={faSearch} className="icon" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );

    const awaitingApprovalOrganizationsTab: TabForPage = {
      id: routePaths.organization.awaitingApprovalList,
      header: <span>Awaiting Approval <Badge color="primary" pill>{this._awaitingApprovalOrganizationsGridStore.totalCount}</Badge></span>,
      component: <AwaitingApprovalOrganizationsGrid
        store={this._awaitingApprovalOrganizationsGridStore}
        openOrgApprovalModalFunc={(organizationId: number) => this.openModal(OpenModal.OrganizationApprove, organizationId)}
        openOrgRejectModalFunc={(organizationId: number) => this.openModal(OpenModal.OrganizationReject, organizationId)}
      />
    };

    const activeOrganizationsTab: TabForPage = {
      id: routePaths.organization.activeList,
      header: <span>Active <Badge color="primary" pill>{this._activeOrganizationsGridStore.totalCount}</Badge></span>,
      component: <ActiveOrganizationsGrid store={this._activeOrganizationsGridStore} />
    };

    const inactiveOrganizationsTab: TabForPage = {
      id: routePaths.organization.inactiveList,
      header: <span>Inactive <Badge color="primary" pill>{this._inactiveOrganizationsGridStore.totalCount}</Badge></span>,
      component: <InactiveOrganizationsGrid store={this._inactiveOrganizationsGridStore} />
    };

    const rejectedOrganizationsTab: TabForPage = {
      id: routePaths.organization.rejectedList,
      header: <span>Rejected <Badge color="primary" pill>{this._rejectedOrganizationsGridStore.totalCount}</Badge></span>,
      component: <RejectedOrganizationsGrid
        store={this._rejectedOrganizationsGridStore}
        openOrgApprovalModalFunc={(organizationId: number) => this.openModal(OpenModal.OrganizationApprove, organizationId)}
        openOrgDeleteModalFunc={(organizationId: number) => this.openModal(OpenModal.OrganizationDelete, organizationId)}
      />
    };

    const additionalTabsContent = (
      <Link className="btn btn-primary" color="primary" to={routePaths.organization.new}>+ Add Organization</Link>
    );

    const tabs: TabForPage[] = [awaitingApprovalOrganizationsTab, activeOrganizationsTab, inactiveOrganizationsTab, rejectedOrganizationsTab];

    return (
      <div className={combineClassNames(styles.manageOrganizationsTabsPage, 'container')}>
        <div className="header-container">
          <h1>Manage Organizations</h1>
        </div>
        <Row className="search-container">
          <Col xs={12} sm={7} md={5} />
          <Col xs={12} sm={5} md={5} lg={4} className="offset-md-2 offset-lg-3">
            <Form>
              <FormInput
                id="organizationSearchName"
                labelText="Search"
                storeRef={makeRef(this._store, 'searchPhrase')}
                customInput={searchInput}
              />
            </Form>
          </Col>
        </Row>
        <Tabs
          className="grid-tabs"
          activeTabId={this.props.location.pathname}
          additionalContent={additionalTabsContent}
          tabs={tabs}
          onTabToggledFunc={newTabId => this.handleTabSwitch(newTabId)}
        />
        {(this.state.openModal === OpenModal.OrganizationDeactivate
          || this.state.openModal === OpenModal.OrganizationReject) && (
          <OrganizationDeactivationRejectionModal
            store={new OrganizationDeactivationRejectionStore()}
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            organizationId={this.state.organizationId || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
          />
        )}
        {this.state.openModal === OpenModal.OrganizationApprove && (
          <OrganizationApprovalModal
            openModal={this.state.openModal}
            organizationId={this.state.organizationId || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
            closeModalFunc={() => this.closeModal()}
          />
        )}
        {this.state.openModal === OpenModal.OrganizationDelete && (
          <OrganizationDeletionModal
            openModal={this.state.openModal}
            organizationId={this.state.organizationId || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
            closeModalFunc={() => this.closeModal()}
          />
        )}
      </div>
    );
  }

  private handleInputValueChange(newValue: string): void {
    this._store.searchPhrase = newValue;
  }

  private async handleSearch(e: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();
    this._awaitingApprovalOrganizationsGridStore.search = this._store.searchPhrase;
    this._activeOrganizationsGridStore.search = this._store.searchPhrase;
    this._inactiveOrganizationsGridStore.search = this._store.searchPhrase;
    this._rejectedOrganizationsGridStore.search = this._store.searchPhrase;
    this._store.refreshGrids();
  }

  private handleTabSwitch(newTabId: string): void {
    this.props.history.push(newTabId);
  }

  private openModal(modalToOpen: OpenModal, organizationId?: number) {
    this.setState({
      openModal: modalToOpen,
      organizationId
    });
  }

  private closeModal() {
    this.setState({
      openModal: OpenModal.None
    });
  }
}
