import React from 'react';
import { Column, SortingRule } from 'react-table';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import NaloxoneRecordForGrid from '../../../../../model/grid/naloxone/NaloxoneRecordForGrid';
import userStore from '../../../../../data/UserStore';
import permissions from '../../../../../model/user/permissions';
import Icon from '../../../../../shared/controls/icons/Icon';
import DynamoGrid, { DynamoGridProps } from '../../../../../shared/grid/DynamoGrid';

interface NaloxoneRecordsGridProps extends DynamoGridProps<NaloxoneRecordForGrid, string> {
  openSubmissionDeletionModalFunc: (id: string) => void;
}

@observer
export default class NaloxoneRecordsGrid extends DynamoGrid<NaloxoneRecordsGridProps, NaloxoneRecordForGrid, string> {
  protected defineColumns(): Array<Column<NaloxoneRecordForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        id: 'name',
        Header: 'Name',
        sortable: false,
        accessor: d => `${d.lastName}, ${d.firstName}`,
        Cell: ({ row }) => <Link to={{ pathname: `/data/opioidantidote/${row.id}` }}>{row.name}</Link>
      },
      {
        Header: 'Date of Birth',
        id: 'dateOfBirth',
        sortable: false,
        accessor: d => d.dateOfBirth
      },
      {
        Header: 'Zip Code',
        id: 'zipCode',
        sortable: false,
        accessor: d => d.zipCode
      },
      {
        Header: 'Administered On',
        id: 'administeredOn',
        sortable: false,
        accessor: d => d.administeredOn
      },
      {
        Header: 'Created On',
        id: 'createdOn',
        sortable: false,
        accessor: d => d.createdOn
      },
      {
        Header: 'Actions',
        width: 100,
        sortable: false,
        Cell: ({ row }) => (
          <>
            {userStore.session?.role?.hasPermission(permissions.data.submissionDelete)
            && (
              <button className="drop-styles" type="button" onClick={() => this.props.openSubmissionDeletionModalFunc(row.id)}>
                <Icon name="delete" size="4rem" className="actionButton" />
              </button>
            )}
          </>
        )
      }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No opioid antidote records';
  }
}
