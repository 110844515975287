import Validator, { ValidationResult } from './Validator';

export default class GreaterThan implements Validator<string> {
  private readonly _limit: number;

  public constructor(limit: number) {
    this._limit = limit;
  }

  public validate(value: string): Promise<ValidationResult> {
    if (Number(value) <= this._limit) {
      return Promise.resolve({ isValid: false, message: `Field must be greater than ${this._limit}` });
    }

    return Promise.resolve({ isValid: true });
  }
}
