import React, { useContext } from 'react';

import InlineEntry from '../../../../../../../../shared/layout/inlineEntry/InlineEntry';
import { LicenseeContext } from '../../../../../../LicenseeContext';

export const ConfigurationsSection: React.FC = () => {
  const context = useContext(LicenseeContext);
  if (!context.loaded) {
    return null;
  }

  const { configuration: { applicationUrl, roles } } = context;
  return (
    <>
      <InlineEntry id="roles" labelText="Roles Enabled" value={roles.join(', ')} />
    </>
  );
};
