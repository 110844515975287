import * as React from 'react';
import { Redirect } from 'react-router';
import routePaths from '../../constants/routePaths';
import { page } from '../../services/route/decorators';

@page({
  path: routePaths.authentication.redirectCallback,
  needsAuthentication: false,
  needsLayout: false
})
export default class RedirectCallbackPage extends React.Component {
  public render(): React.ReactNode {
    // TODO(tomek): we might handle redirect url differently here at some point
    return <Redirect to={routePaths.dashboard.root} />;
  }
}
