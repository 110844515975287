import React from 'react';
import Grid, { GridProps } from './Grid';
import DynamoGridPagination, { DynamoGridPaginationProps } from './dynamoPagination/DynamoGridPagination';
import DynamoGridStore from './DynamoGridStore';

export type DynamoGridProps<TRowModel, TSearch> = GridProps<TRowModel, TSearch> & {
  store: DynamoGridStore<TRowModel, TSearch>;
  paginationToken?: string;
};

export default abstract class DynamoGrid<TGridProps extends DynamoGridProps<TRowModel, TSearch>, TRowModel, TSearch>
  extends Grid<TGridProps, TRowModel, TSearch> {
  protected getPaginationComponent(): React.ElementType {
    const paginationProps: DynamoGridPaginationProps = {
      page: this.props.store.page,
      pageSize: this.props.store.pageSize,
      pageSizes: this.props.store.pageSizes,
      totalCount: this.props.store.totalCount,
      backwardPaginationToken: this.props.store.backwardPaginationToken,
      forwardPaginationToken: this.props.store.forwardPaginationToken,
      onPageSizeChangeHandler: newPageSize => this.props.store.onPageSizeChangedHandler(newPageSize),
      onPageChangeHandler: newPage => this.props.store.onPageChangedHandler(newPage)
    };
    return () => <DynamoGridPagination {...paginationProps} />;
  }
}
