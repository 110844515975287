import { computed, observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';
import ValidateTokenResponse from '../../../model/user/forgotPassword/ValidateTokenResponse';
import { SetNewPasswordResponseCode } from '../../../model/user/forgotPassword/SetNewPasswordResponse';
import { validate } from '../../../shared/validation/helpers';
import NotEmpty from '../../../shared/validation/validators/NotEmpty';
import PasswordStrength from '../../../shared/validation/validators/PasswordStrength';
import MatchPassword from '../../../shared/validation/validators/MatchPassword';

export enum TokenValidationState {
  NotValidated,
  Valid,
  Invalid
}

export class NewPasswordStore extends ValidatableObject {
  @observable
  private _isBusy = false;

  @observable
  private _tokenValidationState: TokenValidationState = TokenValidationState.NotValidated;

  @observable
  private _firstName = '';

  @observable
  private _lastName = '';

  @observable
  private _success = false;

  public token?: string;

  public userId?: number;

  @validate(() => new NotEmpty(), () => new PasswordStrength())
  @observable
  public password = '';

  @validate(() => new NotEmpty(), () => new MatchPassword('password'))
  @observable
  public confirmPassword = '';

  @computed
  public get isBusy() {
    return this._isBusy;
  }

  @computed
  public get tokenValidationState() {
    return this._tokenValidationState;
  }

  @computed
  public get firstName() {
    return this._firstName;
  }

  @computed
  public get lastName() {
    return this._lastName;
  }

  @computed
  public get success() {
    return this._success;
  }

  public async validateToken(): Promise<void> {
    if (!this.token || !this.userId) {
      this._tokenValidationState = TokenValidationState.Invalid;
      return;
    }

    try {
      const response = await apiService.post<ValidateTokenResponse>('/user/validateToken', {
        token: this.token,
        userId: this.userId
      });

      this._firstName = response.data.firstName;
      this._lastName = response.data.lastName;
      this._tokenValidationState = response.data.isValid ? TokenValidationState.Valid : TokenValidationState.Invalid;
    } catch (e) {
      if (!e.response.data.isValid) {
        this._tokenValidationState = TokenValidationState.Invalid;
        return;
      }
      throw e;
    }
  }

  public async validate<TKey extends keyof this>(field?: TKey): Promise<boolean> {
    if (field === 'password' && Boolean(this.confirmPassword)) {
      await super.validate('confirmPassword');
    }
    return super.validate(field);
  }

  public async setNewPassword(): Promise<SetNewPasswordResult> {
    let result: SetNewPasswordResult = SetNewPasswordResult.NoError;

    this._isBusy = true;
    try {
      const response = await apiService.post('/user/setPassword', {
        userId: this.userId,
        token: this.token,
        password: this.password
      });
      if (response.data.code === SetNewPasswordResponseCode.InvalidToken) {
        result = SetNewPasswordResult.InvalidToken;
      } else {
        result = SetNewPasswordResult.NoError;
      }

      this._success = true;
    } finally {
      this._isBusy = false;
    }
    return result;
  }

  public reset(): void {
    this._success = false;
    this._tokenValidationState = TokenValidationState.NotValidated;
    this._firstName = '';
    this._lastName = '';
    this.token = undefined;
    this.userId = undefined;
    this.password = '';
    this.confirmPassword = '';
    this.resetValidationState();
  }
}

export enum SetNewPasswordResult {
  NoError = 1,
  InvalidToken
}

export default new NewPasswordStore();
