import React, { ChangeEvent, FocusEvent } from 'react';
import {
  FormFeedback, FormGroup, FormText, Input, InputProps, Label, Spinner, InputGroup
} from 'reactstrap';
import { Omit } from 'react-router';
import { observer } from 'mobx-react';
import { combineClassNames } from '../../../helpers/reactHelpers';
import Icon from '../icons/Icon';
import styles from './FormInput.scss';
import { InputWithIdProps } from '../SharedProps';
import Ref from '../../../helpers/Ref';
import ValidatableObject from '../../validation/ValidatableObject';
import { ValidationState } from '../../validation/FieldValidationCollection';

type InnerProps = Omit<InputProps, 'value' | 'invalid'>;

export type FormInputProps<T extends ValidatableObject, TKey extends keyof T> = {
  id: string;
  labelText?: string;
  helpText?: string;
  customInput?: React.ReactNode;
  storeRef: Ref<T, TKey, any>;
} & InnerProps & InputWithIdProps;

@observer
export default class FormInput<T extends ValidatableObject, TKey extends keyof T> extends React.Component<FormInputProps<T, TKey>> {
  public render(): React.ReactNode {
    const {
      storeRef, labelText, helpText, className, customInput, id, ...innerProps
    } = this.props;

    const labelTextNode = labelText !== undefined
      ? <><Label for={id} className={styles.labelText}>{labelText}</Label>{this.props.required && <span style={{ color: 'red' }}> * </span>}</> : null;
    const helpTextNode = helpText !== undefined
      ? <FormText>{helpText}</FormText>
      : null;
    let validationFeedback: React.ReactNode;

    switch (storeRef.ref.validationState(storeRef.field)) {
      case ValidationState.NotValidated:
      case ValidationState.Valid:
        validationFeedback = null;
        break;
      case ValidationState.ValidationInProgress:
        validationFeedback = (
          <FormFeedback htmlFor={id} className={`${styles.validationText} validating`}>
            <Spinner color="danger" size="sm" />
            <span>validating...</span>
          </FormFeedback>
        );
        break;
      case ValidationState.Invalid:
        validationFeedback = (
          <FormFeedback htmlFor={id} className={styles.validationText}>
            {
              storeRef.ref.getErrorForFields(storeRef.field).map(error => (
                <div key={error}><Icon name="error" size="1.2rem" /><span> {error}</span> </div>
              ))
            }
          </FormFeedback>
        );
        break;
    }
    const inputNode = customInput
      ? this.props.customInput
      : (
        <InputGroup className={styles.inputGroup}>
          <Input
            {...innerProps}
            id={this.props.id}
            value={storeRef.value}
            invalid={!storeRef.ref.isValid(storeRef.field)}
            onChange={e => this.onChange(e)}
            onBlur={e => this.onBlur(e)}
          >
            {innerProps.children}
          </Input>
        </InputGroup>
      );

    return (
      <FormGroup className={combineClassNames(className)}>
        {labelTextNode}
        {inputNode}
        {helpTextNode}
        {validationFeedback}
      </FormGroup>
    );
  }

  private onChange(e: ChangeEvent<HTMLInputElement>): void {
    this.props.storeRef.value = e.target.value as any;
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  private onBlur(e: FocusEvent<HTMLInputElement>) {
    this.props.storeRef.ref.validate(this.props.storeRef.field);
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }
}
