import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import FormStateSelector from '../../../../../../shared/controls/formSelect/stateSelector/FormStateSelector';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import { State } from '../../../../../../model/address/State';
import apiService from '../../../../../../services/ApiService';
import { EditableSectionProps } from '../../EditableSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';

export default class ScnaEditPatientContactInformation extends React.Component<EditableSectionProps> {
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Edit Patient Contact Information">
              <Row>
                <FormInput
                  id="Patient_Address1"
                  className="col-12"
                  storeRef={makeRef(context.patientContactSectionStore!, 'patientAddress1')}
                  labelText="Patient Street Address"
                  required
                />
              </Row>

              <Row>
                <FormInput
                  id="Patient_Address2"
                  className="col-12"
                  storeRef={makeRef(context.patientContactSectionStore!, 'patientAddress2')}
                  labelText="Patient Street Address Line 2"
                />
              </Row>

              <Row>
                <FormInput
                  id="Patient_City"
                  className="col-12 col-sm-4"
                  storeRef={makeRef(context.patientContactSectionStore!, 'patientCity')}
                  labelText="Patient City"
                  required
                />
                <FormStateSelector
                  id="Patient_State"
                  className="col-12 col-sm-4"
                  storeRef={makeRef(context.patientContactSectionStore!, 'patientState')}
                  labelText="Patient State"
                  fetchOptionsFunc={() => this.fetchStates()}
                  required
                />
                <FormInput
                  id="Patient_ZipCode"
                  className="col-12 col-sm-4"
                  storeRef={makeRef(context.patientContactSectionStore!, 'patientZipCode')}
                  labelText="Patient Zipcode"
                  required
                />
              </Row>
              <Row>
                <Col sm={3} className="offset-sm-3">
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onSaveButtonHandler(e)}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn w-100"
                    onClick={(e: React.MouseEvent) => this.props.onCancelButtonHandler(e)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }

  public async fetchStates(): Promise<State[]> {
    const response = await apiService.get('state/allStates');
    return response.data;
  }
}
