import Validator, { ValidationResult } from './Validator';

export default class ValidEmail implements Validator<string | undefined> {
  private readonly _message: string;

  public constructor(message = 'Invalid Email address') {
    this._message = message;
  }

  public validate(value: string | undefined): Promise<ValidationResult> {
    // eslint-disable-next-line max-len
    const emailRegExp = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (Boolean(value) && emailRegExp.test(value!)) {
      return Promise.resolve({ isValid: true });
    }

    return Promise.resolve({ isValid: false, message: this._message });
  }
}
