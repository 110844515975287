import React, { createContext, useState } from 'react';
import { LicenseeOverview } from './InfoLicenseeStore';
import apiService from '../../services/ApiService';

export const LicenseeContext = createContext<LicenseeOverview>({} as LicenseeOverview);
export const LicenseeContextProvider: React.FC = ({ children }) => {
  const [licenseeState, setState] = useState({
    loaded: false,
  } as LicenseeOverview);

  if (!licenseeState.loaded) {
    apiService.get<LicenseeOverview>('license').then(response => {
      setState({
        ...response.data,
        loaded: true
      });
    });
  }

  return (
    <LicenseeContext.Provider value={licenseeState}>{children}</LicenseeContext.Provider>
  );
};
