import EditProfileStore from '../../../shared/user/profile/editProfile/EditProfileStore';
import apiService from '../../../services/ApiService';

export class EditProfileUserNameStore extends EditProfileStore {
  public async updateProfile(): Promise<void> {
    this._isBusy = true;

    try {
      await apiService.post('/user/updateName', {
        firstName: this.firstName,
        lastName: this.lastName
      });
    } finally {
      this._isBusy = false;
    }
  }
}
