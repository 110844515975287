import React from 'react';
import { Form } from 'reactstrap';
import { observer } from 'mobx-react';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../helpers/Ref';
import { SimpleOperationModal } from '../../../shared/layout/modal/SimpleOperationModal';
import notificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import UserDeactivationRejectionStore from './UserDeactivationRejectionStore';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';

export type UserDeactivationModalProps = {
  openModal: OpenModal.UserDeactivate | OpenModal.UserReject;
  id: number;
  closeModalFunc: () => void;
  onSubmitFunc?: () => void;
  store: UserDeactivationRejectionStore;
};

const UserDeactivationRejectionModal:
  React.FC<UserDeactivationModalProps> = (props: UserDeactivationModalProps) => {
    const { store } = props;
    const modalTitle = props.openModal === OpenModal.UserDeactivate ? 'Deactivate User' : 'Reject User';
    const labelText = props.openModal === OpenModal.UserDeactivate ? 'Deactivation Reason' : 'Rejection Reason';
    const disableUser = async () => {
      if (await store.validate()) {
        switch (props.openModal) {
          case OpenModal.UserDeactivate:
            await store.disableUser(props.id);
            notificationToast.showSuccess('User has been deactivated.');
            break;
          case OpenModal.UserReject:
            await store.rejectUser(props.id);
            notificationToast.showSuccess('User has been rejected.');
        }

        props.closeModalFunc();
        if (props.onSubmitFunc) props.onSubmitFunc!();
      }

      return true;
    };

    return (
      <SimpleOperationModal
        isOpen={props.openModal === OpenModal.UserReject || props.openModal === OpenModal.UserDeactivate}
        headerTitle={modalTitle}
        onSubmitButtonClicked={disableUser}
        onCancelButtonClicked={props.closeModalFunc}
        isSubmitButtonBusy={store.isBusy}
      >
        <Form>
          <FormInput
            id="deactivationReason"
            type="textarea"
            rows="4"
            labelText={labelText}
            storeRef={makeRef(store, 'reason')}
          />
        </Form>
      </SimpleOperationModal>
    );
  };

export default observer(UserDeactivationRejectionModal);
