import { AccountStatus } from '../../../../model/user/AccountStatus';
import { UsersGridStore } from '../UsersGridStore';
import { RejectedUserForGrid } from '../../../../model/grid/user/UserForGrid';

export class RejectedUsersGridStore extends UsersGridStore<RejectedUserForGrid> {
  protected get accountStatus(): AccountStatus {
    return AccountStatus.Rejected;
  }
}

export default new RejectedUsersGridStore();
