import { AccountStatus } from '../../../../model/user/AccountStatus';
import { UsersGridStore } from '../UsersGridStore';
import { InactiveUserForGrid } from '../../../../model/grid/user/UserForGrid';

export class InactiveUsersGridStore extends UsersGridStore<InactiveUserForGrid> {
  protected get accountStatus(): AccountStatus {
    return AccountStatus.Disabled;
  }
}

export default new InactiveUsersGridStore();
