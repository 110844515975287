import React from 'react';
import { NavbarBrand } from 'reactstrap';
import logo from '../../../../assets/images/ervive-white.svg';

const Logo: React.FC = () => (
  <NavbarBrand href="#/">
    <img src={logo} alt="logo" />
  </NavbarBrand>
);

export default Logo;
