import { observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import OrganizationCreationRequest from '../../../model/organization/create/OrganizationCreationRequest';
import organizationInfoSectionStore, { OrganizationInfoSectionStore } from './OrganizationInfoSection/OrganizationInfoSectionStore';
import { Address } from '../../../model/address/Address';
import apiService from '../../../services/ApiService';
import OrganizationCreationResponse from '../../../model/organization/create/OrganizationCreationResponse';
import { OrganizationStatus } from '../../../model/organization/OrganizationStatus';

export enum CreateOrganizationPageSection {
  OrganizationInfo = 1,
  Review,
  Complete
}

export class CreateOrganizationStore extends ValidatableObject {
  @observable
  public pageSection: CreateOrganizationPageSection = CreateOrganizationPageSection.OrganizationInfo;

  @observable
  public organizationStatus?: OrganizationStatus;

  private _organizationInfoSectionStore: OrganizationInfoSectionStore = organizationInfoSectionStore;

  public async createOrganization(): Promise<void> {
    const request = this.prepareRequest();
    const response = await apiService.post<OrganizationCreationResponse>('/organization/create', request);
    this.organizationStatus = response.data.organizationStatus;
  }

  private prepareRequest(): OrganizationCreationRequest {
    const organizationAddress: Address = {
      street: this._organizationInfoSectionStore.streetAddress,
      city: this._organizationInfoSectionStore.city,
      state: this._organizationInfoSectionStore.state!,
      zipCode: this._organizationInfoSectionStore.zip,
      county: ''
    };

    const organizationRequest: OrganizationCreationRequest = {
      id: 0,
      name: this._organizationInfoSectionStore.organizationName,
      address: organizationAddress,
      phone: this._organizationInfoSectionStore.phone,
      email: this._organizationInfoSectionStore.email,
      submissionType: this._organizationInfoSectionStore.dataSubmissionType!,
      roles: []
    };

    return organizationRequest;
  }
}

export default new CreateOrganizationStore();
