import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import { UserDetailsPageStore } from './UserDetailsPageStore';
import ProfilePage from '../../../shared/user/profile/ProfilePage';
import { EditDetailsEmailStore } from './EditDetailsEmailStore';
import { EditDetailsPasswordStore } from './EditDetailsPasswordStore';
import { EditDetailsUserNameStore } from './EditDetailsUserNameStore';
import { EditDetailsPhoneStore } from './EditDetailsPhoneStore';
import permissions from '../../../model/user/permissions';
import { EditDetailsOrganizationStore } from './EditDetailsOrganizationStore';

interface RouteParams {
  userId: string;
}

@page({
  path: routePaths.user.details,
  permitActiveUsersOnly: false,
  hasPermission: permissions.user.details
})
export class UserDetailsPage extends React.Component<RouteComponentProps<RouteParams>> {
  private _editUserNameStore = new EditDetailsUserNameStore();
  private _editPhoneStore = new EditDetailsPhoneStore();
  private _editPasswordStore = new EditDetailsPasswordStore();
  private _editEmailStore = new EditDetailsEmailStore();
  private _editOrganizationStore = new EditDetailsOrganizationStore();
  private _mainStore = new UserDetailsPageStore(
    this._editUserNameStore,
    this._editPhoneStore,
    this._editPasswordStore,
    this._editEmailStore,
    this._editOrganizationStore
  );

  constructor(props: RouteComponentProps<RouteParams>) {
    super(props);
    const { userId } = this.props.match.params;
    this._mainStore.userId = this.props.match.params.userId;
    this._mainStore.editUserNameStore.userId = userId;
    this._mainStore.editEmailStore.userId = userId;
    this._mainStore.editPhoneStore.userId = userId;
    this._mainStore.editPasswordStore.userId = userId;
    this._mainStore.editOrganizationStore.userId = userId;
  }

  public render() {
    return <ProfilePage store={this._mainStore} />;
  }
}

export default withRouter(UserDetailsPage);
