import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import { EditableSectionProps } from '../../EditableSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';
import FormGenderSelector from '../../../../../../shared/controls/formSelect/genderSelector/FormGenderSelector';

export default class ScnaEditPatientInformationSection extends React.Component<EditableSectionProps> {
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Edit Patient Information">
              <Row>
                <FormInput className="col-12 col-sm-4" labelText="First Name" type="text" storeRef={makeRef(context.patientInformationSectionStore!, 'firstName')} placeholder="First Name" id="patientInformationSectionStore.FirstName" />
                <FormInput className="col-12 col-sm-4" labelText="Middle Name" type="text" storeRef={makeRef(context.patientInformationSectionStore!, 'middleName')} placeholder="Middle Name" id="patientInformationSectionStore.MiddleName" />
                <FormInput className="col-12 col-sm-4" labelText="Last Name" type="text" storeRef={makeRef(context.patientInformationSectionStore!, 'lastName')} placeholder="Last Name" id="patientInformationSectionStore.LastName" />
              </Row>
              <Row>
                <FormInput
                  id="DateOfBirth"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.patientInformationSectionStore!, 'dateOfBirth')}
                  labelText="Date of Birth"
                  type="date"
                />
                <FormGenderSelector
                  id="Patient_Gender"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.patientInformationSectionStore!, 'patientGender')}
                  labelText="Patient Gender"
                />
              </Row>
              <Row>
                <Col sm={3} className="offset-sm-3">
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onSaveButtonHandler(e)}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn w-100"
                    onClick={(e: React.MouseEvent) => this.props.onCancelButtonHandler(e)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
