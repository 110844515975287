import { observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';

export class DataSubmissionErrorsPageStore extends ValidatableObject {
  @observable
  public firstName = '';

  @observable
  public lastName = '';

  @observable
  public dateOfBirth = '';
}
