import * as React from 'react';
import logo from '../../../../assets/images/bamboo-health-green.png';
import styles from './AppFooter.scss';
import apiService from '../../../services/ApiService';

interface AppFooterState {
  serverVersion: string;
}

export default class AppFooter extends React.Component<{}, AppFooterState> {
  public constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      serverVersion: ''
    };
  }

  public async componentDidMount(): Promise<void> {
    const result = await apiService.get<string>('version');
    this.setState({ serverVersion: result.data });
  }

  public render(): React.ReactNode {
    const { state } = this;
    return (
      <footer className={styles.footer}>
        <div className="footer-container container text-left">
          <div className="row">
            <div className="footer-container_logo col-12">
              <p>Powered by:</p>
              <img src={logo} alt="Bamboo Health Logo" />
            </div>
            <div className="footer-container_links col-12">
              <p>© 2022 Bamboo Health. All Rights Reserved.</p>
            </div>
            {ENVIRONMENT !== 'Production' && (
            <div className="footer-container_version col-12">
              <div>client version: {VERSION.substring(0, 8)}</div>
              {state.serverVersion && <div>server version: {state.serverVersion.substring(0, 8)}</div>}
            </div>
            )}
          </div>
        </div>
      </footer>
    );
  }
}
