import { observable } from 'mobx';
import ValidatableObject from '../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../shared/validation/validators/NotEmpty';
import Organization from '../../../../../model/organization/Organization';
import apiService from '../../../../../services/ApiService';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import FieldValidationContext from '../../../../../shared/validation/FieldValidationContext';
import {
  IanaAdministerInformationSectionStore
} from './ianaAdministerInformationForm/IanaAdministerInformationSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from './scnaAdministerInformationForm/ScnaAdministerInformationSectionStore';

export class AdministerInformationSectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @validate(() => new NotEmpty())
  @observable
  public OrganizationId = 0;

  @validate(() => new NotEmpty())
  @observable
  public SubmissionType: DataSubmissionType = 0;

  public AdministerInformationFormStore: IanaAdministerInformationSectionStore | ScnaAdministerInformationSectionStore | undefined;

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }

  public isScna(): boolean {
    return this.SubmissionType === DataSubmissionType.SCNA;
  }

  private static shouldValidateScnaFieldFn(value: any, context: FieldValidationContext): boolean {
    return (context.target as AdministerInformationSectionStore).isScna();
  }
}
