import moment from 'moment';
import Validator, { ValidationResult } from './Validator';

export default class NotDaysAhead implements Validator<string> {
  private readonly isValid = 'Invalid date.';
  private readonly _daysAhead: number;

  constructor(daysAhead: number) {
    this._daysAhead = daysAhead;
  }

  public validate(value: string): Promise<ValidationResult> {
    if (!value || !moment(value, 'YYYY-MM-DD').isValid()) {
      return Promise.resolve({ isValid: false, message: this.isValid });
    }

    const bufferDate = moment().add(this._daysAhead, 'days');
    const targetDate = moment(value);
    const unit = this._daysAhead > 1 || this._daysAhead === 0 ? 'days' : 'day';

    if (targetDate.isAfter(bufferDate, 'days')) {
      return Promise.resolve({ isValid: false, message: `Dates more than ${this._daysAhead} ${unit} ahead are not allowed` });
    }

    return Promise.resolve({ isValid: true });
  }
}
