import React from 'react';
import { Row } from 'reactstrap';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import FormDosageUnitSelector
  from '../../../../../../shared/controls/formSelect/dosageUnitSelector/FormDosageUnitSelector';
import FormTimeZoneSelector from '../../../../../../shared/controls/formSelect/timezoneSelector/TimezoneSelector';
import { IanaAdministerInformationSectionStore } from './IanaAdministerInformationSectionStore';

export type IanaAdministerInformationFormProps = {
  store: IanaAdministerInformationSectionStore | undefined;
};

export class IanaAdministerInformationForm extends React.Component<IanaAdministerInformationFormProps> {
  public constructor(props: IanaAdministerInformationFormProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <>
        <Row>
          <FormInput
            id="Incident_AdministerFirstName"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_AdministerFirstName')}
            labelText="Administer First Name"
            required
          />
          <FormInput
            id="Incident_AdministerLastName"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_AdministerLastName')}
            labelText="Administer Last Name"
            required
          />
        </Row>
        <Row>
          <FormInput
            id="Incident_MedicationAdministeredDate"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationAdministeredDate')}
            labelText="Administered Date"
            type="date"
            required
          />
          <FormInput
            id="Incident_MedicationAdministeredTime"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationAdministeredTime')}
            labelText="Administered Time"
            type="time"
            required
          />
          <FormTimeZoneSelector
            id="Incident_MedicationAdministeredTimeZone"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationAdministeredTimeZone')}
            labelText="Administered Timezone"
          />
        </Row>
        <Row>
          <FormInput
            id="Incident_NotifiedDate"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_NotifiedDate')}
            labelText="Notified Date"
            type="date"
            format=""
          />
          <FormInput
            id="Incident_NotifiedTime"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_NotifiedTime')}
            labelText="Notified Time"
            type="time"
          />
          <FormTimeZoneSelector
            id="Incident_NotifiedTimeZone"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_NotifiedTimeZone')}
            labelText="Notified Timezone"
          />
        </Row>
        <Row>
          <FormInput
            id="Incident_OrganizationName"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_OrganizationName')}
            labelText="Incident Organization Name"
          />
        </Row>
        <Row>
          <FormInput
            id="Incident_MedicationName"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationName')}
            labelText="Name of Drug"
            required
          />
        </Row>
        <Row>
          <FormInput
            id="Incident_MedicationDosage"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationDosage')}
            labelText="Medication Dosage"
            type="number"
            min="0"
            required
          />
          <FormDosageUnitSelector
            id="Incident_MedicationDosageUnit"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationDosageUnit')}
            labelText="Dosage Unit"
            required
          />
        </Row>
      </>
    );
  }
}
