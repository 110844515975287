import { observable } from 'mobx';
import nodaTimeHelper from '../../../helpers/nodaTimeHelper';
import ValidatableObject from '../../../shared/validation/ValidatableObject';

export default class ZonedDateTime extends ValidatableObject {
  @observable
  private _date: string;
  @observable
  private _time: string;
  @observable
  private readonly _timezone: string;

  public static fromApiResponse(isoDate: string, timezone: string): ZonedDateTime {
    const { date, time } = nodaTimeHelper.fromApi(isoDate);
    return new ZonedDateTime(date, time, timezone);
  }

  public static default(): ZonedDateTime {
    return new ZonedDateTime('', '', '');
  }

  private constructor(date: string, time: string, timezone: string) {
    super();
    this._date = date;
    this._time = time;
    this._timezone = timezone;
  }

  public set forDatePickerTime(value: string) {
    this._time = value;
  }

  public get forDatePickerTime() {
    return nodaTimeHelper.to24hFormat(this._time);
  }

  public get forDatePickerDate() {
    return this._date;
  }
  public set forDatePickerDate(value: string) {
    this._date = value;
  }

  public get forApi() {
    if (this._time === '') {
      this._time = '00:00';
    }

    const response = nodaTimeHelper.forApiFormat(this._timezone, this._date, nodaTimeHelper.to24hFormat(this._time));
    if (response.indexOf('Invalid date') > 0) return '';
    return nodaTimeHelper.forApiFormat(this._timezone, this._date, nodaTimeHelper.to24hFormat(this._time));
  }

  public get forPreview() {
    return nodaTimeHelper.toZonedDateTime(this._timezone, this._date, nodaTimeHelper.to12hFormat(this._time));
  }

  public get date() {
    return this._date;
  }

  public get time() {
    return this._time;
  }

  public get timezone() {
    return this._time;
  }
}
