import * as React from 'react';
import { Redirect } from 'react-router';
import { UserManager } from 'oidc-client';
import routePaths from '../../constants/routePaths';
import { page } from '../../services/route/decorators';

@page({
  path: routePaths.authentication.logoutCallback,
  needsAuthentication: false,
  needsLayout: false
})
export default class LogoutCallbackPage extends React.Component {
  public componentDidMount(): void {
    new UserManager({}).signoutRedirectCallback();
  }

  public render(): React.ReactNode {
    return <Redirect to={routePaths.general.root} />;
  }
}
