import { observer } from 'mobx-react';
import React from 'react';
import { Form } from 'reactstrap';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import Steps, { StepOption } from '../../../shared/controls/steps/Steps';
import createOrganizationStore, {
  CreateOrganizationPageSection,
  CreateOrganizationStore
} from './CreateOrganizationStore';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import { showPromptOnPageUnload } from '../../../helpers/reactHelpers';
import organizationInfoSectionStore, { OrganizationInfoSectionStore } from './OrganizationInfoSection/OrganizationInfoSectionStore';
import OrganizationInfoSection from './OrganizationInfoSection/OrganizationInfoSection';
import NotificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import OrganizationReviewSection from './OrganizationReviewSection/OrganizationReviewSection';
import { FormCompleteSection } from '../../../shared/controls/formCompleteSection/FormCompleteSection';
import { OrganizationStatus } from '../../../model/organization/OrganizationStatus';
import FormNavigationFooter from '../../../shared/controls/formNavigationFooter/formNavigationFooter';
import permissions from '../../../model/user/permissions';

interface CreateOrganizationPageState {
  isSubmitting: boolean;
}

@page({
  path: routePaths.organization.new,
  hasPermission: permissions.organization.create
})
@observer
export default class CreateOrganizationPage extends React.Component<{}, CreateOrganizationPageState> {
  private _mainStore: CreateOrganizationStore = createOrganizationStore;
  private _organizationInfoStore: OrganizationInfoSectionStore = organizationInfoSectionStore;
  private _beforeUnloadFunc: (e: BeforeUnloadEvent) => void = e => showPromptOnPageUnload(e);

  public constructor(props: {}) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  public componentDidMount(): void {
    window.addEventListener('beforeunload', this._beforeUnloadFunc);
  }

  public componentWillUnmount(): void {
    this._organizationInfoStore.email = '';
    this._organizationInfoStore.phone = '';
    window.removeEventListener('beforeunload', this._beforeUnloadFunc);
  }

  public render(): React.ReactNode {
    const currentSection = this._mainStore.pageSection;

    const stepOptions: StepOption[] = [
      { id: CreateOrganizationPageSection.OrganizationInfo, description: 'Organization' },
      { id: CreateOrganizationPageSection.Review, description: 'Review' },
      { id: CreateOrganizationPageSection.Complete, description: 'Complete' }
    ];

    const completeStatus = OrganizationStatus[this._mainStore.organizationStatus!];
    const completeContent = (
      <>
        <p>Thank you for adding a new organization.</p>
        {this.renderPendingStatusText()}
      </>
    );
    const completeLinkPath = this._mainStore.organizationStatus === OrganizationStatus.Pending
      ? routePaths.organization.awaitingApprovalList : routePaths.organization.activeList;

    return (
      <FormContainer headerText="Add New Organization">
        <Steps steps={stepOptions} activeStepId={this._mainStore.pageSection} />
        <Form>
          { currentSection === CreateOrganizationPageSection.OrganizationInfo && <OrganizationInfoSection />}
          { currentSection === CreateOrganizationPageSection.Review && <OrganizationReviewSection />}
          { currentSection === CreateOrganizationPageSection.Complete
          && (
            <FormCompleteSection
              status={completeStatus}
              content={completeContent}
              linkPath={completeLinkPath}
              linkText="Return to Organizations Page"
            />
          )}
          <FormNavigationFooter
            isSubmitting={() => this.state.isSubmitting}
            cancelLink={routePaths.organization.activeList}
            continueButtonDisabled={() => this._mainStore.pageSection === CreateOrganizationPageSection.Review}
            shouldRenderContinueButton={() => this._mainStore.pageSection === CreateOrganizationPageSection.OrganizationInfo}
            shouldRenderSubmitButton={() => this._mainStore.pageSection === CreateOrganizationPageSection.Review}
            shouldRenderBackButton={() => this._mainStore.pageSection === CreateOrganizationPageSection.Review}
            shouldRenderCancelLink={() => this._mainStore.pageSection !== CreateOrganizationPageSection.Complete}
            onContinueButtonClickedHandler={e => this.onContinueButtonClickedHandler(e)}
            onSubmitButtonClickedHandler={e => this.onSubmitButtonClickedHandler(e)}
            onBackButtonClickedHandler={e => this.onBackButtonClickedHandler(e)}
          />
        </Form>
      </FormContainer>
    );
  }

  private renderPendingStatusText(): React.ReactNode {
    const shouldRenderPendingText = this._mainStore.organizationStatus === OrganizationStatus.Pending;

    if (shouldRenderPendingText) {
      return (
        <p>
          The organization still needs to be approved by an admin.
          You will receive an email once your organization has been approved.
        </p>
      );
    }
    return null;
  }

  private async onContinueButtonClickedHandler(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();

    if (this._mainStore.pageSection === CreateOrganizationPageSection.OrganizationInfo) {
      if (!await this._organizationInfoStore.validate()) {
        return;
      }
    }

    this._mainStore.pageSection++;
  }

  private async onSubmitButtonClickedHandler(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    try {
      await this._mainStore.createOrganization();
      this._mainStore.pageSection++;
    } catch (ex) {
      NotificationToast.showNetworkError();
      throw ex;
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  private onBackButtonClickedHandler(e: React.MouseEvent<any>): void {
    e.preventDefault();
    this._mainStore.pageSection--;
  }
}
