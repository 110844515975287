import * as React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'reactstrap';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { makeRef } from '../../../../../../helpers/Ref';
import { CreateUserOrganizationSectionStore } from '../../CreateUserOrganizationSectionStore';
import FormInput from '../../../../../controls/formInput/FormInput';
import PhoneFormInput from '../../../../../controls/formInput/PhoneFormInput';
import { State } from '../../../../../../model/address/State';
import FormRoleSelector from '../../../../../controls/formSelect/roleSelector/FormRoleSelector';
import ZipCodeInput from '../../../../../controls/formInput/ZipCodeInput';
import { DataSubmissionTypeDisplayName } from '../../../../../../helpers/enums/dataSubmissionTypeDisplayName';
import { StateDisplayName } from '../../../../../../helpers/enums/stateDisplayName';
import { EntryTile } from '../../../../../layout/entryTile/EntryTile';
import { Role } from '../../../../../../model/role/RoleList';

export const CreateUserNewOrganizationTile: React.FC<NewOrganizationTileProps> = (props: NewOrganizationTileProps) => {
  const { store } = props;

  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);

  const buttonContent = <span className="org-list_entry_new">+ Add New Organization</span>;

  const stateInput = () => (
    <Input
      value={`${State[store.selectedOrganization!.address.state]} 
        (${StateDisplayName[store.selectedOrganization!.address.state]})`}
      disabled
    />
  );

  const dataTypeInput = () => <Input value={DataSubmissionTypeDisplayName[store.selectedOrganization!.submissionType]} disabled />;

  const handleUserRoleChange = (value?: number) => {
    const role = store.selectedOrganization!.roles.find(r => r.id === value);
    store.userRole = role;
    setSelectedRole(role);
  };

  const onSelected = () => {
    if (!store.selectedOrganization || store.selectedOrganization.id > 0) {
      store.selectedOrganization = {
        id: -1,
        name: '',
        phone: '',
        email: '',
        roles: [],
        selectedUserRoleId: 0,
        submissionType: store.organizationSearchDataSubmissionType!,
        address: {
          street: '',
          city: '',
          state: store.organizationSearchState!,
          zipCode: '',
          county: ''
        }
      };
    }

    store.resetValidationState();
  };

  const outerContent = () => (
    <div className="p-3 p-md-5">
      <div className="row">
        <FormInput
          id="newOrganizationName"
          className="col-12"
          storeRef={makeRef(store, 'newOrganizationName')}
          labelText="Organization Name"
        />
      </div>
      <div className="row">
        <FormInput
          id="newOrganizationAddress"
          className="col-12"
          storeRef={makeRef(store, 'newOrganizationStreet')}
          labelText="Street Address"
        />
      </div>
      <div className="row">
        <FormInput
          id="newOrganizationCity"
          className="col-12 col-sm-5"
          storeRef={makeRef(store, 'newOrganizationCity')}
          labelText="City"
        />
        <FormInput
          id="newOrgState"
          storeRef={makeRef(store, 'newOrganizationState')}
          labelText="State"
          className="col-12 col-sm-4"
          customInput={stateInput()}
        />
        <ZipCodeInput
          id="newOrganizationZip"
          className="col-sm-3"
          storeRef={makeRef(store, 'newOrganizationZip')}
          labelText="Zip Code"
        />
      </div>
      <div className="row">
        <FormInput id="email" className="col-sm-6" storeRef={makeRef(store, 'newOrganizationEmail')} labelText="Email" />
        <PhoneFormInput
          className="col-sm-6"
          storeRef={makeRef(store, 'newOrganizationPhone')}
          id="phone"
          labelText="Phone Number"
        />
      </div>
      <div className="row">
        <FormInput
          id="newOrgDataType"
          storeRef={makeRef(store, 'newOrganizationDataSubmissionType')}
          labelText="Data Type"
          className="col-12 col-sm-6"
          customInput={dataTypeInput()}
        />
      </div>
      <div className="row">
        <FormRoleSelector
          id="newOrgUserRole"
          className="col-12 col-sm-6"
          name="role"
          labelText="Role"
          storeRef={makeRef(store, 'userRole')}
          handleValueChangeFunc={handleUserRoleChange}
          fetchOptionsFunc={async () => store.fetchRoles()}
        />
        {
          selectedRole && <div className="col-12"> <FontAwesomeIcon icon={faInfoCircle} /> {selectedRole.description}</div>
        }
      </div>
    </div>
  );

  return (
    <EntryTile
      id={-1}
      selected={props.isSelected}
      onSelected={onSelected}
      buttonContent={buttonContent}
      outerContent={props.isSelected && outerContent()}
    />
  );
};

type NewOrganizationTileProps = {
  store: CreateUserOrganizationSectionStore;
  isSelected: boolean;
};

export default observer(CreateUserNewOrganizationTile);
