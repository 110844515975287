import UpdatePatientInformationRequest from '../../../../../model/naloxone/UpdatePatientInformationRequest';
import UpdateContactInformationRequest from '../../../../../model/naloxone/UpdateContactInformationRequest';
import UpdateIncidentInformationRequest from '../../../../../model/naloxone/UpdateIncidentInformationRequest';
import { Section } from '../Section';
import nodaTimeHelper from '../../../../../helpers/nodaTimeHelper';
import UpdateNaloxoneRequest from '../../../../../model/naloxone/UpdateNaloxoneRequest';
import { IanaPatientContactSectionStore } from '../contactInformationSection/IanaPatientContactSection/IanaPatientContactSectionStore';
import {
  IanaAdministerInformationSectionStore
} from '../administerInformationSection/IanaAdministerInformationSection/IanaAdministerInformationSectionStore';
import {
  IanaPatientInformationSectionStore
} from '../patientInformationSection/IanaPatientInformationSection/IanaPatientInformationSectionStore';

const createRequest = (section: Section,
  patientContactSectionStore: IanaPatientContactSectionStore,
  patientInformationSectionStore: IanaPatientInformationSectionStore,
  administerInformationSectionStore: IanaAdministerInformationSectionStore): UpdateNaloxoneRequest => {
  switch (section) {
    case 'contactInformation':
      return {
        contactInformation: prepareContactInformationRequest(patientContactSectionStore!)
      };
    case 'incidentInformation':
      return {
        incidentInformation: prepareIncidentInformationRequest(administerInformationSectionStore!)
      };
    case 'patientInformation':
      return {
        patientInformation: preparePatientInformationRequest(patientInformationSectionStore!)
      };
    default:
      throw new Error(`Could not create request for section ${section}`);
  }
};

const preparePatientInformationRequest = (store: IanaPatientInformationSectionStore): UpdatePatientInformationRequest => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    patientGender,
    patientIdentifier
  } = store;
  return {
    FirstName: firstName,
    MiddleName: middleName,
    LastName: lastName,
    DateOfBirth: nodaTimeHelper.toDate(dateOfBirth),
    Patient_Gender: patientGender,
    Patient_Identifier: patientIdentifier,
  };
};

const prepareContactInformationRequest = (store: IanaPatientContactSectionStore): UpdateContactInformationRequest => {
  const {
    patientAddress1,
    patientAddress2,
    patientCity,
    patientState,
    patientZipCode
  } = store;

  return {
    Patient_Address1: patientAddress1,
    Patient_Address2: patientAddress2,
    Patient_City: patientCity,
    Patient_State: patientState,
    Patient_ZipCode: patientZipCode,
  };
};

const prepareIncidentInformationRequest = (store: IanaAdministerInformationSectionStore): UpdateIncidentInformationRequest => {
  const {
    incidentOrganizationName,
    incidentAdministerFirstName,
    incidentAdministerLastName,
    incidentMedicationName,
    incidentMedicationDosage,
    incidentMedicationDosageUnit,
    medicationAdministered,
    incidentNotified
  } = store;
  return {
    Incident_MedicationAdministerId:
      `${incidentAdministerFirstName}_${incidentAdministerLastName}`,
    Incident_OrganizationName: incidentOrganizationName,
    Incident_MedicationName: incidentMedicationName,
    Incident_MedicationDosage: incidentMedicationDosage,
    Incident_MedicationDosageUnit: incidentMedicationDosageUnit,
    Incident_MedicationAdministered: medicationAdministered.forApi,
    Incident_NotifiedDateTime: incidentNotified.forApi
  };
};

export const ianaNaloxoneRequestFactory = {
  createRequest,
};
