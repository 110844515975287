import { observable } from 'mobx';
import EditPhoneStore from '../../../shared/user/profile/editPhone/EditPhoneStore';
import apiService from '../../../services/ApiService';

export class EditDetailsPhoneStore extends EditPhoneStore {
  @observable
  public userId = '';

  public async updatePhone(): Promise<void> {
    this._isBusy = true;

    try {
      await apiService.post(`/user/${this.userId}/updatePhone`, {
        phone: this.newPhone
      });
    } finally {
      this._isBusy = false;
    }
  }
}
