import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import { OrganizationForProfile } from '../../../../model/organization/Organization';

export default abstract class EditOrganizationStore extends ValidatableObject {
  @observable
  protected _isBusy = false;

  @observable
  @validate(() => new NotEmpty())
  public organizationId = 0;

  @computed
  public get isBusy(): boolean {
    return this._isBusy;
  }

  public abstract async addOrganization(): Promise<OrganizationForProfile>;
}
