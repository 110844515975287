import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import fileDownload from 'js-file-download';
import { DataSubmissionType } from '../../../model/dataSubmission/DataSubmissionType';
import apiService from '../../../services/ApiService';
import { DataSubmissionTypeDisplayName } from '../../../helpers/enums/dataSubmissionTypeDisplayName';
import { StaticFileType } from '../../../helpers/enums/staticFileType';
import notificationToast from '../../layout/notificationToast/NotificationToast';
import { SubmitterType } from '../../../helpers/enums/submitterType';

export interface StaticFileButtonProps {
  fileType: StaticFileType;
  fileExtension: string;
  submissionType: DataSubmissionType;
  suffix?: string;
  submitterType?: SubmitterType;
}

export const MimeMappings = {
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/plain': 'txt',
  'text/tab-separated-values': 'tsv',
  'application/vnd.ms-excel': 'xls',
  'application/pdf': 'pdf'
};

const DEFAULT = 'Sample';
const NALOXONE_ADMINISTRATION_SCNA = 'SAMPLE_OPIOID_ANTIDOTE';

export const FileDisplayName: { [index: number]: string } = [];
FileDisplayName[DataSubmissionType.NDMM] = DEFAULT;
FileDisplayName[DataSubmissionType.AZMM] = DEFAULT;
FileDisplayName[DataSubmissionType.IANA] = DEFAULT;
FileDisplayName[DataSubmissionType.OHDC] = DEFAULT;
FileDisplayName[DataSubmissionType.SCNA] = NALOXONE_ADMINISTRATION_SCNA;
FileDisplayName[DataSubmissionType.OHOTP] = DEFAULT;

export class StaticFileButton extends React.Component<StaticFileButtonProps> {
  public constructor(props: StaticFileButtonProps) {
    super(props);
  }

  public getFileDisplayName() {
    if (this.props.fileType === StaticFileType.SampleFiles) {
      if (this.props.submissionType === DataSubmissionType.SCNA) {
        return `${DataSubmissionType[DataSubmissionType.SCNA]}-${SubmitterType[this.props.submitterType!].toUpperCase()}`;
      }
      return FileDisplayName[this.props.submissionType];
    }

    return 'Upload_Instructions';
  }

  public render(): React.ReactNode {
    return (
      <button type="button" className="sampleFile" onClick={e => this.showSampleFile(e)}>
        {this.getFileDisplayName()}.{this.props.fileExtension}
        {this.props.fileType === StaticFileType.UploadInstructions && <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> }
      </button>
    );
  }

  private async showSampleFile(e: React.MouseEvent) {
    e.preventDefault();

    try {
      const url = `/StaticAssets/${StaticFileType[this.props.fileType]}/${this.props.submissionType}/${this.props.fileExtension}?submitterType=${this.props.submitterType}`;
      const response = await apiService.get(url, { responseType: 'blob' });

      if (this.props.fileType === StaticFileType.UploadInstructions) {
        const fileUrl = URL.createObjectURL(response.data);
        window.open(fileUrl);
      } else {
        fileDownload(response.data, this.buildFileForDownloadName(response.data.type));
      }
    } catch {
      notificationToast.showError('File download failed.');
    }
  }

  private buildFileForDownloadName(mimeType: string): string {
    const regexp = new RegExp(/\s/, 'g');
    const underscoredName = DataSubmissionTypeDisplayName[this.props.submissionType].replace(regexp, '_');
    if (this.props.submissionType === DataSubmissionType.SCNA) {
      return `${this.getFileDisplayName()}.${MimeMappings[mimeType]}`;
    }

    return `${this.getFileDisplayName()}_${underscoredName}.${MimeMappings[mimeType]}`;
  }
}
