import React from 'react';
import { observer } from 'mobx-react';
import FormSectionContainer from '../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import FormStateSelector from '../../../../shared/controls/formSelect/stateSelector/FormStateSelector';
import { State } from '../../../../model/address/State';
import apiService from '../../../../services/ApiService';
import organizationInfoSectionStore, { OrganizationInfoSectionStore } from './OrganizationInfoSectionStore';
import PhoneFormInput from '../../../../shared/controls/formInput/PhoneFormInput';
import ZipCodeInput from '../../../../shared/controls/formInput/ZipCodeInput';
import FormDataTypeSelector from '../../../../shared/controls/formSelect/dataTypeSelector/FormDataTypeSelector';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';

interface OrganizationInfoSectionState {
  shouldFetchDataTypes: boolean;
}

@observer
export default class OrganizationInfoSection extends React.Component<{}, OrganizationInfoSectionState> {
  private _store: OrganizationInfoSectionStore = organizationInfoSectionStore;

  public constructor(props: {}) {
    super(props);

    this.state = {
      shouldFetchDataTypes: false
    };
  }

  public render(): React.ReactNode {
    return (
      <FormSectionContainer>
        <div className="row">
          <FormInput
            id="organizationName"
            className="col-12"
            labelText="Organization Name"
            storeRef={makeRef(this._store, 'organizationName')}
          />
          <FormInput id="streetAddress" className="col-12" labelText="Street Address" storeRef={makeRef(this._store, 'streetAddress')} />
          <FormInput id="city" className="col-12 col-md-6" labelText="City" storeRef={makeRef(this._store, 'city')} />
          <FormStateSelector
            id="state"
            className="col-12 col-md-3"
            storeRef={makeRef(this._store, 'state')}
            labelText="State"
            onValueChangedFunc={(state: number) => this.onStateChanged(state)}
            fetchOptionsFunc={() => this.fetchStates()}
          />
          <ZipCodeInput id="zip" className="col-12 col-md-3" labelText="Zip" storeRef={makeRef(this._store, 'zip')} />
          <PhoneFormInput id="phone" className="col-12 col-md-6" labelText="Phone Number" storeRef={makeRef(this._store, 'phone')} />
          <FormInput id="email" className="col-12 col-md-6" labelText="Email Address" storeRef={makeRef(this._store, 'email')} />
          <FormDataTypeSelector
            id="dataTypeSearch"
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'dataSubmissionType')}
            labelText="What data type are you submitting?"
            onValueChangedFunc={(dataType: number) => this.onDataTypeChanged(dataType)}
            shouldFetchOptions={this.state.shouldFetchDataTypes}
            fetchOptionsFunc={() => this.fetchDataTypes()}
          />
        </div>
      </FormSectionContainer>
    );
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private onStateChanged(state: number): void {
    this.setState(prevState => ({ shouldFetchDataTypes: !prevState.shouldFetchDataTypes }));
    this._store.dataSubmissionType = undefined;
    this._store.resetValidationState();
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private onDataTypeChanged(submissionType: DataSubmissionType): void {
    this._store.resetValidationState('dataSubmissionType');
  }

  private async fetchDataTypes(): Promise<DataSubmissionType[]> {
    try {
      return await this._store.fetchDataTypes(this._store.state!);
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  public async fetchStates(): Promise<State[]> {
    const response = await apiService.get('license/states');
    return response.data;
  }
}
