import EditEmailStore from '../../../shared/user/profile/editEmail/EditEmailStore';
import apiService from '../../../services/ApiService';

export class EditProfileEmailStore extends EditEmailStore {
  public async updateEmail(): Promise<void> {
    this._isBusy = true;

    try {
      await apiService.post('/user/updateEmail', {
        email: this.newEmail
      });
    } finally {
      this._isBusy = false;
    }
  }
}
