import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import nodaTimeHelper from '../../../../../../helpers/nodaTimeHelper';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import Organization from '../../../../../../model/organization/Organization';
import apiService from '../../../../../../services/ApiService';
import { DataSubmissionType } from '../../../../../../model/dataSubmission/DataSubmissionType';
import NotDaysAhead from '../../../../../../shared/validation/validators/NotDaysAhead';
import { SubmitterType } from '../../../../../../helpers/enums/submitterType';
import Conditional from '../../../../../../shared/validation/validators/Conditional';
import FieldValidationContext from '../../../../../../shared/validation/FieldValidationContext';
import ValidZipCode from '../../../../../../shared/validation/validators/ValidZipCode';

export class ScnaAdministerInformationSectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @observable
  public incidentOrganizationName = '';

  @validate(() => new NotEmpty())
  @observable
  public incidentCounty = '';

  @validate(() => new ValidZipCode())
  @observable
  public incidentZipCode = '';

  @validate(() => new NotEmpty())
  @observable
  public submissionId = 0;

  @validate(() => new NotEmpty())
  @observable
  public submissionType: DataSubmissionType = 0;

  @observable
  @validate(() => new NotEmpty(), () => new NotDaysAhead(1))
  public medicationAdministered = '';

  @observable
  public submitterType: SubmitterType = 0;

  @validate(() => new Conditional(ScnaAdministerInformationSectionStore.shouldValidateFirstResponderField, new NotEmpty()))
  @observable
  public firstResponder_FirstAndLastName = '';

  @validate(() => new Conditional(ScnaAdministerInformationSectionStore.shouldValidateFirstResponderField, new NotEmpty()))
  @observable
  public firstResponder_Type = '';

  public get medicationAdministeredForPreview() {
    return nodaTimeHelper.toDate(this.medicationAdministered);
  }

  private static shouldValidateFirstResponderField(value: any, context: FieldValidationContext): boolean {
    return (context.target as ScnaAdministerInformationSectionStore).submitterType === SubmitterType.FirstResponder;
  }

  // @validate(() => new NotEmpty())
  @observable
  public incidentMedicationName = '';

  // //////////////
  // NON SCNA FIELDS
  // //////////////
  public incidentNotified = null;
  public incidentMedicationDosage = null;
  public incidentMedicationDosageUnit = null;
  public incidentAdministerFirstName = null;
  public incidentAdministerLastName = null;
  public incidentMedicationAdministerId = null;
  // ///////////////
  // ///////////////

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }
}
