import React from 'react';
import { Row } from 'reactstrap';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import ZipCodeInput from '../../../../../../shared/controls/formInput/ZipCodeInput';
import { ScnaAdministerInformationSectionStore } from './ScnaAdministerInformationSectionStore';
import {
  IanaAdministerInformationSectionStore
} from '../ianaAdministerInformationForm/IanaAdministerInformationSectionStore';
import FormSubmitterTypeSelector
  from '../../../../../../shared/controls/formSelect/submitterTypeSelector/FormSubmitterTypeSelector';
import { SubmitterType } from '../../../../../../helpers/enums/submitterType';
import FormFirstResponderTypeSelector
  from '../../../../../../shared/controls/formSelect/firstResponderTypeSelector/FormFirstResponderTypeSelector';

export type ScnaAdministerInformationFormProps = {
  store: ScnaAdministerInformationSectionStore | IanaAdministerInformationSectionStore | undefined;
};

export interface ScnaAdministerInformationFormState {
  submitterType: SubmitterType;
}

export class ScnaAdministerInformationForm extends React.Component<ScnaAdministerInformationFormProps, ScnaAdministerInformationFormState> {
  public constructor(props: ScnaAdministerInformationFormProps) {
    super(props);

    this.state = { submitterType: 0 };
  }

  public render(): React.ReactNode {
    return (
      <>
        <Row>
          <FormSubmitterTypeSelector
            id="submitterTypeSelector"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'SubmitterType')}
            labelText="What type of submitter are you?"
            onValueChangedFunc={(submitterType: SubmitterType) => this.onSubmitterTypeChanged(submitterType)}
          />
          {this.state.submitterType === SubmitterType.FirstResponder && (
            <FormFirstResponderTypeSelector
              id="FirstResponder_Type"
              className="col-12 col-sm-6"
              storeRef={makeRef(this.props.store!, 'FirstResponder_Type')}
              labelText="What type of First Responder are you?"
            />
          )}
        </Row>
        <Row>
          {this.state.submitterType === SubmitterType.FirstResponder && (
          <FormInput
            id="FirstResponder_FirstAndLastName"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'FirstResponder_FirstAndLastName')}
            labelText="First Responder Name"
            required
          />
          )}
          <FormInput
            id="Incident_MedicationAdministeredDate"
            className="col-12 col-sm-4"
            storeRef={makeRef(this.props.store!, 'Incident_MedicationAdministeredDate')}
            labelText="Administered Date"
            type="date"
            required
          />
        </Row>
        <Row>
          <ZipCodeInput
            id="Incident_Zipcode"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_Zipcode')}
            labelText="Incident Zipcode"
            required
          />
          <FormInput
            id="Incident_County"
            className="col-12 col-sm-6"
            storeRef={makeRef(this.props.store!, 'Incident_County')}
            labelText="Incident County"
            required
          />
        </Row>
      </>
    );
  }
  private onSubmitterTypeChanged(submitterType: SubmitterType): void {
    this.setState({
      submitterType,
    });
  }
}
