import { observable } from 'mobx';
import GridStore from '../../../../shared/grid/GridStore';
import GridPageRequest from '../../../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../../../model/grid/GridPageResponse';
import apiService from '../../../../services/ApiService';
import DataSubmissionErrorForGrid from '../../../../model/grid/dataSubmission/DataSubmissionErrorForGrid';
import { DynamoSearch } from '../../../../model/grid/dynamo/DynamoSearch';

export class DataSubmissionErrorsGridStore<TRowModel extends DataSubmissionErrorForGrid> extends GridStore<TRowModel, string> {
  @observable
  public paginationToken = '';

  @observable
  public submissionId = 0;

  @observable
  public recordsSearch?: DynamoSearch;

  public pageSize = 100;

  protected get shouldFetchOnMount(): boolean {
    return false;
  }

  protected get shouldDebounceFetch(): boolean {
    return false;
  }

  protected get shouldFetchData(): boolean {
    return true;
  }

  public async onPageChangedHandler(newPage: number): Promise<void> {
    this.isBackwardSearch = newPage < this.page;
    this.page = newPage;
    await this.refreshGrid(this.recordsSearch);
  }

  protected async apiCall(gridPageRequest: GridPageRequest<string>): Promise<GridPageResponse<TRowModel>> {
    if (!this.submissionId) {
      return {
        rows: [],
        totalCount: 0
      };
    }

    const params = { gridPageRequest };
    const gridData = await apiService.get<DataSubmissionErrorsGridPageResponse<TRowModel>>('dataSubmission/listInvalidRecordsForGrid', { params });
    this.paginationToken = gridData.data.paginationToken;
    return gridData.data.records;
  }

  public async dynamoSearch(search: DynamoSearch) {
    this.paginationToken = '';
    this.page = 1;
    const { firstName, lastName, dateOfBirth } = search;

    if (!firstName && !lastName && !dateOfBirth) {
      this.recordsSearch = undefined;
      await this.refreshGrid();
    } else {
      this.recordsSearch = search;
      await this.refreshGrid(search);
    }
  }

  public async refreshGrid(search?: DynamoSearch, ignorePaginationToken?: boolean): Promise<void> {
    this.isLoading = true;
    const gridPageRequest = this.getGridPageRequest();
    gridPageRequest.recordSearch = search;

    if (ignorePaginationToken) {
      this.paginationToken = '';
    }

    const params = { gridPageRequest };

    const gridData = await apiService.get<DataSubmissionErrorsGridPageResponse<TRowModel>>('dataSubmission/listInvalidRecordsForGrid', { params });
    this.paginationToken = gridData.data.paginationToken;
    this.rows = gridData.data.records.rows;
    this.totalCount = gridData.data.records.totalCount;
    this.isLoading = false;
  }

  public getGridPageRequest(): DataSubmissionErrorsGridPageRequest {
    return {
      ...super.getGridPageRequest(),
      submissionId: this.submissionId,
      paginationToken: this.paginationToken,
    };
  }
}

export interface DataSubmissionErrorsGridPageRequest extends GridPageRequest<string> {
  submissionId: number;
  paginationToken: string;
  recordSearch?: DynamoSearch;
}

export interface DataSubmissionErrorsGridPageResponse<TRowModel> {
  records: GridPageResponse<TRowModel>;
  paginationToken: string;
}
