export enum OpenModal {
  None,
  OrganizationApprove,
  OrganizationDeactivate,
  OrganizationReject,
  OrganizationDelete,
  UserApprove,
  UserDeactivate,
  UserReject,
  UserDelete,
  SubmissionDelete
}
