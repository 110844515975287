import React from 'react';
import styles from './BusyOverlay.scss';
import Spinner from '../spinner/Spinner';

interface BusyOverlayProps {
  isBusy: boolean;
  children: React.ReactNode;
  message?: React.ReactNode | string;
}

// TODO: Switch to skeleton
export default class BusyOverlay extends React.Component<BusyOverlayProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.busyOverlayContainer}>
        {this.props.children}
        {this.props.isBusy
        && (
        <div className="busyOverlay">
          <Spinner size="2x" />
          {this.props.message}
        </div>
        )}
      </div>
    );
  }
}
