import Validator, { ValidationResult } from './Validator';
import { PasswordChecker } from '../../../helpers/passwordChecker';

export default class PasswordStrength implements Validator<string | undefined> {
  private readonly _message: string;

  public constructor(message = 'Password does not meet strength criteria') {
    this._message = message;
  }

  public validate(value: string | undefined): Promise<ValidationResult> {
    if ((typeof value === 'string')
      && PasswordChecker.checkLength(value)
      && PasswordChecker.checkPasswordUppercaseChar(value)
      && PasswordChecker.checkPasswordLowercaseChar(value)
      && PasswordChecker.checkPasswordNumericChar(value)
      && PasswordChecker.checkPasswordSpecialChar(value)) {
      return Promise.resolve({ isValid: true });
    }

    return Promise.resolve({ isValid: false, message: this._message });
  }
}
