import { OrganizationsGridStore } from '../OrganizationsGridStore';
import { ActiveOrganizationForGrid } from '../../../../model/grid/organization/OrganizationForGrid';
import { OrganizationStatus } from '../../../../model/organization/OrganizationStatus';

export class ActiveOrganizationsGridStore extends OrganizationsGridStore<ActiveOrganizationForGrid> {
  protected get organizationStatus(): OrganizationStatus {
    return OrganizationStatus.Active;
  }
}

export default new ActiveOrganizationsGridStore();
