import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import routePaths from '../../constants/routePaths';
import { page } from '../../services/route/decorators';

@page({
  path: routePaths.dashboard.root,
  needsAuthentication: true
})
class DashboardPage extends React.Component<RouteComponentProps> {
  public render(): React.ReactNode {
    return (
      <div className="pt-5 pb-5">
        <div className="container d-flex justify-content-center flex-column text-center">
          <h1 className="p-3">Welcome to ERvive</h1>
          <h4 className="p-3">
            The integration solution to electronically and securely submit and monitor
            data to the Patient consolidation records of the PMP
          </h4>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardPage);
