import React from 'react';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import { AdministerInformationSectionStore } from './AdministerInformationSectionStore';
import FormSectionContainer from '../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import { makeRef } from '../../../../../helpers/Ref';
import OrganizationFormSelector from '../../../../management/users/organizationSelector/OrganizationFormSelector';
import Organization from '../../../../../model/organization/Organization';
import NotificationToast from '../../../../../shared/layout/notificationToast/NotificationToast';
import BusyOverlay from '../../../../../shared/layout/busyOverlay/BusyOverlay';
import FormDataTypeSelector from '../../../../../shared/controls/formSelect/dataTypeSelector/FormDataTypeSelector';
import apiService from '../../../../../services/ApiService';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import { ScnaAdministerInformationForm } from './scnaAdministerInformationForm/ScnaAdministerInformationForm';
import { IanaAdministerInformationForm } from './ianaAdministerInformationForm/IanaAdministerInformationForm';
import {
  IanaAdministerInformationSectionStore
} from './ianaAdministerInformationForm/IanaAdministerInformationSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from './scnaAdministerInformationForm/ScnaAdministerInformationSectionStore';

export type AdministerInformationSectionProps = {
  administerInformationSectionStore: AdministerInformationSectionStore;
  setPatientInformationStoreFn: (submissionType: DataSubmissionType) => void;
};

interface AdministerInformationSectionState {
  shouldFetchDataTypes: boolean;
  organizations: Organization[];
  organizationId: number;
}

@observer
export class AdministerInformationSection extends React.Component<AdministerInformationSectionProps, AdministerInformationSectionState> {
  private _store: AdministerInformationSectionStore = this.props.administerInformationSectionStore;

  public constructor(props: AdministerInformationSectionProps) {
    super(props);

    this.state = {
      shouldFetchDataTypes: false,
      organizations: [],
      organizationId: 0
    };
  }

  public async componentDidMount(): Promise<void> {
    this.toggleIsBusy();
  }

  public render(): React.ReactNode {
    return (
      <BusyOverlay isBusy={this._store.isBusy}>
        <FormSectionContainer>
          <Row>
            <OrganizationFormSelector
              id="submissionId"
              className="col-12 col-sm-6"
              labelText="Incident Organization Name"
              storeRef={makeRef(this._store, 'OrganizationId')}
              onValueChangedFunc={id => this.handleOrganizationChange(id)}
              fetchOptionsFunc={() => this.fetchOrganizations()}
              required
            />
            <FormDataTypeSelector
              id="dataTypeSearch"
              className="col-12 col-sm-6"
              storeRef={makeRef(this._store, 'SubmissionType')}
              labelText="What data type are you submitting?"
              shouldFetchOptions={this.state.shouldFetchDataTypes}
              fetchOptionsFunc={() => this.fetchDataTypes()}
              onValueChangedFunc={submissionType => {
                this.props.setPatientInformationStoreFn(submissionType);
                this.resetAdministerInformationForm(submissionType);
              }}
              required
            />
          </Row>
          {this.renderForm()}
        </FormSectionContainer>
      </BusyOverlay>
    );
  }

  private resetAdministerInformationForm(submissionType: DataSubmissionType) {
    switch (submissionType) {
      case DataSubmissionType.IANA:
        this._store.AdministerInformationFormStore = new IanaAdministerInformationSectionStore();
        break;
      case DataSubmissionType.SCNA:
        this._store.AdministerInformationFormStore = new ScnaAdministerInformationSectionStore();
        break;
      default:
    }
  }

  private renderForm() {
    switch (this.props.administerInformationSectionStore.SubmissionType) {
      case DataSubmissionType.IANA:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return <IanaAdministerInformationForm store={this._store.AdministerInformationFormStore} />;
      case DataSubmissionType.SCNA:
        return <ScnaAdministerInformationForm store={this._store.AdministerInformationFormStore} />;
      default:
        return <></>;
    }
  }

  private toggleIsBusy() {
    this._store.isBusy = !this._store.isBusy;
  }

  private async fetchOrganizations(): Promise<Organization[]> {
    try {
      const organizations = await this._store.fetchOrganizations();
      this.setState({ organizations });
      return organizations;
    } catch (e) {
      NotificationToast.showNetworkError();
      return [];
    } finally {
      this.toggleIsBusy();
    }
  }

  private getSelectedOrganization(): Organization | undefined {
    return this.state.organizations.find(org => org.id === this.state.organizationId);
  }

  private async handleOrganizationChange(organizationId: number): Promise<void> {
    this.setState(prevState => ({ organizationId, shouldFetchDataTypes: !prevState.shouldFetchDataTypes }));
  }

  private async fetchDataTypes(): Promise<DataSubmissionType[]> {
    try {
      const response = await apiService.get('license/dataTypes', {
        params: {
          state: this.getSelectedOrganization()?.address.state
        }
      });
      return response.data;
    } catch (e) {
      return [];
    }
  }
}
