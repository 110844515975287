import moment from 'moment';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import displayConstants from '../constants/displayConstants';

export function formatUsDate(date: Date): string {
  if (!date) {
    return '';
  }

  return moment(date).format(displayConstants.US_DATE_FORMAT);
}

export function formatUsDateTime(date: Date): string {
  const defaultDateTime = '0001-01-01T00:00:00';

  if (!date) return '';

  const formattedDate = moment(date).format(displayConstants.US_DATETIME_FORMAT);

  if (moment(date).isSame(moment(defaultDateTime))) return '';

  return formattedDate;
}

export function formatPhoneNumber(number?: string): string | undefined {
  if (!number) {
    return number;
  }

  const phoneNumberUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneNumberUtil.parse(number, 'US');

  return phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
}
