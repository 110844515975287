export enum SubmitterType {
  HealthcareFacility = 1,
  FirstResponder,
}

const FirstResponder = 'First Responder';
const HealthcareFacility = 'Healthcare Facility';

export const SubmitterTypeDisplayName: { [index: number]: string } = [];
SubmitterTypeDisplayName[SubmitterType.FirstResponder] = FirstResponder;
SubmitterTypeDisplayName[SubmitterType.HealthcareFacility] = HealthcareFacility;
