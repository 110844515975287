import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { State } from '../../../../../../model/address/State';
import { validate } from '../../../../../../shared/validation/helpers';
import ValidZipCode from '../../../../../../shared/validation/validators/ValidZipCode';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';

export class ScnaPatientContactSectionStore extends ValidatableObject {
  @observable
  @validate(() => new NotEmpty())
  public patientAddress1 = '';

  @observable
  public patientAddress2 = '';

  @observable
  @validate(() => new NotEmpty())
  public patientCity = '';

  @observable
  @validate(() => new NotEmpty())
  public patientState: State = 0;

  @observable
  @validate(() => new NotEmpty(), () => new ValidZipCode())
  public patientZipCode = '';
}
