export enum MedicationDosageUnit {
  Mg = 1,
  Each,
  Ml,
}

const Mg = 'Mg';
const Each = 'Each';
const Ml = 'Ml';

export const MedicationDosageUnitDisplayName: { [index: number]: string } = [];
MedicationDosageUnitDisplayName[MedicationDosageUnit.Mg] = Mg;
MedicationDosageUnitDisplayName[MedicationDosageUnit.Each] = Each;
MedicationDosageUnitDisplayName[MedicationDosageUnit.Ml] = Ml;
