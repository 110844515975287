import * as React from 'react';
import { Button, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import {
  RadioButtonGroup,
  RadioButtonGroupProps
} from '../../../../shared/controls/selectableInputGroup/SelectableInputGroup';
import ButtonWithSpinner from '../../../../shared/controls/buttons/ButtonWithSpinner';
import MessageBox, { MessageType } from '../../../../shared/layout/messageBox/MessageBox';
import FormSectionContainer from '../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormSectionDivider from '../../../../shared/layout/formContainer/sectionDivider/FormSectionDivider';
import { AddOrganizationAdminSource } from '../../../../model/organization/admin/AddOrganizationAdmin';
import addOrganizationAdminStore, { AddOrganizationAdminStore } from './AddOrganizationAdminStore';
import { makeRef } from '../../../../helpers/Ref';
import AddOrganizationAdminUserAsyncSelector from './AddOrganizationAdminUserAsyncSelector';
import FormInput from '../../../../shared/controls/formInput/FormInput';

interface AddOrganizationAdminProps {
  organizationId: number;
  hideAddOrgAdminSectionFunc: () => void;
  onOrgAdminAddedFunc: () => void;
}

interface AddOrganizationAdminState {
  errorMessage: string;
  isBusy: boolean;
}

@observer
export default class AddOrganizationAdminSection extends React.Component<AddOrganizationAdminProps, AddOrganizationAdminState> {
  private readonly addOrgAdminStore: AddOrganizationAdminStore = addOrganizationAdminStore;

  constructor(props: Readonly<AddOrganizationAdminProps>) {
    super(props);
    this.addOrgAdminStore.organizationId = this.props.organizationId;

    this.state = {
      errorMessage: '',
      isBusy: false
    };
  }

  public render(): React.ReactNode {
    const { hideAddOrgAdminSectionFunc } = this.props;
    const radioButtonProps: RadioButtonGroupProps = {
      id: 'userSourceRadioButtonGroup',
      isValid: true, // TODO
      items: [
        {
          id: 'existingUser',
          displayName: 'Make an existing NPEDE Basic user account holder an organization Admin',
          propertyName: 'userSource',
          checked: this.addOrgAdminStore.adminUserSource === AddOrganizationAdminSource.ExistingUser,
          radioButtonValue: AddOrganizationAdminSource.ExistingUser
        }
      ],
      onChange: event => { this.addOrgAdminStore.adminUserSource = Number(event.target.value); }
    };

    return (
      <FormSectionContainer>
        <FormSectionDivider headerText="Add Admin" />
        <div className="mb-3">
          <RadioButtonGroup {...radioButtonProps} />
        </div>
        <Form>
          <div className="mb-4">
            {this.getUserSection()}
            <MessageBox
              id="addOrgAdminFormErrorBox"
              isVisible={this.state.errorMessage !== ''}
              message={this.state.errorMessage}
              type={MessageType.Error}
            />
          </div>
          <div className="row">
            <div className="col-5 col-sm-4 col-md-3 col-lg-3">
              <ButtonWithSpinner
                id="saveOrgAdminButton"
                block
                color="primary"
                buttonText="Save Changes"
                onClick={(e: React.MouseEvent) => this.addOrganizationAdmin(e)}
                isBusy={this.state.isBusy}
              />
            </div>
            <div className="col-3 col-sm-2 pl-0">
              <Button
                color="secondary"
                block
                onClick={() => hideAddOrgAdminSectionFunc()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </FormSectionContainer>
    );
  }

  private toggleIsBusy() {
    this.setState(prevState => ({ isBusy: !prevState.isBusy }));
  }

  private async addOrganizationAdmin(e: React.MouseEvent) {
    this.toggleIsBusy();
    await this.addOrgAdminStore.addOrganizationAdmin(e);
    this.props.onOrgAdminAddedFunc();
    this.toggleIsBusy();
    this.props.hideAddOrgAdminSectionFunc();
  }

  private getUserSection(): React.ReactNode {
    // TODO: New user details
    if (this.addOrgAdminStore.adminUserSource === AddOrganizationAdminSource.NewUser) {
      return (
        <div>TODO: New user details</div>
      );
    }

    const userSelector = <AddOrganizationAdminUserAsyncSelector organizationId={this.props.organizationId} />;

    return (
      <FormInput
        id="userSelectorId"
        className="mb-2"
        storeRef={makeRef(this.addOrgAdminStore, 'existingUserId')}
        customInput={userSelector}
      />
    );
  }
}
