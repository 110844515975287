import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { SimpleOperationModal } from '../../../shared/layout/modal/SimpleOperationModal';
import notificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import apiService from '../../../services/ApiService';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';

export type SubmissionRecordDeletionModalProps = {
  openModal: OpenModal.SubmissionDelete;
  id: string;
  closeModalFunc: () => void;
  onSubmitFunc?: () => void;
};

const SubmissionRecordDeletionModal:
  React.FC<SubmissionRecordDeletionModalProps> = (props: SubmissionRecordDeletionModalProps) => {
    const [isBusy, setIsBusy] = useState(false);
    const modalTitle = 'Delete Patient Data';
    const deleteDataSubmission = async () => {
      setIsBusy(true);

      try {
        await apiService.post('dataSubmission/deleteRecord', { id: props.id });
        notificationToast.showSuccess('Patient data has been deleted.');
      } catch {
        notificationToast.showError('Unable to delete patient data.');
      } finally {
        setIsBusy(false);
      }

      props.closeModalFunc();
      if (props.onSubmitFunc) props.onSubmitFunc!();

      return true;
    };

    return (
      <SimpleOperationModal
        isOpen={props.openModal === OpenModal.SubmissionDelete}
        headerTitle={modalTitle}
        onSubmitButtonClicked={deleteDataSubmission}
        onCancelButtonClicked={props.closeModalFunc}
        isSubmitButtonBusy={isBusy}
      >
        <p>Are you sure you want to delete this patient data?</p>
      </SimpleOperationModal>
    );
  };

export default observer(SubmissionRecordDeletionModal);
