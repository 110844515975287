import * as React from 'react';

import Box from '../../shared/layout/box/Box';
import styles from './BadRequestPage.scss';
import { page } from '../../services/route/decorators';
import routePaths from '../../constants/routePaths';

@page({
  path: routePaths.error.badRequest,
})
export default class BadRequestPage extends React.Component {
  public render(): React.ReactNode {
    return (
      <div className={styles.badRequest}>
        <Box border>
          <h2 className="text-center">Invalid Request</h2>
        </Box>
      </div>
    );
  }
}
