import { Column, SortingRule } from 'react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatPhoneNumber, formatUsDateTime } from '../../../../helpers/displayHelper';
import { AwaitingApprovalUserForGrid } from '../../../../model/grid/user/UserForGrid';
import Icon from '../../../../shared/controls/icons/Icon';
import styles from './AwaitingApprovalUsersGrid.scss';
import Grid, { GridProps } from '../../../../shared/grid/Grid';
import userStore from '../../../../data/UserStore';
import permissions from '../../../../model/user/permissions';

interface AwaitingApprovalUsersGridProps extends GridProps<AwaitingApprovalUserForGrid, string> {
  openUserApprovalModalFunc: (id: number) => void;
  openUserRejectModalFunc: (id: number) => void;
}

@observer
export default class AwaitingApprovalUsersGrid extends Grid<AwaitingApprovalUsersGridProps, AwaitingApprovalUserForGrid, string> {
  public render(): React.ReactNode {
    return (
      <div className={styles.awaitingApprovalUsersGrid}>
        {super.render()}
      </div>
    );
  }

  protected canChangeUserStatus(row: any): boolean | undefined {
    switch (row.role.toLowerCase()) {
      case 'license admin': {
        return userStore.session?.role?.hasPermission(permissions.user.adminUserAssignRole);
      }
      default: {
        return userStore.session?.role?.hasPermission(permissions.user.approve);
      }
    }
  }

  protected defineColumns(): Array<Column<AwaitingApprovalUserForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        id: 'name',
        Header: 'Name',
        accessor: d => `${d.lastName}, ${d.firstName}`,
        Cell: ({ row }) => <Link to={{ pathname: `/user/${row.id}/details` }}>{row.name}</Link>
      },
      { Header: 'Role', id: 'role', accessor: d => d.role },
      { Header: 'Licensee Name', id: 'licenseeName', accessor: d => d.licenseeName },
      { Header: 'Email', id: 'email', accessor: d => d.email },
      { Header: 'Phone Number', id: 'phone', accessor: d => formatPhoneNumber(d.phone) },
      { Header: 'Registered On', id: 'createdOn', accessor: d => formatUsDateTime(d.createdOn) },
      {
        Header: 'Actions',
        width: 100,
        sortable: false,
        Cell: ({ row }) => (
          <>
            {this.canChangeUserStatus(row)
            && (
              <button className="drop-styles" type="button" onClick={() => this.props.openUserApprovalModalFunc(row.id)}>
                <Icon name="approve" size="4rem" className="actionButton" />
              </button>
            )}
            {this.canChangeUserStatus(row)
            && (
              <button className="drop-styles" type="button" onClick={() => this.props.openUserRejectModalFunc(row.id)}>
                <Icon name="reject" size="4rem" className="actionButton" />
              </button>
            )}
          </>
        )
      }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No Users awaiting approval';
  }
}
