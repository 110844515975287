import React from 'react';
import { observable } from 'mobx';
import { Col, Row } from 'reactstrap';
import styles from './DataSubmissionPage.scss';
import { FileUpload } from '../../../shared/controls/fileUpload/FileUpload';
import { makeRef } from '../../../helpers/Ref';
import OrganizationFormSelector from '../../management/users/organizationSelector/OrganizationFormSelector';
import Organization from '../../../model/organization/Organization';
import NotificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import apiService from '../../../services/ApiService';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import FormDataTypeSelector from '../../../shared/controls/formSelect/dataTypeSelector/FormDataTypeSelector';
import { DataSubmissionType } from '../../../model/dataSubmission/DataSubmissionType';
import { SupportedFileExtensions } from '../../../constants/supportedFileExtensions';
import { StaticFileButton } from '../../../shared/controls/staticFileButton/StaticFileButton';
import { StaticFileType } from '../../../helpers/enums/staticFileType';
import { SampleFileExtensions } from '../../../constants/sampleFileExtensions';
import FormSubmitterTypeSelector
  from '../../../shared/controls/formSelect/submitterTypeSelector/FormSubmitterTypeSelector';
import { SubmitterType } from '../../../helpers/enums/submitterType';

export type DataSubmissionPageState = {
  uploadDisabled: boolean;
  organizationId: number;
  submissionType: DataSubmissionType;
  submitterType: SubmitterType;
  organizations: Organization[];
  shouldFetchDataTypes: boolean;
  showStaticFileLinks: boolean;
  permittedFileExtensions: string[];
  sampleFileExtensions: string[];
};

export class DataSubmissionPageStore extends ValidatableObject {
  @observable
  organizationId = 0;

  @observable
  dataSubmissionType: DataSubmissionType = 0;

  @observable
  submitterType = '';
}

const defaultState = {
  uploadDisabled: true,
  organizationId: 0,
  submissionType: 0,
  submitterType: 0,
  shouldFetchDataTypes: false,
  showStaticFileLinks: false,
  permittedFileExtensions: [],
  sampleFileExtensions: []
};

const maxSize: Record<DataSubmissionType, number> = {
  [DataSubmissionType.NDMM]: 20,
  [DataSubmissionType.AZMM]: 25,
  [DataSubmissionType.IANA]: 20,
  [DataSubmissionType.OHDC]: 20,
  [DataSubmissionType.SCNA]: 20,
  [DataSubmissionType.OHOTP]: 20
};

const hasUploadInstructions: Record<DataSubmissionType, boolean> = {
  [DataSubmissionType.NDMM]: false,
  [DataSubmissionType.AZMM]: false,
  [DataSubmissionType.IANA]: true,
  [DataSubmissionType.OHDC]: false,
  [DataSubmissionType.SCNA]: true,
  [DataSubmissionType.OHOTP]: false
};

export class DataSubmissionPage extends React.Component<{}, DataSubmissionPageState> {
  public store = new DataSubmissionPageStore();
  private fileUploadRef = React.createRef<FileUpload>();

  constructor(props: any) {
    super(props);

    this.state = { ...defaultState, organizations: [] };
  }

  public render() {
    const fileUploadHeader = (
      <p><b>File Upload</b> (Max Size: {maxSize[this.state.submissionType]}MB)</p>
    );

    return (
      <div className={styles.dataSubmission}>
        <div className="header">
          <h3>File Upload</h3>
        </div>
        <div className="subHeader">
          <h5>SUBMIT NEW FILE FOR INTEGRATION WITH STATE PMP</h5>
        </div>
        <Row>
          <Col md={6}>
            <p>
              Please upload a file. Upon Upload, click Submit to transfer the file.
              Larger files may take a few moments to upload.
              Please keep this page open until the Upload is complete.
            </p>
          </Col>
        </Row>
        <p>
          <span>Overdose Event Data</span>
          {this.state.showStaticFileLinks && (
            <>
              {this.state.sampleFileExtensions.map(fileExtension => (
                <StaticFileButton
                  key={Math.random()}
                  fileType={StaticFileType.SampleFiles}
                  fileExtension={fileExtension}
                  submissionType={this.state.submissionType}
                  submitterType={this.state.submitterType}
                  suffix="File"
                />
              ))}
              {hasUploadInstructions[this.state.submissionType] && (
                <StaticFileButton
                  key={Math.random()}
                  fileType={StaticFileType.UploadInstructions}
                  fileExtension="pdf"
                  submissionType={this.state.submissionType}
                  submitterType={this.state.submitterType}
                />
              )}
            </>
          )}
        </p>
        <Row>
          <Col md={6}>
            <OrganizationFormSelector
              id="organizationFormSelector"
              labelText="Organization"
              storeRef={makeRef(this.store, 'organizationId')}
              onValueChangedFunc={id => this.handleOrganizationChange(id)}
              fetchOptionsFunc={() => this.fetchOrganizations()}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormDataTypeSelector
              id="dataTypeSearch"
              storeRef={makeRef(this.store, 'dataSubmissionType')}
              labelText="What data type are you submitting?"
              onValueChangedFunc={(dataType: number) => this.onDataTypeChanged(dataType)}
              shouldFetchOptions={this.state.shouldFetchDataTypes}
              fetchOptionsFunc={() => this.fetchDataTypes()}
            />
          </Col>
        </Row>
        {this.state.submissionType === DataSubmissionType.SCNA && (
          <Row>
            <Col md={6}>
              <FormSubmitterTypeSelector
                id="submitterTypeSelector"
                storeRef={makeRef(this.store, 'submitterType')}
                labelText="What type of submitter are you?"
                onValueChangedFunc={(submitterType: SubmitterType) => this.onSubmitterTypeChanged(submitterType)}
              />
            </Col>
          </Row>
        )}
        <FileUpload
          ref={this.fileUploadRef}
          fileUploadHeader={fileUploadHeader}
          permittedFileExtensions={this.state.permittedFileExtensions}
          maxMbForFile={maxSize[this.state.submissionType]}
          isDisabled={this.state.uploadDisabled}
          organizationId={this.state.organizationId}
          submissionType={this.state.submissionType}
          submitterType={this.state.submitterType}
          allowMultipleFiles={false}
        />
      </div>
    );
  }

  private async fetchOrganizations(): Promise<Organization[]> {
    try {
      const response = await apiService.get<Organization[]>('/organization/listActive');
      this.setState({ organizations: response.data });
      return response.data;
    } catch (e) {
      NotificationToast.showNetworkError();
      return [];
    }
  }

  private onSubmitterTypeChanged(submitterType: SubmitterType): void {
    this.setState({
      submitterType,
      uploadDisabled: false,
      showStaticFileLinks: true
    });
  }

  private onDataTypeChanged(submissionType: DataSubmissionType): void {
    const uploadDisabled = submissionType === DataSubmissionType.SCNA;
    this.setState({
      submissionType,
      uploadDisabled,
      permittedFileExtensions: SupportedFileExtensions[submissionType],
      sampleFileExtensions: SampleFileExtensions[submissionType],
      showStaticFileLinks: submissionType !== DataSubmissionType.SCNA
    });
    this.store.resetValidationState('dataSubmissionType');
  }

  private getSelectedOrganization(): Organization | undefined {
    return this.state.organizations.find(org => org.id === this.state.organizationId);
  }

  private async fetchDataTypes(): Promise<DataSubmissionType[]> {
    try {
      const response = await apiService.get('license/dataTypes', {
        params: {
          state: this.getSelectedOrganization()?.address.state
        }
      });
      return response.data;
    } catch (e) {
      return [];
    }
  }

  private async handleOrganizationChange(organizationId: number): Promise<void> {
    this.fileUploadRef.current!.reset();
    this.store.dataSubmissionType = 0;
    this.setState(prevState => ({
      ...defaultState,
      organizationId,
      shouldFetchDataTypes: !prevState.shouldFetchDataTypes
    }));
  }
}
