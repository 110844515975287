import { DataSubmissionType } from '../../model/dataSubmission/DataSubmissionType';

const MEDICAL_MARIJUANA = 'Medical Marijuana Program';
// eslint-disable-next-line unused-imports/no-unused-vars-ts
const NALOXONE_ADMINISTRATION = 'Opioid Antidote';
// eslint-disable-next-line unused-imports/no-unused-vars-ts
const VITAL_STATISTICS = 'Vital Statistics';
// eslint-disable-next-line unused-imports/no-unused-vars-ts
const DRUG_COURT = 'Drug Court';
// eslint-disable-next-line unused-imports/no-unused-vars-ts
const MORTALITY = 'Mortality';
const OPIOID_TREATMENT_PROGRAM = 'Opioid Treatment Program';

export const DataSubmissionTypeDisplayName: { [index: number]: string } = [];
DataSubmissionTypeDisplayName[DataSubmissionType.NDMM] = MEDICAL_MARIJUANA;
DataSubmissionTypeDisplayName[DataSubmissionType.AZMM] = MEDICAL_MARIJUANA;
DataSubmissionTypeDisplayName[DataSubmissionType.IANA] = NALOXONE_ADMINISTRATION;
DataSubmissionTypeDisplayName[DataSubmissionType.OHDC] = DRUG_COURT;
DataSubmissionTypeDisplayName[DataSubmissionType.SCNA] = NALOXONE_ADMINISTRATION;
DataSubmissionTypeDisplayName[DataSubmissionType.OHOTP] = OPIOID_TREATMENT_PROGRAM;
