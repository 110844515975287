import { observable, reaction } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';
import awaitingApprovalUsersGridStore, { AwaitingApprovalUsersGridStore } from './awaitingApproval/AwaitingApprovalUsersGridStore';
import activeUsersGridStore, { ActiveUsersGridStore } from './active/ActiveUsersGridStore';
import inactiveUsersGridStore, { InactiveUsersGridStore } from './inactive/InactiveUsersGridStore';
import UsersGridPageResponseAggregate from '../../../model/grid/user/UsersGridPageResponseAggregate';
import UsersGridPageRequestAggregate from '../../../model/grid/user/UsersGridPageRequestAggregate';
import rejectedUsersGridStore, { RejectedUsersGridStore } from './rejected/RejectedUsersGridStore';
import Organization from '../../../model/organization/Organization';

export class ManageUsersTabsPageStore extends ValidatableObject {
  private readonly _awaitingApprovalUsersGridStore: AwaitingApprovalUsersGridStore = awaitingApprovalUsersGridStore;
  private readonly _activeUsersGridStore: ActiveUsersGridStore = activeUsersGridStore;
  private readonly _inactiveUsersGridStore: InactiveUsersGridStore = inactiveUsersGridStore;
  private readonly _rejectedUsersGridStore: RejectedUsersGridStore = rejectedUsersGridStore;

  @observable
  public organizationId?: number;

  @observable
  public searchPhrase = '';

  public constructor() {
    super();

    reaction(() => this.organizationId, organizationId => {
      this._awaitingApprovalUsersGridStore.organizationId = organizationId;
      this._activeUsersGridStore.organizationId = organizationId;
      this._inactiveUsersGridStore.organizationId = organizationId;
      this._rejectedUsersGridStore.organizationId = organizationId;
    });
  }

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }

  public refreshGrids(): void {
    const aggregate: UsersGridPageRequestAggregate = {
      organizationId: this.organizationId!,
      awaitingApprovalUsersGridPageRequest: this._awaitingApprovalUsersGridStore.getGridPageRequest(),
      activeUsersGridPageRequest: this._activeUsersGridStore.getGridPageRequest(),
      inactiveUsersGridPageRequest: this._inactiveUsersGridStore.getGridPageRequest(),
      rejectedUsersGridPageRequest: this._rejectedUsersGridStore.getGridPageRequest()
    };

    const params = { gridPageRequestAggregate: aggregate };
    const dataPromise = apiService.get<UsersGridPageResponseAggregate>('user/listForGridAggregate', { params });

    this._awaitingApprovalUsersGridStore.populateFromExternalData(dataPromise.then(result => result.data.awaitingApprovalUsersGridPageResponse));
    this._activeUsersGridStore.populateFromExternalData(dataPromise.then(result => result.data.activeUsersGridPageResponse));
    this._inactiveUsersGridStore.populateFromExternalData(dataPromise.then(result => result.data.inactiveUsersGridPageResponse));
    this._rejectedUsersGridStore.populateFromExternalData(dataPromise.then(result => result.data.rejectedUsersGridPageResponse));
  }
}

export default new ManageUsersTabsPageStore();
