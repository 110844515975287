import * as React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { Theme, ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Props } from 'react-select/lib/Select';
import { Omit } from 'react-router';
import { debounce } from '../../../helpers/reactHelpers';
import styles from './FormSelect.scss';

export type FormSelectOption = ValueType<any> & {
  value: number | string;
  label: string;
};

export type FormSelectBaseProps = Omit<Props<FormSelectOption>, 'className' | 'theme'> & {
  invalid: boolean;
};

abstract class FormSelectBase<TProps extends FormSelectBaseProps> extends React.Component<TProps> {
  protected constructor(props: Readonly<TProps>) {
    super(props);
  }

  public render(): React.ReactNode {
    const customTheme = (theme: Theme) => ({
      ...theme,
      borderRadius: 0,
      spacing: {
        controlHeight: 35,
        baseUnit: 3.5 // paddings
      },
      colors: {
        ...theme.colors,
        primary: this.props.invalid ? 'red' : '#5A53AF',
        primary25: '#F4F3FF',
        primary50: '#F4F3FF',
        neutral20: this.props.invalid ? 'red' : theme.colors.neutral20,
        neutral30: this.props.invalid ? 'red' : theme.colors.neutral30
      }
    });

    return this.getInput(customTheme);
  }

  protected abstract getInput(theme: any): React.ReactNode;

  protected handleValueChangeInternal(option: ValueType<FormSelectOption>): void {
    this.props.onChange(option.value);
  }
}

export interface FormSelectProps extends FormSelectBaseProps {
  options: FormSelectOption[];
}

export class FormSelect extends FormSelectBase<FormSelectProps> {
  protected constructor(props: Readonly<FormSelectProps>) {
    super(props);
  }

  protected getInput(theme: Theme): React.ReactNode {
    const { classNamePrefix, value, ...innerProps } = this.props;

    return (
      <Select
        {...innerProps}
        value={value}
        onChange={(option: ValueType<FormSelectOption>) => this.handleValueChangeInternal(option)}
        theme={theme}
        classNamePrefix={classNamePrefix || 'select'}
        className={styles.formSelect}
      />
    );
  }
}

interface AsyncFormSelectProps extends FormSelectBaseProps {
  fetchDataFunc: (searchPhrase: string) => Promise<FormSelectOption[]>;
}

export class AsyncFormSelect extends FormSelectBase<AsyncFormSelectProps> {
  constructor(props: Readonly<AsyncFormSelectProps>) {
    super(props);
  }

  protected getInput(theme: Theme): React.ReactNode {
    return (
      <AsyncSelect
        id={this.props.id}
        placeholder={this.props.placeholder}
        cacheOptions={false}
        loadOptions={debounce((searchPhrase: string) => this.getOptions(searchPhrase), 500)}
        defaultOptions
        value={this.props.value}
        onChange={(value: ValueType<FormSelectOption>) => this.handleValueChangeInternal(value)}
        isClearable={this.props.isClearable}
        backspaceRemovesValue={this.props.backspaceRemovesValue}
        theme={theme}
      />
    );
  }

  private getOptions(searchPhrase: string): Promise<FormSelectOption[]> {
    return this.props.fetchDataFunc(searchPhrase);
  }
}
