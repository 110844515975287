import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './PasswordCheckerSection.scss';
import { PasswordChecker } from '../../../helpers/passwordChecker';

export interface PasswordCheckerSectionProps {
  password: string;
}

@observer
export default class PasswordCheckerSection extends React.Component<PasswordCheckerSectionProps> {
  public render(): React.ReactNode {
    const { password } = this.props;
    return (
      <div className={styles.passwordChecker}>
        <p>Recent passwords must not be reused and passwords must:</p>
        <ul>
          <li className={this.getClassName(() => PasswordChecker.checkLength(password))}>
            Be between 8-15 characters long
          </li>
          <li className={this.getClassName(() => PasswordChecker.checkPasswordLowercaseChar(password))}>
            Contain at least one lower case character
          </li>
          <li className={this.getClassName(() => PasswordChecker.checkPasswordUppercaseChar(password))}>
            Contain at least one lower upper character
          </li>
          <li className={this.getClassName(() => PasswordChecker.checkPasswordNumericChar(password))}>
            Contain at least one numeral character
          </li>
          <li className={this.getClassName(() => PasswordChecker.checkPasswordSpecialChar(password))}>
            Contain at least one special character (&, %, # etc.)
          </li>
        </ul>
      </div>
    );
  }

  private getClassName(validateFunc: () => boolean): string {
    return validateFunc() ? 'valid' : 'invalid';
  }
}
