import React from 'react';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import IanaEditPatientInformationSection from './IanaPatientInformationSection/IanaEditPatientInformationSection';
import ScnaEditPatientInformationSection from './ScnaPatientInformationSection/ScnaEditPatientInformationSection';

export const EditPatientInformationSection = (
  submissionType: DataSubmissionType,
  handleSaveButton: () => void,
  handleCancelButton: () => void
) => {
  switch (submissionType) {
    case DataSubmissionType.IANA:
      return (
        <IanaEditPatientInformationSection
          onSaveButtonHandler={async () => handleSaveButton()}
          onCancelButtonHandler={async () => handleCancelButton()}
        />
      );
      break;
    case DataSubmissionType.SCNA:
      return (
        <ScnaEditPatientInformationSection
          onSaveButtonHandler={async () => handleSaveButton()}
          onCancelButtonHandler={async () => handleCancelButton()}
        />
      );
      break;
    default:
      throw new Error('DataSubmissionType not recognized');
  }
};
