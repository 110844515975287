import * as React from 'react';

interface SectionDividerProps {
    headerText: string;
}

export default class FormSectionDivider extends React.Component<SectionDividerProps> {
  public render(): React.ReactNode {
    return (
      <>
        <h5 className="text-uppercase">{this.props.headerText}</h5>
        <hr />
      </>
    );
  }
}
