import PNF from 'google-libphonenumber';
import Validator, { ValidationResult } from './Validator';

export default class ValidPhoneNumber implements Validator<string | undefined> {
  private readonly _message: string;

  public constructor(message = 'Invalid phone number') {
    this._message = message;
  }

  public validate(value: string | undefined): Promise<ValidationResult> {
    const phoneUtil = PNF.PhoneNumberUtil.getInstance();
    let parsedPhone: libphonenumber.PhoneNumber;
    try {
      parsedPhone = phoneUtil.parse(value, 'US');
    } catch (e) {
      return Promise.resolve({ isValid: false, message: this._message });
    }

    if (phoneUtil.isValidNumber(parsedPhone)) {
      return Promise.resolve({ isValid: true });
    }

    return Promise.resolve({ isValid: false, message: this._message });
  }
}
