import React from 'react';
import { Column, SortingRule } from 'react-table';
import { observer } from 'mobx-react';
import Grid, { GridProps } from '../../../../shared/grid/Grid';
import DataSubmissionErrorForGrid from '../../../../model/grid/dataSubmission/DataSubmissionErrorForGrid';
import { formatUsDateTime } from '../../../../helpers/displayHelper';

interface DataSubmissionErrorsGridProps extends GridProps<DataSubmissionErrorForGrid, string> {
  submissionId: number;
}

@observer
export default class DataSubmissionErrorsGrid extends Grid<DataSubmissionErrorsGridProps, DataSubmissionErrorForGrid, string> {
  protected defineColumns(): Array<Column<DataSubmissionErrorForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      { Header: 'Row Number', id: 'rowNumber', accessor: d => d.rowNumber },
      { Header: 'Error Messages', id: 'errorMessages', accessor: d => (<ul>{d.errorMessages.map(x => (<li>{x}</li>))}</ul>) },
      { Header: 'Submitted', id: 'submitted', accessor: d => formatUsDateTime(d.submittedDate) },
      { Header: 'First Name', id: 'firstName', accessor: d => d.firstName },
      { Header: 'Last Name', id: 'lastName', accessor: d => d.lastName },
      { Header: 'Date Of Birth', id: 'birthDate', accessor: d => d.birthDate },
      { Header: 'Address', id: 'address', accessor: d => d.address },
      { Header: 'City', id: 'city', accessor: d => d.city },
      { Header: 'State', id: 'state', accessor: d => d.state },
      { Header: 'Zip Code', id: 'zipCode', accessor: d => d.zipCode }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'RowNumber',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No records found';
  }
}
