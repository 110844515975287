import { observer } from 'mobx-react';
import React from 'react';
import FormInput from '../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import FormStateSelector from '../../../../shared/controls/formSelect/stateSelector/FormStateSelector';
import ZipCodeInput from '../../../../shared/controls/formInput/ZipCodeInput';
import PhoneFormInput from '../../../../shared/controls/formInput/PhoneFormInput';
import FormSectionContainer from '../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import { State } from '../../../../model/address/State';
import apiService from '../../../../services/ApiService';
import editOrganizationDetailsStore, { EditOrganizationDetailsStore } from '../EditOrganizationDetailsStore';
import Spinner from '../../../../shared/layout/spinner/Spinner';
import MessageBox, { MessageType } from '../../../../shared/layout/messageBox/MessageBox';

interface OrganizationEditSectionState {
  shouldFetchDataTypes: boolean;
}

@observer
export default class OrganizationDetailsEditSection extends React.Component<{}, OrganizationEditSectionState> {
  private _store: EditOrganizationDetailsStore = editOrganizationDetailsStore;

  public constructor(props: {}) {
    super(props);

    this.state = {
      shouldFetchDataTypes: false
    };
  }

  public render(): React.ReactNode {
    if (this._store.isBusy) {
      return <h3><Spinner size="1x" /> Loading...</h3>;
    }

    if (this._store.error) {
      return (
        <MessageBox
          id="agencyDetailsErrorBox"
          isVisible
          message={this._store.error}
          type={MessageType.Error}
        />
      );
    }

    return (
      <FormSectionContainer>
        <div className="row">
          <FormInput id="name" className="col-12" labelText="Name" storeRef={makeRef(this._store, 'name')} />
          <FormInput id="streetAddress" className="col-12" labelText="Address" storeRef={makeRef(this._store, 'street')} />
          <FormInput id="city" className="col-12 col-md-6" labelText="City" storeRef={makeRef(this._store, 'city')} />
          <FormStateSelector
            id="state"
            className="col-12 col-md-4"
            storeRef={makeRef(this._store, 'state')}
            labelText="State"
            onValueChangedFunc={(state: number) => this.onStateChanged(state)}
            fetchOptionsFunc={() => this.fetchStates()}
          />
          <ZipCodeInput id="zip" className="col-12 col-md-2" labelText="Zip" storeRef={makeRef(this._store, 'zip')} />
        </div>
        <div className="row">
          <PhoneFormInput id="phone" className="col-12 col-md-6" labelText="Phone Number" storeRef={makeRef(this._store, 'phone')} />
        </div>
        <div className="row">
          <FormInput id="email" className="col-12 col-md-6" labelText="Email Address" storeRef={makeRef(this._store, 'email')} />
        </div>
      </FormSectionContainer>
    );
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private onStateChanged(state: number): void {
    this.setState(prevState => ({ shouldFetchDataTypes: !prevState.shouldFetchDataTypes }));
    this._store.resetValidationState();
  }

  public async fetchStates(): Promise<State[]> {
    const response = await apiService.get('license/states');
    return response.data;
  }
}
