import React from 'react';
import {
  Button, Col, Form, Row
} from 'reactstrap';
import { observer } from 'mobx-react';
import ProfilePageStore from '../ProfilePageStore';
import EditProfileStore from '../editProfile/EditProfileStore';
import EditPhoneStore from '../editPhone/EditPhoneStore';
import EditPasswordStore from '../editPassword/EditPasswordStore';
import EditEmailStore from '../editEmail/EditEmailStore';
import EditOrganizationStore from './EditOrganizationStore';
import OrganizationFormSelector from '../../../../pages/management/users/organizationSelector/OrganizationFormSelector';
import { makeRef } from '../../../../helpers/Ref';
import Organization from '../../../../model/organization/Organization';
import apiService from '../../../../services/ApiService';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import BusyOverlay from '../../../layout/busyOverlay/BusyOverlay';

interface EditOrganizationSectionProps {
  toggleEditOrganizationFunc: () => void;
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}
const EditOrganizationSection: React.FC<EditOrganizationSectionProps> = (props: EditOrganizationSectionProps) => {
  const { store, toggleEditOrganizationFunc } = props;

  const addUserToOrganization = async (e: React.MouseEvent<any>): Promise<void> => {
    e.preventDefault();
    if (!await store.editOrganizationStore.validate()) {
      return;
    }

    const organization = await store.editOrganizationStore.addOrganization();
    if (organization) {
      store.userProfile!.organizations.push(organization);
      notificationToast.showSuccess('User added to organization');
    }
    toggleEditOrganizationFunc();
  };

  const fetchOrganizations = async (): Promise<Organization[]> => {
    try {
      const response = await apiService.get<Organization[]>(`/user/${store.userProfile!.id}/license/organizations/active`);
      return response.data;
    } catch (e) {
      notificationToast.showNetworkError();
      return [];
    }
  };

  const handleOrganizationChange = async (organizationId: number): Promise<void> => {
    store.editOrganizationStore.organizationId = organizationId;
  };

  return (
    <BusyOverlay isBusy={store.editOrganizationStore.isBusy}>
      <Form>
        <Row>
          <Col md={8}>
            <OrganizationFormSelector
              id="organizationFormSelector"
              labelText="Organization"
              storeRef={makeRef(store.editOrganizationStore, 'organizationId')}
              onValueChangedFunc={id => handleOrganizationChange(id)}
              fetchOptionsFunc={() => fetchOrganizations()}
            />
          </Col>
        </Row>
        <Row>
          <div className="col-6 col-sm-4 col-lg-3">
            <Button
              type="submit"
              color="primary"
              block
              onClick={(e: React.MouseEvent<any>) => addUserToOrganization(e)}
            >
              Save changes
            </Button>
          </div>
          <div className="col-6 col-sm-3 col-lg-2 pl-0">
            <Button color="secondary" block onClick={toggleEditOrganizationFunc}>Cancel</Button>
          </div>
        </Row>
      </Form>
    </BusyOverlay>
  );
};

export default observer(EditOrganizationSection);
