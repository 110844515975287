import { User } from 'oidc-client';

export default class Role {
  private _name: string;
  private _isApprissAdmin: boolean;
  private _permissions: string[];

  public get name(): string {
    return this._name;
  }

  public get isApprissAdmin(): boolean {
    return this._isApprissAdmin;
  }

  public get permissions(): string[] {
    return this._permissions;
  }

  constructor(name: string, permissions: string[], isApprissAdmin = false) {
    this._name = name;
    this._isApprissAdmin = isApprissAdmin;
    this._permissions = permissions;
  }

  public static fromOidcUser(user: User): Role {
    return new Role(user.profile.role, user.profile.permissions, user.profile.is_appriss_admin);
  }

  public hasPermission(permissionName: string): boolean {
    if (this.isApprissAdmin) {
      return true;
    }

    if (!this.permissions) {
      return false;
    }

    return this.permissions.includes(permissionName);
  }
}
