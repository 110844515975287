import * as React from 'react';
import styles from './CreateUserReviewSection.scss';
import InlineEntry from '../../../layout/inlineEntry/InlineEntry';
import { State } from '../../../../model/address/State';
import { formatPhoneNumber } from '../../../../helpers/displayHelper';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';
import { CreateUserPageSection } from '../CreateUserPageSection';
import CreateUserStore from '../CreateUserStore';
import { CreateUserPersonalInfoSectionStore } from '../personalInfoSection/CreateUserPersonalInfoSectionStore';
import { CreateUserOrganizationSectionStore } from '../organizationSection/CreateUserOrganizationSectionStore';
import { combineClassNames } from '../../../../helpers/reactHelpers';

export default class CreateUserReviewSection extends React.Component<CreateUserReviewSectionProps> {
  private readonly _mainStore = this.props.store;
  private readonly _personalInfoStore = this._mainStore.personalInfoStore;
  private _organizationSectionStore = this._mainStore.organizationStore;

  public render(): React.ReactNode {
    return (
      <div className={combineClassNames(styles.reviewSection, 'd-flex', 'flex-column')}>
        <div className="subsection_header">
          <span>Personal Info</span>
          <button type="button" className="link" onClick={e => this.goToPersonalInfoPage(e)}>Edit</button>
        </div>
        <div className="subsection_content">
          <InlineEntry value={this._personalInfoStore.firstName} id="firstName" labelText="First Name" />
          <InlineEntry value={this._personalInfoStore.lastName} id="lastName" labelText="Last Name" />
          <InlineEntry value={formatPhoneNumber(this._personalInfoStore.phone)} id="phone" labelText="Phone" />
          <InlineEntry value={this._personalInfoStore.email} id="email" labelText="Email" />
        </div>
        <div className="subsection_header">
          <span>Organization Info</span>
          <button type="button" className="link" onClick={e => this.goToOrganizationPage(e)}>Edit</button>
        </div>
        <div className="subsection_content">
          {this.renderOrganizationDetails()}
        </div>
      </div>
    );
  }

  private renderOrganizationDetails(): React.ReactNode {
    const organization = this._organizationSectionStore.selectedOrganization!;

    return (
      <>
        <InlineEntry value={this._organizationSectionStore.userRole!.name} id="orgUserRole" labelText="Role" />
        <InlineEntry value={StateDisplayName[organization.address.state]} id="orgState" labelText="State" />
        <InlineEntry value={organization.name} id="orgName" labelText="Name" />
        <InlineEntry
          id="orgAddress"
          labelText="Address"
          value={`${organization.address.street} ${organization.address.city},
                  ${State[organization.address.state]} ${organization.address.zipCode}`}
        />
        <InlineEntry value={formatPhoneNumber(organization.phone)} id="orgPhone" labelText="Phone" />
        <InlineEntry value={organization.email} id="orgEmail" labelText="Email" />
      </>
    );
  }

  private goToPersonalInfoPage(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    this._mainStore.pageSection = CreateUserPageSection.PersonalInfo;
  }

  private goToOrganizationPage(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    this._mainStore.pageSection = CreateUserPageSection.Organization;
  }
}

interface CreateUserReviewSectionProps {
  store: CreateUserStore<CreateUserPersonalInfoSectionStore, CreateUserOrganizationSectionStore>;
}
