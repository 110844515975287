import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import { NaloxoneRecord } from './NaloxoneRecord';
import { Section } from './Section';
import ZonedDateTime from '../../../../model/naloxone/DateTime/ZonedDateTime';
import { naloxoneService } from '../../../../services/NaloxoneAdministration/NaloxoneService';
import { ianaNaloxoneRequestFactory } from './requestFactories/IanaNaloxoneRequestFactory';
import { scnaNaloxoneRequestFactory } from './requestFactories/ScnaNaloxoneRequestFactory';
import {
  IanaPatientContactSectionStore
} from './contactInformationSection/IanaPatientContactSection/IanaPatientContactSectionStore';
import {
  ScnaPatientContactSectionStore
} from './contactInformationSection/ScnaPatientContactSection/ScnaPatientContactSectionStore';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import {
  IanaPatientInformationSectionStore
} from './patientInformationSection/IanaPatientInformationSection/IanaPatientInformationSectionStore';
import {
  ScnaPatientInformationSectionStore
} from './patientInformationSection/ScnaPatientInformationSection/ScnaPatientInformationSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from './administerInformationSection/ScnaAdministerInformationSection/ScnaAdministerInformationSectionStore';
import {
  IanaAdministerInformationSectionStore
} from './administerInformationSection/IanaAdministerInformationSection/IanaAdministerInformationSectionStore';

export default class DetailsNaloxoneEventStore extends ValidatableObject {
  public administerInformationSectionStore: IanaAdministerInformationSectionStore | ScnaAdministerInformationSectionStore | undefined;
  public patientInformationSectionStore: IanaPatientInformationSectionStore | ScnaPatientInformationSectionStore | undefined;
  public patientContactSectionStore: IanaPatientContactSectionStore | ScnaPatientContactSectionStore | undefined;
  public async fetchRecord(id: string | undefined): Promise<NaloxoneRecord> {
    const response = await naloxoneService.fetchRecord(id);
    const {
      id: recordId,
      submissionType,
      incident_MedicationDosage,
      firstName,
      incident_MedicationAdministered,
      lastName,
      middleName,
      patient_City,
      incident_MedicationAdministerId,
      incident_OrganizationName,
      patient_Address2,
      patient_Address1,
      incident_NotifiedDateTime,
      patient_State,
      dateOfBirth,
      incident_MedicationDosageUnit,
      patient_Gender,
      incident_MedicationName,
      patient_ZipCode,
      patient_Identifier,
      submissionId,
      timezone,
      // SCNA Fields
      submitterType,
      firstResponder_Type,
      firstResponder_FirstAndLastName,
      incident_County,
      incident_ZipCode
    } = response;

    this.initializeStores(submissionType);

    this.administerInformationSectionStore!.submissionId = submissionId;
    this.administerInformationSectionStore!.submissionType = submissionType;
    this.patientInformationSectionStore!.id = recordId;
    this.patientInformationSectionStore!.dateOfBirth = dateOfBirth;
    this.patientInformationSectionStore!.patientIdentifier = patient_Identifier;

    this.patientInformationSectionStore!.submissionType = submissionType;
    this.patientInformationSectionStore!.firstName = firstName;
    this.patientInformationSectionStore!.middleName = middleName;
    this.patientInformationSectionStore!.lastName = lastName;
    this.patientInformationSectionStore!.patientGender = patient_Gender;

    this.patientContactSectionStore!.patientAddress1 = patient_Address1;
    this.patientContactSectionStore!.patientAddress2 = patient_Address2;
    this.patientContactSectionStore!.patientCity = patient_City;
    this.patientContactSectionStore!.patientZipCode = patient_ZipCode;
    this.patientContactSectionStore!.patientState = patient_State;

    this.administerInformationSectionStore!.incidentMedicationAdministerId = incident_MedicationAdministerId;
    this.administerInformationSectionStore!.incidentOrganizationName = incident_OrganizationName;
    // eslint-disable-next-line max-len
    if (submissionType === DataSubmissionType.IANA) {
      this.administerInformationSectionStore!.medicationAdministered = ZonedDateTime.fromApiResponse(incident_MedicationAdministered, timezone);
      this.administerInformationSectionStore!.incidentNotified = ZonedDateTime.fromApiResponse(incident_NotifiedDateTime, timezone);
      this.administerInformationSectionStore!.incidentMedicationName = incident_MedicationName;
      this.administerInformationSectionStore!.incidentMedicationDosage = incident_MedicationDosage;
      this.administerInformationSectionStore!.incidentMedicationDosageUnit = incident_MedicationDosageUnit;
    }
    if (submissionType === DataSubmissionType.SCNA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.administerInformationSectionStore!.medicationAdministered = incident_MedicationAdministered;
      this.administerInformationSectionStore!.submitterType = submitterType;
      this.administerInformationSectionStore!.firstResponder_Type = firstResponder_Type;
      this.administerInformationSectionStore!.firstResponder_FirstAndLastName = firstResponder_FirstAndLastName;
      this.administerInformationSectionStore!.incidentCounty = incident_County;
      this.administerInformationSectionStore!.incidentZipCode = incident_ZipCode;
    }

    return response;
  }

  public async save(id: string, sectionName: Section): Promise<{
    isSuccess: boolean;
    error: string;
  }> {
    const { submissionType } = this.administerInformationSectionStore!;

    let request;

    if (submissionType === DataSubmissionType.IANA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      request = ianaNaloxoneRequestFactory.createRequest(sectionName, this.patientContactSectionStore!, this.patientInformationSectionStore!, this.administerInformationSectionStore!);
    }

    if (submissionType === DataSubmissionType.SCNA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      request = scnaNaloxoneRequestFactory.createRequest(sectionName, this.patientContactSectionStore!, this.patientInformationSectionStore!, this.administerInformationSectionStore!);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { status, statusText } = await naloxoneService.updateRecord(request, id, submissionType);
    return {
      isSuccess: status >= 200 && status < 300,
      error: statusText
    };
  }

  private initializeStores(dataType: DataSubmissionType) {
    switch (dataType) {
      case DataSubmissionType.IANA:
        this.patientContactSectionStore = new IanaPatientContactSectionStore();
        this.patientInformationSectionStore = new IanaPatientInformationSectionStore();
        this.administerInformationSectionStore = new IanaAdministerInformationSectionStore();
        break;
      case DataSubmissionType.SCNA:
        this.patientContactSectionStore = new ScnaPatientContactSectionStore();
        this.patientInformationSectionStore = new ScnaPatientInformationSectionStore();
        this.administerInformationSectionStore = new ScnaAdministerInformationSectionStore();
        break;
      default:
        throw new Error('DataSubmissionType not recognized');
    }
  }
}
