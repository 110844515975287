export abstract class PasswordChecker {
  public static checkLength(value: string): boolean {
    return value.length >= 8 && value.length <= 15;
  }

  public static checkPasswordUppercaseChar(value: string): boolean {
    return value.toLowerCase() !== value;
  }

  public static checkPasswordLowercaseChar(value: string): boolean {
    return value.toUpperCase() !== value;
  }

  public static checkPasswordNumericChar(value: string): boolean {
    return /\d/.test(value);
  }

  public static checkPasswordSpecialChar(value: string): boolean {
    const specialChars = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]/;
    return specialChars.test(value);
  }
}
