import Alert from 'react-s-alert';
import apiService from '../../../services/ApiService';

class NotificationToast {
  private readonly defaultOptions: Alert.SAlertConfigProps = {
    position: 'top-right',
    offset: 48,
    effect: 'slide',
    timeout: 30000
  };

  public showSuccess(message: string, customOptions?: Alert.SAlertConfigProps): void {
    const alertOptions = {
      ...this.defaultOptions,
      ...customOptions
    };

    Alert.success(message, alertOptions);
  }

  public showInfo(message: string, customOptions?: Alert.SAlertConfigProps): void {
    const infoOptions = {
      ...this.defaultOptions,
      ...customOptions
    };

    Alert.info(message, infoOptions);
  }

  public showError(message: string, customOptions?: Alert.SAlertConfigProps): void {
    const errorAlertOptions = {
      ...this.defaultOptions,
      ...customOptions,
      timeout: 'none',
    };

    Alert.error(message, errorAlertOptions);
  }

  public showNetworkError(): void {
    const errorMessage = 'Network error. Please try again';
    this.showError(errorMessage);
  }
}

const notificationToast = new NotificationToast();
apiService.interceptors.response.use(undefined, error => {
  if (!error.response || error.response.status === 500) {
    notificationToast.showNetworkError();
  }

  switch (error.response.status) {
    case 401:
      notificationToast.showError('You need to log in to access this page');
      break;
    case 403:
      notificationToast.showError('You lack permissions to access this page');
      break;
  }

  return Promise.reject(error);
});

export default notificationToast;
