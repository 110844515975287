import React from 'react';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import IanaPreviewAdministerInformationSection
  from './IanaAdministerInformationSection/IanaPreviewAdministerInformationSection';
import ScnaPreviewAdministerInformationSection
  from './ScnaAdministerInformationSection/ScnaPreviewAdministerInformationSection';

export interface PreviewAdministerSectionProps {
  submissionType: DataSubmissionType;
  onUpdateButtonHandler: (e: React.MouseEvent<any>) => void;
}

export default class PreviewAdministerInformationSection extends React.Component<PreviewAdministerSectionProps> {
  public constructor(props: any) {
    super(props);
  }

  public render() {
    switch (this.props.submissionType) {
      case DataSubmissionType.IANA:
        return (
          <IanaPreviewAdministerInformationSection
            onUpdateButtonHandler={this.props.onUpdateButtonHandler}
          />
        );
        break;
      case DataSubmissionType.SCNA:
        return (
          <ScnaPreviewAdministerInformationSection
            onUpdateButtonHandler={this.props.onUpdateButtonHandler}
          />
        );
        break;
      default:
        return <></>;
    }
  }
}
