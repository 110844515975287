import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SpinnerProps {
  size: SizeProp;
}

export default class Spinner extends React.Component<SpinnerProps> {
  public render(): React.ReactNode {
    return (
      <FontAwesomeIcon size={this.props.size} spin icon={faSpinner} className="icon" />
    );
  }
}
