import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React, { PropsWithChildren } from 'react';
import {
  Button, Col, Form, FormGroup, Row
} from 'reactstrap';
import styles from './SearchForm.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

export interface SearchFormProps extends PropsWithChildren<{}>{
  searchFn: (e: React.MouseEvent<HTMLButtonElement>) => void;
  clearFn?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export const SearchForm: React.FC<SearchFormProps> = (props: SearchFormProps) => (
  <Form className={combineClassNames(styles.searchForm, 'mb-5')}>
    <Row>
      {props.children}
      <Col className="d-flex align-items-end ml-auto">
        <FormGroup className="w-100">
          <Button
            id="searchButton"
            color="primary"
            block
            className="searchButton"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.searchFn(e)}
            type="submit"
            disabled={props.disabled}
          >
            <span className="pr-3">Search</span>
            <FontAwesomeIcon icon={faSearch} className="icon" />
          </Button>
          {props.clearFn && (
            <Button
              color="link"
              className="clear"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (props.clearFn) props.clearFn(e);
              }}
              disabled={props.disabled}
            >
              Clear
            </Button>
          )}
        </FormGroup>
      </Col>
    </Row>
  </Form>
);
