import { observer } from 'mobx-react';
import { FormSelectOption } from '../FormSelect';
import ValidatableObject from '../../../validation/ValidatableObject';
import FormSelectorBase from '../FormSelectorBase';
import { SubmitterType } from '../../../../helpers/enums/submitterType';

@observer
export default class FormFirstResponderTypeSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, SubmitterType> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && s.value === storeRefValue) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return [
      { value: 'EMS', label: 'EMS' },
      { value: 'LAW ENFORCEMENT', label: 'Law Enforcement' },
      { value: 'FIRE DEPARTMENT', label: 'Fire Department' }
    ];
  }

  protected prepareFetchedOptions(data: FormSelectOption[]): FormSelectOption[] {
    return data;
  }
}
