import { computed, observable } from 'mobx';
import Validator from './validators/Validator';
import ValidatableObject from './ValidatableObject';
import FieldValidationContext from './FieldValidationContext';

export default class FieldValidationCollection<TClass extends ValidatableObject, TKey extends keyof TClass> {
  private readonly _key: TKey;
  private _validators: Array<Validator<TClass[TKey]>> = [];

  @observable
  private _validationState: ValidationState = ValidationState.NotValidated;

  @observable
  private _messages: string[] = [];

  @computed
  public get validationState(): ValidationState {
    return this._validationState;
  }

  @computed
  public get messages(): string[] {
    return this._messages;
  }

  public constructor(key: TKey) {
    this._key = key;
  }

  public addValidator(validator: Validator<unknown>) {
    this._validators.push(validator);
  }

  public async validate(target: TClass): Promise<boolean> {
    const context = new FieldValidationContext(target, this._key as string);
    this._validationState = ValidationState.ValidationInProgress;

    const promises = this._validators.map(v => v.validate(target[this._key], context));
    const results = await Promise.all(promises);
    for (const result of results) {
      if (result.isValid) {
        context.addValidationResult(ValidationState.Valid);
      } else {
        context.addValidationResult(ValidationState.Invalid, result.message);
      }
    }

    if (context.currentState === ValidationState.Invalid) {
      this._validationState = context.currentState;
      this._messages = context.messages;
      return false;
    }

    this._validationState = context.currentState;
    this._messages = context.messages;
    return true;
  }

  public reset(): void {
    this._validationState = ValidationState.NotValidated;
    this._messages = [];
  }
}

export enum ValidationState {
  NotValidated,
  ValidationInProgress,
  Valid,
  Invalid
}
