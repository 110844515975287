import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'reactstrap';
import routePaths from '../../../../constants/routePaths';
import { page } from '../../../../services/route/decorators';
import { FormContainer } from '../../../../shared/layout/formContainer/FormContainer';
import Steps, { StepOption } from '../../../../shared/controls/steps/Steps';
import CreateNaloxoneEventStore from './CreateNaloxoneEventStore';
import { AdministerInformationSection } from './administerInformationSection/AdministerInformationSection';
import FormNavigationFooter from '../../../../shared/controls/formNavigationFooter/formNavigationFooter';
import { PatientInformationSection } from './patientInformationSection/PatientInformationSection';
import CreateNaloxoneEventReviewSection from './reviewSection/CreateNaloxoneEventReviewSection';
import { FormCompleteSection } from '../../../../shared/controls/formCompleteSection/FormCompleteSection';
import NotificationToast from '../../../../shared/layout/notificationToast/NotificationToast';
import permissions from '../../../../model/user/permissions';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import { IanaPatientInformationSectionStore } from './patientInformationSection/ianaPatientInformationForm/IanaPatientInformationSectionStore';
import { ScnaPatientInformationSectionStore } from './patientInformationSection/scnaPatientInformationForm/ScnaPatientInformationSectionStore';

export enum CreateNaloxoneEventPageSection {
  AdministerInformation = 1,
  PatientInformation,
  NaloxoneEventReview,
  NaloxoneEventComplete
}

interface CreateNaloxoneEventPageState {
  isSubmitting: boolean;
  guid: string;
}

@page({
  path: routePaths.data.naloxone.create,
  hasPermission: permissions.data.submissionCreate
})
@observer
export default class CreateNaloxoneEventPage extends React.Component<{}, CreateNaloxoneEventPageState> {
  private _mainStore: CreateNaloxoneEventStore = new CreateNaloxoneEventStore();

  public constructor(props: {}) {
    super(props);

    this.state = {
      isSubmitting: false,
      guid: ''
    };
  }

  public setPatientInformationStore(submissionType: DataSubmissionType) {
    switch (submissionType) {
      case DataSubmissionType.IANA:
        this._mainStore.patientInformationSectionStore = new IanaPatientInformationSectionStore();
        break;
      case DataSubmissionType.SCNA:
        this._mainStore.patientInformationSectionStore = new ScnaPatientInformationSectionStore();
        break;
      default:
        throw new Error('Invalid submission type');
    }
  }

  public render(): React.ReactNode {
    const currentSection = this._mainStore.pageSection;
    const stepOptions: StepOption[] = [
      { id: CreateNaloxoneEventPageSection.AdministerInformation, description: 'Administer Information' },
      { id: CreateNaloxoneEventPageSection.PatientInformation, description: 'Patient Information' },
      { id: CreateNaloxoneEventPageSection.NaloxoneEventReview, description: 'Review' },
      { id: CreateNaloxoneEventPageSection.NaloxoneEventComplete, description: 'Complete' }
    ];

    const formCompleteContent: React.ReactNode = (
      <>
        <p>Thank you for submitting a new Opioid Antidote record.</p>
        <p>Your record identifier is <b>{this.state.guid}</b>.</p>
      </>
    );

    return (
      <FormContainer headerText="Add New Overdose Incident">
        <Steps steps={stepOptions} activeStepId={this._mainStore.pageSection} />
        <Form>
          {currentSection === CreateNaloxoneEventPageSection.AdministerInformation
            && <AdministerInformationSection administerInformationSectionStore={this._mainStore.administerInformationSectionStore} setPatientInformationStoreFn={submissionType => this.setPatientInformationStore(submissionType)} />}
          {currentSection === CreateNaloxoneEventPageSection.PatientInformation
          && <PatientInformationSection patientInformationSectionStore={this._mainStore.patientInformationSectionStore!} />}
          {currentSection === CreateNaloxoneEventPageSection.NaloxoneEventReview
          && <CreateNaloxoneEventReviewSection store={this._mainStore} />}
          {currentSection === CreateNaloxoneEventPageSection.NaloxoneEventComplete
          && <FormCompleteSection content={formCompleteContent} linkPath={routePaths.dashboard.root} linkText="Back To Dashboard" />}
        </Form>
        <FormNavigationFooter
          isSubmitting={() => this.state.isSubmitting}
          cancelLink={routePaths.dashboard.root}
          continueButtonDisabled={() => false}
          shouldRenderContinueButton={() => this.shouldRenderContinueButton()}
          shouldRenderSubmitButton={() => this.shouldRenderSubmitButtion()}
          shouldRenderBackButton={() => this._mainStore.pageSection !== CreateNaloxoneEventPageSection.AdministerInformation
            && this._mainStore.pageSection !== CreateNaloxoneEventPageSection.NaloxoneEventComplete}
          shouldRenderCancelLink={() => this.shouldRenderCancelButton()}
          onContinueButtonClickedHandler={e => this.onContinueButtonClickedHandler(e)}
          onSubmitButtonClickedHandler={e => this.onSubmitButtonClickedHandler(e)}
          onBackButtonClickedHandler={e => this._mainStore.pageSection--}
        />
      </FormContainer>
    );
  }

  private async onSubmitButtonClickedHandler(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    try {
      const guid = await this._mainStore.createNaloxoneEvent();
      this.setState({ guid });
      this._mainStore.pageSection++;
    } catch (ex) {
      NotificationToast.showNetworkError();
      throw ex;
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  private async onContinueButtonClickedHandler(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();

    switch (this._mainStore.pageSection) {
      case CreateNaloxoneEventPageSection.AdministerInformation:
        if (!await this._mainStore.administerInformationSectionStore.validate()) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!await this._mainStore.administerInformationSectionStore.AdministerInformationFormStore?.validate()) {
          return;
        }
        break;
      case CreateNaloxoneEventPageSection.PatientInformation:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!await this._mainStore.patientInformationSectionStore!.validate()) {
          return;
        }
        break;
    }
    this._mainStore.pageSection++;
  }

  private shouldRenderContinueButton(): boolean {
    return this._mainStore.pageSection === CreateNaloxoneEventPageSection.AdministerInformation
      || this._mainStore.pageSection === CreateNaloxoneEventPageSection.PatientInformation;
  }

  private shouldRenderSubmitButtion(): boolean {
    return this._mainStore.pageSection === CreateNaloxoneEventPageSection.NaloxoneEventReview;
  }

  private shouldRenderCancelButton(): boolean {
    return this._mainStore.pageSection !== CreateNaloxoneEventPageSection.NaloxoneEventComplete;
  }
}
