import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import { Gender } from '../../../../../../helpers/enums/gender';
import nodaTimeHelper from '../../../../../../helpers/nodaTimeHelper';
import NotDaysAhead from '../../../../../../shared/validation/validators/NotDaysAhead';
import Conditional from '../../../../../../shared/validation/validators/Conditional';
import FieldValidationContext from '../../../../../../shared/validation/FieldValidationContext';
import { DataSubmissionType } from '../../../../../../model/dataSubmission/DataSubmissionType';

export class ScnaPatientInformationSectionStore extends ValidatableObject {
  public submissionType: DataSubmissionType = 0;

  @validate(() => new NotEmpty())
  @observable
  public id = '';

  @validate(() => new NotEmpty())
  @observable
  public firstName = '';

  @validate(() => new NotEmpty())
  @observable
  public lastName = '';

  @observable
  public middleName = '';

  @validate(() => new NotEmpty(), () => new NotDaysAhead(1))
  @observable
  public dateOfBirth = '';

  public get dateOfBirthForPreview() {
    return nodaTimeHelper.toDate(this.dateOfBirth);
  }

  @observable
  public patientIdentifier = '';

  @observable
  @validate(() => new Conditional(ScnaPatientInformationSectionStore.IsIana, new NotEmpty()))
  public patientGender: Gender = Gender.Male;

  private static IsIana(value: any, context: FieldValidationContext): boolean {
    const { submissionType } = context.target as ScnaPatientInformationSectionStore;
    return submissionType === DataSubmissionType.IANA;
  }
}
