import { observer } from 'mobx-react';
import ValidatableObject from '../../../validation/ValidatableObject';
import { FormSelectOption } from '../FormSelect';
import FormSelectorBase from '../FormSelectorBase';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import { DataSubmissionTypeDisplayName } from '../../../../helpers/enums/dataSubmissionTypeDisplayName';
import userStore from '../../../../data/UserStore';

@observer
export default class FormDataTypeSelector<T extends ValidatableObject, TKey extends keyof T>
  extends FormSelectorBase<T, TKey, DataSubmissionType> {
  protected getStaticOptions(): FormSelectOption[] {
    return [];
  }

  protected prepareFetchedOptions(data: DataSubmissionType[]): FormSelectOption[] {
    if (userStore.session?.role?.isApprissAdmin) {
      return data.map<FormSelectOption>(key => ({
        value: key,
        label: `${DataSubmissionTypeDisplayName[key]} (${DataSubmissionType[key]})`
      }));
    }

    return data.map<FormSelectOption>(key => ({
      value: key,
      label: DataSubmissionTypeDisplayName[key]
    }));
  }
}
