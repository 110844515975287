import { observer } from 'mobx-react';
import { FormSelectOption } from '../FormSelect';
import { State } from '../../../../model/address/State';
import ValidatableObject from '../../../validation/ValidatableObject';
import FormSelectorBase from '../FormSelectorBase';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';

@observer
export default class FormStateSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, State> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && s.value === storeRefValue) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return Object.keys(State).filter(key => !isNaN(Number(State[key]))).map((key, index) => ({
      value: (index + 1).toString(),
      label: `${key} (${StateDisplayName[State[key]]})`
    }));
  }

  protected prepareFetchedOptions(data: State[]): FormSelectOption[] {
    return data.map<FormSelectOption>(key => ({
      value: key,
      label: `${State[key]} (${StateDisplayName[key]})`
    }));
  }
}
