import { observer } from 'mobx-react';
import React from 'react';
import FormInput from '../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import PasswordCheckerSection from '../../../../shared/controls/passwordChecker/PasswordCheckerSection';
import { RegisterUserPersonalInfoSectionStore } from './RegisterUserPersonalInfoSectionStore';

interface PersonalInfoPasswordSectionProps {
  personalInfoStore: RegisterUserPersonalInfoSectionStore;
}

@observer
export default class RegisterUserPersonalInfoPasswordSection extends React.Component<PersonalInfoPasswordSectionProps> {
  private _store: RegisterUserPersonalInfoSectionStore = this.props.personalInfoStore;

  public render(): React.ReactNode {
    return (
      <>
        <div className="row mb-3">
          <FormInput
            id="password"
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'password')}
            labelText="Password"
            type="password"
          />
          <FormInput
            id="confirmPassword"
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'confirmPassword')}
            labelText="Confirm Password"
            type="password"
          />
        </div>
        <div>
          <PasswordCheckerSection password={this._store.password} />
        </div>
      </>
    );
  }
}
