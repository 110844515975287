import EditPhoneStore from '../../../shared/user/profile/editPhone/EditPhoneStore';
import apiService from '../../../services/ApiService';

export class EditProfilePhoneStore extends EditPhoneStore {
  public async updatePhone(): Promise<void> {
    this._isBusy = true;

    try {
      await apiService.post('/user/updatePhone', {
        phone: this.newPhone
      });
    } finally {
      this._isBusy = false;
    }
  }
}
