import React from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { makeRef } from '../../../../helpers/Ref';
import PhoneFormInput from '../../../controls/formInput/PhoneFormInput';
import FormSectionContainer from '../../../layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../controls/formInput/FormInput';
import { CreateUserPersonalInfoSectionStore } from './CreateUserPersonalInfoSectionStore';
import { debounce } from '../../../../helpers/reactHelpers';

@observer
export class CreateUserPersonalInfoSection extends React.Component<PersonalInfoSectionProps> {
  private _store: CreateUserPersonalInfoSectionStore = this.props.personalInfoStore;
  private readonly debouncedFetchExistingUsersFn: () => void;

  constructor(props: PersonalInfoSectionProps) {
    super(props);
    this.debouncedFetchExistingUsersFn = debounce(this.fetchExistingUsers, 500);
  }

  public render(): React.ReactNode {
    return (
      <FormSectionContainer>
        <div className="row">
          <FormInput id="firstName" className="col-12 col-sm-6" storeRef={makeRef(this._store, 'firstName')} labelText="First Name" />
          <FormInput id="lastName" className="col-12 col-sm-6" storeRef={makeRef(this._store, 'lastName')} labelText="Last Name" />
        </div>
        <div className="row">
          <PhoneFormInput
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'phone')}
            id="phone"
            labelText="Phone Number"
            onChange={this.onPhoneChangeInternal}
          />
        </div>
        <div className="row">
          <FormInput
            id="email"
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'email')}
            labelText="Email"
            onChange={this.onEmailChangeInternal}
          />
        </div>
        {this.props.children}
      </FormSectionContainer>
    );
  }

  private onPhoneChangeInternal = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.debouncedFetchExistingUsersFn();
  };

  private onEmailChangeInternal = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.debouncedFetchExistingUsersFn();
  };

  private fetchExistingUsers = async () => {
    const { email, phone } = this._store;
    if (!email && !phone) return;

    if (await this.emailInCorrectState(email) && await this.phoneInCorrectState(phone)) {
      await this._store.getExistingUsers(email, phone);
    }
  };

  private async emailInCorrectState(email: string): Promise<boolean> {
    if (!email) return true;
    return this._store.validate('email');
  }

  private async phoneInCorrectState(phone: string): Promise<boolean> {
    if (!phone) return true;
    return this._store.validate('phone');
  }
}

export type PersonalInfoSectionProps = {
  personalInfoStore: CreateUserPersonalInfoSectionStore;
  onEmailFieldChange?: (value: string) => void;
  onPhoneFieldChange?: (value: string) => void;
} & RouteComponentProps;

export default withRouter(CreateUserPersonalInfoSection);
