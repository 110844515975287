// https://simple.wikipedia.org/wiki/List_of_U.S._states_and_territories_by_time_zone

export enum Timezone {
  CST6CDT = 1,
  EST5EDT,
  GMT10,
  GMT4,
  GMT9,
  Guam,
  MST7MDT,
  PST8PDT,
  PagoPago,
  PortOfSpain
}

const CST6CDT = 'Central Standard Time (CST6CDT)';
const EST5EDT = 'Eastern Standard Time (EST5EDT)';
const GMT10 = 'Hawaii-Aleutian Standard Time (GMT-10)';
const GMT4 = 'Atlantic Standard Time (GMT-4)';
const GMT9 = 'Alaska Standard Time (GMT-9)';
const Guam = 'Guam (Pacific/Guam)';
const MST7MDT = 'Mountain Standard Time (MST7MDT)';
const PST8PDT = 'Pacific Standard Time (PST8PDT)';
const PagoPago = 'American Samoa (Pacific/Pago_Pago)';
const PortOfSpain = 'Virgin Islands (America/Port_of_Spain)';

export const TimeZoneDisplayName: { [index: number]: string } = [];
TimeZoneDisplayName[Timezone.CST6CDT] = CST6CDT;
TimeZoneDisplayName[Timezone.EST5EDT] = EST5EDT;
TimeZoneDisplayName[Timezone.GMT10] = GMT10;
TimeZoneDisplayName[Timezone.GMT4] = GMT4;
TimeZoneDisplayName[Timezone.GMT9] = GMT9;
TimeZoneDisplayName[Timezone.Guam] = Guam;
TimeZoneDisplayName[Timezone.MST7MDT] = MST7MDT;
TimeZoneDisplayName[Timezone.PST8PDT] = PST8PDT;
TimeZoneDisplayName[Timezone.PagoPago] = PagoPago;
TimeZoneDisplayName[Timezone.PortOfSpain] = PortOfSpain;

export const TimeZoneIanaValue: {[index: number]: string} = [];
TimeZoneIanaValue[Timezone.CST6CDT] = 'CST6CDT';
TimeZoneIanaValue[Timezone.EST5EDT] = 'EST5EDT';
TimeZoneIanaValue[Timezone.GMT10] = 'Etc/GMT-10';
TimeZoneIanaValue[Timezone.GMT4] = 'America/Puerto_Rico';
TimeZoneIanaValue[Timezone.GMT9] = 'Etc/GMT-9';
TimeZoneIanaValue[Timezone.Guam] = 'Pacific/Guam';
TimeZoneIanaValue[Timezone.MST7MDT] = 'MST7MDT';
TimeZoneIanaValue[Timezone.PST8PDT] = 'PST8PDT';
TimeZoneIanaValue[Timezone.PagoPago] = 'Pacific/Pago_Pago';
TimeZoneIanaValue[Timezone.PortOfSpain] = 'America/Port_of_Spain';
