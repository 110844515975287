import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import routePaths from '../../../constants/routePaths';

const HelpNavButton: React.FC = () => (
  <NavItem>
    <NavLink to={routePaths.help.root} tag={Link}>
      <FontAwesomeIcon icon={faQuestionCircle} className="menu_hamburger" /><span>Help</span>
    </NavLink>
  </NavItem>
);

export default HelpNavButton;
