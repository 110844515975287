import * as React from 'react';
import { Alert } from 'reactstrap';
import { combineClassNames } from '../../../helpers/reactHelpers';
import Icon from '../../controls/icons/Icon';
import * as styles from './MessageBox.scss';

interface MessageBoxProps {
  id: string;
  isVisible?: boolean;
  className?: string;
  dismiss?: () => void;
  message: string | React.ReactNode;
  type: MessageType;
}

export enum MessageType {
  Success,
  // TODO: warning (missing icon)
  Error
}

export default class MessageBox extends React.Component<MessageBoxProps> {
  public render(): React.ReactNode {
    let color: string | undefined;
    let title: string | undefined;
    let icon: string | undefined;

    switch (this.props.type) {
      case MessageType.Error:
        color = 'danger';
        title = 'Error';
        icon = 'error';
        break;
      case MessageType.Success:
        color = 'success';
        title = 'Success';
        icon = 'success';
        break;
    }

    return (
      <Alert
        id={this.props.id}
        className={combineClassNames(this.props.className,
          styles.messageBox,
          'flex-row align-content-start p-4')}
        hidden={this.props.isVisible === false}
        color={color}
      >
        <Icon name={icon!} size="4rem" className="mr-3" />
        <div className="flex-grow-1 align-self-stretch">
          <h4>{title}</h4>
          {this.props.message}
        </div>
        {this.props.dismiss
      && (
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={this.props.dismiss}
      >
        <span aria-hidden="true">Dismiss</span>
      </button>
      )}
      </Alert>
    );
  }
}
