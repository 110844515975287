import { computed, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';
import { validate } from '../../../shared/validation/helpers';
import NotEmpty from '../../../shared/validation/validators/NotEmpty';
import ValidEmail from '../../../shared/validation/validators/ValidEmail';

export class ForgotPasswordStore extends ValidatableObject {
  @observable
  private _isBusy = false;

  @observable
  private _success = false;

  @validate(() => new NotEmpty(), () => new ValidEmail())
  @observable
  public email = '';

  @computed
  public get isBusy() {
    return this._isBusy;
  }

  @computed
  public get success() {
    return this._success;
  }

  public async resetPassword(): Promise<boolean> {
    let response: AxiosResponse<void>;
    this._isBusy = true;
    try {
      response = await apiService.post('user/resetPassword', {
        email: this.email
      });
      this._success = true;
    } catch (e) {
      return false;
    } finally {
      this._isBusy = false;
    }
    return response.status === 204;
  }

  public reset(): void {
    this._success = false;
    this.email = '';
    this.resetValidationState();
  }
}
export default new ForgotPasswordStore();
