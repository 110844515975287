import React, { useState } from 'react';
import {
  Button, Dropdown, DropdownMenu, DropdownToggle, NavLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import userStore from '../../../../data/UserStore';
import routePaths from '../../../../constants/routePaths';

const UserMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuClass = menuOpen ? 'active' : '';
  const toggle = () => setMenuOpen(!menuOpen);
  const user = userStore.session!;

  return (
    <Dropdown
      nav
      inNavbar
      className={menuClass}
      isOpen={menuOpen}
      toggle={toggle}
    >
      <DropdownToggle nav caret>
        {user.name}
      </DropdownToggle>
      <UserMenuItems />
    </Dropdown>
  );
};

const UserMenuItems: React.FC = () => {
  const logout = () => userStore.logout();

  return (
    <DropdownMenu right className="nav_popper user">
      <h5><NavLink to={routePaths.user.profile} tag={Link} className="pl-0 pb-1">My Account</NavLink></h5>
      <Button block color="primary" onClick={logout}>Logout</Button>
    </DropdownMenu>
  );
};

export default UserMenu;
