import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormSectionContainer from '../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../../../../shared/layout/inlineEntry/InlineEntry';
import { PreviewSectionProps } from '../PreviewSectionProps';
import { StateDisplayName } from '../../../../../helpers/enums/stateDisplayName';
import { NaloxoneStore } from '../NaloxoneStoreContext';

export default class PreviewPatientContactInformation extends React.Component<PreviewSectionProps> {
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Patient Contact Information">
              <Row>
                <Col sm={9} xs={12}>
                  <Row>
                    <Col xs={12} sm={9}>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="Street Address" value={context.patientContactSectionStore?.patientAddress1} id="patientAddress1" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="Street Address Line 2" value={context.patientContactSectionStore?.patientAddress2} id="patientAddress2" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="City" value={context.patientContactSectionStore?.patientCity} id="patientCity" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="Zip code" value={context.patientContactSectionStore?.patientZipCode} id="patientZipCode" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={9}>
                          <InlineEntry labelText="State" value={StateDisplayName[context.patientContactSectionStore?.patientState || 0]} id="patientState" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onUpdateButtonHandler(e)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
