import * as React from 'react';
import { StaticContext } from 'react-router';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import routePaths from '../../constants/routePaths';
import Box from '../../shared/layout/box/Box';
import MessageBox, { MessageType } from '../../shared/layout/messageBox/MessageBox';
import LoginForm from './loginForm/LoginForm';
import styles from './LoginPage.scss';
import { page } from '../../services/route/decorators';
import bambooHealthLogo from '../../../assets/images/bamboohealth-logo.svg';
import userStore from '../../data/UserStore';

type LocationState = {
  passwordResetSuccessful: boolean;
};

type LoginPageProps = RouteComponentProps<any, StaticContext, LocationState>;

interface LoginPageState {
  passwordResetPopupDismissed: boolean;
}

@page({
  path: routePaths.general.root,
  needsAuthentication: false
})
class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  public constructor(props: Readonly<LoginPageProps>) {
    super(props);
    this.state = {
      passwordResetPopupDismissed: false
    };
  }

  public render(): React.ReactNode {
    const passwordResetSuccessful = Boolean(this.props.location.state?.passwordResetSuccessful);

    return (
      <div className={styles.loginPage}>
        <div className="container mt-5 mb-6">
          <div className="row">
            <div className="col-12">
              <MessageBox
                id="passwordResetSuccessful"
                isVisible={!this.state.passwordResetPopupDismissed && passwordResetSuccessful}
                dismiss={() => this.setState({ passwordResetPopupDismissed: true })}
                message="You have successfully reset your password. Please login to your account below."
                type={MessageType.Success}
              />
            </div>
          </div>
          <div className="row">
            <div className="offset-xl-3 offset-lg-3 offset-md-3 col-xl-5 col-lg-5 col-md-6 pt-lg-0 pt-md-3">
              <div className="text-center">
                <h4>National PMP Enhanced Data Exchange</h4>
              </div>
              <Box className="loginFormContainer">
                <LoginForm />
                <div className="text-center font-weight-bold links">
                  <Link to={routePaths.user.register}>Create an Account</Link>
                </div>
                <div className="login-separator mt-5 mb-4">
                  <div className="line" />
                  <span className="text-uppercase">or sign in with</span>
                  <div className="line" />
                </div>
                <div className="d-flex justify-content-center">
                  <Button color="secondary" block className="sso-button" onClick={() => userStore.microsoftLogin()}>
                    <img src={bambooHealthLogo} alt="Bamboo Health Logo" title="Login with Bamboo Health" width="24" />
                    <span className="ml-2 text-uppercase">Bamboo Health</span>
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
