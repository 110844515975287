import * as React from 'react';
import styles from './FormSectionContainer.scss';
import { FormContainerWithHeader, FormContainerWithHeaderProps } from '../FormContainer';
import FormSectionDivider from '../sectionDivider/FormSectionDivider';
import { combineClassNames } from '../../../../helpers/reactHelpers';

interface FormSectionContainerProps extends FormContainerWithHeaderProps {
    headerText?: string;
    className?: string;
    children: React.ReactNode;
}

export default class FormSectionContainer extends FormContainerWithHeader<FormSectionContainerProps> {
  public render(): React.ReactNode {
    return (
      <div className={combineClassNames(styles.formSectionContainer, this.props.className)}>
        {this.getContent()}
      </div>
    );
  }

  protected getContentWithHeader(): React.ReactNode {
    return (
      <>
        <FormSectionDivider headerText={this.props.headerText!} />
        {this.getContentWithoutHeader()}
      </>
    );
  }

  protected getContentWithoutHeader(): React.ReactNode {
    return (
      <div className="form-section-content">
        {this.props.children}
      </div>
    );
  }
}
