import React from 'react';
import Spinner from '../spinner/Spinner';

interface LoadingSpinnerProps {
  loadingText: string;
}

export default class LoadingSpinner extends React.Component<LoadingSpinnerProps> {
  public static defaultProps = {
    loadingText: 'Loading...'
  };

  public render(): React.ReactNode {
    return (
      <div>
        <h3><Spinner size="1x" /> {this.props.loadingText}</h3>
      </div>
    );
  }
}
