import moment from 'moment';
import Validator, { ValidationResult } from './Validator';
import ZonedDateTime from '../../../model/naloxone/DateTime/ZonedDateTime';

export default class NotDaysAheadZonedDateTime implements Validator<ZonedDateTime> {
  private readonly isValid = 'Invalid date.';
  private readonly _daysAhead: number;

  constructor(daysAhead: number) {
    this._daysAhead = daysAhead;
  }

  public validate(value: ZonedDateTime): Promise<ValidationResult> {
    if (!value || !moment(new Date(value.date)).isValid()) {
      return Promise.resolve({ isValid: false, message: this.isValid });
    }

    const bufferDate = moment().add(this._daysAhead, 'days');
    const targetDate = moment(value.date);
    const unit = this._daysAhead > 1 || this._daysAhead === 0 ? 'days' : 'day';

    if (targetDate.isAfter(bufferDate, 'days')) {
      return Promise.resolve({
        isValid: false,
        message: `Dates more than ${this._daysAhead} ${unit} ahead are not allowed`
      });
    }

    return Promise.resolve({ isValid: true });
  }
}
