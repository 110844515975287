import { action, computed, observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';
import NotEmpty from '../../../shared/validation/validators/NotEmpty';
import { validate } from '../../../shared/validation/helpers';
import { State } from '../../../model/address/State';
import ValidPhoneNumber from '../../../shared/validation/validators/ValidPhoneNumber';
import ValidEmail from '../../../shared/validation/validators/ValidEmail';
import { OrganizationDetails } from '../../../model/organization/Organization';
import ValidZipCode from '../../../shared/validation/validators/ValidZipCode';

export class EditOrganizationDetailsStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @observable
  public error = false;

  @action
  public async getOrganizationDetails() {
    this.isBusy = true;

    try {
      const response = await apiService.get<OrganizationDetails>(`organization/${this.id}`);
      this.orgDetails = response.data;
    } finally {
      this.isBusy = false;
    }
  }

  @observable
  public orgDetails?: any = {};

  @validate(() => new NotEmpty())
  @computed
  public get id(): number {
    return this.orgDetails!.id || 0;
  }
  public set id(value: number) {
    this.orgDetails.id = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get name(): string {
    return this.orgDetails!.name;
  }
  public set name(value: string) {
    this.orgDetails.name = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get street(): string {
    return this.orgDetails?.address?.street;
  }
  public set street(value: string) {
    this.orgDetails.address.street = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get city(): string {
    return this.orgDetails?.address?.city;
  }
  public set city(value: string) {
    this.orgDetails.address.city = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get state(): State {
    return this.orgDetails?.address?.state;
  }
  public set state(value: State) {
    this.orgDetails.address.state = value;
  }

  @validate(() => new NotEmpty(), () => new ValidZipCode())
  @computed
  public get zip(): string {
    return this.orgDetails?.address?.zipCode;
  }
  public set zip(value: string) {
    this.orgDetails.address.zipCode = value;
  }

  @validate(() => new NotEmpty(), () => new ValidPhoneNumber())
  @computed
  public get phone(): string {
    return this.orgDetails!.phone;
  }
  public set phone(value: string) {
    this.orgDetails.phone = value;
  }

  @validate(() => new NotEmpty(), () => new ValidEmail())
  @computed
  public get email(): string {
    return this.orgDetails!.email;
  }
  public set email(value: string) {
    this.orgDetails.email = value;
  }

  public async updateOrganization(): Promise<void> {
    await apiService.put(`/organization/edit/${this.id}`, this.orgDetails);
  }
}

const editOrganizationDetailsStore = new EditOrganizationDetailsStore();

export default editOrganizationDetailsStore;
