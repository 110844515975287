import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps
} from 'reactstrap';
import { observer } from 'mobx-react';
import ButtonWithSpinner from '../../controls/buttons/ButtonWithSpinner';

export const SimpleOperationModal: React.FC<SimpleOperationModalProps> = (props: SimpleOperationModalProps) => {
  const {
    headerTitle, submitButtonText, onSubmitButtonClicked, onCancelButtonClicked, isSubmitButtonBusy, ...otherProps
  } = props;
  const submitButtonId = `${headerTitle.replace(/ /g, '')}submitButton`;

  return (
    <Modal {...otherProps} className="p-4">
      <ModalHeader className="p-4">{headerTitle}</ModalHeader>
      <ModalBody className="p-4">
        {props.children}
      </ModalBody>
      <ModalFooter className="p-4">
        <ButtonWithSpinner
          id={submitButtonId}
          className="mr-2"
          color="primary"
          buttonText={submitButtonText || 'Submit'}
          onClick={onSubmitButtonClicked}
          isBusy={isSubmitButtonBusy || false}
        />
        <Button outline color="primary" onClick={onCancelButtonClicked}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export type SimpleOperationModalProps = {
  headerTitle: string;
  submitButtonText?: string;
  onSubmitButtonClicked: () => void;
  onCancelButtonClicked: () => void;
  isSubmitButtonBusy?: boolean;
} & ModalProps;

export default observer(SimpleOperationModal);
