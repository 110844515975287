import * as React from 'react';

import Box from '../../shared/layout/box/Box';
import styles from './NotFoundPage.scss';
import { page } from '../../services/route/decorators';
import routePaths from '../../constants/routePaths';

@page({
  path: routePaths.error.notFound,
})
export default class NotFoundPage extends React.Component {
  public render(): React.ReactNode {
    return (
      <div className={styles.notFound}>
        <Box border>
          <h2 className="text-center">Page not found</h2>
        </Box>
      </div>
    );
  }
}
