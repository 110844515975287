import { action, observable } from 'mobx';
import { defer } from 'q';
import FieldValidationCollection, { ValidationState } from './FieldValidationCollection';
import Validator from './validators/Validator';

export const META_KEY: unique symbol = Symbol('Field validator class collection');

export default abstract class ValidatableObject {
  private [META_KEY]: { [field: string]: Array<() => Validator<any>> };

  private _validationPromise?: Q.Promise<boolean>;

  @observable
  private readonly _fieldValidationCollections: { [field: string]: FieldValidationCollection<any, any> };

  public constructor() {
    this._fieldValidationCollections = {};
    // COMMENT(tomek): this for loop is unfiltered on purpose
    // eslint-disable-next-line guard-for-in
    for (const key in this[META_KEY]) {
      for (const validatorFn of this[META_KEY][key]) {
        this.addValidator(key as any, validatorFn());
      }
    }
  }

  protected getValidatorCollection<TKey extends keyof this>(key: TKey): FieldValidationCollection<this, TKey> {
    let result = this._fieldValidationCollections[key as string];
    if (!result) {
      result = new FieldValidationCollection(key as any);
      this._fieldValidationCollections[key as string] = result;
    }
    return result;
  }

  protected addValidator<TKey extends keyof this>(key: TKey, validator: Validator<this[TKey]>) {
    this.getValidatorCollection(key).addValidator(validator);
  }

  public getErrorForFields<TKey extends keyof this>(field: TKey): string[] {
    return this.getValidatorCollection(field).messages;
  }

  public isValid<TKey extends keyof this>(field: TKey): boolean {
    return this.getValidatorCollection(field).validationState !== ValidationState.Invalid;
  }

  public validationState<TKey extends keyof this>(field: TKey): ValidationState {
    return this.getValidatorCollection(field).validationState;
  }

  @action
  public async validate<TKey extends keyof this = any>(field?: TKey): Promise<boolean> {
    if (this._validationPromise) {
      return this._validationPromise;
    }

    const task = defer<boolean>();
    try {
      this._validationPromise = task.promise;

      if (field) {
        return await this.getValidatorCollection(field).validate(this);
      }

      const validationPromises = [];
      for (const key in this._fieldValidationCollections) {
        if (this._fieldValidationCollections.hasOwnProperty(key)) {
          const fieldValidatorCollection = this._fieldValidationCollections[key];
          validationPromises.push(fieldValidatorCollection.validate(this));
        }
      }

      const validationResult = await Promise.all(validationPromises);
      const result = validationResult.every(vr => vr);

      task.resolve(result);
      return result;
    } catch (e) {
      task.reject(e);
      throw e;
    } finally {
      delete this._validationPromise;
    }
  }

  @action
  public resetValidationState<TKey extends keyof this = any>(field?: TKey): void {
    if (field) {
      this.getValidatorCollection(field).reset();
      return;
    }

    for (const key in this._fieldValidationCollections) {
      if (this._fieldValidationCollections.hasOwnProperty(key)) {
        this._fieldValidationCollections[key].reset();
      }
    }
  }
}

ValidatableObject.prototype[META_KEY] = {};
