import { Omit } from 'react-router';
import { observer } from 'mobx-react';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import FormInput, { FormInputProps } from './FormInput';
import ValidatableObject from '../../validation/ValidatableObject';
import { combineClassNames } from '../../../helpers/reactHelpers';

export type ZipCodeInputProps<T extends ValidatableObject, TKey extends keyof T>
  = Omit<FormInputProps<T, TKey>, 'customInput' | 'valid' >;

@observer
export default class ZipCodeInput<T extends ValidatableObject, TKey extends keyof T>
  extends React.Component<ZipCodeInputProps<T, TKey>> {
  public render(): React.ReactNode {
    const { storeRef: zipCodeStoreRef, id, ...props } = this.props;
    const zipCodeInputValidClassName = zipCodeStoreRef.ref.isValid(zipCodeStoreRef.field) ? '' : 'is-invalid';
    const zipCodeInput = (
      <NumberFormat
        className={combineClassNames('form-control', zipCodeInputValidClassName)}
        value={this.props.storeRef.value}
        format={v => this.formatValue(v)}
        mask="_"
        allowEmptyFormatting
        onValueChange={values => this.onValueChangedHandler(values)}
        onBlur={() => this.validateInput()}
      />
    );
    return (
      <FormInput {...props} id={id} storeRef={zipCodeStoreRef} customInput={zipCodeInput} />
    );
  }

  private onValueChangedHandler(values: NumberFormatValues): void {
    this.props.storeRef.value = values.formattedValue;
  }

  private validateInput(): void {
    const zipCodeStoreRef = this.props.storeRef;
    zipCodeStoreRef.ref.validate(zipCodeStoreRef.field);
  }

  private formatValue(v: string) {
    if (v.length <= 5) {
      return v;
    }

    return `${v.substr(0, 5)}-${v.substr(5, 4)}`;
  }
}
