import { Column, SortingRule } from 'react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatPhoneNumber, formatUsDateTime } from '../../../../helpers/displayHelper';
import { InactiveUserForGrid } from '../../../../model/grid/user/UserForGrid';
import Grid, { GridProps } from '../../../../shared/grid/Grid';

@observer
export default class InactiveUsersGrid extends Grid<GridProps<InactiveUserForGrid, string>, InactiveUserForGrid, string> {
  protected defineColumns(): Array<Column<InactiveUserForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        id: 'name',
        Header: 'Name',
        accessor: d => `${d.lastName}, ${d.firstName}`,
        Cell: ({ row }) => <Link to={{ pathname: `/user/${row.id}/details` }}>{row.name}</Link>
      },
      { Header: 'Role', id: 'role', accessor: d => d.role },
      { Header: 'Licensee Name', id: 'licenseeName', accessor: d => d.licenseeName },
      { Header: 'Email', id: 'email', accessor: d => d.email },
      { Header: 'Phone Number', id: 'phone', accessor: d => formatPhoneNumber(d.phone) },
      { Header: 'Deactivated On', id: 'deactivatedOn', accessor: d => formatUsDateTime(d.deactivatedOn) },
      { Header: 'Deactivation Reason', id: 'deactivationReason', accessor: d => d.deactivationReason }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No inactive Users';
  }
}
