import { action, computed, observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import UserStore from '../../../data/UserStore';
import { validate } from '../../../shared/validation/helpers';
import NotEmpty from '../../../shared/validation/validators/NotEmpty';

export class LoginStore extends ValidatableObject {
  @observable
  @validate(() => new NotEmpty())
  public login = '';

  @observable
  @validate(() => new NotEmpty())
  public password = '';

  @observable
  public rememberMe = false;

  @observable
  private _isBusy = false;
  @computed
  public get isBusy() {
    return this._isBusy;
  }

  @observable
  private _error = '';
  @computed
  public get error() {
    return this._error;
  }

  @observable
  private _loginSuccessful = false;
  @computed
  public get loginSuccessful() {
    return this._loginSuccessful;
  }

  @action
  public async doLogin(): Promise<void> {
    this._isBusy = true;
    if (!await this.validate()) {
      return;
    }

    try {
      try {
        this._loginSuccessful = await UserStore.login(this.login, this.password, this.rememberMe);
      } catch (e) {
        if (e.request?.status === 400) {
          this._error = 'Invalid login or password';
        } else if (e.request?.status === 0) {
          this._error = 'Connection to server lost. Check your internet connection.';
        } else {
          this._error = 'Unknown error while making the request';
        }
      }
    } finally {
      this._isBusy = false;
    }
  }

  public reset(): void {
    this.login = '';
    this.password = '';
    this._loginSuccessful = false;
    this._error = '';
    this.rememberMe = false;
    this.resetValidationState();
  }
}

export default new LoginStore();
