import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Button, Col, Container, Form, FormGroup, Row
} from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import { DataSubmissionErrorsGridStore } from './submissionErrorsGrid/DataSubmissionErrorsGridStore';
import DataSubmissionErrorForGrid from '../../../model/grid/dataSubmission/DataSubmissionErrorForGrid';
import DataSubmissionErrorsGrid from './submissionErrorsGrid/DataSubmissionErrorsGrid';
import { WithIdProps } from '../../../shared/props/WithIdProps';
import { GridType } from '../../../shared/grid/Grid';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../helpers/Ref';
import { DataSubmissionErrorsPageStore } from './DataSubmissionErrorsPageStore';

@page(routePaths.data.submissionErrors)
@observer
export class DataSubmissionErrorsPage extends React.Component<RouteComponentProps<WithIdProps>> {
  private _store: DataSubmissionErrorsPageStore = new DataSubmissionErrorsPageStore();
  private _gridStore: DataSubmissionErrorsGridStore<DataSubmissionErrorForGrid> = new DataSubmissionErrorsGridStore<DataSubmissionErrorForGrid>();

  constructor(props: RouteComponentProps<WithIdProps>) {
    super(props);

    this._gridStore.submissionId = Number(props.match.params.id);
  }

  public render() {
    return (
      <Container>
        <div className="mt-5 mb-5">
          <h1>Data Submission Invalid Records</h1>
        </div>
        <Form className="mb-5">
          <Row>
            <Col>
              <FormInput
                id="searchFirstName"
                labelText="First Name"
                storeRef={makeRef(this._store, 'firstName')}
              />
            </Col>
            <Col>
              <FormInput
                id="searchLastName"
                labelText="Last Name"
                storeRef={makeRef(this._store, 'lastName')}
              />
            </Col>
            <Col>
              <FormInput
                id="searchDateOfBirth"
                labelText="Date of Birth"
                type="date"
                storeRef={makeRef(this._store, 'dateOfBirth')}
              />
            </Col>
            <Col className="d-flex align-items-end">
              <FormGroup className="w-100">
                <Button
                  id="searchButton"
                  color="primary"
                  block
                  className="searchButton"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.search(e)}
                  type="submit"
                >
                  <span className="pr-3">Search</span>
                  <FontAwesomeIcon icon={faSearch} className="icon" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <DataSubmissionErrorsGrid
              store={this._gridStore}
              submissionId={Number(this.props.match.params.id)}
              gridType={GridType.Dynamo}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  private async search(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    // remove pagination token from previous request when new search is initiated
    this._gridStore.paginationToken = '';
    this._gridStore.page = 1;

    await this._gridStore.dynamoSearch({
      firstName: this._store.firstName.trim(),
      lastName: this._store.lastName.trim(),
      dateOfBirth: this._store.dateOfBirth.trim()
    });
  }
}

export default withRouter(DataSubmissionErrorsPage);
