import React from 'react';
import { observer } from 'mobx-react';
import { IanaPatientInformationForm } from './ianaPatientInformationForm/IanaPatientInformationForm';
import { ScnaPatientInformationForm } from './scnaPatientInformationForm/ScnaPatientInformationForm';
import { IanaPatientInformationSectionStore } from './ianaPatientInformationForm/IanaPatientInformationSectionStore';
import { ScnaPatientInformationSectionStore } from './scnaPatientInformationForm/ScnaPatientInformationSectionStore';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';

export type PatientInformationSectionProps = {
  patientInformationSectionStore: IanaPatientInformationSectionStore | ScnaPatientInformationSectionStore;
};

@observer
export class PatientInformationSection extends React.Component<PatientInformationSectionProps> {
  private _store: IanaPatientInformationSectionStore | ScnaPatientInformationSectionStore = this.props.patientInformationSectionStore;

  constructor(props: PatientInformationSectionProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <>
        {this.renderPatientInformationForm()}
      </>
    );
  }

  private renderPatientInformationForm() {
    switch (this._store.DataSubmissionType) {
      case DataSubmissionType.IANA:
        return <IanaPatientInformationForm store={this._store} />;
      case DataSubmissionType.SCNA:
        return <ScnaPatientInformationForm store={this._store} />;
      default:
        throw new Error('Invalid data submission type');
    }
  }
}
