import React from 'react';
import { Row } from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import FormStateSelector from '../../../../../../shared/controls/formSelect/stateSelector/FormStateSelector';
import FormGenderSelector from '../../../../../../shared/controls/formSelect/genderSelector/FormGenderSelector';
import ZipCodeInput from '../../../../../../shared/controls/formInput/ZipCodeInput';
import { IanaPatientInformationSectionStore } from './IanaPatientInformationSectionStore';

export type IanaPatientInformationFormProps = {
  store: IanaPatientInformationSectionStore;
};

export const IanaPatientInformationForm = (props: IanaPatientInformationFormProps) => (
  <FormSectionContainer>
    <Row>
      <FormInput
        id="IncidentUniqueIdentifier"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'Incident_UniqueIdentifier')}
        labelText="Patient Identification Number"
      />
    </Row>
    <Row>
      <FormInput
        id="FirstName"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'FirstName')}
        labelText="Patient First Name"
        required
      />
      <FormInput
        id="MiddleName"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'MiddleName')}
        labelText="Patient Middle Name"
      />
      <FormInput
        id="LastName"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'LastName')}
        labelText="Patient Last Name"
        required
      />
    </Row>

    <Row>
      <FormInput
        id="DateOfBirth"
        className="col-12 col-sm-6"
        storeRef={makeRef(props.store, 'DateOfBirth')}
        labelText="Patient Date of Birth"
        type="date"
        required
      />
      <FormInput
        id="Patient_Identifier"
        className="col-12 col-sm-6"
        storeRef={makeRef(props.store, 'Patient_Identifier')}
        labelText="Patient Identifier"
      />
    </Row>

    <Row>
      <FormInput
        id="Patient_Address1"
        className="col-12"
        storeRef={makeRef(props.store, 'Patient_Address1')}
        labelText="Patient Street Address"
      />
    </Row>

    <Row>
      <FormInput
        id="Patient_Address2"
        className="col-12"
        storeRef={makeRef(props.store, 'Patient_Address2')}
        labelText="Patient Street Address Line 2"
      />
    </Row>

    <Row>
      <FormInput
        id="Patient_City"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'Patient_City')}
        labelText="Patient City"
      />
      <FormStateSelector
        id="Patient_State"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'Patient_State')}
        labelText="Patient State"
      />
      <ZipCodeInput
        id="Patient_ZipCode"
        className="col-12 col-sm-4"
        storeRef={makeRef(props.store, 'Patient_ZipCode')}
        labelText="Patient Zipcode"
      />
    </Row>

    <Row>
      <FormGenderSelector
        id="Patient_Gender"
        className="col-12 col-sm-6"
        storeRef={makeRef(props.store, 'Patient_Gender')}
        labelText="Patient Gender"
      />
    </Row>
  </FormSectionContainer>
);
