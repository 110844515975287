import { observable } from 'mobx';
import GridStore from '../../../shared/grid/GridStore';
import GridPageRequest from '../../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../../model/grid/GridPageResponse';
import DataSubmissionForGrid from '../../../model/grid/dataSubmission/DataSubmissionForGrid';
import apiService from '../../../services/ApiService';

export class FileUploadHistoryGridStore<TRowModel extends DataSubmissionForGrid> extends GridStore<TRowModel, string> {
  @observable
  public organizationId = 0;

  protected get shouldFetchOnMount(): boolean {
    return false;
  }

  protected get shouldDebounceFetch(): boolean {
    return false;
  }

  protected get shouldFetchData(): boolean {
    return (!!this.organizationId);
  }

  protected async apiCall(gridPageRequest: GridPageRequest<string>): Promise<GridPageResponse<TRowModel>> {
    if (!this.organizationId) {
      return {
        rows: [],
        totalCount: 0
      };
    }

    const params = { gridPageRequest };
    const gridData = await apiService.get<GridPageResponse<TRowModel>>('dataSubmission/listForGrid', { params });
    return gridData.data;
  }

  public getGridPageRequest(): DataSubmissionGridPageRequest {
    return {
      ...super.getGridPageRequest(),
      organizationId: this.organizationId
    };
  }
}

export interface DataSubmissionGridPageRequest extends GridPageRequest<string> {
  organizationId: number;
}

export interface DataSubmissionGridPageResponse<TRowModel> {
  records: GridPageResponse<TRowModel>;
  paginationToken: string;
}
