export function getBaseUrl() {
  switch (ENVIRONMENT) {
    case 'Local':
      return 'https://localhost:5001';
    case 'Development':
      return 'https://api.erv.dev.hbu.co';
    case 'Qa':
      return 'https://api.erv.qa.hbu.co';
    case 'Prep':
      return 'https://api.prep.ervive.com';
    case 'Production':
      return 'https://service.ervive.com';
    default:
      throw new Error('Unknown environment!');
  }
}

export function getBaseApiUrl(): string {
  return `${getBaseUrl()}/webapi`;
}
