import * as React from 'react';
import AppFooter from './appFooter/AppFooter';
import AppNavbar from './appNavbar/AppNavbar';
import styles from './Layout.scss';

export default class Layout extends React.Component<{}, {}> {
  public render(): React.ReactNode {
    return (
      <>
        <div className={styles.layout}>
          <AppNavbar />
          <main id="content">
            {this.props.children}
          </main>
          <AppFooter />
        </div>
      </>
    );
  }
}
