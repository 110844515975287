import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import ButtonWithSpinner from '../buttons/ButtonWithSpinner';

export type FormNavigationFooterProps = {
  isSubmitting: () => boolean;
  cancelLink: string;
  continueButtonDisabled: () => boolean;
  shouldRenderContinueButton: () => boolean;
  shouldRenderSubmitButton: () => boolean;
  shouldRenderBackButton: () => boolean;
  shouldRenderCancelLink: () => boolean;
  submitButtonText?: string;
  onContinueButtonClickedHandler: (e: React.MouseEvent<any>) => void;
  onSubmitButtonClickedHandler: (e: React.MouseEvent<any>) => void;
  onBackButtonClickedHandler: (e: React.MouseEvent<any>) => void;
};

@observer
export default class FormNavigationFooter extends React.Component<FormNavigationFooterProps> {
  public render(): React.ReactNode {
    return (
      <div className="row">
        {this.props.shouldRenderContinueButton() && this.renderContinueButton()}
        {this.props.shouldRenderSubmitButton() && this.renderSubmitButton()}
        {this.props.shouldRenderBackButton() && this.renderBackButton()}
        {this.props.shouldRenderCancelLink() && this.renderCancelLink()}
      </div>
    );
  }

  private renderContinueButton = (): React.ReactNode => (
    <div className="col-5 col-sm-3 col-lg-3">
      <Button
        color="primary"
        type="submit"
        block
        onClick={(e: React.MouseEvent<any>) => this.props.onContinueButtonClickedHandler(e)}
        disabled={this.props.continueButtonDisabled()}
      >
        Continue
      </Button>
    </div>
  );

  private renderSubmitButton = (): React.ReactNode => (
    <div className="col-5 col-sm-3 col-lg-3">
      <ButtonWithSpinner
        id="submitBtn"
        buttonText={this.props.submitButtonText || 'Submit'}
        isBusy={this.props.isSubmitting()}
        type="submit"
        block
        onClick={(e: React.MouseEvent) => this.props.onSubmitButtonClickedHandler(e)}
        color="primary"
      />
    </div>
  );

  private renderBackButton = (): React.ReactNode => (
    <div className="col-4 col-sm-2 col-lg-2 pl-0">
      <Button
        color="secondary"
        block
        disabled={this.props.isSubmitting()}
        onClick={(e: React.MouseEvent<any>) => this.props.onBackButtonClickedHandler(e)}
      >
        Back
      </Button>
    </div>
  );

  private renderCancelLink = (): React.ReactNode => (
    <div className="col-3 col-sm-2 col-lg-2 pl-0 d-flex align-items-center">
      <Link to={this.props.cancelLink || '/'} hidden={this.props.isSubmitting()}><b>Cancel</b></Link>
    </div>
  );
}
