import * as React from 'react';
import {
  Badge, Button, Col, Form, Input, InputGroup, InputGroupAddon, Row
} from 'reactstrap';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './ManageUsersTabsPage.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';
import { makeRef } from '../../../helpers/Ref';
import manageUsersTabsPageStore, { ManageUsersTabsPageStore } from './ManageUsersTabsPageStore';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import Tabs, { TabForPage } from '../../../shared/layout/tabs/Tabs';
import RouteManager from '../../../services/route/RouteManager';
import ActiveUsersGrid from './active/ActiveUsersGrid';
import activeUsersGridStore, { ActiveUsersGridStore } from './active/ActiveUsersGridStore';
import AwaitingApprovalUsersGrid from './awaitingApproval/AwaitingApprovalUsersGrid';
import awaitingApprovalUsersGridStore, { AwaitingApprovalUsersGridStore } from './awaitingApproval/AwaitingApprovalUsersGridStore';
import InactiveUsersGrid from './inactive/InactiveUsersGrid';
import inactiveUsersGridStore, { InactiveUsersGridStore } from './inactive/InactiveUsersGridStore';
import rejectedUsersGridStore, { RejectedUsersGridStore } from './rejected/RejectedUsersGridStore';
import RejectedUsersGrid from './rejected/RejectedUsersGrid';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';
import UserDeactivationRejectionStore from '../../user/deactivationRejectionModal/UserDeactivationRejectionStore';
import UserDeactivationRejectionModal from '../../user/deactivationRejectionModal/UserDeactivationRejectionModal';
import UserDeletionModal from '../../user/deletionModal/UserDeletionModal';
import UserApprovalModal from '../../user/approvalModal/UserApprovalModal';
import permissions from '../../../model/user/permissions';
import OrganizationFormSelector from './organizationSelector/OrganizationFormSelector';
import NotificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import Organization from '../../../model/organization/Organization';

RouteManager.registerRedirect(routePaths.user.root, routePaths.user.activeList);

interface ManageUsersTabsPageState {
  id?: number;
  openModal: OpenModal;
}

@page({
  path: routePaths.user.manage,
  hasPermission: permissions.user.list
})
@observer
export default class ManageUsersTabsPage extends React.Component<RouteComponentProps, ManageUsersTabsPageState> {
  private _store: ManageUsersTabsPageStore = manageUsersTabsPageStore;
  private _awaitingApprovalUsersGridStore: AwaitingApprovalUsersGridStore = awaitingApprovalUsersGridStore;
  private _activeUsersGridStore: ActiveUsersGridStore = activeUsersGridStore;
  private _inactiveUsersGridStore: InactiveUsersGridStore = inactiveUsersGridStore;
  private _rejectedUsersGridStore: RejectedUsersGridStore = rejectedUsersGridStore;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      openModal: OpenModal.None,
      id: 0
    };
  }

  public render(): React.ReactNode {
    const searchInput = (
      <InputGroup>
        <Input id="searchPhraseInput" placeholder="Name, Role, Email or Phone Number" onChange={e => this.handleInputValueChange(e.target.value)} />
        <InputGroupAddon addonType="append">
          <Button
            id="searchButton"
            color="primary"
            block
            className="searchButton"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleSearch(e)}
            style={{ width: '50px' }}
            type="submit"
            disabled={!this._store.searchPhrase}
          >
            <FontAwesomeIcon icon={faSearch} className="icon" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );

    const additionalTabsContent = (
      <Link to={{ pathname: routePaths.user.create }}>
        <Button color="primary">+ Add User</Button>
      </Link>
    );

    const awaitingApprovalUsersTab: TabForPage = {
      id: routePaths.user.awaitingApprovalList,
      header: <span>Awaiting Approval <Badge color="primary" pill>{this._awaitingApprovalUsersGridStore.totalCount}</Badge></span>,
      component: <AwaitingApprovalUsersGrid
        store={this._awaitingApprovalUsersGridStore}
        openUserApprovalModalFunc={(id: number) => this.openModal(OpenModal.UserApprove, id)}
        openUserRejectModalFunc={(id: number) => this.openModal(OpenModal.UserReject, id)}
      />
    };

    const activeUsersTab: TabForPage = {
      id: routePaths.user.activeList,
      header: <span>Active <Badge color="primary" pill>{this._activeUsersGridStore.totalCount}</Badge></span>,
      component: <ActiveUsersGrid
        store={this._activeUsersGridStore}
        openUserDeactivationModalFunc={(id: number) => this.openModal(OpenModal.UserDeactivate, id)}
      />
    };

    const inactiveUsersTab: TabForPage = {
      id: routePaths.user.inactiveList,
      header: <span>Inactive <Badge color="primary" pill>{this._inactiveUsersGridStore.totalCount}</Badge></span>,
      component: <InactiveUsersGrid store={this._inactiveUsersGridStore} />
    };

    const rejectedUsersTab: TabForPage = {
      id: routePaths.user.rejectedList,
      header: <span>Rejected <Badge color="primary" pill>{this._rejectedUsersGridStore.totalCount}</Badge></span>,
      component: <RejectedUsersGrid
        store={this._rejectedUsersGridStore}
        openUserApprovalModalFunc={(id: number) => this.openModal(OpenModal.UserApprove, id)}
        openUserDeletionModalFunc={(id: number) => this.openModal(OpenModal.UserDelete, id)}
      />
    };

    const tabs: TabForPage[] = [awaitingApprovalUsersTab, activeUsersTab, inactiveUsersTab, rejectedUsersTab];

    return (
      <div className={combineClassNames(styles.manageUsersTabsPage, 'container')}>
        <div className="header-container">
          <h1>Manage Users</h1>
        </div>
        <Row className="search-container">
          <Col xs={12} sm={7} md={5}>
            <OrganizationFormSelector
              id="organizationFormSelector"
              labelText="Organization"
              storeRef={makeRef(this._store, 'organizationId')}
              onValueChangedFunc={id => this.handleOrganizationChange(id)}
              fetchOptionsFunc={() => this.fetchOrganizations()}
              addAllOption
            />
          </Col>
          <Col xs={12} sm={7} md={5} lg={4} className="offset-md-2 offset-lg-3">
            <Form>
              <FormInput
                id="organizationSearchName"
                labelText="Search"
                storeRef={makeRef(this._store, 'searchPhrase')}
                customInput={searchInput}
              />
            </Form>
          </Col>
        </Row>
        <Tabs
          className="grid-tabs"
          activeTabId={this.props.location.pathname}
          additionalContent={additionalTabsContent}
          tabs={tabs}
          onTabToggledFunc={newTabId => this.handleTabSwitch(newTabId)}
        />
        {(this.state.openModal === OpenModal.UserReject
          || this.state.openModal === OpenModal.UserDeactivate) && (
          <UserDeactivationRejectionModal
            store={new UserDeactivationRejectionStore()}
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.state.id || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
          />
        )}
        {this.state.openModal === OpenModal.UserDelete && (
          <UserDeletionModal
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.state.id || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
          />
        )}
        {this.state.openModal === OpenModal.UserApprove && (
          <UserApprovalModal
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.state.id || 0}
            onSubmitFunc={() => this._store.refreshGrids()}
          />
        )}
      </div>
    );
  }

  private handleInputValueChange(newValue: string): void {
    this._store.searchPhrase = newValue;
  }

  private async fetchOrganizations(): Promise<Organization[]> {
    try {
      return await this._store.fetchOrganizations();
    } catch (e) {
      NotificationToast.showNetworkError();
      console.error(e);
      return [];
    }
  }

  private async handleOrganizationChange(organizationId: number): Promise<void> {
    this._store.organizationId = organizationId;
    this._awaitingApprovalUsersGridStore.search = this._store.searchPhrase;
    this._activeUsersGridStore.search = this._store.searchPhrase;
    this._inactiveUsersGridStore.search = this._store.searchPhrase;
    this._rejectedUsersGridStore.search = this._store.searchPhrase;
    this._store.refreshGrids();
  }

  private async handleSearch(e: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();
    this._awaitingApprovalUsersGridStore.search = this._store.searchPhrase;
    this._activeUsersGridStore.search = this._store.searchPhrase;
    this._inactiveUsersGridStore.search = this._store.searchPhrase;
    this._rejectedUsersGridStore.search = this._store.searchPhrase;
    this._store.refreshGrids();
  }

  private handleTabSwitch(newTabId: string): void {
    this.props.history.push(newTabId);
  }

  private openModal(modalToOpen: OpenModal, id?: number) {
    this.setState({
      openModal: modalToOpen,
      id
    });
  }

  private closeModal(): void {
    this.setState({ openModal: OpenModal.None });
  }
}
