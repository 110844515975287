import Moment from 'moment-timezone';

const API_DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';
const DATEPICKER_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const ISO_DATE = 'YYYY-MM-DD';
const US_DATE_FORMAT = 'MM/DD/YYYY';
const H12_FORMAT = 'hh:mm:ss A';
const H24_FORMAT = 'HH:mm';

export default class NodaTimeHelper {
  // example return value: '05/01/1991 01:01:00 pm CST6CDT';
  public static toZonedDateTime(timezone: string, date: string, time = '00:00'): string {
    if (!date || !timezone) {
      return '';
    }

    if (time === '') {
      time = '00:00';
    }

    const value = `${date}`;

    const parsedDateUtc = Moment(value, DATEPICKER_FORMAT);

    const parsedDate = parsedDateUtc.format(US_DATE_FORMAT);

    const dateTime = `${parsedDate} ${time} ${timezone}`;

    return dateTime;
  }

  public static fromApi(value: string): { date: string; time: string } {
    const parsedDateUtc = Moment(value, API_DATE_FORMAT);

    const date = parsedDateUtc.format(ISO_DATE);
    const time = parsedDateUtc.format(H12_FORMAT);

    return {
      date,
      time,
    };
  }
  public static forApiFormat(timezone: string, date: string, time: string) {
    return `${date} ${time} ${timezone}`;
  }

  public static to24hFormat(time: string): string {
    return Moment(time, H12_FORMAT).format(H24_FORMAT);
  }

  public static to12hFormat(time: string): string {
    return Moment(time, H24_FORMAT).format(H12_FORMAT);
  }
  public static toDate(date: string): string {
    return Moment(date, ISO_DATE).format(US_DATE_FORMAT);
  }
}
