import React from 'react';
import { observer } from 'mobx-react';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import CreateUserPersonalInfoSection from '../../../shared/user/create/personalInfoSection/CreateUserPersonalInfoSection';
import CreateUserPage from '../../../shared/user/create/CreateUserPage';
import { AdminCreateUserStore } from './AdminCreateUserStore';
import AdminCreateUserPersonalInfoExistingUsersSection from './personalInfoSection/AdminCreateUserPersonalInfoExistingUsersSection';
import { AdminCreateUserPersonalInfoSectionStore } from './personalInfoSection/AdminCreateUserPersonalInfoSectionStore';
import CreateUserOrganizationSection from '../../../shared/user/create/organizationSection/CreateUserOrganizationSection';
import CreateUserReviewSection from '../../../shared/user/create/reviewSection/CreateUserReviewSection';
import { AdminCreateUserOrganizationSectionStore } from './organizationSection/AdminCreateUserOrganizationSectionStore';
import { FormCompleteSection } from '../../../shared/controls/formCompleteSection/FormCompleteSection';
import permissions from '../../../model/user/permissions';

@page({
  path: routePaths.user.create,
  hasPermission: permissions.user.create
})
@observer
export default class AdminCreateUserPage extends React.Component<{}, AddUserPageState> {
  private _personalInfoStore = new AdminCreateUserPersonalInfoSectionStore();
  private _organizationStore = new AdminCreateUserOrganizationSectionStore();
  private _mainStore: AdminCreateUserStore = new AdminCreateUserStore(this._personalInfoStore, this._organizationStore);

  public render(): React.ReactNode {
    const userAlreadyExists = this._personalInfoStore.selectedUserId > 0;
    const completeStatus = userAlreadyExists ? 'Complete' : 'Awaiting User Action';
    const completeContent = (
      <>
        {userAlreadyExists && <p>Thank you for adding an existing user to an organization.</p>}
        {!userAlreadyExists
        && <p>Thank you for creating a new user. The user will receive an email with instructions how to set up a password.</p>}
      </>
    );
    const completeLinkPath = userAlreadyExists ? routePaths.user.activeList : routePaths.user.awaitingApprovalList;

    return (
      <CreateUserPage
        pageName="Add User"
        mainStore={this._mainStore}
        currentSection={this._mainStore.pageSection}
        personalInfoSection={this.renderPersonalInfoSection()}
        organizationSection={<CreateUserOrganizationSection store={this._mainStore.organizationStore} />}
        reviewSection={<CreateUserReviewSection store={this._mainStore} />}
        completeSection={(
          <FormCompleteSection
            status={completeStatus}
            content={completeContent}
            linkPath={completeLinkPath}
            linkText="Back to Manage Users"
          />
        )}
        renderTermsAndConditions={false}
        handleSubmit={this.handleSubmit}
        cancelRedirectPath={routePaths.user.activeList}
      />
    );
  }

  private renderPersonalInfoSection = (): React.ReactNode => (
    <CreateUserPersonalInfoSection personalInfoStore={this._mainStore.personalInfoStore}>
      {this._personalInfoStore.usersExist && <AdminCreateUserPersonalInfoExistingUsersSection store={this._personalInfoStore} />}
    </CreateUserPersonalInfoSection>
  );

  private handleSubmit = async (): Promise<void> => {
    await this._mainStore.createAccount();
  };
}

interface AddUserPageState {
  isSubmitting: boolean;
}
