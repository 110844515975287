import { observable } from 'mobx';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import { CreateNaloxoneEventPageSection } from './CreateNaloxoneEventPage';
import CreateNaloxoneEventRequest from '../../../../model/naloxone/CreateNaloxoneEventRequest';
import apiService from '../../../../services/ApiService';
import { MedicationDosageUnitDisplayName } from '../../../../helpers/enums/medicationDosageUnit';
import nodaTimeHelper from '../../../../helpers/nodaTimeHelper';
import {
  IanaPatientInformationSectionStore
} from './patientInformationSection/ianaPatientInformationForm/IanaPatientInformationSectionStore';
import {
  ScnaPatientInformationSectionStore
} from './patientInformationSection/scnaPatientInformationForm/ScnaPatientInformationSectionStore';
import { AdministerInformationSectionStore } from './administerInformationSection/AdministerInformationSectionStore';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import {
  IanaAdministerInformationSectionStore
} from './administerInformationSection/ianaAdministerInformationForm/IanaAdministerInformationSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from './administerInformationSection/scnaAdministerInformationForm/ScnaAdministerInformationSectionStore';

export default class CreateNaloxoneEventStore extends ValidatableObject {
  @observable
  public pageSection: CreateNaloxoneEventPageSection = CreateNaloxoneEventPageSection.AdministerInformation;
  public administerInformationSectionStore: AdministerInformationSectionStore = new AdministerInformationSectionStore();
  public patientInformationSectionStore: IanaPatientInformationSectionStore | ScnaPatientInformationSectionStore | undefined;

  public async createNaloxoneEvent(): Promise<string> {
    const request = this.prepareRequest();
    const uri = `dataSubmission/${DataSubmissionType[this.administerInformationSectionStore!.SubmissionType]}/manualEntry/create`;
    const response = await apiService.post<any>(uri, request);
    return response.data.id;
  }

  private prepareRequest(): CreateNaloxoneEventRequest {
    switch (this.administerInformationSectionStore!.SubmissionType) {
      case DataSubmissionType.IANA:
        return this.prepareIanaRequest();
        break;
      case DataSubmissionType.SCNA:
        return this.prepareScnaRequest();
        break;
      default:
        // eslint-disable-next-line no-console
        return {} as CreateNaloxoneEventRequest;
    }
  }

  private prepareScnaRequest() {
    const {
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth,
      Incident_UniqueIdentifier,
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
    } = this.patientInformationSectionStore! as ScnaPatientInformationSectionStore;

    const {
      OrganizationId,
      SubmissionType,
    } = this.administerInformationSectionStore!;

    const {
      SubmitterType,
      FirstResponder_Type,
      FirstResponder_FirstAndLastName,
      Incident_OrganizationName,
      Incident_MedicationAdministered,
      Incident_Zipcode,
      Incident_County
    } = this.administerInformationSectionStore.AdministerInformationFormStore! as ScnaAdministerInformationSectionStore;

    const request: CreateNaloxoneEventRequest = {
      OrganizationId,
      SubmissionType,
      SubmitterType,
      FirstResponder_FirstAndLastName,
      FirstResponder_Type,
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth: nodaTimeHelper.toDate(DateOfBirth),
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
      Incident_UniqueIdentifier,
      Incident_OrganizationName,
      Incident_MedicationAdministered,
      Incident_Zipcode,
      Incident_County
    };
    return request;
  }

  private prepareIanaRequest() {
    const {
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth,
      Incident_UniqueIdentifier,
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
      Patient_Identifier
    } = this.patientInformationSectionStore! as IanaPatientInformationSectionStore;

    const {
      OrganizationId,
      SubmissionType,
    } = this.administerInformationSectionStore!;

    const {
      Incident_AdministerFirstName,
      Incident_AdministerLastName,
      Incident_MedicationName,
      Incident_OrganizationName,
      Incident_MedicationDosage,
      Incident_MedicationDosageUnit,
      Incident_MedicationAdministered,
      Incident_NotifiedDateTime,
      Incident_Zipcode,
      Incident_County
    } = this.administerInformationSectionStore.AdministerInformationFormStore! as IanaAdministerInformationSectionStore;

    const request: CreateNaloxoneEventRequest = {
      OrganizationId,
      SubmissionType,
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth: nodaTimeHelper.toDate(DateOfBirth),
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
      Patient_Identifier,
      Incident_UniqueIdentifier,
      Incident_MedicationAdministerId:
        `${Incident_AdministerFirstName}_${Incident_AdministerLastName}`,
      Incident_MedicationName,
      Incident_OrganizationName,
      Incident_MedicationDosage,
      Incident_MedicationDosageUnit: MedicationDosageUnitDisplayName[Incident_MedicationDosageUnit],
      Incident_MedicationAdministered,
      Incident_NotifiedDateTime,
      Incident_Zipcode,
      Incident_County
    };
    return request;
  }
}
