import UpdatePatientInformationRequest from '../../../../../model/naloxone/UpdatePatientInformationRequest';
import UpdateContactInformationRequest from '../../../../../model/naloxone/UpdateContactInformationRequest';
import UpdateIncidentInformationRequest from '../../../../../model/naloxone/UpdateIncidentInformationRequest';
import { Section } from '../Section';
import nodaTimeHelper from '../../../../../helpers/nodaTimeHelper';
import UpdateNaloxoneRequest from '../../../../../model/naloxone/UpdateNaloxoneRequest';
import { ScnaPatientContactSectionStore } from '../contactInformationSection/ScnaPatientContactSection/ScnaPatientContactSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from '../administerInformationSection/ScnaAdministerInformationSection/ScnaAdministerInformationSectionStore';
import {
  ScnaPatientInformationSectionStore
} from '../patientInformationSection/ScnaPatientInformationSection/ScnaPatientInformationSectionStore';

const createRequest = (section: Section,
  patientContactSectionStore: ScnaPatientContactSectionStore,
  patientInformationSectionStore: ScnaPatientInformationSectionStore,
  administerInformationSectionStore: ScnaAdministerInformationSectionStore): UpdateNaloxoneRequest => {
  switch (section) {
    case 'contactInformation':
      return {
        contactInformation: prepareContactInformationRequest(patientContactSectionStore!)
      };
    case 'incidentInformation':
      return {
        incidentInformation: prepareIncidentInformationRequest(administerInformationSectionStore!)
      };
    case 'patientInformation':
      return {
        patientInformation: preparePatientInformationRequest(patientInformationSectionStore!)
      };
    default:
      throw new Error(`Could not create request for section ${section}`);
  }
};

const preparePatientInformationRequest = (store: ScnaPatientInformationSectionStore): UpdatePatientInformationRequest => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    patientGender,
    patientIdentifier
  } = store;
  return {
    FirstName: firstName,
    MiddleName: middleName,
    LastName: lastName,
    DateOfBirth: nodaTimeHelper.toDate(dateOfBirth),
    Patient_Gender: patientGender,
    Patient_Identifier: patientIdentifier,
  };
};

const prepareContactInformationRequest = (store: ScnaPatientContactSectionStore): UpdateContactInformationRequest => {
  const {
    patientAddress1,
    patientAddress2,
    patientCity,
    patientState,
    patientZipCode
  } = store;

  return {
    Patient_Address1: patientAddress1,
    Patient_Address2: patientAddress2,
    Patient_City: patientCity,
    Patient_State: patientState,
    Patient_ZipCode: patientZipCode,
  };
};

const prepareIncidentInformationRequest = (store: ScnaAdministerInformationSectionStore): UpdateIncidentInformationRequest => {
  const {
    incidentOrganizationName,
    incidentAdministerFirstName,
    incidentAdministerLastName,
    medicationAdministered,
    firstResponder_Type,
    firstResponder_FirstAndLastName,
    incidentCounty,
    incidentZipCode,
    submitterType
  } = store;
  return {
    Incident_MedicationAdministerId:
      `${incidentAdministerFirstName}_${incidentAdministerLastName}`,
    Incident_OrganizationName: incidentOrganizationName,
    Incident_MedicationAdministered: nodaTimeHelper.toDate(medicationAdministered),
    FirstResponder_Type: firstResponder_Type,
    FirstResponder_FirstAndLastName: firstResponder_FirstAndLastName,
    Incident_County: incidentCounty,
    Incident_ZipCode: incidentZipCode,
    SubmitterType: submitterType
  };
};

export const scnaNaloxoneRequestFactory = {
  createRequest,
};
