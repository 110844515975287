import { computed, observable } from 'mobx';
import { CreateUserPersonalInfoSectionStore } from '../../../../shared/user/create/personalInfoSection/CreateUserPersonalInfoSectionStore';
import apiService from '../../../../services/ApiService';
import ExistingUserForUserCreate from '../../../../model/user/create/ExistingUserForUserCreate';

export class AdminCreateUserPersonalInfoSectionStore extends CreateUserPersonalInfoSectionStore {
  @observable
  public selectedUserId = 0;

  @computed
  public get continueButtonDisabled(): boolean {
    return this.usersExist && this.selectedUserId === 0;
  }

  @observable
  public existingUsers: ExistingUserForUserCreate[] = [];

  @computed
  public get usersExist(): boolean {
    return this.existingUsers?.length > 0;
  }

  public getExistingUsers = async (email: string, phone: string): Promise<void> => {
    const response = await apiService.get<ExistingUserForUserCreate[]>('user/listExistingByEmailAndPhone', {
      params: {
        email,
        phone
      }
    });
    this.existingUsers = response.data;
  };

  public async validate<TKey extends keyof this>(field?: TKey): Promise<boolean> {
    if (this.usersExist && this.selectedUserId >= 0) {
      return true;
    }
    return super.validate(field);
  }
}
