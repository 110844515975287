import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { PreviewSectionProps } from '../../PreviewSectionProps';
import { MedicationDosageUnitDisplayName } from '../../../../../../helpers/enums/medicationDosageUnit';
import { NaloxoneStore } from '../../NaloxoneStoreContext';

export default class IanaPreviewAdministerInformationSection extends React.Component<PreviewSectionProps> {
  public constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Incident Information">
              <Row>
                <Col sm={9} xs={12}>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Submission Id" value={context.administerInformationSectionStore.submissionId} id="submissionId" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Incident Organization Name" value={context.administerInformationSectionStore.incidentOrganizationName} id="incidentOrganizationName" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Administer" value={context.administerInformationSectionStore.incidentMedicationAdministerId} id="incidentMedicationAdministerId" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Administered Date" value={context.administerInformationSectionStore.medicationAdministered.forPreview} id="incidentMedicationAdministeredDate" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Notified Date" value={context.administerInformationSectionStore.incidentNotified.forPreview} id="incidentNotifiedDate" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Medication Name" value={context.administerInformationSectionStore.incidentMedicationName} id="incidentMedicationName" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      { /* @ts-ignore */ }
                      <InlineEntry labelText="Medication Dosage" value={`${context.administerInformationSectionStore.incidentMedicationDosage} ${MedicationDosageUnitDisplayName[context.administerInformationSectionStore.incidentMedicationDosageUnit]}`} id="incidentMedicationDosage" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onUpdateButtonHandler(e)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
