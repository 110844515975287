import * as React from 'react';
import { Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import FormSectionContainer from '../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../../shared/layout/inlineEntry/InlineEntry';
import Spinner from '../../../shared/layout/spinner/Spinner';
import MessageBox, { MessageType } from '../../../shared/layout/messageBox/MessageBox';
import FormSectionDivider from '../../../shared/layout/formContainer/sectionDivider/FormSectionDivider';
import { formatPhoneNumber, formatUsDate } from '../../../helpers/displayHelper';
import { WithIdProps } from '../../../shared/props/WithIdProps';
import AddOrganizationAdminSection from './addOrganizationAdmin/AddOrganizationAdminSection';
import agencyDetailsStore, { OrganizationDetailsStore } from './OrganizationDetailsStore';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import navigationHelpers from '../../../helpers/navigationHelpers';
import { StateDisplayName } from '../../../helpers/enums/stateDisplayName';
import { OrganizationStatus } from '../../../model/organization/OrganizationStatus';
import OrganizationDeactivationRejectionStore from './rejectionModal/OrganizationDeactivationRejectionStore';
import OrganizationApprovalModal from './approvalModal/OrganizationApprovalModal';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';
import OrganizationDeactivationRejectionModal from './rejectionModal/OrganizationDeactivationRejectionModal';
import userStore from '../../../data/UserStore';
import permissions from '../../../model/user/permissions';
import notificationToast from '../../../shared/layout/notificationToast/NotificationToast';

@page(routePaths.organization.detailsWithId)
@observer
class OrganizationDetailsPage extends React.Component<RouteComponentProps<WithIdProps>, OrganizationDetailsPageState> {
  private readonly _store: OrganizationDetailsStore = agencyDetailsStore;

  constructor(props: RouteComponentProps<WithIdProps>) {
    super(props);
    this.state = {
      addAdminToggled: false,
      openModal: OpenModal.None
    };
  }

  public async componentDidMount(): Promise<void> {
    this._store.organizationId = Number(this.props.match.params.id);
    await this._store.getOrganizationDetails();
  }

  public render(): React.ReactNode {
    return (
      <>
        <FormContainer headerText="Organization Information">
          {this.renderContent()}
        </FormContainer>
        {(this.state.openModal === OpenModal.OrganizationReject
          || this.state.openModal === OpenModal.OrganizationDeactivate) && (
          <OrganizationDeactivationRejectionModal
            store={new OrganizationDeactivationRejectionStore()}
            openModal={this.state.openModal}
            closeModalFunc={() => this.toggleModal(OpenModal.None)}
            organizationId={this._store.organizationId}
            onSubmitFunc={() => this.onModalSubmit()}
          />
        )}
        {this.state.openModal === OpenModal.OrganizationApprove && (
          <OrganizationApprovalModal
            openModal={this.state.openModal}
            organizationId={this._store.organizationId}
            onSubmitFunc={() => this.approveOrganization()}
            closeModalFunc={() => this.toggleModal(OpenModal.None)}
          />
        )}
      </>
    );
  }

  private renderContent(): React.ReactNode {
    if (this._store.isGetOrgDetailsBusy) {
      return <h3><Spinner size="1x" /> Loading...</h3>;
    }

    if (this._store.error) {
      return <MessageBox id="agencyDetailsErrorBox" isVisible message={this._store.error} type={MessageType.Error} />;
    }

    return (
      <>
        <FormSectionContainer headerText="General Information">
          {this.renderOrganizationContent()}
        </FormSectionContainer>
        <FormSectionDivider headerText="Admin(s)" />
        {this.renderOrgAdminsContent()}
        {this.renderAddOrgAdminSection()}
        <hr />
        <div>
          {this.renderSummary()}
        </div>
      </>
    );
  }

  private renderOrganizationContent(): React.ReactNode {
    const agencyDetails = this._store.orgDetails!;
    return (
      <div className="row">
        <div className="col-12 col-sm-9 col-md-8">
          <InlineEntry id="agencyName" labelText="Agency" value={agencyDetails.name} />
          <InlineEntry id="agencyPhone" labelText="Phone" value={formatPhoneNumber(agencyDetails.phone)} />
          <InlineEntry id="agencyEmail" labelText="Email" value={agencyDetails.email} />
          <InlineEntry id="agencyAddress" labelText="Address" value={agencyDetails.address!.street} />
          <InlineEntry id="agencyCity" labelText="City" value={agencyDetails.address!.city} />
          <InlineEntry id="agencyState" labelText="State" value={StateDisplayName[agencyDetails.address!.state]} />
          <InlineEntry id="agencyCounty" labelText="County" value={agencyDetails.address!.county} />
          <InlineEntry id="agencyZip" className="pb-0" labelText="Zip" value={agencyDetails.address!.zipCode} />
        </div>
        <div className="col-8 col-sm-3 col-md-4 row pr-0 align-content-start">
          <div className="col-6 col-sm-12 col-md-6 pr-0 mt-3 mt-sm-0 offset-6">
            <Link
              to={navigationHelpers.buildUrlWithId(routePaths.organization.editWithId, agencyDetails.id)}
              className="w-100 btn btn-primary"
            >
              Update
            </Link>
          </div>
          {this.shouldRenderSecondaryButtons() && (
            <div className="col-6 col-sm-12 col-md-6 pr-0 mt-3 mt-md-0 pt-2 offset-6">
              {this.renderOrganizationActionButtons()}
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderOrganizationActionButtons(): React.ReactNode {
    const organizationStatus = this._store.orgDetails!.status;
    const activateButton = (
      <Button
        id="activateOrganizationButton"
        block
        color="primary"
        onClick={() => this.toggleModal(OpenModal.OrganizationApprove)}
      >Activate
      </Button>
    );
    switch (organizationStatus) {
      case OrganizationStatus.Pending:
        return (
          <>
            {activateButton}
            <Button
              id="rejectOrganizationButton"
              block
              outline
              color="primary"
              className="mt-3"
              onClick={() => this.toggleModal(OpenModal.OrganizationReject)}
            >Reject
            </Button>
          </>
        );
      case OrganizationStatus.Active:
        return (
          <Button
            id="deactivateOrganizationButton"
            block
            outline
            color="primary"
            onClick={() => this.toggleModal(OpenModal.OrganizationDeactivate)}
          >Deactivate
          </Button>
        );
      case OrganizationStatus.Deactivated:
        return activateButton;
      default:
        throw new Error('Invalid organization status!');
    }
  }

  private renderOrgAdminsContent(): React.ReactNode {
    const admins = this.getAdmins();

    if (admins.length === 0) {
      return (
        <FormSectionContainer>
          <p id="noAdminsMessage" className="font-italic">Agency has no Admins</p>
        </FormSectionContainer>
      );
    }

    return admins.map(admin => (
      <FormSectionContainer key={admin.id}>
        <div className="row">
          <div className="col-12 col-sm-9 col-md-10">
            <InlineEntry id={`adminName${admin.id}`} className="font-weight-bold" value={`${admin.lastName}, ${admin.firstName} `} />
            <InlineEntry id={`adminEmail${admin.id}`} value={admin.email} />
            <InlineEntry id={`adminPhone${admin.id}`} className="pb-0 adminPhoneNumber" value={formatPhoneNumber(admin.phoneNumber)} />
          </div>
          <div className="col-4 col-sm-3 col-md-2 mt-3 mt-sm-0">
            <Link className="btn btn-primary editButton w-100" to={{ pathname: `/user/${admin.id}/details` }} target="_blank">Edit</Link>
          </div>
        </div>
      </FormSectionContainer>
    ));
  }

  private async onOrgAdminAdded() {
    await this._store.getOrganizationDetails();
    notificationToast.showSuccess('Organization Admin Added!');
  }

  private renderAddOrgAdminSection(): React.ReactNode {
    if (this.state.addAdminToggled) {
      return (
        <AddOrganizationAdminSection
          organizationId={this._store.organizationId}
          hideAddOrgAdminSectionFunc={() => this.toggleAddOrgAdminSection()}
          onOrgAdminAddedFunc={() => this.onOrgAdminAdded()}
        />
      );
    }

    return (
      <div>
        <Button
          id="addAgencyAdminButton"
          className="pl-4 pr-4"
          color="primary"
          onClick={() => this.setState(prevState => ({ addAdminToggled: !prevState.addAdminToggled }))}
        >
          <FontAwesomeIcon icon={faPlus} />
          <span className="align-middle"> Add Agency Admin</span>
        </Button>
      </div>
    );
  }

  private renderSummary(): React.ReactNode {
    const agencyDetails = this._store.orgDetails!;
    return (
      <>
        <InlineEntry
          id="createdOn"
          className="mb-0"
          labelText="Registered On"
          value={formatUsDate(agencyDetails.createdOn)}
        />
        <InlineEntry
          id="lastUpdatedBy"
          className="mb-0"
          labelText="Last Updated By"
          value={`${agencyDetails.lastUpdatedBy?.lastName}, ${agencyDetails.lastUpdatedBy?.firstName}`}
        />
        <InlineEntry
          id="lastUpdatedOn"
          className="mb-0"
          labelText="Last Updated On"
          value={formatUsDate(agencyDetails.lastUpdatedOn)}
        />
      </>
    );
  }

  private shouldRenderSecondaryButtons(): boolean {
    const organizationStatus = this._store.orgDetails!.status;
    switch (organizationStatus) {
      case OrganizationStatus.Pending:
        return !!userStore.session?.role?.hasPermission(permissions.organization.approve) && !!userStore.session?.role?.hasPermission(permissions.organization.reject);
      case OrganizationStatus.Active:
        return !!userStore.session?.role?.hasPermission(permissions.organization.deactivate);
      case OrganizationStatus.Deactivated:
        return !!userStore.session?.role?.hasPermission(permissions.organization.approve);
      default:
        return false;
    }
  }

  private async approveOrganization() {
    await this._store.getOrganizationDetails();
  }

  private getAdmins(): any[] {
    if (this._store.orgDetails) {
      return this._store.orgDetails.admins || [];
    }

    return [];
  }

  private toggleAddOrgAdminSection(): void {
    this.setState(prevState => ({ addAdminToggled: !prevState.addAdminToggled }));
  }

  private toggleModal(openModal: OpenModal) {
    this.setState({
      openModal
    });
  }

  private async onModalSubmit() {
    await this._store.getOrganizationDetails();
  }
}

interface OrganizationDetailsPageState {
  addAdminToggled: boolean;
  openModal: OpenModal;
}

export default withRouter(OrganizationDetailsPage);
