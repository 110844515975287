import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import FormSectionContainer from '../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import { formatUsDateTime } from '../../../helpers/displayHelper';
import styles from './OrganizationAuditPage.scss';
import { WithIdProps } from '../../../shared/props/WithIdProps';
import FormSectionDivider from '../../../shared/layout/formContainer/sectionDivider/FormSectionDivider';
import apiService from '../../../services/ApiService';
import AuditChanges from '../../../model/audit/AuditChanges';
import LoadingSpinner from '../../../shared/layout/loadingSpinner/LoadingSpinner';
import { AuditChangeTypeDisplayName } from '../../../helpers/enums/auditChangeTypeDisplayName';

interface UserAuditPageState {
  isLoading: boolean;
  change?: AuditChanges;
}

@page(routePaths.audit.organizationWithId)
export class OrganizationAuditPage extends React.Component<RouteComponentProps<WithIdProps>, UserAuditPageState> {
  public constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  public async componentDidMount(): Promise<void> {
    const response = await apiService.get<AuditChanges>(`/audit/organization/${this.props.match.params.id}`);
    this.setState({ change: response.data, isLoading: false });
  }

  public render(): React.ReactNode {
    return (
      <FormContainer headerText="Organization Audit" className={styles.userAuditPage}>
        <FormSectionDivider headerText={`Id: ${this.props.match.params.id}`} />
        {this.getContent()}
      </FormContainer>
    );
  }

  private getContent(): React.ReactNode {
    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    return this.state.change!.changes.map(change => (
      <>
        <FormSectionContainer key={change.date.toString()}>
          <div className="changeEntry">
            <div className="changeEntry-header">
              <span className="text-uppercase">
                On <strong>{formatUsDateTime(change.date)}</strong> by <strong>{change.changeBy}</strong>
              </span>
              <span className="font-italic text-uppercase">{AuditChangeTypeDisplayName[change.changeType]}</span>
            </div>
            <hr />
            <table>
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '30%' }} />
              <tbody>
                {change.propertyChanges.map(changeEntry => (
                  <tr key={changeEntry.propertyDisplayName}>
                    <th>{changeEntry.propertyDisplayName}</th>
                    <td className="text-danger text-right">{this.getValue(changeEntry.oldValue)}</td>
                    <td className="text-center"><FontAwesomeIcon icon={faArrowRight} /></td>
                    <td className="text-success text-left">{this.getValue(changeEntry.newValue)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </FormSectionContainer>
      </>
    ));
  }

  private getValue(value: any): string {
    if (value instanceof Array) {
      const valueArray = value as string[];

      return valueArray.join(', ');
    } if (value instanceof Date) {
      return formatUsDateTime(value);
    }

    return value;
  }
}

export default withRouter(OrganizationAuditPage);
