import { observer } from 'mobx-react';
import React from 'react';
import {
  Button, Col, Form, Row
} from 'reactstrap';
import { makeRef } from '../../../../helpers/Ref';
import BusyOverlay from '../../../layout/busyOverlay/BusyOverlay';
import FormInput from '../../../controls/formInput/FormInput';
import EditPasswordStore from './EditPasswordStore';
import PasswordCheckerSection from '../../../controls/passwordChecker/PasswordCheckerSection';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import ProfilePageStore from '../ProfilePageStore';
import EditProfileStore from '../editProfile/EditProfileStore';
import EditPhoneStore from '../editPhone/EditPhoneStore';
import EditEmailStore from '../editEmail/EditEmailStore';
import EditOrganizationStore from '../editOrganization/EditOrganizationStore';
import userStore from '../../../../data/UserStore';

interface EditPasswordSectionProps {
  toggleEditPasswordFunc: () => void;
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}

@observer
export default class EditPasswordSection extends React.Component<EditPasswordSectionProps> {
  private readonly _store: EditPasswordStore;

  constructor(props: EditPasswordSectionProps) {
    super(props);
    this._store = props.store.editPasswordStore;
  }

  public render(): React.ReactNode {
    return (
      <BusyOverlay isBusy={this._store.isBusy}>
        <Form>
          <Row>
            <Col xs={12}>
              <h3 className="mb-4">Update password</h3>
              <FormInput
                id="currentPassword"
                type="password"
                storeRef={makeRef(this._store, 'currentPassword')}
                labelText="Current password"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInput
                id="newPassword"
                type="password"
                storeRef={makeRef(this._store, 'newPassword')}
                labelText="Password"
              />
            </Col>
            <Col md={6}>
              <FormInput
                id="confirmPassword"
                type="password"
                storeRef={makeRef(this._store, 'confirmPassword')}
                labelText="Confirm password"
              />
            </Col>
          </Row>
          <PasswordCheckerSection password={this._store.newPassword} />
          <Row>
            <Col xs={6} sm={4} lg={3}>
              <Button
                type="submit"
                color="primary"
                block
                onClick={(e: React.MouseEvent<any>) => this.updatePassword(e)}
              >Save Changes
              </Button>
            </Col>
            <Col xs={6} sm={4} lg={3} className="pl-0">
              <Button color="secondary" block onClick={this.props.toggleEditPasswordFunc}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </BusyOverlay>
    );
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private async updatePassword(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    try {
      if (await this._store.validate()) {
        const result = await this._store.updatePassword();

        if (result.success) {
          this.props.toggleEditPasswordFunc();
          notificationToast.showSuccess('Password updated successfully. You are now being logged out.');
          // Brief pause to let the user see the notification before logout
          window.setTimeout(() => {
            userStore.logout();
          }, 3000);
        } else if (result.status === 422) {
          notificationToast.showError('The password does not meet requirements or has been used recently.');
        } else if (result.message) {
          notificationToast.showError(result.message);
        } else {
          notificationToast.showError('An error occurred while updating your password. Please try again.');
        }
      }
    } catch (error) {
      notificationToast.showError('An error occurred updating your password. Please try again.');
    }
  }
}
