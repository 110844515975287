import { computed, observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';

export enum EmailConfirmationState {
  NotValidated,
  Valid,
  Invalid
}

export class ConfirmEmailStore extends ValidatableObject {
  @observable
  private _emailConfirmationState: EmailConfirmationState = EmailConfirmationState.NotValidated;

  @observable
  public token?: string;

  @observable
  public userId?: number;

  @observable
  public newEmail?: string;

  @computed
  public get emailConfirmationState(): EmailConfirmationState {
    return this._emailConfirmationState;
  }

  public async validateToken(): Promise<void> {
    const response = await apiService.post('/user/confirmEmail', {
      userId: this.userId,
      token: this.token,
      newEmail: this.newEmail
    });

    if (response.status === 204) {
      this._emailConfirmationState = EmailConfirmationState.Valid;
    } else {
      this._emailConfirmationState = EmailConfirmationState.Invalid;
    }
  }

  public reset() {
    this._emailConfirmationState = EmailConfirmationState.NotValidated;
    this.token = undefined;
    this.userId = undefined;
    this.resetValidationState();
  }
}

export default new ConfirmEmailStore();
