import { observer } from 'mobx-react';
import { FormSelectOption } from '../FormSelect';
import ValidatableObject from '../../../validation/ValidatableObject';
import FormSelectorBase from '../FormSelectorBase';
import { SubmitterType, SubmitterTypeDisplayName } from '../../../../helpers/enums/submitterType';
import GetStaticOptionsHelper from '../../../../helpers/getStaticOptionsHelper';

@observer
export default class FormSubmitterTypeSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, SubmitterType> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && s.value === storeRefValue) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return GetStaticOptionsHelper.buildOptions(SubmitterType, SubmitterTypeDisplayName);
  }

  protected prepareFetchedOptions(data: FormSelectOption[]): FormSelectOption[] {
    return data;
  }
}
