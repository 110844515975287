import { observer } from 'mobx-react';
import Organization from '../../../../model/organization/Organization';
import FormSelectorBase from '../../../../shared/controls/formSelect/FormSelectorBase';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import { FormSelectOption } from '../../../../shared/controls/formSelect/FormSelect';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';

@observer
export default class OrganizationFormSelector<T extends ValidatableObject, TKey extends keyof T>
  extends FormSelectorBase<T, TKey, Organization> {
  protected getStaticOptions(): FormSelectOption[] {
    return [];
  }

  protected prepareFetchedOptions(data: Organization[]): FormSelectOption[] {
    const options = data.map<FormSelectOption>(organization => {
      const { address } = organization;
      return {
        value: organization.id,
        label: `${organization.name} - ${address.street} ${address.city}, ${StateDisplayName[address.state]} ${address.zipCode}`
      };
    });

    if (this.props.addAllOption && data.length > 0) {
      options.unshift(this.allOrganizationsOption(data));
    }
    return options;
  }

  private allOrganizationsOption(data: Organization[]) {
    const multipleOrgs = data.length > 1;
    return {
      value: 0,
      label: `All (${data.length} organization${multipleOrgs ? 's' : ''})`
    };
  }
}
