import React, { useContext } from 'react';
import Spinner from 'reactstrap/lib/Spinner';
import InlineEntry from '../../../../../../../../shared/layout/inlineEntry/InlineEntry';
import { LicenseeContext } from '../../../../../../LicenseeContext';

export const LicenseeInformationSection: React.FC = () => {
  const {
    loaded, primaryContactEmail, licenseeName, primaryContactName, primaryContactPhone
  } = useContext(LicenseeContext);
  if (!loaded) {
    return <Spinner />;
  }
  return (
    <>
      <InlineEntry id="licenseeName" labelText="License Name" value={licenseeName} />
      <InlineEntry id="primaryContactName" labelText="Primary Contact Name" value={primaryContactName} />
      <InlineEntry id="primaryContactEmail" labelText="Primary Contact Email" value={primaryContactEmail} />
      <InlineEntry id="primaryContactPhone" labelText="Primary Contact Phone" value={primaryContactPhone} />
    </>
  );
};
