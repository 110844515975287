import { computed, observable } from 'mobx';
import ValidatableObject from '../../validation/ValidatableObject';
import { CreateUserPageSection } from './CreateUserPageSection';
import { CreateUserPersonalInfoSectionStore } from './personalInfoSection/CreateUserPersonalInfoSectionStore';
import { CreateUserOrganizationSectionStore } from './organizationSection/CreateUserOrganizationSectionStore';
import {
  ExistingOrganizationForCreateUser, NewOrganizationForCreateUser,
  OrganizationForCreateUser,
  UserForCreateUser
} from '../../../model/account/create/CreateUserRequest';

export default class CreateUserStore<TPersonalInfoStore extends CreateUserPersonalInfoSectionStore,
  TOrganizationStore extends CreateUserOrganizationSectionStore> extends ValidatableObject {
  @observable
  public pageSection: CreateUserPageSection = CreateUserPageSection.PersonalInfo;
  public personalInfoStore: TPersonalInfoStore;
  public organizationStore: TOrganizationStore;

  @computed
  public get continueButtonDisabled(): boolean {
    switch (this.pageSection) {
      case CreateUserPageSection.PersonalInfo:
        return this.personalInfoStore.continueButtonDisabled;
      case CreateUserPageSection.Organization:
        return this.organizationStore.continueButtonDisabled;
      default:
        return false;
    }
  }

  constructor(personalInfoStore: TPersonalInfoStore, organizationStore: TOrganizationStore) {
    super();
    this.personalInfoStore = personalInfoStore;
    this.organizationStore = organizationStore;
  }

  protected getUser(): UserForCreateUser {
    const { continueButtonDisabled, ...user } = this.personalInfoStore;
    return {
      id: 0,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      password: ''
    };
  }

  protected getOrganization(): OrganizationForCreateUser | ExistingOrganizationForCreateUser {
    const organization = this.organizationStore.selectedOrganization!;

    if (organization.id > 0) {
      const existingOrganization: ExistingOrganizationForCreateUser = {
        id: organization.id,
        submissionType: organization.submissionType,
        selectedUserRoleId: this.organizationStore.userRole!.id,
      };
      return existingOrganization;
    }

    const newOrganization: NewOrganizationForCreateUser = {
      ...organization,
      id: 0,
      roles: [],
      selectedUserRoleId: this.organizationStore.userRole!.id,
    };
    return newOrganization;
  }
}
