import React from 'react';
import { Form } from 'reactstrap';
import { observer } from 'mobx-react';
import FormInput from '../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import { SimpleOperationModal } from '../../../../shared/layout/modal/SimpleOperationModal';
import notificationToast from '../../../../shared/layout/notificationToast/NotificationToast';
import OrganizationDeactivationRejectionStore from './OrganizationDeactivationRejectionStore';
import { OpenModal } from '../../../../shared/layout/modal/OpenModal';

export type OrganizationDeactivationModalProps = {
  openModal: OpenModal.OrganizationDeactivate | OpenModal.OrganizationReject;
  organizationId: number;
  closeModalFunc: () => void;
  onSubmitFunc?: () => void;
  store: OrganizationDeactivationRejectionStore;
};

const OrganizationDeactivationRejectionModal:
  React.FC<OrganizationDeactivationModalProps> = (props: OrganizationDeactivationModalProps) => {
    const { store } = props;
    const modalTitle = props.openModal === OpenModal.OrganizationDeactivate ? 'Deactivate Organization' : 'Reject Organization';
    const labelText = props.openModal === OpenModal.OrganizationDeactivate ? 'Deactivation Reason' : 'Rejection Reason';
    const disableOrganization = async () => {
      if (await store.validate()) {
        switch (props.openModal) {
          case OpenModal.OrganizationDeactivate:
            await store.disableOrganization(props.organizationId);
            notificationToast.showSuccess('Organization has been deactivated.');
            break;
          case OpenModal.OrganizationReject:
            await store.rejectOrganization(props.organizationId);
            notificationToast.showSuccess('Organization has been rejected.');
        }

        props.closeModalFunc();
        if (props.onSubmitFunc) props.onSubmitFunc!();
      }

      return true;
    };

    return (
      <SimpleOperationModal
        isOpen={props.openModal === OpenModal.OrganizationReject || props.openModal === OpenModal.OrganizationDeactivate}
        headerTitle={modalTitle}
        onSubmitButtonClicked={disableOrganization}
        onCancelButtonClicked={props.closeModalFunc}
        isSubmitButtonBusy={store.isBusy}
      >
        <Form>
          <FormInput
            id="deactivationReason"
            type="textarea"
            rows="4"
            labelText={labelText}
            storeRef={makeRef(store, 'reason')}
          />
        </Form>
      </SimpleOperationModal>
    );
  };

export default observer(OrganizationDeactivationRejectionModal);
