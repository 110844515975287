import * as React from 'react';
import CreateNaloxoneEventStore from '../CreateNaloxoneEventStore';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import IanaCreateNaloxoneEventReviewSection from './ianaReviewSection/IanaCreateNaloxoneEventReviewSection';
import ScnaCreateNaloxoneEventReviewSection from './scnaReviewSection/ScnaCreateNaloxoneEventReviewSection';

interface CreateNaloxoneEventReviewSectionProps {
  store: CreateNaloxoneEventStore;
}

export default class CreateNaloxoneEventReviewSection extends React.Component<CreateNaloxoneEventReviewSectionProps> {
  private readonly _mainStore = this.props.store;

  public render(): React.ReactNode {
    return (
      <>
        {this.renderReviewSection()}
      </>
    );
  }

  private renderReviewSection() {
    switch (this._mainStore.patientInformationSectionStore?.DataSubmissionType) {
      case DataSubmissionType.IANA:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return <IanaCreateNaloxoneEventReviewSection mainStore={this._mainStore} />;
      case DataSubmissionType.SCNA:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        return <ScnaCreateNaloxoneEventReviewSection mainStore={this._mainStore} />;
      default:
        throw new Error('Invalid data submission type');
    }
  }
}
