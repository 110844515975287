import React from 'react';
import ProfilePage from '../../../shared/user/profile/ProfilePage';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import { UserProfilePageStore } from './UserProfilePageStore';
import { EditProfileUserNameStore } from './EditProfileUserNameStore';
import { EditProfilePhoneStore } from './EditProfilePhoneStore';
import { EditProfilePasswordStore } from './EditProfilePasswordStore';
import { EditProfileEmailStore } from './EditProfileEmailStore';
import { EditProfileOrganizationStore } from './EditProfileOrganizationStore';

@page({
  path: routePaths.user.profile,
  permitActiveUsersOnly: false
})
export class UserProfilePage extends React.Component {
  private _editUserNameStore = new EditProfileUserNameStore();
  private _editPhoneStore = new EditProfilePhoneStore();
  private _editPasswordStore = new EditProfilePasswordStore();
  private _editEmailStore = new EditProfileEmailStore();
  private _editOrganizationStore = new EditProfileOrganizationStore();
  private _mainStore = new UserProfilePageStore(
    this._editUserNameStore,
    this._editPhoneStore,
    this._editPasswordStore,
    this._editEmailStore,
    this._editOrganizationStore
  );

  public render() {
    return <ProfilePage store={this._mainStore} />;
  }
}
