import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { SimpleOperationModal } from '../../../../shared/layout/modal/SimpleOperationModal';
import notificationToast from '../../../../shared/layout/notificationToast/NotificationToast';
import { OpenModal } from '../../../../shared/layout/modal/OpenModal';
import apiService from '../../../../services/ApiService';

export type OrganizationApprovalModalProps = {
  openModal: OpenModal.OrganizationApprove;
  modalTitle?: string;
  organizationId: number;
  closeModalFunc: () => void;
  onSubmitFunc?: () => void;
};

const OrganizationApprovalModal:
  React.FC<OrganizationApprovalModalProps> = (props: OrganizationApprovalModalProps) => {
    const [isBusy, setIsBusy] = useState(false);
    const modalTitle = props.modalTitle || 'Approve Organization';
    const approveOrganization = async () => {
      setIsBusy(true);

      try {
        await apiService.post('organization/approve', { organizationId: props.organizationId });
      } finally {
        setIsBusy(false);
      }

      notificationToast.showSuccess('Organization has been approved.');

      props.closeModalFunc();
      if (props.onSubmitFunc) props.onSubmitFunc!();

      return true;
    };

    return (
      <SimpleOperationModal
        isOpen={props.openModal === OpenModal.OrganizationApprove}
        headerTitle={modalTitle}
        onSubmitButtonClicked={approveOrganization}
        onCancelButtonClicked={props.closeModalFunc}
        isSubmitButtonBusy={isBusy}
      >
        <p>Are you sure you want to approve this organization?</p>
      </SimpleOperationModal>
    );
  };

export default observer(OrganizationApprovalModal);
