export enum State {
  AL = 1,
  AK,
  AZ,
  AR,
  CA,
  CO,
  CT,
  DE,
  DC,
  FL,
  GA,
  HI,
  ID,
  IL,
  IN,
  IA,
  KS,
  KY,
  LA,
  ME,
  MD,
  MA,
  MI,
  MN,
  MS,
  MO,
  MT,
  NE,
  NV,
  NH,
  NJ,
  NM,
  NY,
  NC,
  ND,
  OH,
  OK,
  OR,
  PA,
  RI,
  SC,
  SD,
  TN,
  TX,
  UT,
  VT,
  VA,
  WA,
  WV,
  WI,
  WY,
  AS,
  GU,
  MH,
  FM,
  MP,
  PW,
  PR,
  VI
}
