import React from 'react';
import { Button, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import InlineEntry from '../../../layout/inlineEntry/InlineEntry';
import FormInput from '../../../controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import BusyOverlay from '../../../layout/busyOverlay/BusyOverlay';
import EditEmailStore from './EditEmailStore';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import ProfilePageStore from '../ProfilePageStore';
import EditProfileStore from '../editProfile/EditProfileStore';
import EditPhoneStore from '../editPhone/EditPhoneStore';
import EditPasswordStore from '../editPassword/EditPasswordStore';
import EditOrganizationStore from '../editOrganization/EditOrganizationStore';

interface EditEmailSectionProps {
  toggleEditEmailFunc: () => void;
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}

@observer
export default class EditEmailSection extends React.Component<EditEmailSectionProps> {
  private readonly _store: EditEmailStore;

  constructor(props: Readonly<EditEmailSectionProps>) {
    super(props);
    this._store = props.store.editEmailStore;
    this._store.currentEmail = this.props.store.userProfile!.email!;
  }

  public render(): React.ReactNode {
    return (
      <BusyOverlay isBusy={this._store.isBusy}>
        <Form>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4">Update Email Address</h3>
              <p className="font-italic">Your Email is primary contact method. Please keep it up to date.</p>
              <InlineEntry id="userEmail" labelText="Current email address" value={this._store.currentEmail} />
              <FormInput
                id="userEmailInput"
                labelText="New Email Address"
                storeRef={makeRef(this._store, 'newEmail')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-sm-4 col-lg-3">
              <Button
                type="submit"
                color="primary"
                block
                onClick={(e: React.MouseEvent<any>) => this.saveEmailAddress(e)}
              >
                Save changes
              </Button>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 pl-0">
              <Button color="secondary" block onClick={this.props.toggleEditEmailFunc}>Cancel</Button>
            </div>
          </div>
        </Form>
      </BusyOverlay>
    );
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private async saveEmailAddress(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    if (!await this._store.validate()) {
      return;
    }

    await this._store.updateEmail();
    notificationToast.showSuccess('Verification email has been sent. Please check your email for verification message');
    this.props.toggleEditEmailFunc();
  }
}
