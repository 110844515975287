import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import { combineClassNames } from '../../../helpers/reactHelpers';
import styles from './ConfirmEmailPage.scss';
import Icon from '../../../shared/controls/icons/Icon';
import confirmEmailStore, { ConfirmEmailStore, EmailConfirmationState } from './ConfirmEmailStore';

interface RouteParams {
  token?: string;
  userId?: string;
  newEmail?: string;
}

export type ConfirmEmailProps = RouteComponentProps<RouteParams>;

@page({
  path: routePaths.user.confirmEmail,
  needsAuthentication: false
})
@observer
export default class ConfirmEmailPage extends React.Component<ConfirmEmailProps> {
  private _store: ConfirmEmailStore = confirmEmailStore;

  public render(): React.ReactNode {
    return (
      <FormContainer className={combineClassNames(...this.getClassNames())}>
        {this.getContent()}
      </FormContainer>
    );
  }

  private* getClassNames(): Iterable<string> {
    yield styles.confirmEmailPage;
    switch (this._store.emailConfirmationState) {
      case EmailConfirmationState.NotValidated:
        yield 'loading';
        break;
      case EmailConfirmationState.Valid:
        yield 'success';
        break;
      case EmailConfirmationState.Invalid:
        yield 'invalid';
        break;
    }
  }

  private getContent(): React.ReactNode {
    switch (this._store.emailConfirmationState) {
      case EmailConfirmationState.NotValidated:
        return (
          <div>
            <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
            <h2>Validating token...</h2>
          </div>
        );
      case EmailConfirmationState.Valid:
        return (
          <div>
            <Icon name="success" size="5rem" className="mb-2" />
            <h1>Email confirmed</h1>
            <Link to={routePaths.general.root}>Back to login page</Link>
          </div>
        );
      case EmailConfirmationState.Invalid:
        return (
          <div className="invalid_token-container">
            <Icon name="error" size="5rem" />
            <h1>Invalid Link</h1>
            <p>
              The link you are trying to access is invalid or expired.
              Please try confirming your email again.
            </p>
            <Link to={routePaths.general.root}>Back to login page</Link>
          </div>
        );
    }

    return null;
  }

  public componentDidMount(): void {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    const userId = Number(params.get('userId'));
    const newEmail = params.get('newEmail');

    this._store.token = token || undefined;
    this._store.userId = userId;
    this._store.newEmail = newEmail || undefined;
    this._store.validateToken();
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }
}
