import { observable } from 'mobx';
import { SortingRule, TableProps } from 'react-table';
import { debounce } from '../../helpers/reactHelpers';
import GridPageRequest from '../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../model/grid/GridPageResponse';

export default abstract class GridStore<TRowModel, TSearch> {
  @observable
  public rows: TRowModel[] = [];

  @observable
  public totalCount = 0;

  @observable
  public pageSize: number = this.pageSizes[0];

  @observable
  public page = 1;

  @observable
  public search?: TSearch;

  @observable
  public sortingRule?: SortingRule;

  @observable
  public isLoading: boolean = this.shouldFetchOnMount;

  public isBackwardSearch = false;

  public get pageSizes(): number[] {
    return [10, 20, 50, 100, 200];
  }

  protected get shouldFetchOnMount(): boolean {
    return true;
  }

  protected get shouldDebounceFetch(): boolean {
    return true;
  }

  protected get shouldFetchData(): boolean {
    return true;
  }

  public constructor() {
    if (this.shouldDebounceFetch) {
      this.fetchData = debounce(this.fetchData.bind(this), 500) as any;
    }
  }

  public async fetchData(state?: TableProps, search?: TSearch): Promise<void> {
    // (daniel) React-Table fires this on componentDidMount() and there is no other way to stop it
    if (!this.shouldFetchData) {
      return;
    }

    if (state) {
      this.sortingRule = state.sorted && state.sorted.length > 0 ? state.sorted[0] : this.sortingRule;
    }
    if (search) {
      this.search = search;
    }

    this.isLoading = true;
    const gridPageRequest = this.getGridPageRequest();

    const gridData = await this.apiCall(gridPageRequest);
    this.populateGridMetadata(gridData);
    this.isLoading = false;
  }

  public async refreshGrid(): Promise<void> {
    await this.fetchData();
  }

  public async onPageSizeChangedHandler(newPageSize: number): Promise<void> {
    this.page = 1;
    this.pageSize = newPageSize;
    await this.fetchData();
  }

  public async onPageChangedHandler(newPage: number): Promise<void> {
    this.isBackwardSearch = newPage < this.page;
    this.page = newPage;
    await this.fetchData();
  }

  public async populateFromExternalData(dataPromise: Promise<GridPageResponse<TRowModel>>): Promise<void> {
    this.isLoading = true;
    const result = await dataPromise;
    this.populateGridMetadata(result);
    this.isLoading = false;
  }

  public getGridPageRequest(): GridPageRequest<TSearch> {
    return {
      page: this.page,
      pageSize: this.pageSize,
      search: this.search,
      sortingRule: this.sortingRule,
      isBackwardSearch: this.isBackwardSearch
    };
  }

  protected abstract apiCall(gridPageRequest: GridPageRequest<TSearch>): Promise<GridPageResponse<TRowModel>>;

  protected populateGridMetadata(gridPageResponse: GridPageResponse<TRowModel>): void {
    this.rows = gridPageResponse.rows;
    this.totalCount = gridPageResponse.totalCount;
  }
}
