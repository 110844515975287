import { observable } from 'mobx';
import EditEmailStore from '../../../shared/user/profile/editEmail/EditEmailStore';
import apiService from '../../../services/ApiService';

export class EditDetailsEmailStore extends EditEmailStore {
  @observable
  public userId = '';

  public async updateEmail(): Promise<void> {
    this._isBusy = true;

    try {
      await apiService.post(`/user/${this.userId}/updateEmail`, {
        email: this.newEmail
      });
    } finally {
      this._isBusy = false;
    }
  }
}
