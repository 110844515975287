import * as React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './DynamoPagination.scss';
import GridPagination, { GridPaginationProps } from '../pagination/Pagination';

export type DynamoGridPaginationProps = GridPaginationProps & {
  backwardPaginationToken?: string;
  forwardPaginationToken?: string;
};

export default class DynamoGridPagination extends GridPagination<DynamoGridPaginationProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.pagination}>
        <div className="pagination-left">
          <p>{this.getShowResultsLabel()}</p>
          {/* <Tooltip content="Hold Shift when sorting to multi-sort"/> */}
        </div>
        <div className="pagination-center">
          <Button
            color="primary"
            size="sm"
            onClick={() => this.onPageChange(this.currentPage - 1)}
            disabled={this.shouldDisablePreviousPageButton()}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="icon" />
          </Button>
          <p>Page {this.currentPage}</p>
          <Button
            color="primary"
            size="sm"
            onClick={() => this.onPageChange(this.currentPage + 1)}
            disabled={this.shouldDisableNextPageButton()}
          >
            <FontAwesomeIcon icon={faChevronRight} className="icon" />
          </Button>
        </div>
        <div className="pagination-right" />
      </div>
    );
  }

  protected getShowResultsLabel(): string {
    const { totalCount } = this.props;
    return `Showing ${totalCount} results`;
  }

  protected onPageChange(pageNumber: number): void {
    this.props.onPageChangeHandler(pageNumber);
  }

  protected shouldDisablePreviousPageButton(): boolean {
    return this.props.page <= 1;
  }

  protected shouldDisableNextPageButton(): boolean {
    return !this.props.forwardPaginationToken;
  }
}
