import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Omit } from 'react-router';
import { observer } from 'mobx-react';
import ValidatableObject from '../../validation/ValidatableObject';
import { combineClassNames } from '../../../helpers/reactHelpers';
import FormInput, { FormInputProps } from './FormInput';

export type PhoneFormInputProps<T extends ValidatableObject, TKey extends keyof T>
  = Omit<FormInputProps<T, TKey>, 'customInput' | 'valid' >;

@observer
export default class PhoneFormInput<T extends ValidatableObject, TKey extends keyof T>
  extends React.Component<PhoneFormInputProps<T, TKey>> {
  public render(): React.ReactNode {
    const {
      storeRef: phoneStoreRef, id, onChange, ...props
    } = this.props;
    const phoneInputValidClassName = phoneStoreRef.ref.isValid(phoneStoreRef.field) ? '' : 'is-invalid';
    const phoneNumberInput = (
      <NumberFormat
        className={combineClassNames('form-control', phoneInputValidClassName)}
        value={this.props.storeRef.value}
        format="(###) ###-####"
        mask="_"
        onValueChange={values => this.onValueChangedHandler(values)}
        onBlur={() => this.validateInput()}
        onChange={onChange}
      />
    );
    return (
      <FormInput {...props} id={id} storeRef={phoneStoreRef} customInput={phoneNumberInput} />
    );
  }

  private onValueChangedHandler(values: NumberFormatValues): void {
    this.props.storeRef.value = values.value;
  }

  private validateInput(): void {
    const phoneStoreRef = this.props.storeRef;
    phoneStoreRef.ref.validate(phoneStoreRef.field);
  }
}
