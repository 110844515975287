import React from 'react';

export class CreateUserPersonalInfoExistingUsersSection extends React.Component {
  public render() {
    return (
      <div>
        <em className="d-block mt-4 mb-4">
          We found exisitng ERvive user(s) that match the email or phone number you entered. Please select the user you would
          like to add to an organization. If this is a new user, please use another email and/or phone number. A mobile phone
          or email address can only be linked to one account.
        </em>
      </div>
    );
  }
}
