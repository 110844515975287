import { observable } from 'mobx';
import NaloxoneRecordForGrid from '../../../../../model/grid/naloxone/NaloxoneRecordForGrid';
import GridPageRequest from '../../../../../model/grid/GridPageRequest';
import { GridPageResponse } from '../../../../../model/grid/GridPageResponse';
import apiService from '../../../../../services/ApiService';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import { DynamoSearch } from '../../../../../model/grid/dynamo/DynamoSearch';
import DynamoGridStore from '../../../../../shared/grid/DynamoGridStore';

export class NaloxoneRecordsGridStore<TRowModel extends NaloxoneRecordForGrid> extends DynamoGridStore<TRowModel, string> {
  @observable
  public submissionType = 0;

  @observable
  public organizationId = 0;

  @observable
  public fromDate = '';

  @observable
  public toDate = '';

  @observable
  public recordsSearch?: DynamoSearch;

  public pageSize = 100;

  protected get shouldFetchOnMount(): boolean {
    return false;
  }

  protected get shouldDebounceFetch(): boolean {
    return false;
  }

  protected get shouldFetchData(): boolean {
    return false;
  }

  public async onPageChangedHandler(newPage: number): Promise<void> {
    this.isBackwardSearch = newPage < this.page;
    this.page = newPage;
    await this.refreshGrid(this.recordsSearch);
  }

  protected async apiCall(gridPageRequest: GridPageRequest<string>): Promise<GridPageResponse<TRowModel>> {
    // DA: This function is not used in this flow and it is intentional.
    return {
      rows: [],
      totalCount: 0
    };
  }

  public async dynamoDelete(search: DynamoSearch) {
    await this.fetchRecords(search, true);
  }

  public async dynamoSearch(search: DynamoSearch, submissionType: DataSubmissionType, organizationId: number, fromDate: string, toDate: string) {
    this.page = 1;
    this.submissionType = submissionType;
    this.organizationId = organizationId;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.forwardPaginationToken = '';
    this.backwardPaginationToken = '';
    this.isBackwardSearch = false;

    await this.fetchRecords(search);
  }

  public async refreshGrid(search?: DynamoSearch, isPageRefresh = false): Promise<void> {
    this.isLoading = true;
    const gridPageRequest = this.getGridPageRequest(search);
    gridPageRequest.organizationId = this.organizationId;
    gridPageRequest.fromDate = this.fromDate;
    gridPageRequest.toDate = this.toDate;

    if (isPageRefresh) {
      gridPageRequest.paginationToken = this.currentPagePaginationToken;
    }
    const params = { gridPageRequest };

    const { data } = await apiService.get<NaloxoneGridPageResponse<TRowModel>>('naloxoneManagement/listForGrid', { params });
    this.currentPagePaginationToken = gridPageRequest.paginationToken;
    this.backwardPaginationToken = data.backwardPaginationToken;
    this.forwardPaginationToken = data.forwardPaginationToken;
    this.rows = data.records.rows;
    this.totalCount = data.records.totalCount;
    this.isLoading = false;
  }

  public getGridPageRequest(search?: DynamoSearch): NaloxoneGridPageRequest {
    return {
      ...super.getGridPageRequest(),
      submissionType: this.submissionType,
      paginationToken: this.isBackwardSearch ? this.backwardPaginationToken : this.forwardPaginationToken,
      naloxoneSearch: search
    };
  }

  private async fetchRecords(search: DynamoSearch, isPageRefresh = false): Promise<void> {
    const { firstName, lastName, dateOfBirth } = search;

    if (!firstName && !lastName && !dateOfBirth) {
      this.recordsSearch = undefined;
      await this.refreshGrid(undefined, isPageRefresh);
    } else {
      this.recordsSearch = search;
      await this.refreshGrid(search, isPageRefresh);
    }
  }
}

export interface NaloxoneGridPageRequest extends GridPageRequest<string> {
  submissionType: DataSubmissionType;
  paginationToken?: string;
  naloxoneSearch?: DynamoSearch;
  organizationId?: number;
  fromDate?: string;
  toDate?: string;
}

export interface NaloxoneGridPageResponse<TRowModel> {
  records: GridPageResponse<TRowModel>;
  backwardPaginationToken: string;
  forwardPaginationToken: string;
}
