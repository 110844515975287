import React from 'react';
import { Button, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import FormInput from '../../../controls/formInput/FormInput';
import { makeRef } from '../../../../helpers/Ref';
import BusyOverlay from '../../../layout/busyOverlay/BusyOverlay';
import styles from './EditProfileSection.scss';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import ProfilePageStore from '../ProfilePageStore';
import EditProfileStore from './EditProfileStore';
import EditPhoneStore from '../editPhone/EditPhoneStore';
import EditPasswordStore from '../editPassword/EditPasswordStore';
import EditEmailStore from '../editEmail/EditEmailStore';
import EditOrganizationStore from '../editOrganization/EditOrganizationStore';
import FormRoleSelector from '../../../controls/formSelect/roleSelector/FormRoleSelector';
import { Role, RoleList } from '../../../../model/role/RoleList';
import apiService from '../../../../services/ApiService';
import userStore from '../../../../data/UserStore';
import permissions from '../../../../model/user/permissions';

interface EditProfileSectionProps {
  toggleEditProfileFunc: () => void;
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}

@observer
export default class EditProfileSection extends React.Component<EditProfileSectionProps,
  { selectedRole?: Role; roleList?: Role[] }> {
  private readonly _store: EditProfileStore;

  constructor(props: Readonly<EditProfileSectionProps>) {
    super(props);
    this._store = props.store.editUserNameStore;
    this._store.firstName = this.props.store.userProfile!.firstName;
    this._store.lastName = this.props.store.userProfile!.lastName;
    this._store.role = this.props.store.userProfile!.role;
  }

  public componentDidMount() {
    this.setState({
      roleList: []
    });
  }

  public async fetchRoles(): Promise<Role[]> {
    const response = await apiService.get<RoleList>('/role/adminAssignList', {
      params: {
        userId: this.props.store.userProfile?.id
      }
    });

    const { roles } = response.data;

    this.setState({ roleList: roles });

    return roles;
  }

  private userCanEditRole() {
    return (userStore.session?.email !== this.props.store.userProfile?.email) && userStore.session?.role?.hasPermission(permissions.user.adminUserAssignRole);
  }

  private handleUserRoleChange = (value?: number) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const role = this.state.roleList!.find(r => r.id === value);
    this._store.role = role!;
    this._store.validate('role');
  };

  public render(): React.ReactNode {
    return (
      <BusyOverlay isBusy={this._store.isBusy}>
        <Form className={styles.editProfileSection}>
          <div className="row">
            <div className="col-12 col-sm-6">
              <FormInput
                id="userFirstName"
                labelText="First Name"
                storeRef={makeRef(this._store, 'firstName')}
              />
            </div>
            <div className="col-12 col-sm-6">
              <FormInput
                id="userLastName"
                labelText="Last Name"
                storeRef={makeRef(this._store, 'lastName')}
              />
            </div>
          </div>
          {this.userCanEditRole() && (
            <div className="row">
              <div className="col-12 col-sm-6">
                <FormRoleSelector
                  id="userRole"
                  labelText="Role"
                  storeRef={makeRef(this._store, 'role')}
                  fetchOptionsFunc={async () => this.fetchRoles()}
                  handleValueChangeFunc={this.handleUserRoleChange}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-6 col-sm-4 col-lg-3">
              <Button
                type="submit"
                color="primary"
                block
                onClick={(e: React.MouseEvent<any>) => this.saveUserProfile(e)}
              >
                Save changes
              </Button>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 pl-0">
              <Button color="secondary" block onClick={this.props.toggleEditProfileFunc}>Cancel</Button>
            </div>
          </div>
        </Form>
      </BusyOverlay>
    );
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private async saveUserProfile(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();

    if (!await this._store.validate()) {
      return;
    }

    await this._store.updateProfile();
    this.props.store.userProfile!.firstName = this._store.firstName;
    this.props.store.userProfile!.lastName = this._store.lastName;
    this.props.store.userProfile!.role = this._store.role;
    notificationToast.showSuccess('Changes saved successfully');
    this.props.toggleEditProfileFunc();
  }
}
