import EditPasswordStore from '../../../shared/user/profile/editPassword/EditPasswordStore';
import apiService from '../../../services/ApiService';

export class EditProfilePasswordStore extends EditPasswordStore {
  public async updatePassword(): Promise<{ success: boolean; status?: number; message?: string }> {
    this._isBusy = true;
    try {
      const response = await apiService.post('user/updatePassword', {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      });
      return {
        success: response.status === 204,
        status: response.status
      };
    } catch (error) {
      if (error.response) {
        return {
          success: false,
          status: error.response.status,
          message: error.response.data?.message || 'An error occurred while updating the password'
        };
      }
      return {
        success: false,
        message: 'Network error occurred while updating the password'
      };
    } finally {
      this._isBusy = false;
    }
  }
}
