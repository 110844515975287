import Validator, { ValidationResult } from './Validator';
import FieldValidationContext from '../FieldValidationContext';

export default class MatchPassword implements Validator<string | undefined> {
  private readonly _message: string;
  private readonly _passwordFieldName: string;

  public constructor(passwordFieldName: string, message = 'Passwords do not match') {
    this._passwordFieldName = passwordFieldName;
    this._message = message;
  }

  public validate(value: string | undefined, context: FieldValidationContext): Promise<ValidationResult> {
    if (value === context.target[this._passwordFieldName]) {
      return Promise.resolve({ isValid: true });
    }

    return Promise.resolve({ isValid: false, message: this._message });
  }
}
