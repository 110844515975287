import React from 'react';
import { RouteComponentProps } from 'react-router';
import Tabs, { TabForPage } from '../../shared/layout/tabs/Tabs';
import routePaths from '../../constants/routePaths';
import { DataSubmissionPage } from './submission/DataSubmissionPage';
import { page } from '../../services/route/decorators';
import styles from './DataManagementTabsPage.scss';
import { combineClassNames } from '../../helpers/reactHelpers';
import FileUploadHistoryGrid from './history/FileUploadHistoryGrid';
import { FileUploadHistoryGridStore } from './history/FileUploadHistoryGridStore';
import DataSubmissionForGrid from '../../model/grid/dataSubmission/DataSubmissionForGrid';
import permissions from '../../model/user/permissions';

@page({
  path: routePaths.data.manage,
  hasPermission: permissions.data.submissionList
})
export class DataManagementTabsPage extends React.Component<RouteComponentProps> {
  _fileUploadHistoryGridStore: FileUploadHistoryGridStore<DataSubmissionForGrid>;

  public constructor(props: RouteComponentProps) {
    super(props);
    this._fileUploadHistoryGridStore = new FileUploadHistoryGridStore<DataSubmissionForGrid>();
  }

  public render() {
    const fileUploadTab: TabForPage = {
      id: routePaths.data.submission,
      header: <span>File Upload</span>,
      component: <DataSubmissionPage />
    };

    const uploadHistory: TabForPage = {
      id: routePaths.data.history,
      header: <span>Upload History</span>,
      component: <FileUploadHistoryGrid store={this._fileUploadHistoryGridStore} />
    };

    const tabs: TabForPage[] = [fileUploadTab, uploadHistory];

    return (
      <div className={combineClassNames(styles.dataManagement, 'container')}>
        <div className="header-container">
          <h1>Data Submission</h1>
        </div>
        <Tabs
          className="grid-tabs"
          activeTabId={this.props.location.pathname}
          tabs={tabs}
          onTabToggledFunc={newTabId => this.handleTabSwitch(newTabId)}
          asyncTabs
        />
      </div>
    );
  }

  private handleTabSwitch(newTabId: string) {
    this.props.history.push(newTabId);
  }
}
