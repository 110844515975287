import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { GenderDisplayName } from '../../../../../../helpers/enums/gender';
import { PreviewSectionProps } from '../../PreviewSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';

export default class IanaPreviewPatientInformationSection extends React.Component<PreviewSectionProps> {
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Patient Information">
              <Row>
                <Col xs={12} sm={9}>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="First name" value={context.patientInformationSectionStore!.firstName} id="FirstName" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Middle name" value={context.patientInformationSectionStore!.middleName} id="MiddleName" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Last name" value={context.patientInformationSectionStore!.lastName} id="LastName" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Date of Birth" value={context.patientInformationSectionStore!.dateOfBirthForPreview} id="DateOfBirth" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Patient Identifier" value={context.patientInformationSectionStore!.patientIdentifier} id="PatientIdentifier" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={9}>
                      <InlineEntry labelText="Gender" value={GenderDisplayName[context.patientInformationSectionStore!.patientGender]} id="Gender" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onUpdateButtonHandler(e)}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
