import moment from 'moment';
import Validator, { ValidationResult } from './Validator';
import FieldValidationContext from '../FieldValidationContext';

export default class NotHoursAhead implements Validator<string> {
  private readonly _hoursAhead: number;
  private readonly _dateFieldName: string;

  constructor(hoursAhead: number, dateFieldName: string) {
    this._hoursAhead = hoursAhead;
    this._dateFieldName = dateFieldName;
  }

  public validate(value: string, context: FieldValidationContext): Promise<ValidationResult> {
    const bufferDateTime = moment().add(this._hoursAhead, 'hours');
    const targetDate = moment(`${context.target[this._dateFieldName]} ${value}`);
    const unit = this._hoursAhead > 1 || this._hoursAhead === 0 ? 'hours' : 'hour';

    if (targetDate.isAfter(bufferDateTime, 'minutes')) {
      return Promise.resolve({ isValid: false, message: `Times more than ${this._hoursAhead} ${unit} ahead are not allowed` });
    }

    return Promise.resolve({ isValid: true });
  }
}
