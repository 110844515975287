import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import PasswordStrength from '../../../validation/validators/PasswordStrength';
import MatchPassword from '../../../validation/validators/MatchPassword';

export default abstract class EditPasswordStore extends ValidatableObject {
  @observable
  protected _isBusy = false;

  @computed
  public get isBusy(): boolean {
    return this._isBusy;
  }

  @validate(() => new NotEmpty())
  @observable
  public currentPassword = '';

  @validate(() => new NotEmpty(), () => new PasswordStrength())
  @observable
  public newPassword = '';

  @validate(() => new NotEmpty(), () => new MatchPassword('newPassword'))
  @observable
  public confirmPassword = '';

  public async validate<TKey extends keyof this>(field?: TKey): Promise<boolean> {
    if (field === 'newPassword' && Boolean(this.confirmPassword)) {
      await super.validate('confirmPassword');
    }
    return super.validate(field);
  }

  public reset(): void {
    this.resetValidationState();
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }

  public abstract async updatePassword(): Promise<{ success: boolean; status?: number; message?: string }>;
}
