import { State } from '../../model/address/State';

const AL = 'Alabama';
const AK = 'Alaska';
const AZ = 'Arizona';
const AR = 'Arkansas';
const CA = 'California';
const CO = 'Colorado';
const CT = 'Connecticut';
const DE = 'Delaware';
const DC = 'District of Columbia';
const FL = 'Florida';
const GA = 'Georgia';
const HI = 'Hawaii';
const ID = 'Idaho';
const IL = 'Illinois';
const IN = 'Indiana';
const IA = 'Iowa';
const KS = 'Kansas';
const KY = 'Kentucky';
const LA = 'Louisiana';
const ME = 'Maine';
const MD = 'Maryland';
const MA = 'Massachusetts';
const MI = 'Michigan';
const MN = 'Minnesota';
const MS = 'Mississippi';
const MO = 'Missouri';
const MT = 'Montana';
const NE = 'Nebraska';
const NV = 'Nevada';
const NH = 'New Hampshire';
const NJ = 'New Jersey';
const NM = 'New Mexico';
const NY = 'New York';
const NC = 'North Carolina';
const ND = 'North Dakota';
const OH = 'Ohio';
const OK = 'Oklahoma';
const OR = 'Oregon';
const PA = 'Pennsylvania';
const RI = 'Rhode Island';
const SC = 'South Carolina';
const SD = 'South Dakota';
const TN = 'Tennessee';
const TX = 'Texas';
const UT = 'Utah';
const VT = 'Vermont';
const VA = 'Virginia';
const WA = 'Washington';
const WV = 'West Virginia';
const WI = 'Wisconsin';
const WY = 'Wyoming';
const AS = 'American Samoa';
const GU = 'Guam';
const MH = 'Marshall Islands';
const FM = 'Micronesia';
const MP = 'Northern Marianas';
const PW = 'Palau';
const PR = 'Puerto Rico';
const VI = 'Virgin Islands';

export const StateDisplayName: { [index: number]: string } = [];
StateDisplayName[0] = 'Not specified';
StateDisplayName[State.AL] = AL;
StateDisplayName[State.AK] = AK;
StateDisplayName[State.AZ] = AZ;
StateDisplayName[State.AR] = AR;
StateDisplayName[State.CA] = CA;
StateDisplayName[State.CO] = CO;
StateDisplayName[State.CT] = CT;
StateDisplayName[State.DE] = DE;
StateDisplayName[State.DC] = DC;
StateDisplayName[State.FL] = FL;
StateDisplayName[State.GA] = GA;
StateDisplayName[State.HI] = HI;
StateDisplayName[State.ID] = ID;
StateDisplayName[State.IL] = IL;
StateDisplayName[State.IN] = IN;
StateDisplayName[State.IA] = IA;
StateDisplayName[State.KS] = KS;
StateDisplayName[State.KY] = KY;
StateDisplayName[State.LA] = LA;
StateDisplayName[State.ME] = ME;
StateDisplayName[State.MD] = MD;
StateDisplayName[State.MA] = MA;
StateDisplayName[State.MI] = MI;
StateDisplayName[State.MN] = MN;
StateDisplayName[State.MS] = MS;
StateDisplayName[State.MO] = MO;
StateDisplayName[State.MT] = MT;
StateDisplayName[State.NE] = NE;
StateDisplayName[State.NV] = NV;
StateDisplayName[State.NH] = NH;
StateDisplayName[State.NJ] = NJ;
StateDisplayName[State.NM] = NM;
StateDisplayName[State.NY] = NY;
StateDisplayName[State.NC] = NC;
StateDisplayName[State.ND] = ND;
StateDisplayName[State.OH] = OH;
StateDisplayName[State.OK] = OK;
StateDisplayName[State.OR] = OR;
StateDisplayName[State.PA] = PA;
StateDisplayName[State.RI] = RI;
StateDisplayName[State.SC] = SC;
StateDisplayName[State.SD] = SD;
StateDisplayName[State.TN] = TN;
StateDisplayName[State.TX] = TX;
StateDisplayName[State.UT] = UT;
StateDisplayName[State.VT] = VT;
StateDisplayName[State.VA] = VA;
StateDisplayName[State.WA] = WA;
StateDisplayName[State.WV] = WV;
StateDisplayName[State.WI] = WI;
StateDisplayName[State.WY] = WY;
StateDisplayName[State.AS] = AS;
StateDisplayName[State.GU] = GU;
StateDisplayName[State.MH] = MH;
StateDisplayName[State.FM] = FM;
StateDisplayName[State.MP] = MP;
StateDisplayName[State.PW] = PW;
StateDisplayName[State.PR] = PR;
StateDisplayName[State.VI] = VI;
