import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import ValidEmail from '../../../validation/validators/ValidEmail';

export default abstract class EditEmailStore extends ValidatableObject {
  @observable
  protected _isBusy = false;

  @observable
  public currentEmail = '';

  @validate(() => new NotEmpty(), () => new ValidEmail())
  @observable
  public newEmail = '';

  @computed
  public get isBusy(): boolean {
    return this._isBusy;
  }

  public abstract async updateEmail(): Promise<void>;

  public reset() {
    this.currentEmail = '';
    this.newEmail = '';
    this.resetValidationState();
  }
}
