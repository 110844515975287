export enum LogLevel {
  Information = 1,
  Warn,
  Error
}
export default {
  logForNonProdEnv(logLevel: LogLevel, message: string) {
    if (ENVIRONMENT === 'Local' || ENVIRONMENT === 'Development') return;

    switch (logLevel) {
      case LogLevel.Information:
        // eslint-disable-next-line no-console
        console.log(message);
        break;
      case LogLevel.Warn:
        // eslint-disable-next-line no-console
        console.warn(message);
        break;
      default:
        // eslint-disable-next-line no-console
        console.error(message);
    }
  }
};
