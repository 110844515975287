import * as React from 'react';
import styles from '../CreateNaloxoneEventReviewSection.scss';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { State } from '../../../../../../model/address/State';
import { combineClassNames } from '../../../../../../helpers/reactHelpers';
import CreateNaloxoneEventStore from '../../CreateNaloxoneEventStore';
import { CreateNaloxoneEventPageSection } from '../../CreateNaloxoneEventPage';
import { MedicationDosageUnitDisplayName } from '../../../../../../helpers/enums/medicationDosageUnit';
import { GenderDisplayName } from '../../../../../../helpers/enums/gender';
import {
  IanaAdministerInformationSectionStore
} from '../../administerInformationSection/ianaAdministerInformationForm/IanaAdministerInformationSectionStore';
import {
  IanaPatientInformationSectionStore
} from '../../patientInformationSection/ianaPatientInformationForm/IanaPatientInformationSectionStore';

interface IanaCreateNaloxoneEventReviewSectionProps {
  mainStore: CreateNaloxoneEventStore;
}

export default class IanaCreateNaloxoneEventReviewSection extends React.Component<IanaCreateNaloxoneEventReviewSectionProps> {
  constructor(props: IanaCreateNaloxoneEventReviewSectionProps) {
    super(props);
  }

  private readonly _mainStore = this.props.mainStore;
  private readonly _administerInformationStore = this.props.mainStore.administerInformationSectionStore;
  private readonly _patientInformationStore = this.props.mainStore.patientInformationSectionStore;

  public render(): React.ReactNode {
    const {
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth,
      Incident_UniqueIdentifier,
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
      Patient_Identifier
    } = this._patientInformationStore! as IanaPatientInformationSectionStore;

    const {
      Incident_AdministerFirstName,
      Incident_AdministerLastName,
      Incident_MedicationAdministered,
      Incident_NotifiedDateTime,
      Incident_MedicationName,
      Incident_MedicationDosage,
      Incident_MedicationDosageUnit,
    } = this._administerInformationStore.AdministerInformationFormStore! as IanaAdministerInformationSectionStore;

    return (
      <div className={combineClassNames(styles.reviewSection, 'd-flex', 'flex-column')}>
        <div className="subsection_header">
          <span>Administer Information</span>
          <button type="button" className="link" onClick={e => this.goToPage(e, CreateNaloxoneEventPageSection.AdministerInformation)}>Edit</button>
        </div>
        <div className="subsection_content">
          <InlineEntry value={Incident_AdministerFirstName} id="Incident_AdministerFirstName" labelText="Administer First Name" />
          <InlineEntry value={Incident_AdministerLastName} id="Incident_AdministerLastName" labelText="Administer Last Name" />
          <>
            <InlineEntry value={Incident_MedicationAdministered} id="Incident_MedicationAdministered" labelText="Administered Time" />
            <InlineEntry value={Incident_NotifiedDateTime} id="Incident_NotifiedDateTime" labelText="Notified Time" />
            <InlineEntry value={Incident_MedicationName} id="Incident_MedicationName" labelText="Name of Drug" />
            <InlineEntry value={Incident_MedicationDosage} id="Incident_MedicationDosage" labelText="Medication Dosage" />
          </>
          <InlineEntry value={MedicationDosageUnitDisplayName[Incident_MedicationDosageUnit]} id="Incident_MedicationDosageUnit" labelText="Medication Dosage Unit" />
        </div>
        <div className="subsection_header">
          <span>Patient Information</span>
          <button type="button" className="link" onClick={e => this.goToPage(e, CreateNaloxoneEventPageSection.PatientInformation)}>Edit</button>
        </div>
        <div className="subsection_content">
          <InlineEntry value={Incident_UniqueIdentifier} id="Incident_UniqueIdentifier" labelText="Patient Identification Number" />
          <InlineEntry value={FirstName} id="patientFirstName" labelText="Patient First Name" />
          <InlineEntry value={MiddleName} id="patientMiddleName" labelText="Patient Middle Name" />
          <InlineEntry value={LastName} id="patientLastName" labelText="Patient Last Name" />
          <InlineEntry value={DateOfBirth} id="patientDateOfBirth" labelText="Patient Date Of Birth" />
          <>
            <InlineEntry value={Patient_Identifier} id="patientIdentifier" labelText="Patient Identifier" />
            <InlineEntry value={GenderDisplayName[Patient_Gender]} id="patientGender" labelText="Patient Gender" />
          </>
          <InlineEntry
            id="patientAddress"
            labelText="Address"
            value={`${Patient_Address1}, ${Patient_Address2}, ${Patient_City}, ${State[Patient_State]} ${Patient_ZipCode}`}
          />
        </div>
      </div>
    );
  }

  private goToPage(e: React.MouseEvent<HTMLButtonElement>, page: CreateNaloxoneEventPageSection): void {
    e.preventDefault();
    this._mainStore.pageSection = page;
  }
}
