import * as React from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './Pagination.scss';

export interface GridPaginationProps {
  page: number;
  totalCount: number;
  pageSize: number;
  pageSizes: number[];
  onPageSizeChangeHandler: (pageSize: number) => void;
  onPageChangeHandler: (index: number) => void;
}

export default class GridPagination<TProps extends GridPaginationProps> extends React.Component<TProps> {
  protected get currentPage(): number {
    return this.props.page;
  }

  protected get totalPageCount(): number {
    const { pageSize, totalCount } = this.props;
    return Math.max(1, Math.ceil(totalCount / pageSize));
  }

  public render(): React.ReactNode {
    return (
      <div className={styles.pagination}>
        <div className="pagination-left">
          <p>{this.getShowResultsLabel()}</p>
          {/* <Tooltip content="Hold Shift when sorting to multi-sort"/> */}
        </div>
        <div className="pagination-center">
          <Button
            color="primary"
            size="sm"
            onClick={() => this.onPageChange(this.currentPage - 1)}
            disabled={this.shouldDisablePreviousPageButton()}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="icon" />
          </Button>
          <Input
            type="number"
            name="number"
            id="pageIndex"
            value={this.currentPage}
            onChange={event => this.onPageChange(+event.currentTarget.value)}
          />
          <p>of {this.totalPageCount}</p>
          <Button
            color="primary"
            size="sm"
            onClick={() => this.onPageChange(this.currentPage + 1)}
            disabled={this.shouldDisableNextPageButton()}
          >
            <FontAwesomeIcon icon={faChevronRight} className="icon" />
          </Button>
        </div>
        <div className="pagination-right">
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            onChange={event => this.onPageSizeChange(event)}
            value={this.props.pageSize}
          >
            {this.props.pageSizes.map(ps => (
              <option value={ps} key={ps}>{ps} rows</option>
            ))}
          </Input>
        </div>
      </div>
    );
  }

  protected getShowResultsLabel(): string {
    const { page, pageSize, totalCount } = this.props;
    const startResult = Math.min(pageSize * (page - 1) + 1, totalCount);
    const endResult = Math.min(startResult + pageSize - 1, totalCount);
    return `Showing results ${startResult} - ${endResult} of ${totalCount}`;
  }

  protected onPageChange(pageNumber: number): void {
    if (pageNumber < 1 || pageNumber > this.totalPageCount) { return; }

    this.props.onPageChangeHandler(pageNumber);
  }

  protected shouldDisablePreviousPageButton(): boolean {
    return this.currentPage <= 1;
  }

  protected shouldDisableNextPageButton(): boolean {
    return this.currentPage >= this.totalPageCount;
  }

  protected onPageSizeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.currentTarget;
    const pageSize: number = parseInt(value, 10);

    this.props.onPageSizeChangeHandler(pageSize);
  }
}
