import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { State } from '../../../../../../model/address/State';

export class IanaPatientContactSectionStore extends ValidatableObject {
  @observable
  public patientAddress1 = '';

  @observable
  public patientAddress2 = '';

  @observable
  public patientCity = '';

  @observable
  public patientState: State = 0;

  @observable
  public patientZipCode = '';
}
