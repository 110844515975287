import { observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import apiService from '../../../services/ApiService';
import awaitingApprovalOrganizationsGridStore, {
  AwaitingApprovalOrganizationsGridStore
} from './awaitingApproval/AwaitingApprovalOrganizationsGridStore';
import activeOrganizationsGridStore, { ActiveOrganizationsGridStore } from './active/ActiveOrganizationsGridStore';
import inactiveOrganizationsGridStore, { InactiveOrganizationsGridStore } from './inactive/InactiveOrganizationsGridStore';
import OrganizationsGridPageRequestAggregate
  from '../../../model/grid/organization/OrganizationsGridPageRequestAggregate';
import OrganizationsGridPageResponseAggregate
  from '../../../model/grid/organization/OrganizationsGridPageResponseAggregate';
import rejectedOrganizationsGridStore, { RejectedOrganizationsGridStore } from './rejected/RejectedOrganizationsGridStore';

export class ManageOrganizationsTabsPageStore extends ValidatableObject {
  private readonly _awaitingApprovalOrganizationsGridStore: AwaitingApprovalOrganizationsGridStore = awaitingApprovalOrganizationsGridStore;
  private readonly _activeOrganizationsGridStore: ActiveOrganizationsGridStore = activeOrganizationsGridStore;
  private readonly _inactiveOrganizationsGridStore: InactiveOrganizationsGridStore = inactiveOrganizationsGridStore;
  private readonly _rejectedOrganizationsGridStore: RejectedOrganizationsGridStore = rejectedOrganizationsGridStore;

  @observable
  public searchPhrase = '';

  constructor() {
    super();
  }

  public refreshGrids(): void {
    const aggregate: OrganizationsGridPageRequestAggregate = {
      awaitingApprovalOrganizationsGridPageRequest: this._awaitingApprovalOrganizationsGridStore.getGridPageRequest(),
      activeOrganizationsGridPageRequest: this._activeOrganizationsGridStore.getGridPageRequest(),
      inactiveOrganizationsGridPageRequest: this._inactiveOrganizationsGridStore.getGridPageRequest(),
      rejectedOrganizationsGridPageRequest: this._rejectedOrganizationsGridStore.getGridPageRequest()
    };

    const params = { gridPageRequestAggregate: aggregate };
    const dataPromise = apiService.get<OrganizationsGridPageResponseAggregate>('organization/listForGridAggregate', { params });

    this._awaitingApprovalOrganizationsGridStore.populateFromExternalData(dataPromise
      .then(result => result.data.awaitingApprovalOrganizationsGridPageResponse));
    this._activeOrganizationsGridStore.populateFromExternalData(dataPromise
      .then(result => result.data.activeOrganizationsGridPageResponse));
    this._inactiveOrganizationsGridStore.populateFromExternalData(dataPromise
      .then(result => result.data.inactiveOrganizationsGridPageResponse));
    this._rejectedOrganizationsGridStore.populateFromExternalData(dataPromise
      .then(result => result.data.rejectedOrganizationsGridPageResponse));
  }
}

export default new ManageOrganizationsTabsPageStore();
