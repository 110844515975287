import * as React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { Omit } from 'react-router';
import Spinner from '../../layout/spinner/Spinner';

type ButtonWithSpinnerProps = Omit<ButtonProps, 'disabled'> & {
  id: string;
  buttonText: string;
  isBusy: boolean;
};

export default class ButtonWithSpinner extends React.Component<ButtonWithSpinnerProps> {
  public render(): React.ReactNode {
    const { buttonText, isBusy, ...innerProps } = this.props;

    return (
      <Button {...innerProps} id={this.props.id} disabled={isBusy}>
        {isBusy ? <Spinner size="lg" /> : buttonText}
      </Button>
    );
  }
}
