import React from 'react';
import { combineClassNames } from '../../../helpers/reactHelpers';
import Icon from '../../controls/icons/Icon';
import styles from './EntryTile.scss';

export const EntryTile: React.FC<EntryTileProps> = (props: EntryTileProps) => {
  const {
    id, selected, onSelected, disabled
  } = props;

  const getContainerClassNames = () => {
    const selectedClass = selected ? 'active' : '';
    const disabledClass = disabled ? 'disabled' : '';

    return combineClassNames(styles.entryTile, selectedClass, disabledClass);
  };

  return (
    <div className={getContainerClassNames()}>
      <button
        className="btn tile__button w-100 drop-styles d-flex"
        type="button"
        onClick={() => onSelected(id)}
      >
        <Icon name="selectionCheck" size="2.4rem" className={combineClassNames(selected ? '' : 'hidden', 'tile__icon', 'mr-3')} />
        {props.buttonContent}
      </button>
      {props.outerContent}
    </div>
  );
};

interface EntryTileProps {
  id: number;
  selected: boolean;
  onSelected: (id: number) => void;
  disabled?: boolean;
  buttonContent: React.ReactNode;
  outerContent?: React.ReactNode;
}
