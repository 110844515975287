import { action, observable } from 'mobx';
import ValidatableObject from '../../../shared/validation/ValidatableObject';
import { validate } from '../../../shared/validation/helpers';
import NotEmpty from '../../../shared/validation/validators/NotEmpty';
import apiService from '../../../services/ApiService';

export default class UserDeactivationRejectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @observable
  @validate(() => new NotEmpty())
  public reason = '';

  @action
  public async disableUser(id: number) {
    await this.postRequest('user/deactivate', id);
  }

  @action
  public async rejectUser(id: number) {
    await this.postRequest('user/reject', id);
  }

  private async postRequest(endpoint: string, id: number) {
    this.isBusy = true;

    const request: UserDeactivationRejection = {
      id,
      reason: this.reason
    };

    try {
      await apiService.post(endpoint, request);
    } finally {
      this.isBusy = false;
    }
  }
}

interface UserDeactivationRejection {
  id: number;
  reason: string;
}
