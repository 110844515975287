import * as React from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler
} from 'reactstrap';
import styles from './AppNavbar.scss';
import userStore from '../../../data/UserStore';
import Logo from './Logo';
import Menu from './menu/Menu';
import UserMenu from './menu/UserMenu';
import HelpNavButton from './HelpNavButton';
import { combineClassNames } from '../../../helpers/reactHelpers';

interface AppNavbarState {
  isNavbarTogglerOpen: boolean;
}

export default class AppNavbar extends React.Component<any, AppNavbarState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isNavbarTogglerOpen: false,
    };
  }

  public render(): React.ReactNode {
    const user = userStore.session;
    const navItemsClass = !user ? 'single-item' : '';

    return (
      <Navbar className={styles.navbar} id="navbar" color="dark" dark expand="md">
        <div className="container">
          <Logo />
          <NavbarToggler onClick={() => this.toggleNavbar()} />
          <Collapse isOpen={this.state.isNavbarTogglerOpen} navbar className="collapse">
            <Nav className={combineClassNames('nav', navItemsClass)} navbar>
              {this.renderNav()}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    );
  }

  private renderNav(): React.ReactNode {
    const user = userStore.session;
    if (!user) {
      return <HelpNavButton />;
    }

    return (
      <>
        <Menu />
        <li className="d-md-flex">
          <ul className="d-md-flex p-0">
            <UserMenu />
            <HelpNavButton />
          </ul>
        </li>
      </>
    );
  }

  private toggleNavbar(): void {
    this.setState(prevState => ({ isNavbarTogglerOpen: !prevState.isNavbarTogglerOpen }));
  }
}
