import { observable } from 'mobx';
import GridStore from './GridStore';

export default abstract class DynamoGridStore<TRowModel, TSearch> extends GridStore<TRowModel, TSearch> {
  // might be the first the last record of the previous page or the next record of the page with backward searching
  @observable
  public currentPagePaginationToken?: string;

  @observable
  public backwardPaginationToken?: string;

  @observable
  public forwardPaginationToken?: string;
}
