import { observable } from 'mobx';
import apiService from '../../../services/ApiService';
import { RegisterUserPersonalInfoSectionStore } from './personalInfoSection/RegisterUserPersonalInfoSectionStore';
import { CreateUserPageSection } from '../../../shared/user/create/CreateUserPageSection';
import CreateUserStore from '../../../shared/user/create/CreateUserStore';
import { CreateUserOrganizationSectionStore } from '../../../shared/user/create/organizationSection/CreateUserOrganizationSectionStore';
import CreateUserRequest, {
  UserForCreateUser
} from '../../../model/account/create/CreateUserRequest';

export class RegisterUserStore extends CreateUserStore<RegisterUserPersonalInfoSectionStore, CreateUserOrganizationSectionStore> {
  @observable
  public pageSection: CreateUserPageSection = CreateUserPageSection.PersonalInfo;

  constructor(personalInfoStore: RegisterUserPersonalInfoSectionStore, organizationSectionStore: CreateUserOrganizationSectionStore) {
    super(personalInfoStore, organizationSectionStore);
  }
  public async createAccount(): Promise<void> {
    const request = this.prepareRequest();
    await apiService.post('/user/register', request);
  }

  protected getUser(): UserForCreateUser {
    const user = super.getUser();
    user.password = this.personalInfoStore.password;
    return user;
  }

  private prepareRequest(): CreateUserRequest {
    const user = this.getUser();
    const organization = this.getOrganization();

    return {
      user,
      organization
    };
  }
}
