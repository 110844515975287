export default {
  organization: {
    manage: 'OrganizationManage',
    list: 'OrganizationList',
    create: 'OrganizationCreate',
    edit: 'OrganizationEdit',
    details: 'OrganizationDetails',
    deactivate: 'OrganizationDeactivate',
    reject: 'OrganizationReject',
    approve: 'OrganizationApprove',
    delete: 'OrganizationDelete',
    audit: 'OrganizationAudit'
  },
  user: {
    list: 'UserList',
    profile: 'UserProfile',
    details: 'UserDetails',
    create: 'UserCreate',
    reject: 'UserReject',
    audit: 'UserAudit',
    delete: 'UserDelete',
    approve: 'UserApprove',
    deactivate: 'UserDeactivate',
    adminUserAssignRole: 'AdminUserAssignRole'
  },
  license: {
    overview: 'LicenseeOverview',
  },
  data: {
    submissionCreate: 'SubmissionCreate',
    submissionDelete: 'SubmissionDelete',
    submissionFileUpload: 'SubmissionFileUpload',
    submissionList: 'SubmissionList',
    naloxoneList: 'NaloxoneList'
  }
};
