import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import ValidEmail from '../../../validation/validators/ValidEmail';
import ValidPhoneNumber from '../../../validation/validators/ValidPhoneNumber';
import NotApprissEmail from '../../../validation/validators/NotApprissEmail';
import apiService from '../../../../services/ApiService';

export class CreateUserPersonalInfoSectionStore extends ValidatableObject {
  @computed
  public get continueButtonDisabled(): boolean {
    return this.usersExist;
  }

  @validate(() => new NotEmpty())
  @observable
  public firstName = '';

  @validate(() => new NotEmpty())
  @observable
  public lastName = '';

  @validate(() => new NotEmpty(), () => new ValidPhoneNumber())
  @observable
  public phone = '';

  @validate(
    () => new NotEmpty(), () => new ValidEmail(), () => new NotApprissEmail()
  )
  @observable
  public email = '';

  @observable
  public hasExistingUsers = false;

  @computed
  public get usersExist(): boolean {
    return this.hasExistingUsers;
  }

  public getExistingUsers = async (email: string, phone: string): Promise<void> => {
    const response = await apiService.get<boolean>('user/checkExistingByEmailAndPhone', {
      params: {
        email,
        phone
      }
    });
    this.hasExistingUsers = response.data;
  };
}
