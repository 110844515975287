import { AccountStatus } from '../../../../model/user/AccountStatus';
import { UsersGridStore } from '../UsersGridStore';
import { AwaitingApprovalUserForGrid } from '../../../../model/grid/user/UserForGrid';

export class AwaitingApprovalUsersGridStore extends UsersGridStore<AwaitingApprovalUserForGrid> {
  protected get accountStatus(): AccountStatus {
    return AccountStatus.Pending;
  }
}

export default new AwaitingApprovalUsersGridStore();
