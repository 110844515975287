import React from 'react';
import { Button, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import InlineEntry from '../../../layout/inlineEntry/InlineEntry';
import { makeRef } from '../../../../helpers/Ref';
import { formatPhoneNumber } from '../../../../helpers/displayHelper';
import BusyOverlay from '../../../layout/busyOverlay/BusyOverlay';
import EditPhoneStore from './EditPhoneStore';
import PhoneFormInput from '../../../controls/formInput/PhoneFormInput';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import ProfilePageStore from '../ProfilePageStore';
import EditProfileStore from '../editProfile/EditProfileStore';
import EditPasswordStore from '../editPassword/EditPasswordStore';
import EditEmailStore from '../editEmail/EditEmailStore';
import EditOrganizationStore from '../editOrganization/EditOrganizationStore';

interface EditPhoneSectionProps {
  toggleEditPhoneFunc: () => void;
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}

@observer
export default class EditPhoneSection extends React.Component<EditPhoneSectionProps> {
  private readonly _store: EditPhoneStore;

  constructor(props: Readonly<EditPhoneSectionProps>) {
    super(props);
    this._store = props.store.editPhoneStore;
    this._store.currentPhone = this.props.store.userProfile!.phone;
  }

  public render(): React.ReactNode {
    return (
      <BusyOverlay isBusy={this._store.isBusy}>
        <Form>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4">Update Phone Number</h3>
              <InlineEntry
                id="userPhone"
                labelText="Current phone number"
                value={formatPhoneNumber(this._store.currentPhone)}
              />
              <PhoneFormInput
                id="userPhoneInput"
                labelText="New phone number"
                storeRef={makeRef(this._store, 'newPhone')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-sm-4 col-lg-3">
              <Button
                type="submit"
                color="primary"
                block
                onClick={(e: React.MouseEvent<any>) => this.savePhoneNumber(e)}
              >
                Save Changes
              </Button>
            </div>
            <div className="col-6 col-sm-3 col-lg-2 pl-0">
              <Button color="secondary" block onClick={this.props.toggleEditPhoneFunc}>Cancel</Button>
            </div>
          </div>
        </Form>
      </BusyOverlay>
    );
  }

  private async savePhoneNumber(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    // TODO: email change save logic
    if (!await this._store.validate()) {
      return;
    }

    await this._store.updatePhone();
    notificationToast.showSuccess('Phone updated successfully');
    this.props.store.userProfile!.phone = this._store.newPhone;
    this.props.toggleEditPhoneFunc();
  }
}
