import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import React from 'react';
import { Form } from 'reactstrap';
import { WithIdProps } from '../../../shared/props/WithIdProps';
import editOrganizationDetailsStore, {
  EditOrganizationDetailsStore
} from './EditOrganizationDetailsStore';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import { showPromptOnPageUnload } from '../../../helpers/reactHelpers';
import OrganizationDetailsEditSection from './OrganizationDetailsEditSection/OrganizationDetailsEditSection';
import navigationHelpers from '../../../helpers/navigationHelpers';
import FormSectionDivider from '../../../shared/layout/formContainer/sectionDivider/FormSectionDivider';
import notificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import FormNavigationFooter from '../../../shared/controls/formNavigationFooter/formNavigationFooter';
import permissions from '../../../model/user/permissions';

interface EditOrganizationDetailsPageState {
  isSubmitting: boolean;
}

@page({
  path: routePaths.organization.editWithId,
  hasPermission: permissions.organization.edit
})
@observer
class EditOrganizationDetailsPage extends React.Component<RouteComponentProps<WithIdProps>, EditOrganizationDetailsPageState> {
  private _store: EditOrganizationDetailsStore = editOrganizationDetailsStore;
  private _beforeUnloadFunc: (e: BeforeUnloadEvent) => void = e => showPromptOnPageUnload(e);

  constructor(props: any) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  public async componentDidMount(): Promise<void> {
    window.addEventListener('beforeunload', this._beforeUnloadFunc);
    this._store.id = Number(this.props.match.params.id);
    await this._store.getOrganizationDetails();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this._beforeUnloadFunc);
  }

  public render(): React.ReactNode {
    return (
      <FormContainer headerText="Update Organization Information">
        <FormSectionDivider headerText="Organization Information" />
        <Form>
          <OrganizationDetailsEditSection />
          <FormNavigationFooter
            isSubmitting={() => this.state.isSubmitting}
            cancelLink={navigationHelpers.buildUrlWithId(routePaths.organization.detailsWithId, this._store.id)}
            continueButtonDisabled={() => true}
            shouldRenderContinueButton={() => false}
            shouldRenderSubmitButton={() => true}
            shouldRenderBackButton={() => false}
            shouldRenderCancelLink={() => true}
            submitButtonText="Save Changes"
            onContinueButtonClickedHandler={() => null}
            onSubmitButtonClickedHandler={e => this.onSubmitButtonClickedHandler(e)}
            onBackButtonClickedHandler={() => null}
          />
        </Form>
      </FormContainer>
    );
  }

  private async onSubmitButtonClickedHandler(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    try {
      await this._store.updateOrganization();
      notificationToast.showSuccess('Changes saved successfully');
      this.props.history.push(navigationHelpers.buildUrlWithId(routePaths.organization.detailsWithId, Number(this.props.match.params.id)));
    } catch (ex) {
      notificationToast.showNetworkError();
      throw ex;
    } finally {
      this.setState({ isSubmitting: false });
    }
  }
}

export default withRouter(EditOrganizationDetailsPage);
