import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { formatUsDateTime } from '../../../../../../helpers/displayHelper';
import { LicenseeContext } from '../../../../LicenseeContext';

export const Summary: React.FC = () => {
  const { createdOn, lastUpdatedOn, lastUpdatedBy } = useContext(LicenseeContext);

  return (
    <Row>
      <Col xs={12} sm={9} className="mb-3 mb-sm-0">
        <InlineEntry id="createdOn" labelText="Created on" value={formatUsDateTime(createdOn)} />
        <InlineEntry id="lastUpdatedBy" labelText="Last Updated By" value={lastUpdatedBy} />
        <InlineEntry
          id="lastUpdatedOn"
          labelText="Last Updated On"
          value={formatUsDateTime(lastUpdatedOn)}
        />
      </Col>
    </Row>
  );
};
