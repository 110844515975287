import { observable } from 'mobx';
import UserProfile from '../../../model/user/UserProfile';
import ValidatableObject from '../../validation/ValidatableObject';
import { validate } from '../../validation/helpers';
import NotEmpty from '../../validation/validators/NotEmpty';
import ValidEmail from '../../validation/validators/ValidEmail';
import ValidPhoneNumber from '../../validation/validators/ValidPhoneNumber';
import EditProfileStore from './editProfile/EditProfileStore';
import EditPhoneStore from './editPhone/EditPhoneStore';
import EditPasswordStore from './editPassword/EditPasswordStore';
import EditEmailStore from './editEmail/EditEmailStore';
import EditOrganizationStore from './editOrganization/EditOrganizationStore';
import Role from '../../../model/user/Role';

export default abstract class ProfilePageStore<TEditUserNameStore extends EditProfileStore,
  TEditPhoneStore extends EditPhoneStore,
  TEditPasswordStore extends EditPasswordStore,
  TEditEmailStore extends EditEmailStore,
  TEditOrganizationStore extends EditOrganizationStore> extends ValidatableObject {
  @observable
  public editUserNameStore: TEditUserNameStore;
  public editPhoneStore: TEditPhoneStore;
  public editPasswordStore: TEditPasswordStore;
  public editEmailStore: TEditEmailStore;
  public editOrganizationStore: TEditOrganizationStore;

  @observable
  public isBusy = false;

  @observable
  public isPhoneSaving: boolean;

  @observable
  public isEmailSaving: boolean;

  @observable
  @validate(() => new NotEmpty())
  public userFirstName?: string;

  @observable
  @validate(() => new NotEmpty())
  public userLastName?: string;

  @observable
  @validate(() => new ValidEmail())
  public userEmail?: string;

  @observable
  @validate(() => new ValidPhoneNumber())
  public userPhone?: string;

  @observable
  @validate(() => new NotEmpty())
  public role?: Role;

  @observable
  public userProfile?: UserProfile;

  constructor(editUserNameStore: TEditUserNameStore,
    editPhoneStore: TEditPhoneStore,
    editPasswordStore: TEditPasswordStore,
    editEmailStore: TEditEmailStore,
    editOrganizationStore: TEditOrganizationStore) {
    super();
    this.isPhoneSaving = false;
    this.isEmailSaving = false;
    this.editUserNameStore = editUserNameStore;
    this.editPhoneStore = editPhoneStore;
    this.editPasswordStore = editPasswordStore;
    this.editEmailStore = editEmailStore;
    this.editOrganizationStore = editOrganizationStore;
  }

  public abstract async getUserProfile(): Promise<void>;
}
