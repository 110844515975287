import ValidatableObject from './ValidatableObject';
import { ValidationState } from './FieldValidationCollection';

export default class FieldValidationContext {
  private readonly _target: ValidatableObject;
  private readonly _key: string;
  private _messages: string[] = [];

  private _currentState: ValidationState;

  public get messages(): string[] {
    return this._messages;
  }

  public get target(): ValidatableObject {
    return this._target;
  }

  public get key(): string {
    return this._key;
  }

  public get currentState(): ValidationState {
    return this._currentState;
  }

  public constructor(target: ValidatableObject, key: string) {
    this._target = target;
    this._key = key;
    this._currentState = ValidationState.NotValidated;
  }

  public addValidationResult(state: ValidationState, message?: string): void {
    if (state === ValidationState.Invalid) {
      if (!message) {
        throw new Error('Invalid state requires a message');
      }

      this._messages.push(message);
    }

    if (state > this._currentState) {
      this._currentState = state;
    }
  }
}
