import React, { useContext } from 'react';

import InlineEntry from '../../../../../../../../shared/layout/inlineEntry/InlineEntry';
import { LicenseeContext } from '../../../../../../LicenseeContext';

export const ManageOrganizationsSection: React.FC = () => {
  const context = useContext(LicenseeContext);
  if (!context.loaded) {
    return null;
  }
  const { manageOrganizations: { awaitingApprovalOrganizations, activeOrganizations, inactiveOrganizations } } = context;
  return (
    <>
      <InlineEntry id="awaitingApprovalOrgs" labelText="Awaiting Approval" value={awaitingApprovalOrganizations} />
      <InlineEntry id="activeOrgs" labelText="Active" value={activeOrganizations} />
      <InlineEntry id="inactiveOrgs" labelText="Inactive" value={inactiveOrganizations} />
    </>
  );
};
