import React from 'react';
import {
  Button, Col, Row
} from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import Organization from '../../../../../../model/organization/Organization';
import { EditableSectionProps } from '../../EditableSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';
import { SubmitterType } from '../../../../../../helpers/enums/submitterType';
import FormFirstResponderTypeSelector
  from '../../../../../../shared/controls/formSelect/firstResponderTypeSelector/FormFirstResponderTypeSelector';

interface AdministerInformationSectionState {
  shouldFetchDataTypes: boolean;
  organizations: Organization[];
  organizationId: number;
}

export default class ScnaEditAdministerInformationSection extends React.Component<EditableSectionProps, AdministerInformationSectionState> {
  public constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Edit Incident Information">
              <Row>
                {context.administerInformationSectionStore!.submitterType === SubmitterType.FirstResponder && (
                <FormInput
                  id="FirstResponder_FirstAndLastName"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'firstResponder_FirstAndLastName')}
                  labelText="First Responder Name"
                  required
                />
                )}
              </Row>
              <Row>
                {context.administerInformationSectionStore!.submitterType === SubmitterType.FirstResponder && (
                  <FormFirstResponderTypeSelector
                    id="FirstResponder_Type"
                    className="col-12 col-sm-6"
                    storeRef={makeRef(context.administerInformationSectionStore!, 'firstResponder_Type')}
                    labelText="First Responder Type"
                  />
                )}
                <FormInput
                  id="Incident_MedicationAdministered"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'medicationAdministered')}
                  type="date"
                  labelText="Administered Date"
                />
              </Row>
              <Row>
                <FormInput
                  id="Incident_County"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentCounty')}
                  labelText="Incident County"
                />
                <FormInput
                  id="Incident_Zipcode"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentZipCode')}
                  labelText="Incident Zip Code"
                />
              </Row>
              <Row>
                <Col sm={3} className="offset-sm-3">
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onSaveButtonHandler(e)}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn w-100"
                    onClick={(e: React.MouseEvent) => this.props.onCancelButtonHandler(e)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
