export default {
  general: {
    root: '/'
  },

  authentication: {
    silentCallback: '/silentCallback',
    redirectCallback: '/redirectCallback',
    logoutCallback: '/logoutCallback'
  },

  dashboard: {
    root: '/dashboard'
  },

  organization: {
    root: '/organization',
    awaitingApprovalList: '/organization/awaitingApproval',
    manage: '/organization/:subPath(awaitingApproval|active|inactive|rejected)',
    activeList: '/organization/active',
    inactiveList: '/organization/inactive',
    rejectedList: '/organization/rejected',
    register: '/organization/register',
    details: '/organization/details',
    detailsWithId: '/organization/:id/details',
    edit: '/organization/edit',
    editWithId: '/organization/:id/edit',
    new: '/organization/new'
  },

  user: {
    root: '/user',
    manage: '/user/:subPath(awaitingApproval|active|inactive|rejected)',
    awaitingApprovalList: '/user/awaitingApproval',
    activeList: '/user/active',
    inactiveList: '/user/inactive',
    rejectedList: '/user/rejected',
    register: '/user/register',
    forgotPassword: '/user/forgotPassword',
    newPassword: '/user/newPassword',
    confirmEmail: '/user/confirmEmail',
    profile: '/user/profile',
    details: '/user/:userId/details',
    create: '/user/create'
  },

  data: {
    manage: '/data/:subPath(submission|history)',
    submission: '/data/submission',
    history: '/data/history',
    submissionErrors: '/data/submission/:id/errors',
    naloxone: {
      create: '/data/opioidantidote/create',
      details: '/data/opioidantidote/:id',
      manage: '/data/opioidantidote'
    }
  },

  license: {
    overview: '/license/overview',
  },

  help: {
    root: '/help'
  },

  audit: {
    userWithId: '/audit/user/:id',
    organizationWithId: '/audit/organization/:id'
  },

  error: {
    notFound: '/notfound',
    badRequest: '/badRequest'
  }
};
