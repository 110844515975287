import Validator, { ValidationResult } from './Validator';

export default class ValidZipCode implements Validator<string | undefined> {
  private readonly _message: string;

  public constructor(message = 'Invalid Zip Code') {
    this._message = message;
  }

  public validate(value: string | undefined): Promise<ValidationResult> {
    const regExp = new RegExp(/^\d{5}(?:-\d{4})?$/);

    if (Boolean(value) && regExp.test(value!)) {
      return Promise.resolve({ isValid: true });
    }

    return Promise.resolve({ isValid: false, message: this._message });
  }
}
