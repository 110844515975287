import * as React from 'react';
import { AsyncFormSelect, FormSelectOption } from '../../../../shared/controls/formSelect/FormSelect';
import addAgencyAdminStore, { AddOrganizationAdminStore } from './AddOrganizationAdminStore';
import apiService from '../../../../services/ApiService';
import { makeRef } from '../../../../helpers/Ref';
import { GetOrganizationBasicUsersResponse } from '../../../../model/organization/GetOrganizationBasicUsersResponse';

interface AddOrganizationAdminUserAsyncSelectorProps {
  organizationId: number;
}

export default class AddOrganizationAdminUserAsyncSelector extends React.Component<AddOrganizationAdminUserAsyncSelectorProps> {
  private readonly addOrgAdminStore: AddOrganizationAdminStore = addAgencyAdminStore;

  public render(): React.ReactNode {
    return (
      <div className="row">
        <div className="col-12 col-sm-6">
          <AsyncFormSelect
            id="users"
            placeholder="Select User..."
            fetchDataFunc={searchPhrase => this.getUsers(searchPhrase)}
            onChange={(id: number) => this.onUserChange(id)}
            invalid={!makeRef<AddOrganizationAdminStore, 'existingUserId', number | undefined>(this.addOrgAdminStore, 'existingUserId')
              .ref.isValid('existingUserId')}
          />
        </div>
      </div>
    );
  }

  public async getUsers(searchPhrase = ''): Promise<FormSelectOption[]> {
    const response = await apiService.get<GetOrganizationBasicUsersResponse>(`organization/${this.props.organizationId}/users/basic`);

    const result = response.data.users.map(fr => ({
      value: fr.id.toString(),
      label: `${fr.lastName}, ${fr.firstName}`
    }));
    return result;
  }

  private onUserChange(id?: number): void {
    this.addOrgAdminStore.existingUserId = id;
  }
}
