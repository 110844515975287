import { action, observable } from 'mobx';
import * as React from 'react';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import NotEmpty from '../../../../shared/validation/validators/NotEmpty';
import { AddOrganizationAdminSource } from '../../../../model/organization/admin/AddOrganizationAdmin';
import apiService from '../../../../services/ApiService';
import FieldValidationContext from '../../../../shared/validation/FieldValidationContext';
import { validate } from '../../../../shared/validation/helpers';
import Conditional from '../../../../shared/validation/validators/Conditional';

export class AddOrganizationAdminStore extends ValidatableObject {
  @observable
  public adminUserSource = AddOrganizationAdminSource.ExistingUser;

  public organizationId = 0;

  @observable
  @validate(() => new Conditional(AddOrganizationAdminStore.shouldValidateUserIdFn, new NotEmpty()))
  public existingUserId: number | undefined;

  @action
  public async addOrganizationAdmin(e: React.FormEvent<any>): Promise<void> {
    e.preventDefault();
    if (await this.validate()) {
      await apiService.put(`/user/${this.existingUserId}/role/update/organizationAdmin`);
    }
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private shouldValidateUserId(value?: number): boolean {
    return this.adminUserSource === AddOrganizationAdminSource.ExistingUser;
  }

  private static shouldValidateUserIdFn(value: any, context: FieldValidationContext): boolean {
    return (context.target as AddOrganizationAdminStore).shouldValidateUserId(value);
  }
}

export default new AddOrganizationAdminStore();
