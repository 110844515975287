import { OrganizationAdmin } from './OrganizationAdmin';

export default interface AddOrganizationAdmin {
  organizationId: number;
  source: AddOrganizationAdminSource;
  newUserDetails: OrganizationAdmin;
  existingUserId: number;
}

export enum AddOrganizationAdminSource {
  ExistingUser = 1,
  NewUser
}
