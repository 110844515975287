import * as React from 'react';
import { UserManager } from 'oidc-client';
import { page } from '../../services/route/decorators';
import routePaths from '../../constants/routePaths';

@page({
  path: routePaths.authentication.silentCallback,
  needsAuthentication: false,
  needsLayout: false
})
export default class SilentCallbackPage extends React.Component {
  public async componentDidMount(): Promise<void> {
    try {
      await new UserManager({ response_mode: 'query' }).signinSilentCallback();
    } catch (e) {
      console.warn(e.message);
    }
  }

  public render(): React.ReactNode {
    return (
      <div>
        If Microsoft buys Facebook. Than the first notification we will get will be: “You have to install driver to add friends”.
      </div>
    );
  }
}
