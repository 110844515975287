import React from 'react';
import { observer } from 'mobx-react';
import { EntryTile } from '../../../../shared/layout/entryTile/EntryTile';
import { AdminCreateUserPersonalInfoSectionStore } from './AdminCreateUserPersonalInfoSectionStore';
import { formatPhoneNumber } from '../../../../helpers/displayHelper';
import ExistingUserForUserCreate from '../../../../model/user/create/ExistingUserForUserCreate';

const AdminCreateUserPersonalInfoExistingUsersSection: React.FC<SectionProps> = ({ store }: SectionProps) => {
  const onUserSelected = (userId: number) => {
    const selectedUser = store.existingUsers.find(u => u.id === userId);
    if (selectedUser?.isApprissAdmin) return;

    store.resetValidationState();
    if (store.selectedUserId !== userId) {
      store.selectedUserId = userId;
    } else {
      store.selectedUserId = 0;
    }
  };

  const mapTile = (user: ExistingUserForUserCreate) => {
    const content = (
      <div>
        <span className="d-block d-md-inline-block text-break"><b>{`${user.firstName} ${user.lastName}`}</b>,&nbsp;</span>
        <span className="d-block d-md-inline-block text-break">{`phone: ${formatPhoneNumber(user.phone)}`},&nbsp;</span>
        <span className="d-block d-md-inline-block text-break">
          <span>email:&nbsp;</span>
          <span>{user.email}</span>
        </span>
        {user.isApprissAdmin && (
          <em className="d-block">Appriss Admin - you cannot select this user</em>
        )}
      </div>
    );

    return (
      <EntryTile
        key={user.id}
        id={user.id}
        onSelected={onUserSelected}
        selected={store.selectedUserId === user.id}
        disabled={user.isApprissAdmin}
        buttonContent={content}
      />
    );
  };

  return (
    <div>
      <em className="d-block mt-4 mb-4">
        We found exisitng ERvive user(s) that match the email or phone number you entered. Please select the user you would
        like to add to an organization. If this is a new user, please use another email and/or phone number. A mobile phone
        or email address can only be linked to one account. If you select an existing user, the information
        provided above will be discarded.
      </em>
      <div>
        {store.existingUsers.map(u => mapTile(u))}
      </div>
    </div>
  );
};

interface SectionProps {
  store: AdminCreateUserPersonalInfoSectionStore;
}

export default observer(AdminCreateUserPersonalInfoExistingUsersSection);
