import React from 'react';
import { registerPage } from '../../services/route/decorators';
import routePaths from '../../constants/routePaths';
import { FormContainer } from '../../shared/layout/formContainer/FormContainer';
import styles from './HelpPage.scss';

const HelpPage: React.FC = () => (
  <FormContainer headerText="Help" className={styles.helpPage}>
    <h2 className="h4 pt-3">How do I log in?</h2>
    <ol className="mb-5">
      <li>Go to login screen</li>
      <li>Click on the <i>Forgot Password?</i> link.</li>
      <li>Enter the email address registered with the site.</li>
      <li>You will then receive an email with a link to reset your password. How quickly you receive the password reset email will depend on internal firewalls, spam filters, etc.</li>
      <li>If you are not able to receive, the password reset email, look for the email in your junk or spam folder.</li>
    </ol>
    <h2 className="h4">File Submission Errors</h2>
    <ul>
      <li><u>Invalid Header</u> - If a file has been converted from another format, it will create an Invalid Header Format error. To resolve it, you need to create a new file in the appropriate format with the records.</li>
    </ul>
    <a href="https://ervive.zendesk.com/hc/en-us/" className="contact-us-btn btn btn-primary px-4" target="_blank" rel="nofollow noreferrer noopener">
      Contact Us
    </a>
  </FormContainer>
);

export default registerPage({
  path: routePaths.help.root,
  needsAuthentication: false
}, HelpPage);
