import { AxiosResponse } from 'axios';
import EditOrganizationStore from '../../../shared/user/profile/editOrganization/EditOrganizationStore';
import apiService from '../../../services/ApiService';
import { OrganizationForProfile } from '../../../model/organization/Organization';

export class EditProfileOrganizationStore extends EditOrganizationStore {
  public async addOrganization(): Promise<OrganizationForProfile> {
    this._isBusy = true;
    let response: AxiosResponse<OrganizationForProfile>;
    try {
      response = await apiService.post<OrganizationForProfile>(`/account/organization/${this.organizationId}/add`);
      return response.data;
    } finally {
      this._isBusy = false;
    }
  }
}
