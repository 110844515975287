import NaloxoneStore from './DetailsNaloxoneEventStore';
import { Section } from './Section';

const validate = (section: Section, store: NaloxoneStore): Promise<boolean> => {
  const { patientContactSectionStore, patientInformationSectionStore, administerInformationSectionStore } = store;
  switch (section) {
    case 'contactInformation':
      return patientContactSectionStore!.validate();
    case 'incidentInformation':
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return administerInformationSectionStore!.validate();
    case 'patientInformation':
      return patientInformationSectionStore!.validate();
    default:
      return Promise.resolve(false);
  }
};
export const sectionValidationStrategy = {
  validate,
};
