import { OrganizationsGridStore } from '../OrganizationsGridStore';
import { RejectedOrganizationForGrid } from '../../../../model/grid/organization/OrganizationForGrid';
import { OrganizationStatus } from '../../../../model/organization/OrganizationStatus';

export class RejectedOrganizationsGridStore extends OrganizationsGridStore<RejectedOrganizationForGrid> {
  protected get organizationStatus(): OrganizationStatus {
    return OrganizationStatus.Rejected;
  }
}

export default new RejectedOrganizationsGridStore();
