import * as React from 'react';
import ValidatableObject from '../../validation/ValidatableObject';
import FormInput, { FormInputProps } from '../formInput/FormInput';
import { FormSelect, FormSelectOption } from './FormSelect';

export type FormSelectorBaseProps <T extends ValidatableObject, TKey extends keyof T, TOption> = FormInputProps<T, TKey> & {
  shouldFetchOptions?: boolean;
  fetchOptionsFunc?: () => Promise<TOption[]>;
  onValueChangedFunc?: (id: number) => void;
  handleValueChangeFunc?: (id: number) => void;
  addAllOption?: boolean;
};

interface FormSelectorBaseState {
  selectorOptions: FormSelectOption[];
  isLoading: boolean;
}

export default abstract class FormSelectorBase<T extends ValidatableObject, TKey extends keyof T, TOption>
  extends React.Component<FormSelectorBaseProps<T, TKey, TOption>, FormSelectorBaseState> {
  public constructor(props: Readonly<FormSelectorBaseProps<T, TKey, TOption>>) {
    super(props);

    this.state = {
      selectorOptions: !this.props.fetchOptionsFunc ? this.getStaticOptions() : [],
      isLoading: !!this.props.shouldFetchOptions && !!this.props.fetchOptionsFunc
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.tryFetchOptionsInternal(this.props);
  }

  // eslint-disable-next-line react/no-deprecated
  public async componentWillReceiveProps(nextProps: Readonly<FormSelectorBaseProps<T, TKey, TOption>>): Promise<void> {
    if (nextProps.shouldFetchOptions !== this.props.shouldFetchOptions) {
      await this.tryFetchOptionsInternal(nextProps);
    }
  }

  public render(): React.ReactNode {
    const { storeRef } = this.props;

    const { selectorOptions } = this.state;
    const selectedOption = this.getSelectedOption(selectorOptions, storeRef.value);

    const selector = (
      <FormSelect
        value={selectedOption}
        options={selectorOptions}
        isClearable={false}
        backspaceRemovesValue={false}
        onChange={(id: number) => this.handleValueChangeInternal(id)}
        invalid={!storeRef.ref.isValid(storeRef.field)}
        isLoading={this.state.isLoading}
      />
    );

    return (
      <FormInput
        id={this.props.id}
        name={this.props.name}
        labelText={this.props.labelText}
        className={this.props.className}
        storeRef={this.props.storeRef}
        customInput={selector}
        onBlur={this.props.onBlur}
        required={this.props.required}
      />
    );
  }

  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => Number(s.value) === storeRefValue) || '';
  }

  protected abstract getStaticOptions(): FormSelectOption[];

  protected abstract prepareFetchedOptions(data: TOption[]): FormSelectOption[];

  private handleValueChangeInternal(id?: number): void {
    if (id === undefined) {
      throw new Error('Selector value cannot be undefined!');
    }

    if (this.props.handleValueChangeFunc) {
      this.props.handleValueChangeFunc(id);
    } else {
      this.props.storeRef.value = id;
    }

    if (this.props.onValueChangedFunc) {
      this.props.onValueChangedFunc(id);
    }
  }

  private async tryFetchOptionsInternal(props: FormSelectorBaseProps<T, TKey, TOption>): Promise<void> {
    this.setState({ isLoading: true });

    let selectorOptions: any[];

    if (props.fetchOptionsFunc) {
      const data = await props.fetchOptionsFunc();
      selectorOptions = this.prepareFetchedOptions(data);
    } else {
      selectorOptions = this.getStaticOptions();
    }

    this.setState({
      selectorOptions,
      isLoading: false
    });
  }
}
