import { OrganizationsGridStore } from '../OrganizationsGridStore';
import { InactiveOrganizationForGrid } from '../../../../model/grid/organization/OrganizationForGrid';
import { OrganizationStatus } from '../../../../model/organization/OrganizationStatus';

export class InactiveOrganizationsGridStore extends OrganizationsGridStore<InactiveOrganizationForGrid> {
  protected get organizationStatus(): OrganizationStatus {
    return OrganizationStatus.Deactivated;
  }
}

export default new InactiveOrganizationsGridStore();
