import * as React from 'react';
import styles from './FormContainer.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

export interface FormContainerWithHeaderProps {
    headerText?: string;
}

export abstract class FormContainerWithHeader<P extends FormContainerWithHeaderProps> extends React.Component<P, {}> {
  protected getContent(): React.ReactNode {
    return this.props.headerText
      ? this.getContentWithHeader()
      : this.getContentWithoutHeader();
  }

  protected getContentWithoutHeader(): React.ReactNode {
    return this.props.children;
  }

    protected abstract getContentWithHeader(): React.ReactNode;
}

interface FormContainerProps extends FormContainerWithHeaderProps {
    headerText?: string;
    children: React.ReactNode;
    className?: string;
}

export class FormContainer extends FormContainerWithHeader<FormContainerProps> {
  public render(): React.ReactNode {
    return (
      <div className={combineClassNames(styles.formContainer, 'container', this.props.className)}>
        {this.getContent()}
      </div>
    );
  }

  protected getContentWithHeader(): React.ReactNode {
    return (
      <>
        <h1 className="mb-4">{this.props.headerText}</h1>
        {this.props.children}
      </>
    );
  }
}
