import { observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import { State } from '../../../../../../model/address/State';
import { Gender } from '../../../../../../helpers/enums/gender';
import NotDaysAhead from '../../../../../../shared/validation/validators/NotDaysAhead';
import ValidZipCode from '../../../../../../shared/validation/validators/ValidZipCode';
import { DataSubmissionType } from '../../../../../../model/dataSubmission/DataSubmissionType';

export class ScnaPatientInformationSectionStore extends ValidatableObject {
  public readonly DataSubmissionType = DataSubmissionType.SCNA;

  @validate(() => new NotEmpty())
  @observable
  public FirstName = '';

  @observable
  public MiddleName = '';

  @validate(() => new NotEmpty())
  @observable
  public LastName = '';

  @validate(() => new NotEmpty(), () => new NotDaysAhead(1))
  @observable
  public DateOfBirth = '';

  @validate(() => new NotEmpty())
  @observable
  public Patient_Address1 = '';

  @observable
  public Patient_Address2 = '';

  @validate(() => new NotEmpty())
  @observable
  public Patient_City = '';

  @validate(() => new NotEmpty())
  @observable
  public Patient_State: State = 0;

  @validate(() => new ValidZipCode())
  @observable
  public Patient_ZipCode = '';

  @observable
  public Patient_Gender: Gender = 0;

  @observable
  public Incident_UniqueIdentifier = '';
}
