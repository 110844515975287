import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import ValidPhoneNumber from '../../../validation/validators/ValidPhoneNumber';

export default abstract class EditPhoneStore extends ValidatableObject {
  @observable
  protected _isBusy = false;

  @observable
  public currentPhone?: string;

  @validate(() => new NotEmpty(), () => new ValidPhoneNumber())
  @observable
  public newPhone = '';

  @computed
  public get isBusy(): boolean {
    return this._isBusy;
  }

  public abstract async updatePhone(): Promise<void>;
}
