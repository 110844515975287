import { observer } from 'mobx-react';
import ValidatableObject from '../../../validation/ValidatableObject';
import FormSelectorBase from '../FormSelectorBase';
import { FormSelectOption } from '../FormSelect';
import GetStaticOptionsHelper from '../../../../helpers/getStaticOptionsHelper';
import { Timezone, TimeZoneDisplayName } from '../../../../helpers/enums/timezone';

@observer
export default class FormTimeZoneSelector<T extends ValidatableObject, TKey extends keyof T> extends FormSelectorBase<T, TKey, Timezone> {
  protected getSelectedOption(selectorOptions: FormSelectOption[], storeRefValue: any): FormSelectOption | '' {
    return selectorOptions.find(s => storeRefValue && s.value === storeRefValue) || '';
  }

  protected getStaticOptions(): FormSelectOption[] {
    return GetStaticOptionsHelper.buildOptions(Timezone, TimeZoneDisplayName);
  }

  protected prepareFetchedOptions(data: FormSelectOption[]): FormSelectOption[] {
    return data;
  }
}
