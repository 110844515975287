import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Button, Col, Form, Row, Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormContainer } from '../../../shared/layout/formContainer/FormContainer';
import styles from './NewPasswordPage.scss';
import FormSectionContainer from '../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../helpers/Ref';
import ButtonWithSpinner from '../../../shared/controls/buttons/ButtonWithSpinner';
import { page } from '../../../services/route/decorators';
import routePaths from '../../../constants/routePaths';
import newPasswordStore, { NewPasswordStore, SetNewPasswordResult, TokenValidationState } from './NewPasswordStore';
import PasswordCheckerSection from '../../../shared/controls/passwordChecker/PasswordCheckerSection';
import Icon from '../../../shared/controls/icons/Icon';
import InlineEntry from '../../../shared/layout/inlineEntry/InlineEntry';
import { combineClassNames } from '../../../helpers/reactHelpers';
import NotificationToast from '../../../shared/layout/notificationToast/NotificationToast';

interface RouteParams {
  token?: string;
  userId?: string;
}

export type NewPasswordPageProps = RouteComponentProps<RouteParams>;

@page({
  path: routePaths.user.newPassword,
  needsAuthentication: false
})
@observer
export class NewPasswordPage extends React.Component<NewPasswordPageProps, {}> {
  private _store: NewPasswordStore = newPasswordStore;

  public render(): React.ReactNode {
    return (
      <FormContainer className={combineClassNames(...this.getClassNames())}>
        {this.getContent()}
      </FormContainer>
    );
  }

  private *getClassNames(): Iterable<string> {
    yield styles.newPasswordPage;
    if (this._store.success) {
      yield 'success';
    } else {
      switch (this._store.tokenValidationState) {
        case TokenValidationState.NotValidated:
          yield 'loading';
          break;
        case TokenValidationState.Valid:
          // noop
          break;
        case TokenValidationState.Invalid:
          yield 'invalid';
          break;
      }
    }
  }

  private getContent(): React.ReactNode {
    if (this._store.success) {
      return (
        <div>
          <Icon name="success" size="5rem" className="mb-2" />
          <h1>Password reset successfully</h1>
          <Link to={{ pathname: routePaths.general.root, state: { passwordResetSuccessful: true } }}>Back to login page</Link>
        </div>
      );
    }

    switch (this._store.tokenValidationState) {
      case TokenValidationState.NotValidated:
        return (
          <div>
            <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
            <h2>Validating token...</h2>
          </div>
        );
      case TokenValidationState.Valid:
        return (
          <Form>
            <FormSectionContainer>
              <InlineEntry
                labelText="User's full name"
                value={`${this._store.firstName} ${this._store.lastName}`}
                id="userName"
              />
              <p>Please enter a password.</p>
              <Row>
                <Col md={6}>
                  <FormInput
                    id="newPassword"
                    type="password"
                    storeRef={makeRef(this._store, 'password')}
                    labelText="Password"
                  />
                </Col>
                <Col md={6}>
                  <FormInput
                    id="confirmPassword"
                    type="password"
                    storeRef={makeRef(this._store, 'confirmPassword')}
                    labelText="Confirm password"
                  />
                </Col>
              </Row>
              <PasswordCheckerSection password={this._store.password} />
            </FormSectionContainer>
            <Row>
              <Col lg={3} md={4} sm={6}>
                <ButtonWithSpinner
                  id="submit"
                  color="primary"
                  buttonText="Set password"
                  isBusy={this._store.isBusy}
                  type="submit"
                  onClick={(e: React.MouseEvent) => this.handleSubmit(e)}
                  block
                />
              </Col>
            </Row>
          </Form>
        );
      case TokenValidationState.Invalid:
        return (
          <div className="invalid_token-container">
            <Icon name="error" size="5rem" />
            <h1>Invalid Link</h1>
            <p>
              The link you are trying to access is invalid or expired.
              Please try resetting your password again.
            </p>
            <div>
              <Link to={routePaths.user.forgotPassword}>
                <Button color="primary">Reset Password</Button>
              </Link>
            </div>
            <div>
              <Link to={routePaths.general.root}>Back to login page</Link>
            </div>
          </div>
        );
    }

    return null;
  }

  public componentDidMount(): void {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    const userId = Number(params.get('userId'));

    this._store.token = token || undefined;
    this._store.userId = userId;
    this._store.validateToken();
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private async handleSubmit(e: React.MouseEvent): Promise<void> {
    e.preventDefault();
    if (this._store.validate()) {
      const result = await this._store.setNewPassword();
      if (result === SetNewPasswordResult.InvalidToken) {
        NotificationToast.showError('Your token is invalid or expired. Please request password reset again');
      }
    }
  }
}

export default withRouter(NewPasswordPage);
