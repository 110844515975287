import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'reactstrap';
import { delay } from 'q';
import Steps, { StepOption } from '../../controls/steps/Steps';
import { showPromptOnPageUnload } from '../../../helpers/reactHelpers';
import { FormContainer } from '../../layout/formContainer/FormContainer';
import NotificationToast from '../../layout/notificationToast/NotificationToast';
import { CreateUserPageSection } from './CreateUserPageSection';
import CreateUserStore from './CreateUserStore';
import { CreateUserPersonalInfoSectionStore } from './personalInfoSection/CreateUserPersonalInfoSectionStore';
import { CreateUserOrganizationSectionStore } from './organizationSection/CreateUserOrganizationSectionStore';
import FormNavigationFooter from '../../controls/formNavigationFooter/formNavigationFooter';

@observer
export default class CreateUserPage
  extends React.Component<CreateUserAccountPageProps, CreateUserAccountPageState> {
  private _mainStore: CreateUserStore<CreateUserPersonalInfoSectionStore, CreateUserOrganizationSectionStore> = this.props.mainStore;

  private _beforeUnloadFunc: (e: BeforeUnloadEvent) => void = e => showPromptOnPageUnload(e);

  public constructor(props: CreateUserAccountPageProps) {
    super(props);

    this.state = {
      isSubmitting: false
    };
  }

  public componentDidMount(): void {
    window.addEventListener('beforeunload', this._beforeUnloadFunc);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this._beforeUnloadFunc);
  }

  public render(): React.ReactNode {
    const currentSection = this._mainStore.pageSection;
    const stepOptions: StepOption[] = [
      { id: CreateUserPageSection.PersonalInfo, description: 'Personal Info' },
      { id: CreateUserPageSection.Organization, description: 'Organization' },
      { id: CreateUserPageSection.Review, description: 'Review' },
      { id: CreateUserPageSection.Complete, description: 'Complete' }
    ];

    const {
      personalInfoSection, organizationSection, reviewSection, completeSection
    } = this.props;

    return (
      <FormContainer headerText={this.props.pageName}>
        <Steps steps={stepOptions} activeStepId={this._mainStore.pageSection} />
        <Form>
          {currentSection === CreateUserPageSection.PersonalInfo && personalInfoSection}
          {currentSection === CreateUserPageSection.Organization && organizationSection}
          {currentSection === CreateUserPageSection.Review && reviewSection}
          {currentSection === CreateUserPageSection.Complete && completeSection}
          {currentSection === CreateUserPageSection.PersonalInfo
            && this.props.renderTermsAndConditions
            && this.renderTermsAndConditions()}
          <FormNavigationFooter
            isSubmitting={() => this.state.isSubmitting}
            cancelLink={this.props.cancelRedirectPath}
            continueButtonDisabled={() => this._mainStore.pageSection === CreateUserPageSection.Complete
              || this._mainStore.continueButtonDisabled}
            shouldRenderContinueButton={() => this._mainStore.pageSection === CreateUserPageSection.PersonalInfo
              || this._mainStore.pageSection === CreateUserPageSection.Organization}
            shouldRenderSubmitButton={() => this._mainStore.pageSection === CreateUserPageSection.Review}
            shouldRenderBackButton={() => this._mainStore.pageSection === CreateUserPageSection.Organization
              || this._mainStore.pageSection === CreateUserPageSection.Review}
            shouldRenderCancelLink={() => this._mainStore.pageSection !== CreateUserPageSection.Complete}
            onContinueButtonClickedHandler={e => this.onContinueButtonClickedHandler(e)}
            onSubmitButtonClickedHandler={e => this.onSubmitButtonClickedHandler(e)}
            onBackButtonClickedHandler={e => this.onBackButtonClickedHandler(e)}
          />
        </Form>
      </FormContainer>
    );
  }

  private renderTermsAndConditions = (): React.ReactNode => (
    <div>
      <p>
        By clicking <b>Continue</b>, I agree to ERvive&apos;s&nbsp;
        <a href="/terms-conditions">Terms & Conditions</a> and <a href="/privacy">Privacy Policy</a>.
      </p>
    </div>
  );

  private onContinueButtonClickedHandler = async (e: React.MouseEvent<any>): Promise<void> => {
    e.preventDefault();

    switch (this._mainStore.pageSection) {
      case CreateUserPageSection.PersonalInfo:
        if (!await this._mainStore.personalInfoStore.validate()) {
          return;
        }
        break;
      case CreateUserPageSection.Organization:
        if (!await this._mainStore.organizationStore.validate()) {
          await delay(100);

          const firstError = window.document.querySelector('.invalid-feedback');
          if (firstError?.parentElement) {
            firstError.parentElement.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
          }

          return;
        }
        break;
    }
    this._mainStore.pageSection++;
  };

  private onSubmitButtonClickedHandler = async (e: React.MouseEvent<any>): Promise<void> => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    try {
      await this.props.handleSubmit();
      this._mainStore.pageSection++;
    } catch (ex) {
      NotificationToast.showNetworkError();
      throw ex;
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  private onBackButtonClickedHandler(e: React.MouseEvent<any>): void {
    e.preventDefault();
    this._mainStore.pageSection--;
  }
}

interface CreateUserAccountPageProps {
  pageName: string;
  mainStore: CreateUserStore<CreateUserPersonalInfoSectionStore, CreateUserOrganizationSectionStore>;
  currentSection: CreateUserPageSection;
  personalInfoSection: React.ReactNode;
  organizationSection: React.ReactNode;
  reviewSection: React.ReactNode;
  completeSection: React.ReactNode;
  renderTermsAndConditions: boolean;
  handleSubmit: () => Promise<void>;
  cancelRedirectPath: string;
}

interface CreateUserAccountPageState {
  isSubmitting: boolean;
}
