import React from 'react';
import { observer } from 'mobx-react';
import routePaths from '../../../constants/routePaths';
import { page } from '../../../services/route/decorators';
import CreateUserPage from '../../../shared/user/create/CreateUserPage';
import { RegisterUserStore } from './RegisterUserStore';
import CreateUserPersonalInfoSection from '../../../shared/user/create/personalInfoSection/CreateUserPersonalInfoSection';
import CreateUserReviewSection from '../../../shared/user/create/reviewSection/CreateUserReviewSection';
import RegisterUserPersonalInfoPasswordSection from './personalInfoSection/RegisterUserPersonalInfoPasswordSection';
import OrganizationSection from '../../../shared/user/create/organizationSection/CreateUserOrganizationSection';
import { RegisterUserPersonalInfoSectionStore } from './personalInfoSection/RegisterUserPersonalInfoSectionStore';
import { CreateUserOrganizationSectionStore } from '../../../shared/user/create/organizationSection/CreateUserOrganizationSectionStore';
import { FormCompleteSection } from '../../../shared/controls/formCompleteSection/FormCompleteSection';
import { CreateUserPersonalInfoExistingUsersSection } from '../../../shared/user/create/personalInfoSection/CreateUserPersonalInfoExistingUsersSection';

@page({
  path: routePaths.user.register,
  needsAuthentication: false
})
@observer
export default class RegisterUserPage extends React.Component {
  private _personalInfoStore: RegisterUserPersonalInfoSectionStore = new RegisterUserPersonalInfoSectionStore();
  private _organizationStore: CreateUserOrganizationSectionStore = new CreateUserOrganizationSectionStore();
  private _mainStore: RegisterUserStore = new RegisterUserStore(this._personalInfoStore, this._organizationStore);

  public componentWillUnmount() {
    this._mainStore.personalInfoStore.password = '';
    this._mainStore.personalInfoStore.confirmPassword = '';
  }

  public render(): React.ReactNode {
    const completeContent = (
      <>
        <p>Thank you for creating your account.</p>
        <p>
          Your account still needs to be approved by an admin before you can access the application.<br />
          You will receive an email once your account has been approved.
        </p>
      </>
    );

    return (
      <CreateUserPage
        pageName="Create an Account"
        mainStore={this._mainStore}
        currentSection={this._mainStore.pageSection}
        personalInfoSection={this.renderPersonalInfoSection()}
        organizationSection={<OrganizationSection store={this._mainStore.organizationStore} />}
        reviewSection={<CreateUserReviewSection store={this._mainStore} />}
        completeSection={(
          <FormCompleteSection
            status="Pending Approval"
            content={completeContent}
            linkPath={routePaths.general.root}
            linkText="Return to Home Page"
          />
        )}
        renderTermsAndConditions={false}
        handleSubmit={this.handleSubmit}
        cancelRedirectPath={routePaths.general.root}
      />
    );
  }

  private renderPersonalInfoSection = (): React.ReactNode => (
    <CreateUserPersonalInfoSection personalInfoStore={this._mainStore.personalInfoStore}>
      {this._personalInfoStore.usersExist && <CreateUserPersonalInfoExistingUsersSection />}
      <RegisterUserPersonalInfoPasswordSection personalInfoStore={this._mainStore.personalInfoStore} />
    </CreateUserPersonalInfoSection>
  );

  private handleSubmit = async (): Promise<void> => {
    await this._mainStore.createAccount();
  };
}
