import React from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import { FormContainer } from '../../layout/formContainer/FormContainer';
import FormSectionContainer from '../../layout/formContainer/sectionContainer/FormSectionContainer';
import InlineEntry from '../../layout/inlineEntry/InlineEntry';
import LoadingSpinner from '../../layout/loadingSpinner/LoadingSpinner';
import { formatPhoneNumber, formatUsDateTime } from '../../../helpers/displayHelper';
import EditEmailSection from './editEmail/EditEmailSection';
import EditPhoneSection from './editPhone/EditPhoneSection';
import EditProfileSection from './editProfile/EditProfileSection';
import styles from './ProfilePage.scss';
import EditPasswordSection from './editPassword/EditPasswordSection';
import { OrganizationForProfile } from '../../../model/organization/Organization';
import { OrganizationStatus } from '../../../model/organization/OrganizationStatus';
import ProfilePageStore from './ProfilePageStore';
import EditProfileStore from './editProfile/EditProfileStore';
import EditPhoneStore from './editPhone/EditPhoneStore';
import EditPasswordStore from './editPassword/EditPasswordStore';
import EditEmailStore from './editEmail/EditEmailStore';
import { OpenModal } from '../../layout/modal/OpenModal';
import UserDeactivationRejectionModal
  from '../../../pages/user/deactivationRejectionModal/UserDeactivationRejectionModal';
import UserDeactivationRejectionStore
  from '../../../pages/user/deactivationRejectionModal/UserDeactivationRejectionStore';
import { AccountStatus } from '../../../model/user/AccountStatus';
import UserApprovalModal from '../../../pages/user/approvalModal/UserApprovalModal';
import userStore from '../../../data/UserStore';
import permissions from '../../../model/user/permissions';
import apiService from '../../../services/ApiService';
import notificationToast from '../../layout/notificationToast/NotificationToast';
import EditOrganizationStore from './editOrganization/EditOrganizationStore';
import EditOrganizationSection from './editOrganization/EditOrganizationSection';
import { DataAccessLevel } from '../../../helpers/enums/dataAccessLevel';

interface ProfilePageState {
  openModal: OpenModal;
  editProfile: boolean;
  editEmail: boolean;
  editPhone: boolean;
  editPassword: boolean;
  editOrganizations: boolean;
}

interface ProfilePageProps {
  store: ProfilePageStore<EditProfileStore, EditPhoneStore, EditPasswordStore, EditEmailStore, EditOrganizationStore>;
}

@observer
export default class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  constructor(props: ProfilePageProps) {
    super(props);

    this.state = {
      openModal: OpenModal.None,
      editProfile: false,
      editEmail: false,
      editPhone: false,
      editPassword: false,
      editOrganizations: false
    };
  }

  public componentDidMount(): void {
    this.props.store.getUserProfile();
  }

  public render(): React.ReactNode {
    if (this.props.store.isBusy || !this.props.store.userProfile) {
      return (
        <FormContainer>
          <LoadingSpinner />
        </FormContainer>
      );
    }

    const shouldRenderOrganizations = this.props.store.userProfile!.organizations?.length;
    return (
      <div className={styles.profilePage}>
        <FormContainer headerText="My Account">
          <FormSectionContainer headerText="Personal information">
            {this.state.editProfile
              ? <EditProfileSection store={this.props.store} toggleEditProfileFunc={() => this.setState({ editProfile: false })} />
              : this.renderUserProfile()}
          </FormSectionContainer>
          <FormSectionContainer headerText="Contact information">
            {this.state.editEmail
              ? <EditEmailSection store={this.props.store} toggleEditEmailFunc={() => this.setState({ editEmail: false })} />
              : this.renderEmailDetails()}
          </FormSectionContainer>
          <FormSectionContainer>
            {this.state.editPhone
              ? <EditPhoneSection store={this.props.store} toggleEditPhoneFunc={() => this.setState({ editPhone: false })} />
              : this.renderPhoneDetails()}
          </FormSectionContainer>
          {shouldRenderOrganizations && (
            <FormSectionContainer headerText="Organizations">
              {this.state.editOrganizations
                ? <EditOrganizationSection store={this.props.store} toggleEditOrganizationFunc={() => this.setState({ editOrganizations: false })} />
                : this.renderOrganizationDetails()}
            </FormSectionContainer>
          )}
          {(this.canUpdateUser() && !this.props.store.userProfile!.isExternal) && (
            <FormSectionContainer headerText="Password">
              {this.state.editPassword
                ? <EditPasswordSection store={this.props.store} toggleEditPasswordFunc={() => this.setState({ editPassword: false })} />
                : this.renderPasswordDetails()}
            </FormSectionContainer>
          )}
          {this.renderSummary()}
        </FormContainer>
      </div>
    );
  }

  private renderUserProfile(): React.ReactNode {
    return (
      <Row>
        <Col xs={12} sm={9} className="mb-3 mb-sm-0">
          <InlineEntry id="userFirstName" labelText="First Name" value={this.props.store.userProfile!.firstName} />
          <InlineEntry id="userLastName" labelText="Last Name" value={this.props.store.userProfile!.lastName} />
          <InlineEntry id="userRole" labelText="Role" value={this.props.store.userProfile!.role.name} />
        </Col>
        {!this.props.store.userProfile!.isExternal && (
          <Col xs={12} sm={3}>
            <Row>
              <Col xs={6} sm={12} className="mb-sm-3">
                {this.canUpdateUser() && (
                  <Button color="primary" block onClick={() => this.setState({ editProfile: true })}>Update</Button>
                )}
                {this.renderAccountStatusButton()}
              </Col>
            </Row>
          </Col>
        )}
        {(this.state.openModal === OpenModal.UserReject
          || this.state.openModal === OpenModal.UserDeactivate) && (
          <UserDeactivationRejectionModal
            store={new UserDeactivationRejectionStore()}
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.props.store.userProfile?.id || 0}
            onSubmitFunc={() => this.props.store.getUserProfile()}
          />
        )}
        {(this.state.openModal === OpenModal.UserApprove) && (
          <UserApprovalModal
            openModal={this.state.openModal}
            closeModalFunc={() => this.closeModal()}
            id={this.props.store.userProfile?.id || 0}
            onSubmitFunc={() => this.props.store.getUserProfile()}
          />
        )}
      </Row>
    );
  }

  private renderAccountStatusButton(): React.ReactNode {
    const status = this.props.store.userProfile?.status;

    return (
      <>
        {this.canUpdateUser() && (
          <>
            {this.canApprove(status) && (<Button color="primary" block onClick={() => this.setState({ openModal: OpenModal.UserApprove })}>Approve</Button>)}
            {this.canReject(status) && <Button color="primary" block onClick={() => this.setState({ openModal: OpenModal.UserReject })}>Reject</Button>}
            {this.canDeactivate(status) && <Button color="primary" block onClick={() => this.setState({ openModal: OpenModal.UserDeactivate })}>Deactivate</Button>}
          </>
        )}
      </>
    );
  }

  private canUpdateUser(): boolean | undefined {
    switch (this.props.store.userProfile?.role.dataAccessLevel) {
      case DataAccessLevel.Licensee: {
        return userStore.session?.role?.hasPermission(permissions.user.adminUserAssignRole);
      }
      case DataAccessLevel.FullAccess: {
        return userStore.session?.role?.isApprissAdmin;
      }
      default: {
        return userStore.session?.role?.hasPermission(permissions.user.approve);
      }
    }
  }

  private canApprove(status?: AccountStatus) {
    return userStore.session?.role?.hasPermission(permissions.user.approve)
      && (status === AccountStatus.Pending || status === AccountStatus.Rejected || status === AccountStatus.Disabled);
  }

  private canReject(status?: AccountStatus) {
    return userStore.session?.role?.hasPermission(permissions.user.reject)
      && (status === AccountStatus.Pending);
  }

  private canDeactivate(status?: AccountStatus) {
    return userStore.session?.role?.hasPermission(permissions.user.deactivate)
      && (status === AccountStatus.Active);
  }

  private canSendCreatePasswordEmail(): boolean | undefined {
    const accountStatus = this.props.store.userProfile?.status;

    // do not display option to send reset password email if user is viewing their own profile
    if (userStore.session?.email === this.props.store.userProfile?.email) {
      return false;
    }

    return userStore.session?.role?.hasPermission(permissions.user.create);
  }

  get canAddUserOrganizations(): boolean {
    return !this.props.store.userProfile?.isExternal && this.props.store.userProfile?.role.name !== 'License Admin';
  }

  private renderEmailDetails(): React.ReactNode {
    return (
      <div className="row">
        <div className="col-12 col-sm-9 mb-3 mb-sm-0 d-flex align-items-center">
          <InlineEntry id="userEmail" labelText="Email" value={this.props.store.userProfile!.email!} />
        </div>
        {(this.canUpdateUser() && !this.props.store.userProfile!.isExternal) && (
          <div className="col-6 col-sm-3">
            <Button color="primary" block onClick={() => this.setState({ editEmail: true })}>Update</Button>
          </div>
        )}
      </div>
    );
  }

  private renderPhoneDetails(): React.ReactNode {
    return (
      <div className="row">
        <div className="col-12 col-sm-9 mb-3 mb-sm-0 d-flex align-items-center">
          <InlineEntry
            id="userPhone"
            labelText="Phone Number"
            value={formatPhoneNumber(this.props.store.userProfile!.phone) || <em>none</em>}
          />
        </div>
        {(this.canUpdateUser() && !this.props.store.userProfile!.isExternal) && (
          <div className="col-6 col-sm-3">
            <Button color="primary" block onClick={() => this.setState({ editPhone: true })}>Update</Button>
          </div>
        )}
      </div>
    );
  }

  private renderPasswordDetails(): React.ReactNode {
    return (
      <Row>
        {this.canSendCreatePasswordEmail() && (
          <Col md={{ size: 6, offset: 3 }}>
            <Button color="primary" block onClick={() => this.sendSetPasswordEmail()}>Send Create Password Email</Button>
          </Col>
        )}
        {!this.canSendCreatePasswordEmail() && (
          <>
            <Col xs={12} sm={9} className="mb-3 mb-sm-0 d-flex align-items-center">
              <InlineEntry
                id="userPassword"
                labelText="Password"
                value="********"
              />
            </Col>
            <Col xs={6} sm={3}>
              <Button color="primary" block onClick={() => this.setState({ editPassword: true })}>Change</Button>
            </Col>
          </>
        )}
      </Row>
    );
  }

  private renderSummary(): React.ReactNode {
    return (
      <div className="summary">
        <InlineEntry
          id="lastLogin"
          className="mb-0"
          labelText="Last login"
          value={formatUsDateTime(this.props.store.userProfile!.lastLoggedIn)}
        />
      </div>
    );
  }

  private async sendSetPasswordEmail() {
    try {
      await apiService.post('user/sendSetPasswordEmail', { userId: this.props.store.userProfile?.id });
      notificationToast.showSuccess('Create password email sent');
    } catch {
      notificationToast.showError('Failed to send create password email');
    }
  }

  private renderOrganizationDetails() {
    const activeOrganizations = this.props.store.userProfile!.organizations.filter(value => value.status
      === OrganizationStatus.Active);
    const awaitingApprovalOrganizations = this.props.store.userProfile!.organizations.filter(value => value.status
      === OrganizationStatus.Pending);
    return (
      <Row>
        <Col xs={12} sm={9} className="mb-3 mb-sm-0 d-flex align-items-center">
          {ProfilePage.listOrganizations(activeOrganizations, 'Active')}
          {ProfilePage.listOrganizations(awaitingApprovalOrganizations, 'Awaiting approval')}
        </Col>
        {this.canAddUserOrganizations && (
          <Col xs={6} sm={3}>
            <Button color="primary" block onClick={() => this.setState({ editOrganizations: true })}>Update</Button>
          </Col>
        )}
      </Row>
    );
  }

  private static listOrganizations(list: OrganizationForProfile[], title: string): React.ReactElement | undefined {
    if (!list || !list.length) {
      return undefined;
    }

    return (
      <Row>
        <Col>
          <em>{title}</em>
          {list.map(value => (
            <FormSectionContainer key={value.id}>
              {value.name} - {value.address.street} {value.address.city} {value.address.state} {value.address.zipCode}
            </FormSectionContainer>
          ))}
        </Col>
      </Row>
    );
  }

  private closeModal(): void {
    this.setState({ openModal: OpenModal.None });
  }
}
