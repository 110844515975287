import React from 'react';
import {
  Button, Col, Row
} from 'reactstrap';
import FormSectionContainer from '../../../../../../shared/layout/formContainer/sectionContainer/FormSectionContainer';
import FormInput from '../../../../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../../../../helpers/Ref';
import FormDosageUnitSelector from '../../../../../../shared/controls/formSelect/dosageUnitSelector/FormDosageUnitSelector';
import Organization from '../../../../../../model/organization/Organization';
import { EditableSectionProps } from '../../EditableSectionProps';
import { NaloxoneStore } from '../../NaloxoneStoreContext';

interface AdministerInformationSectionState {
  shouldFetchDataTypes: boolean;
  organizations: Organization[];
  organizationId: number;
}

export default class IanaEditAdministerInformationSection extends React.Component<EditableSectionProps, AdministerInformationSectionState> {
  public constructor(props: any) {
    super(props);
  }
  public render() {
    return (
      <NaloxoneStore.Consumer>
        {
          context => (
            <FormSectionContainer headerText="Edit Incident Information">
              <Row>
                <FormInput
                  id="Incident_OrganizationName"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentOrganizationName')}
                  labelText="Incident Organization Name"
                />
              </Row>
              <Row>
                <FormInput
                  id="Incident_AdministerFirstName"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentAdministerFirstName')}
                  labelText="Administer First Name"
                />
                <FormInput
                  id="Incident_AdministerLastName"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentAdministerLastName')}
                  labelText="Administer Last Name"
                />
              </Row>
              <Row>
                <FormInput
                  id="Incident_MedicationAdministeredDate"
                  className="col-12 col-sm-6"
                  /* @ts-ignore */
                  storeRef={makeRef(context.administerInformationSectionStore!.medicationAdministered, 'forDatePickerDate')}
                  labelText="Medication Administered Date"
                  type="date"
                />
                <FormInput
                  id="Incident_MedicationAdministeredTime"
                  className="col-12 col-sm-6"
                  /* @ts-ignore */
                  storeRef={makeRef(context.administerInformationSectionStore!.medicationAdministered, 'forDatePickerTime')}
                  labelText="Medication Administered Time"
                  type="time"
                />
                <FormInput
                  id="Incident_MedicationAdministeredDate_Hidden"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'medicationAdministered')}
                  type="hidden"
                />
              </Row>

              <Row>
                <FormInput
                  id="Incident_NotifiedDate"
                  className="col-12 col-sm-6"
                  /* @ts-ignore */
                  storeRef={makeRef(context.administerInformationSectionStore!.incidentNotified, 'forDatePickerDate')}
                  labelText="Notified Date"
                  type="date"
                />
                <FormInput
                  id="Incident_NotifiedTime"
                  className="col-12 col-sm-6"
                  /* @ts-ignore */
                  storeRef={makeRef(context.administerInformationSectionStore!.incidentNotified, 'forDatePickerTime')}
                  labelText="Notified Time"
                  type="time"
                />
                <FormInput
                  id="Incident_NotifiedDate_Hidden"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentNotified')}
                  type="hidden"
                />
              </Row>
              <Row>
                <FormInput
                  id="Incident_MedicationName"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentMedicationName')}
                  labelText="Name of Drug"
                />
              </Row>
              <Row>
                <FormInput
                  id="Incident_MedicationDosage"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentMedicationDosage')}
                  labelText="Medication Dosage"
                  type="number"
                />
                <FormDosageUnitSelector
                  id="Incident_MedicationDosageUnit"
                  className="col-12 col-sm-6"
                  storeRef={makeRef(context.administerInformationSectionStore!, 'incidentMedicationDosageUnit')}
                  labelText="Dosage Unit"
                />
              </Row>
              <Row>
                <Col sm={3} className="offset-sm-3">
                  <Button
                    className="btn-primary w-100"
                    onClick={(e: React.MouseEvent) => this.props.onSaveButtonHandler(e)}
                  >
                    Save
                  </Button>
                </Col>
                <Col sm={3}>
                  <Button
                    className="btn w-100"
                    onClick={(e: React.MouseEvent) => this.props.onCancelButtonHandler(e)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </FormSectionContainer>
          )
        }
      </NaloxoneStore.Consumer>
    );
  }
}
