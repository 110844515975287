import Validator from './validators/Validator';
import ValidatableObject, { META_KEY } from './ValidatableObject';
import { chainify } from '../../helpers/jsHelpers';

type TValidatorFn<TSupportedTypes = never> = () => Validator<TSupportedTypes>;

function getOrAddMeta<TClass extends ValidatableObject, TKey extends keyof TClass, TSupportedTypes = never>(
  proto: HasTypedProperty<TClass, TKey, TSupportedTypes>, key: TKey
): Array<() => Validator<TSupportedTypes>> {
  if (!proto.hasOwnProperty(META_KEY)) {
    proto[META_KEY] = chainify(proto[META_KEY]);
  }
  let result = proto[META_KEY][key as string];
  if (!result) {
    proto[META_KEY][key as string] = [];
    result = proto[META_KEY][key as string];
  }

  return result;
}

export function validate<TSupportedTypes = never>(...validatorFns: Array<TValidatorFn<TSupportedTypes>>) {
  return <TObj extends ValidatableObject, TKey extends keyof TObj>
  (target: HasTypedProperty<TObj, TKey, TSupportedTypes>, propertyKey: TKey): void => {
    for (const validatorFn of validatorFns) {
      getOrAddMeta<TObj, TKey, TSupportedTypes>(target, propertyKey).push(validatorFn);
    }
  };
}
