import * as React from 'react';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane
} from 'reactstrap';
import styles from './Tabs.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

export interface TabForPage {
  header: string | React.ReactNode;
  component: React.ReactNode;
  id: string;
}

interface TabsProps {
  activeTabId: string;
  className?: string;
  tabs: TabForPage[];
  additionalContent?: React.ReactNode;
  onTabToggledFunc: (id: string) => void;
  asyncTabs?: boolean;
}

type TabsState = {
  activeTab: string;
};

class Tabs extends React.Component<TabsProps, TabsState> {
  public constructor(props: TabsProps) {
    super(props);
    this.state = { activeTab: props.activeTabId };
  }

  public render(): React.ReactNode {
    return (
      <>
        <Nav tabs className={combineClassNames(styles.tabs, this.props.className)}>
          {this.props.tabs.map(tab => (
            <NavItem key={tab.id}>
              <NavLink className={this.getActiveTabClass(tab.id)} onClick={() => this.onTabToggled(tab.id)}>
                {tab.header}
              </NavLink>
            </NavItem>
          ))}
          {this.props.additionalContent && (
            <NavItem className="additionalContent-container">
              {this.props.additionalContent}
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={this.props.activeTabId}>
          {this.props.tabs.map(tab => (
            <TabPane tabId={tab.id} key={tab.id}>
              <Row>
                <Col>{this.renderTabComponent(tab)}</Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </>
    );
  }

  private renderTabComponent(tab: TabForPage): React.ReactNode {
    if (!this.props.asyncTabs) {
      return tab.component;
    }

    if (tab.id === this.state.activeTab) {
      return tab.component;
    }

    return null;
  }

  private onTabToggled(tabId: string): void {
    if (this.props.asyncTabs) {
      this.setState({ activeTab: tabId });
    }
    this.props.onTabToggledFunc(tabId);
  }

  private getActiveTabClass(id: string): string {
    return this.props.activeTabId === id ? 'active' : '';
  }
}

export default Tabs;
