import React, { useState } from 'react';
import {
  Dropdown, DropdownMenu, DropdownToggle, NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { AccountStatus } from '../../../../model/user/AccountStatus';
import routePaths from '../../../../constants/routePaths';
import userStore from '../../../../data/UserStore';
import permissions from '../../../../model/user/permissions';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuClass = menuOpen ? 'active' : '';
  const toggle = () => setMenuOpen(!menuOpen);

  return (
    <Dropdown
      nav
      inNavbar
      className={menuClass}
      isOpen={menuOpen}
      toggle={toggle}
    >
      <DropdownToggle nav>
        <FontAwesomeIcon icon={faBars} className="menu_hamburger" /><span>Menu</span>
      </DropdownToggle>
      <MenuBox />
    </Dropdown>
  );
};

const MenuBox = () => {
  const user = userStore.session!;
  return (
    <DropdownMenu className="nav_popper menu">
      {user.status === AccountStatus.Active && <MenuItems />}
      <h5><NavLink to={routePaths.user.profile} tag={Link} className="pl-0">My Account</NavLink></h5>
      <h5><NavLink to={routePaths.help.root} tag={Link} className="pl-0">Help</NavLink></h5>
    </DropdownMenu>
  );
};

const MenuItems = () => {
  const role = userStore.session!.role!;

  return (
    <>
      {role.hasPermission(permissions.data.submissionCreate)
      && (
        <div>
          <h4>Data</h4>
          {role.hasPermission(permissions.data.submissionCreate) && <NavLink to={routePaths.data.submission} tag={Link}>Data Submission</NavLink>}
          {role.hasPermission(permissions.data.submissionList) && <NavLink to={routePaths.data.history} tag={Link}>Submission History</NavLink>}
          {role.hasPermission(permissions.data.submissionCreate) && <NavLink to={routePaths.data.naloxone.create} tag={Link}>Opioid Antidote Entry</NavLink>}
          {role.hasPermission(permissions.data.naloxoneList) && <NavLink to={routePaths.data.naloxone.manage} tag={Link}>Manage Opioid Antidote Records</NavLink>}
        </div>
      )}
      {(role.hasPermission(permissions.organization.manage)
        || role.hasPermission(permissions.user.list)
        || role.hasPermission(permissions.license.overview))
      && (
        <div>
          <h4>Admin</h4>
          {role.hasPermission(permissions.organization.manage) && <NavLink to={routePaths.organization.root} tag={Link}>My Organizations</NavLink>}
          {role.hasPermission(permissions.user.list) && <NavLink to={routePaths.user.root} tag={Link}>Manage Users</NavLink>}
        </div>
      )}
      {role.hasPermission(permissions.license.overview) && !role?.isApprissAdmin
      && (
        <div>
          <h4>My Licensee</h4>
          <NavLink to={routePaths.license.overview} tag={Link}>License overview</NavLink>
        </div>
      )}
    </>
  );
};

export default Menu;
