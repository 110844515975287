import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { State } from '../../../../model/address/State';
import apiService from '../../../../services/ApiService';
import { Role, RoleList } from '../../../../model/role/RoleList';
import { validate } from '../../../validation/helpers';
import NotEmpty from '../../../validation/validators/NotEmpty';
import ValidZipCode from '../../../validation/validators/ValidZipCode';
import ValidPhoneNumber from '../../../validation/validators/ValidPhoneNumber';
import ValidEmail from '../../../validation/validators/ValidEmail';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import { OrganizationForCreateUser } from '../../../../model/account/create/CreateUserRequest';
import userStore from '../../../../data/UserStore';
import permissions from '../../../../model/user/permissions';

export class CreateUserOrganizationSectionStore extends ValidatableObject {
  @computed
  public get continueButtonDisabled(): boolean {
    return this.selectedOrganization === undefined;
  }

  @validate(() => new NotEmpty())
  @observable
  public organizationSearchState?: State;

  @validate(() => new NotEmpty())
  @observable
  public organizationSearchDataSubmissionType?: DataSubmissionType;

  @observable
  public organizationSearchName = '';

  @observable
  public selectedOrganization?: OrganizationForCreateUser;

  @validate(() => new NotEmpty())
  @computed
  public get newOrganizationName(): string {
    return this.selectedOrganization!.name;
  }

  public set newOrganizationName(value: string) {
    this.selectedOrganization!.name = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get newOrganizationStreet(): string {
    return this.selectedOrganization!.address.street;
  }

  public set newOrganizationStreet(value: string) {
    this.selectedOrganization!.address.street = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get newOrganizationCity(): string {
    return this.selectedOrganization!.address.city;
  }

  public set newOrganizationCity(value: string) {
    this.selectedOrganization!.address.city = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get newOrganizationState(): State {
    return this.selectedOrganization!.address.state;
  }

  public set newOrganizationState(value: State) {
    this.selectedOrganization!.address.state = value;
  }

  @validate(() => new NotEmpty())
  @computed
  public get newOrganizationDataSubmissionType(): DataSubmissionType {
    return this.selectedOrganization!.submissionType;
  }

  public set newOrganizationDataSubmissionType(value: DataSubmissionType) {
    this.selectedOrganization!.submissionType = value;
  }

  @validate(() => new NotEmpty(), () => new ValidZipCode())
  @computed
  public get newOrganizationZip(): string {
    return this.selectedOrganization!.address.zipCode;
  }

  public set newOrganizationZip(value: string) {
    this.selectedOrganization!.address.zipCode = value;
  }

  @validate(() => new NotEmpty(), () => new ValidPhoneNumber())
  @computed
  public get newOrganizationPhone(): string {
    return this.selectedOrganization!.phone;
  }

  public set newOrganizationPhone(value: string) {
    this.selectedOrganization!.phone = value;
  }

  @validate(() => new NotEmpty(), () => new ValidEmail())
  @computed
  public get newOrganizationEmail(): string {
    return this.selectedOrganization!.email;
  }

  public set newOrganizationEmail(value: string) {
    this.selectedOrganization!.email = value;
  }

  @observable
  @validate(() => new NotEmpty())
  public userRole?: Role;

  @observable
  public existingOrganizations?: OrganizationForCreateUser[];

  public existingUserId = 0;

  public async validate<TKey extends keyof this>(field?: TKey): Promise<boolean> {
    if (this.selectedOrganization && this.selectedOrganization.id > 0) {
      return super.validate('userRole');
    }
    return super.validate(field);
  }

  public async fetchOrganizations(): Promise<void> {
    this.selectedOrganization = undefined;
    this.userRole = undefined;

    const params = this.getFetchOrganizationParams();
    const response = await apiService.get<OrganizationForCreateUser[]>('/organization/listForUserCreate', {
      params
    });

    // RED-1740: hide license admin role from SCNA users below the level of license admin
    if (this.organizationSearchState === State.SC && this.isAnonymousOrNonAdminUser()) {
      const filteredData: OrganizationForCreateUser[] = [];

      response.data.forEach((item: OrganizationForCreateUser) => {
        item.roles = item.roles.filter((role: Role) => role.name.toLowerCase() !== 'license admin');
        filteredData.push(item);
      });

      response.data = filteredData;
    }

    this.existingOrganizations = response.data;
  }

  public async fetchStates(): Promise<State[]> {
    // If there is no session or if user is bamboo admin, get all active states
    if (!userStore?.session || userStore?.session?.role?.isApprissAdmin) {
      const response = await apiService.get('license/states');
      return response.data;
    }

    // otherwise, return state belonging to user's license
    const response = await apiService.get('license/state');
    return [response.data];
  }

  public async fetchDataTypes(state: State): Promise<DataSubmissionType[]> {
    const response = await apiService.get('license/dataTypes', {
      params: {
        state
      }
    });
    return response.data;
  }

  public async fetchRoles(): Promise<Role[]> {
    const { state } = this.selectedOrganization!.address;
    const { submissionType } = this.selectedOrganization!;

    const response = await apiService.get<RoleList>('/role/list', {
      params: {
        state,
        submissionType
      }
    });

    let { roles } = response.data;

    // RED-1740: hide license admin role from SCNA users below the level of license admin
    if (state === State.SC && this.isAnonymousOrNonAdminUser()) {
      roles = roles.filter((role: Role) => role.name.toLowerCase() !== 'license admin');
    }

    this.selectedOrganization!.roles = roles ?? [];

    return roles;
  }

  protected isAnonymousOrNonAdminUser() {
    return !userStore.session || !userStore.session?.role?.hasPermission(permissions.license.overview);
  }

  protected getFetchOrganizationParams(): any {
    return {
      name: this.organizationSearchName,
      state: this.organizationSearchState,
      submissionType: this.organizationSearchDataSubmissionType,
    };
  }
}
