import { computed, observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import Organization from '../../../../../../model/organization/Organization';
import apiService from '../../../../../../services/ApiService';
import { MedicationDosageUnit } from '../../../../../../helpers/enums/medicationDosageUnit';
import nodaTimeHelper from '../../../../../../helpers/nodaTimeHelper';
import { Timezone, TimeZoneIanaValue } from '../../../../../../helpers/enums/timezone';
import Conditional from '../../../../../../shared/validation/validators/Conditional';
import FieldValidationContext from '../../../../../../shared/validation/FieldValidationContext';
import NotDaysAhead from '../../../../../../shared/validation/validators/NotDaysAhead';
import NotHoursAhead from '../../../../../../shared/validation/validators/NotHoursAhead';
import GreaterThan from '../../../../../../shared/validation/validators/GreaterThan';

export class IanaAdministerInformationSectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @validate(() => new NotEmpty())
  @observable
  public Incident_AdministerFirstName = '';

  @validate(() => new NotEmpty())
  @observable
  public Incident_AdministerLastName = '';

  @observable
  public Incident_OrganizationName = '';

  @observable
  public Incident_County = '';

  @computed
  public get Incident_MedicationAdministered() {
    return nodaTimeHelper.toZonedDateTime(
      TimeZoneIanaValue[this.Incident_MedicationAdministeredTimeZone],
      this.Incident_MedicationAdministeredDate,
      this.Incident_MedicationAdministeredTime
    );
  }

  @validate(() => new NotEmpty(), () => new NotDaysAhead(1))
  @observable
  public Incident_MedicationAdministeredDate = '';

  @validate(() => new NotEmpty(), () => new NotHoursAhead(24, 'Incident_MedicationAdministeredDate'))
  @observable
  public Incident_MedicationAdministeredTime = '';

  @validate(() => new NotEmpty())
  @observable
  public Incident_MedicationAdministeredTimeZone: Timezone = 0;

  @computed
  public get Incident_NotifiedDateTime() {
    return nodaTimeHelper.toZonedDateTime(
      TimeZoneIanaValue[this.Incident_NotifiedTimeZone],
      this.Incident_NotifiedDate,
      this.Incident_NotifiedTime,
    );
  }

  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateIncidentNotifiedDate, new NotDaysAhead(1)))
  @observable
  public Incident_NotifiedDate = '';

  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateIncidentNotifiedTime, new NotHoursAhead(24, 'Incident_NotifiedDate')))
  @observable
  public Incident_NotifiedTime = '';

  @validate(() => new Conditional(IanaAdministerInformationSectionStore.shouldValidateIncidentNotifiedTime, new NotEmpty()))
  @observable
  public Incident_NotifiedTimeZone: Timezone = 0;

  @validate(() => new NotEmpty())
  @observable
  public Incident_MedicationName = '';

  @validate(() => new NotEmpty(), () => new GreaterThan(0))
  @observable
  public Incident_MedicationDosage = '';

  @validate(() => new NotEmpty())
  @observable
  public Incident_MedicationDosageUnit: MedicationDosageUnit = 0;

  /// /////////////////
  // SCNA ONLY FIELDS
  /// ////////////////
  @observable
  public Incident_Zipcode = '';
  public FirstResponder_Type = '';
  public FirstResponder_FirstAndLastName = '';
  public SubmitterType = 0;
  /// /////////////////
  /// /////////////////

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }

  private static shouldValidateIncidentNotifiedDate(value: any, context: FieldValidationContext): boolean {
    return !!(context.target as IanaAdministerInformationSectionStore).Incident_NotifiedTime || !!(context.target as IanaAdministerInformationSectionStore).Incident_NotifiedDate;
  }

  private static shouldValidateIncidentNotifiedTime(value: any, context: FieldValidationContext): boolean {
    return !!(context.target as IanaAdministerInformationSectionStore).Incident_NotifiedTime && !!(context.target as IanaAdministerInformationSectionStore).Incident_NotifiedDate;
  }
}
