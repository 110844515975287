import React from 'react';
import { DataSubmissionType } from '../../../../../model/dataSubmission/DataSubmissionType';
import IanaEditPatientContactInformation from './IanaPatientContactSection/IanaEditPatientContactInformation';
import ScnaEditPatientContactInformation from './ScnaPatientContactSection/ScnaEditPatientContactInformation';

export const editPatientContactInformationSection = (
  submissionType: DataSubmissionType,
  handleSaveButton: () => void,
  handleCancelButton: () => void
) => {
  switch (submissionType) {
    case DataSubmissionType.IANA:
      return (
        <IanaEditPatientContactInformation
          onSaveButtonHandler={async () => handleSaveButton()}
          onCancelButtonHandler={async () => handleCancelButton()}
        />
      );
      break;
    case DataSubmissionType.SCNA:
      return (
        <ScnaEditPatientContactInformation
          onSaveButtonHandler={async () => handleSaveButton()}
          onCancelButtonHandler={async () => handleCancelButton()}
        />
      );
      break;
    default:
      throw new Error('DataSubmissionType not recognized');
  }
};
