import { observer } from 'mobx-react';
import { Column, SortingRule } from 'react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import Grid, { GridProps } from '../../../../shared/grid/Grid';
import { InactiveOrganizationForGrid } from '../../../../model/grid/organization/OrganizationForGrid';
import { formatUsDateTime } from '../../../../helpers/displayHelper';
import { StateDisplayName } from '../../../../helpers/enums/stateDisplayName';
import userStore from '../../../../data/UserStore';

@observer
export default class InactiveOrganizationsGrid
  extends Grid<GridProps<InactiveOrganizationForGrid, string>, InactiveOrganizationForGrid, string> {
  protected defineColumns(): Array<Column<InactiveOrganizationForGrid>> {
    return [
      { Header: 'Id', accessor: 'id', show: false },
      {
        id: 'name',
        Header: 'Organization',
        accessor: d => d.name,
        Cell: ({ row }) => <Link to={{ pathname: `/organization/${row.id}/details` }}>{row.name}</Link>
      },
      {
        id: 'address.city', Header: 'City', accessor: d => d.address.city, show: true
      },
      {
        id: 'address.state', Header: 'State', accessor: d => StateDisplayName[d.address.state], show: !!userStore.session?.role?.isApprissAdmin
      },
      {
        id: 'email', Header: 'Email', accessor: d => d.email, show: true
      },
      {
        id: 'deactivated_on', Header: 'Deactivation Date', accessor: d => formatUsDateTime(d.deactivatedOn), show: true
      },
      {
        id: 'deactivation_reason', Header: 'Deactivation Reason', accessor: d => d.deactivationReason, show: true
      }
    ];
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No active Organizations';
  }
}
