export function combineClassNames(...className: Array<string | undefined>): string {
  return className.filter(value => Boolean(value)).join(' ');
}

export function debounce<TResult>(inner: (...params: any[]) => Promise<TResult>, ms = 0) {
  let timer: number;
  let resolves: Array<(param: any) => void> = [];

  return (...args: any[]) => {
    // Run the function after a certain amount of time
    if (timer) {
      clearTimeout(timer);
    }
    timer = window.setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...args);
      resolves.forEach((r: any) => r(result));
      resolves = [];
    }, ms);

    return new Promise(r => resolves.push(r));
  };
}

export function showPromptOnPageUnload(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = '';
}
