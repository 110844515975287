import React, { RefObject } from 'react';
import { Button, Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { resolve } from 'q';
import { FileItem } from './fileItem/FileItem';
import styles from './FileUpload.scss';
import notificationToast from '../../layout/notificationToast/NotificationToast';
import { combineClassNames } from '../../../helpers/reactHelpers';
import { DataSubmissionType } from '../../../model/dataSubmission/DataSubmissionType';
import apiService from '../../../services/ApiService';
import { GetSubmissionFileNameUniquenessResult } from '../../../model/fileUpload/GetSubmissionFileNameUniquenessResult';
import BusyOverlay from '../../layout/busyOverlay/BusyOverlay';
import { FileProcessStatus } from '../../../helpers/enums/fileProcessStatus';
import { SubmitterType } from '../../../helpers/enums/submitterType';

export type FileUploadProps = {
  fileUploadHeader: React.ReactNode;
  permittedFileExtensions: Array<string>;
  maxMbForFile: number;
  isDisabled: boolean;
  organizationId: number;
  submissionType: DataSubmissionType;
  submitterType: SubmitterType;
  allowMultipleFiles: boolean;
};

export type FileUploadState = {
  files: Array<File>;
  refs: Array<RefObject<FileItem>>;
  fileItems: React.ReactNode[];
  submitDisabled: boolean;
  isBusy: boolean;
};
const regex = new RegExp(/(?:(?!\.)(?!-)[\W])+/g);
const defaultState: FileUploadState = {
  files: [], refs: [], fileItems: [], submitDisabled: true, isBusy: false
};

export class FileUpload extends React.Component<FileUploadProps, FileUploadState> {
  constructor(props: FileUploadProps) {
    super(props);
    this.state = defaultState;
  }

  public reset(): void {
    this.setState(defaultState);
  }

  public render() {
    const extensionsForDisplay = this.props.permittedFileExtensions.map((ext: string, index: number) => {
      if (this.props.permittedFileExtensions.length === 1) {
        return `.${ext}`;
      }

      if ((this.props.permittedFileExtensions.length - 1) === index) {
        return `or .${ext}`;
      }

      return `.${ext}`;
    }).join(', ');

    return (
      <BusyOverlay isBusy={this.state.isBusy} message="Checking file...">
        <div className={combineClassNames(styles.multiFileUpload, 'container-fluid')}>
          <Row>
            <Dropzone onDrop={(files: Array<File>) => this.onFilesAdded(files)} multiple={this.props.allowMultipleFiles}>
              {({ getRootProps, getInputProps }) => (
                <section className="col-12 col-md-6 pl-0">
                  {this.props.fileUploadHeader}
                  <div
                    className={combineClassNames('dragAndDropArea', this.props.isDisabled ? 'disabled' : '')}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} disabled={this.props.isDisabled} />
                    <div className="dragAndDropInstructions">
                      Drop files here to upload or click to <span className="chooseFile">Choose File</span>
                      {this.props.permittedFileExtensions.length > 0 && (
                        <p className="text-center font-weight-bold font-italic">(File must be {extensionsForDisplay})</p>
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </Row>
          <Row>
            <Button
              className="my-4"
              color="primary"
              type="submit"
              onClick={(e: React.MouseEvent<any>) => this.submit(e)}
              disabled={this.state.submitDisabled}
            >Submit
            </Button>
          </Row>
          <Row>
            <ul className="pl-0 col-12 col-md-6">
              {this.state.fileItems}
            </ul>
          </Row>
        </div>
      </BusyOverlay>
    );
  }

  private submit(e: React.MouseEvent<any>) {
    e.preventDefault();

    this.state.refs.forEach(ref => ref.current?.upload());
    this.setState({ submitDisabled: true });
  }

  private async hasUniqueFileName(filename: string): Promise<GetSubmissionFileNameUniquenessResult> {
    const result = await apiService.post<GetSubmissionFileNameUniquenessResult>('/dataSubmission/checkFileNameUniqueness', {
      organizationId: this.props.organizationId,
      fileName: filename
    });

    return result.data;
  }

  private fileNamesAreUnsafe(files: Array<File>): boolean {
    return files.map(file => {
      const containUnsafeCharacters = regex.test(file.name);
      if (containUnsafeCharacters) {
        notificationToast.showError(`File ${file.name} cannot contain spaces or unsafe characters. Could not be uploaded.`);
        this.setState({
          isBusy: false,
          submitDisabled: true
        });
      }
      return containUnsafeCharacters;
    }).includes(true);
  }

  private filesReadyForUpload(refs: RefObject<FileItem>[]): boolean {
    return refs.map(ref => {
      const uploadStatus = ref.current?.state.uploadStatus;
      return uploadStatus === FileProcessStatus.Pending || ref.current === undefined;
    }).includes(true);
  }

  private async onFilesAdded(files: Array<File>) {
    this.setState({ isBusy: true });

    if (this.fileNamesAreUnsafe(files)) {
      this.setState({ isBusy: false, submitDisabled: true });
      return;
    }

    // confirm no file is pending upload
    if (this.props.isDisabled || this.filesReadyForUpload(this.state.refs)) {
      notificationToast.showInfo('Please click Submit to upload the queued file(s) before adding new ones.');
      this.setState({ isBusy: false });
      return;
    }

    // confirm filename(s) is unique
    const uniqueFileNameResults = Promise.all(files.map(file => this.hasUniqueFileName(file.name)));
    const allFileNamesAreUnique = await resolve(uniqueFileNameResults.then(data => data.every(result => result.success === true)));
    if (!allFileNamesAreUnique) {
      uniqueFileNameResults.then(data => data.forEach(result => {
        if (!result.success) {
          notificationToast.showError(result.errorMessage);
        }
      }));
      this.setState({ isBusy: false });
      return;
    }

    // confirm files of valid mimetype are present
    const filteredFiles = this.filterInvalidFiles(files);
    if (filteredFiles.length === 0) {
      this.setState({ isBusy: false });
      return;
    }

    // queue file(s) for upload
    const newRefs = filteredFiles.map(x => React.createRef<FileItem>());
    const newFileNodes = this.buildFileItems(filteredFiles, newRefs);

    this.setState(prevState => {
      const updatedFiles = prevState.files.concat(filteredFiles);
      const updatedRefs = prevState.refs.concat(newRefs);
      const updatedNodes = prevState.fileItems.concat(newFileNodes);
      return {
        files: updatedFiles,
        refs: updatedRefs,
        fileItems: updatedNodes,
        isDisabled: false,
        submitDisabled: false,
        isBusy: false
      };
    });
    notificationToast.showInfo('Your file(s) are queued. Please click Submit to upload.');
  }

  private onFileRemoved(file: File) {
    this.setState(prevState => {
      const removalIndex = prevState.files.indexOf(file);
      prevState.files.splice(removalIndex, 1);
      prevState.refs.splice(removalIndex, 1);
      prevState.fileItems.splice(removalIndex, 1);
      return {
        files: prevState.files,
        refs: prevState.refs,
        fileItems: prevState.fileItems,
        submitDisabled: !this.filesReadyForUpload(prevState.refs)
      };
    });
  }

  private filterInvalidFiles(files: Array<File>): Array<File> {
    let filteredFiles = this.filterInvalidFileTypes(files);
    filteredFiles = this.filterInvalidFileSizes(filteredFiles);
    return filteredFiles;
  }

  private filterInvalidFileTypes(files: Array<File>): Array<File> {
    return files.filter((file: File) => {
      const isAcceptedFileType = this.props.permittedFileExtensions.includes(file.name.split('.').pop() || '');
      if (isAcceptedFileType) {
        return file;
      }
      notificationToast.showError(`${file.name} is not a permitted file type.`);
      return null;
    });
  }

  private filterInvalidFileSizes(files: Array<File>): Array<File> {
    return files.filter((file: File) => {
      const maxBytes = this.props.maxMbForFile * 1024 * 1024;
      const fileBytes = file.size;

      const isAcceptedFileSize = fileBytes <= maxBytes;
      if (isAcceptedFileSize) {
        return file;
      }
      notificationToast.showError(`${file.name} is above the max file size of ${this.props.maxMbForFile}MB`);
      return null;
    });
  }

  private buildFileItems(files: File[], refs: React.RefObject<FileItem>[]): React.ReactNode {
    return files.map((file: File, index: number) => (
      <FileItem
        key={`${file.name}_${Math.random()}`}
        file={file}
        organizationId={this.props.organizationId}
        submissionType={this.props.submissionType}
        submitterType={this.props.submitterType}
        ref={refs[index]}
        onRemoveButtonClick={(f: File) => this.onFileRemoved(f)}
      />
    ));
  }
}
