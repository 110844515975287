import React from 'react';
import InlineEntry from '../../../../shared/layout/inlineEntry/InlineEntry';
import organizationInfoSectionStore, { OrganizationInfoSectionStore } from '../OrganizationInfoSection/OrganizationInfoSectionStore';
import { formatPhoneNumber } from '../../../../helpers/displayHelper';
import styles from './OrganizationReviewSection.scss';

import { DataSubmissionTypeDisplayName } from '../../../../helpers/enums/dataSubmissionTypeDisplayName';

export default class OrganizationReviewSection extends React.Component {
  private readonly _organizationInfoSectionStore: OrganizationInfoSectionStore = organizationInfoSectionStore;

  public render(): React.ReactNode {
    return (
      <div className={styles.organizationReviewSection}>
        <div className="subsection_header">
          <span>Organization Details</span>
        </div>
        <div className="subsection_content">
          <InlineEntry value={this._organizationInfoSectionStore.organizationName} id="organizationName" labelText="Name" />
          <InlineEntry value={this._organizationInfoSectionStore.fullAddress()} id="organizationAddress" labelText="Address" />
          <InlineEntry value={formatPhoneNumber(this._organizationInfoSectionStore.phone)} id="organizationPhone" labelText="Phone" />
          <InlineEntry value={this._organizationInfoSectionStore.email} id="organizaitonEmail" labelText="Email" />
          <InlineEntry
            value={DataSubmissionTypeDisplayName[this._organizationInfoSectionStore.dataSubmissionType!]}
            id="dataSubmissionType"
            labelText="Data Submission Type"
          />
        </div>
      </div>
    );
  }
}
