import Validator, { ValidationResult } from './Validator';
import FieldValidationContext from '../FieldValidationContext';

export default class Conditional<T> implements Validator<T> {
  private readonly shouldValidate: (value: T, context: FieldValidationContext) => boolean;
  private readonly innerValidator: Validator<T>;

  public constructor(shouldValidate: (value: T, context: FieldValidationContext) => boolean,
    innerValidator: Validator<T>) {
    this.shouldValidate = shouldValidate;
    this.innerValidator = innerValidator;
  }

  public async validate(value: T, context: FieldValidationContext): Promise<ValidationResult> {
    if (!this.shouldValidate(value, context)) {
      return { isValid: true };
    }

    return this.innerValidator.validate(value, context);
  }
}
