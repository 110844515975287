import { computed, observable } from 'mobx';
import ValidatableObject from '../../../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../../../shared/validation/helpers';
import NotEmpty from '../../../../../../shared/validation/validators/NotEmpty';
import Organization from '../../../../../../model/organization/Organization';
import apiService from '../../../../../../services/ApiService';
import ValidZipCode from '../../../../../../shared/validation/validators/ValidZipCode';
import NotDaysAhead from '../../../../../../shared/validation/validators/NotDaysAhead';
import FieldValidationContext from '../../../../../../shared/validation/FieldValidationContext';
import { SubmitterType } from '../../../../../../helpers/enums/submitterType';
import Conditional from '../../../../../../shared/validation/validators/Conditional';

export class ScnaAdministerInformationSectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @validate(() => new NotEmpty())
  @observable
  public SubmitterType = 0;

  @validate(() => new Conditional(ScnaAdministerInformationSectionStore.shouldValidateFirstResponderField, new NotEmpty()))
  @observable
  public FirstResponder_FirstAndLastName = '';

  @validate(() => new Conditional(ScnaAdministerInformationSectionStore.shouldValidateFirstResponderField, new NotEmpty()))
  @observable
  public FirstResponder_Type = '';

  @observable
  public Incident_OrganizationName = '';

  @validate(() => new ValidZipCode())
  @observable
  public Incident_Zipcode = '';

  @validate(() => new NotEmpty())
  @observable
  public Incident_County = '';

  @validate(() => new NotEmpty(), () => new NotDaysAhead(1))
  @observable
  public Incident_MedicationAdministeredDate = '';

  @computed
  public get Incident_MedicationAdministered() {
    return this.Incident_MedicationAdministeredDate;
  }

  public async fetchOrganizations(): Promise<Organization[]> {
    const response = await apiService.get<Organization[]>('/organization/listActive');
    return response.data;
  }

  private static shouldValidateFirstResponderField(value: any, context: FieldValidationContext): boolean {
    return (context.target as ScnaAdministerInformationSectionStore).SubmitterType === SubmitterType.FirstResponder;
  }
}
