import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { CreateUserOrganizationSectionStore } from '../CreateUserOrganizationSectionStore';
import { CreateUserNewOrganizationTile } from './newOrganizationTile/CreateUserNewOrganizationTile';
import { EntryTile } from '../../../../layout/entryTile/EntryTile';
import FormRoleSelector from '../../../../controls/formSelect/roleSelector/FormRoleSelector';
import { makeRef } from '../../../../../helpers/Ref';
import { StateDisplayName } from '../../../../../helpers/enums/stateDisplayName';
import { Role } from '../../../../../model/role/RoleList';
import { OrganizationForCreateUser } from '../../../../../model/account/create/CreateUserRequest';
import paramsHelper from '../../../../../helpers/paramsHelper';

interface RouteParams {
  organizationId?: string;
}

interface OrganizationListProps {
  store: CreateUserOrganizationSectionStore;
  organizationId?: number;
}

export type OrganizationListRouteProps = RouteComponentProps<RouteParams> & OrganizationListProps;

@observer
export class CreateUserOrganizationList extends React.Component<OrganizationListRouteProps, {
  selectedRole?: Role;
}> {
  private _store: CreateUserOrganizationSectionStore = this.props.store;

  constructor(props: OrganizationListRouteProps) {
    super(props);

    this.setDefaultSelection(props);
    if (this._store.selectedOrganization) {
      this.getUserRoles();
    }
  }

  public componentDidMount() {
    this.setState({
      selectedRole: this._store.userRole
    });
  }

  public render(): React.ReactNode {
    return (
      <div>
        {this._store.existingOrganizations?.map(org => this.mapTile(org))}
        {this._store.existingOrganizations && <CreateUserNewOrganizationTile store={this._store} isSelected={this.isAddNewOrganizationSelected()} />}
      </div>
    );
  }

  private isAddNewOrganizationSelected(): boolean {
    // id of -1 indicates a new organization
    return this._store.selectedOrganization?.id === -1;
  }

  private setDefaultSelection(props: OrganizationListRouteProps): void {
    // default to the first org if there exists only one
    // or use the org passed down from params
    if (this._store.existingOrganizations?.length === 1) {
      [this._store.selectedOrganization] = this._store.existingOrganizations;
      return;
    }

    const organizationId = paramsHelper.getParam(props, 'organizationId');
    if (organizationId) {
      this._store.selectedOrganization = this._store.existingOrganizations?.find(org => org.id === Number(organizationId));
    }
  }

  private mapTile = (organization: OrganizationForCreateUser) => {
    const isSelected = this._store.selectedOrganization?.id === organization.id;

    const roleSelector = (
      <div className="p-3 p-md-5">
        <div className="row">
          <FormRoleSelector
            id="existingOrgRole"
            className="col-12 col-sm-6 role-selector"
            labelText="Role"
            fetchOptionsFunc={this.getUserRoles}
            storeRef={makeRef(this._store, 'userRole')}
            handleValueChangeFunc={this.handleUserRoleChange}
          />
        </div>
        {
          this.state?.selectedRole && <div> <FontAwesomeIcon icon={faInfoCircle} /> {this.state.selectedRole.description}</div>
        }
      </div>
    );

    const buttonContent = (
      <div>
        <span className="d-block d-md-inline-block text-break">
          {organization.name} - {organization.address.street},&nbsp;
          {organization.address.city},&nbsp;
          {StateDisplayName[organization.address.state]}&nbsp;
          {organization.address.zipCode}
        </span>
      </div>
    );

    return (
      <EntryTile
        key={organization.id}
        id={organization.id}
        onSelected={this.onOrganizationSelected}
        selected={isSelected}
        buttonContent={buttonContent}
        outerContent={isSelected && roleSelector}
      />
    );
  };

  private getUserRoles = () => Promise.resolve<Role[]>(this._store.selectedOrganization!.roles);

  private handleUserRoleChange = (value?: number) => {
    const role = this._store.selectedOrganization!.roles.find(r => r.id === value);
    this._store.userRole = role;
    this._store.validate('userRole');
    this.setState({
      selectedRole: role
    });
  };

  private onOrganizationSelected = (organizationId: number) => {
    const store = this._store;
    store.selectedOrganization = store.existingOrganizations?.find(org => org.id === organizationId);
    store.resetValidationState();
  };
}

export default withRouter(CreateUserOrganizationList);
