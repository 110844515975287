import { User as OidcUser, UserManager } from 'oidc-client';
import { defer, Deferred } from 'q';
import { computed, observable } from 'mobx';
import { getBaseApiUrl, getBaseUrl } from '../services/baseUrl';
import apiService from '../services/ApiService';
import routePaths from '../constants/routePaths';
import Session from '../model/user/Session';

export enum UserStoreState {
  NotInitialized = 1,
  Initializing,
  LoggedIn,
  NotLoggedIn,
  SessionExpired,
  UnknownError = 99
}

export class UserStore {
  @observable
  private _loadingState: UserStoreState = UserStoreState.NotInitialized;

  private _onInitialized?: Deferred<void>;

  @observable
  private _oidcUser?: OidcUser | null;
  private _session?: Session;
  private _manager!: UserManager;

  @computed
  public get loadingState(): UserStoreState {
    return this._loadingState;
  }

  @computed
  public get session(): Session | undefined {
    if (!this._session) {
      if (this._oidcUser) {
        this._session = Session.fromOidcUser(this._oidcUser);
      }
    }
    return this._session;
  }

  public get accessToken(): string | undefined {
    return this._oidcUser?.access_token;
  }

  public async initialize(): Promise<void> {
    if (this._loadingState !== UserStoreState.NotInitialized && this._loadingState !== UserStoreState.Initializing) {
      return;
    }

    if (this._onInitialized) {
      await this._onInitialized;
      return;
    }

    this._loadingState = UserStoreState.Initializing;

    this._onInitialized = defer();
    try {
      const baseUrl = getBaseUrl();
      this._manager = new UserManager({
        authority: getBaseUrl(),
        client_id: 'ervive.webapp.react',
        silent_redirect_uri: `${location.protocol}//${location.host}/#${routePaths.authentication.silentCallback}`,
        post_logout_redirect_uri: `${location.protocol}//${location.host}/#${routePaths.authentication.logoutCallback}`,
        response_type: 'code',
        scope: 'web_api openid profile role email',
        automaticSilentRenew: true,
        metadata: {
          issuer: baseUrl,
          authorization_endpoint: `${baseUrl}/connect/authorize`,
          token_endpoint: `${baseUrl}/connect/token`,
          userinfo_endpoint: `${baseUrl}/connect/userinfo`,
          jwks_uri: `${baseUrl}/.well-known/openid-configuration/jwks`,
          end_session_endpoint: `${baseUrl}/connect/endsession`
        }
      });

      this._manager.events.addAccessTokenExpired(() => {
        this._manager.signinSilent().catch(err => {
          this._manager.signinRedirect();
        });
      });

      this._oidcUser = await this._manager.getUser();
      if (!this.session || this._oidcUser?.expired) {
        try {
          await this._manager.signinSilent();
        } catch (e) {
          if (e.error === 'login_required') {
            this._loadingState = this.session ? UserStoreState.SessionExpired : UserStoreState.NotLoggedIn;
            this._onInitialized.resolve();
            this._onInitialized = undefined;
            return;
          }
          this._loadingState = UserStoreState.UnknownError;
          throw e;
        }
        this._oidcUser = await this._manager.getUser();
      }

      this._loadingState = UserStoreState.LoggedIn;
      this._onInitialized.resolve();
      this._onInitialized = undefined;
    } finally {
      if (this._onInitialized) {
        if (this._loadingState === UserStoreState.Initializing) {
          this._loadingState = UserStoreState.UnknownError;
        }
        this._onInitialized.reject();
        this._onInitialized = undefined;
      }
    }
  }

  public async login(username: string, password: string, rememberMe: boolean): Promise<boolean> {
    const response = await apiService.post('authentication/login', {
      username,
      password,
      rememberMe
    });
    if (response.status !== 204) {
      return false;
    }
    await this._manager.signinSilent();
    this._oidcUser = await this._manager.getUser();
    if (!this._oidcUser) {
      this._loadingState = UserStoreState.NotLoggedIn;
      return false;
    }

    this._loadingState = UserStoreState.LoggedIn;
    return true;
  }

  public microsoftLogin() {
    const returnUrl = `${location.protocol}//${location.host}/#${routePaths.authentication.redirectCallback}`;
    location.href = `${getBaseApiUrl()}/authentication/external?provider=AzureAd&returnUrl=${encodeURIComponent(returnUrl)}`;
  }

  public logout() {
    this._manager.signoutRedirect();
  }
}

export default new UserStore();
