import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './FormCompleteSection.scss';
import Icon from '../icons/Icon';

interface FormCompleteSectionProps {
  status?: string;
  content: React.ReactNode;
  linkPath: string;
  linkText: string;
}

export class FormCompleteSection extends React.Component<FormCompleteSectionProps> {
  public render(): React.ReactNode {
    const shouldRenderStatus = this.props.status !== undefined;

    const status = (
      <h4>Status: {this.props.status}</h4>
    );

    return (
      <div className={styles.completeSection}>
        <div className="header">
          <Icon name="success" size="5rem" />
          <h1>Submitted Successfully</h1>
          {shouldRenderStatus && status}
        </div>
        <div className="content">
          {this.props.content}
          <p>
            <Link to={this.props.linkPath}>{this.props.linkText}</Link>
          </p>
        </div>
      </div>
    );
  }
}
