import Validator, { ValidationResult } from './Validator';

import ZonedDateTime from '../../../model/naloxone/DateTime/ZonedDateTime';
import FieldValidationContext from '../FieldValidationContext';

export default class RequiredZonedDate implements Validator<ZonedDateTime> {
  constructor(private readonly errorMessage: string) {
  }

  public validate(value: ZonedDateTime, context: FieldValidationContext): Promise<ValidationResult> {
    const { date, time } = value;

    if (!date || !time) {
      return Promise.resolve({
        isValid: false,
        message: this.errorMessage,
      });
    }

    return Promise.resolve({
      isValid: true,
    });
  }
}
