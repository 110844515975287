import { computed, observable } from 'mobx';
import ValidatableObject from '../../../validation/ValidatableObject';
import { Role } from '../../../../model/role/RoleList';

export default abstract class EditProfileStore extends ValidatableObject {
  @observable
  protected _isBusy = false;

  @observable
  public firstName = '';

  @observable
  public lastName = '';

  @observable
  public role: Role = {} as Role;

  @computed
  public get isBusy(): boolean {
    return this._isBusy;
  }

  public abstract async updateProfile(): Promise<void>;

  public reset() {
    this.firstName = '';
    this.lastName = '';
    this.role = {} as Role;
    this.resetValidationState();
  }
}
