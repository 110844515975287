import React from 'react';
import { Col, Row } from 'reactstrap';
import { ViewMode } from '../ViewMode';

interface EditableSectionProps {
  isEditable: ViewMode;
  actions: any[];
  renderSection: React.ReactNode;
}
export const EditableSection: React.FC<EditableSectionProps> = ({ isEditable, actions, renderSection }) => (
  <Row>
    <Col xs={12} sm={9}>
      {
        renderSection
      }
    </Col>
    {isEditable === ViewMode.edit && (
    <Col xs={12} sm={3}>
      {
        actions.map(action => action)
      }
    </Col>
    )}
  </Row>
);
