import { Column, SortingRule } from 'react-table';
import React from 'react';
import {
  Button, Col, Form, Input, InputGroup, InputGroupAddon, Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { formatUsDateTime } from '../../../helpers/displayHelper';
import DataSubmissionForGrid from '../../../model/grid/dataSubmission/DataSubmissionForGrid';
import styles from './FileUploadHistoryGrid.scss';
import Grid, { GridProps } from '../../../shared/grid/Grid';
import { makeRef } from '../../../helpers/Ref';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { FileUploadHistoryPageStore } from './FileUploadHistoryPageStore';
import OrganizationFormSelector from '../../management/users/organizationSelector/OrganizationFormSelector';
import Organization from '../../../model/organization/Organization';
import apiService from '../../../services/ApiService';
import NotificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import { FileUploadHistoryGridStore } from './FileUploadHistoryGridStore';
import NavigationHelpers from '../../../helpers/navigationHelpers';
import routePaths from '../../../constants/routePaths';
import { FileProcessStatusDisplayName } from '../../../helpers/enums/fileProcessStatusDisplayName';
import { FileProcessStatus } from '../../../helpers/enums/fileProcessStatus';

interface FileUploadHistoryGridProps extends GridProps<DataSubmissionForGrid, string> {
  store: FileUploadHistoryGridStore<DataSubmissionForGrid>;
}

@observer
export default class FileUploadHistoryGrid extends Grid<FileUploadHistoryGridProps, DataSubmissionForGrid, string> {
  private readonly _store: FileUploadHistoryPageStore = new FileUploadHistoryPageStore();

  public render(): React.ReactNode {
    const searchInput = (
      <InputGroup>
        <Input id="searchPhraseInput" onChange={e => this.handleInputValueChange(e.target.value)} />
        <InputGroupAddon addonType="append">
          <Button
            id="searchButton"
            color="primary"
            block
            className="searchButton"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleSearch(e)}
            style={{ width: '50px' }}
            type="submit"
            disabled={!this._store.organizationId}
          >
            <FontAwesomeIcon icon={faSearch} className="icon" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );

    return (
      <div className={styles.fileUploadHistory}>
        <div className="header">
          <h3>Upload History</h3>
          <p>Review the file status for submitted files.
            If errors are found, click Fix Errors to fix
            all errors and resubmit files. To download a
            copy of the file uploaded, select Download.
          </p>
        </div>
        <Row className="search-container">
          <Col md={4}>
            <OrganizationFormSelector
              id="organizationFormSelector"
              labelText="Organization"
              storeRef={makeRef(this._store, 'organizationId')}
              onValueChangedFunc={id => this.handleOrganizationChange(id)}
              fetchOptionsFunc={() => this.fetchOrganizations()}
            />
          </Col>
          <Col md={4} className="offset-md-4">
            <Form>
              <FormInput
                id="dataSubmissionsSearch"
                labelText="Search"
                storeRef={makeRef(this._store, 'searchPhrase')}
                customInput={searchInput}
              />
            </Form>
          </Col>
        </Row>
        {super.render()}
      </div>
    );
  }

  public componentWillUnmount() {
    this.props.store.organizationId = 0;
    this.props.store.rows = [];
  }

  protected defineColumns(): Array<Column<DataSubmissionForGrid>> {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        show: true,
        Cell: ({ row }) => (
          <div data-submissionid={row.id} />
        ),
        className: 'd-none',
        headerClassName: 'd-none'
      },
      {
        Header: 'ErrorFileLocation', id: 'errorFileLocation', show: false, accessor: d => d.errorFileLocation
      },
      { Header: 'File', id: 'fileName', accessor: d => `${d.fileName}` },
      { Header: 'Submitted', id: 'createdOn', accessor: d => formatUsDateTime(d.createdOn) },
      { Header: 'Submitted By', id: 'user', accessor: d => `${d.submittedByName} - ${d.submittedByEmail}` },
      // Id needs to be 'status' so that we can access the value of 'status' later on the row object
      { Header: 'Status', id: 'fileStatus', accessor: d => FileProcessStatusDisplayName[d.fileStatus] },
      {
        Header: 'Errors', id: 'errors', accessor: d => (d.errors)
      },
      { Header: 'Organization', id: 'organization', accessor: d => d.organizationName },
      {
        Header: 'Action',
        width: 100,
        sortable: false,
        Cell: ({ row }) => (
          <>
            {this.errorButton(row)}
          </>
        )
      },
    ];
  }

  protected errorButton(row: DataSubmissionForGrid) {
    if (row.errorFileLocation) {
      return (
        <>
          <hr />
          <Button
            type="button"
            color="link"
            className="drop-styles"
            onClick={() => this.downloadErrorFile(row)}
          >
            Errors
          </Button>
        </>
      );
    }

    if (this.hasRecordLevelErrors(row)) {
      return (
        <>
          <hr />
          <Link
            type="button"
            className="drop-styles"
            to={NavigationHelpers.buildUrlWithId(routePaths.data.submissionErrors, row.id)}
          >
            Errors
          </Link>
        </>
      );
    }

    return null;
  }

  private hasRecordLevelErrors(row: DataSubmissionForGrid): boolean {
    // As of 2/1221 to distinguish if the data submission has file level errors we need to check the error message
    // And though the errors column is now being set to an empty string in the DB if no errors were present
    // The safety operator on row.errors is still necessary because there remain some null values in the DB
    // We also call toString() on row.status because the DataSubmissionForGrid has status (overwritten in defineColumns()) typed as FileUploadStatus
    const errorMsg = 'Record-level errors found';
    const hasRecordErrors = row.errors?.indexOf(errorMsg) !== -1;

    return row.fileStatus?.toString() === FileProcessStatus[FileProcessStatus.Error] && hasRecordErrors;
  }

  private async downloadErrorFile(row: DataSubmissionForGrid) {
    try {
      const response = await apiService.get('/dataSubmission/getErrorFileUrl', {
        params: {
          id: row.id
        }
      });
      window.open(response.data.url, '_blank');
    } catch {
      NotificationToast.showNetworkError();
    }
  }

  protected handleInputValueChange(searchPhrase: string) {
    this._store.searchPhrase = searchPhrase;
  }

  protected async handleSearch(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.props.store.search = this._store.searchPhrase;
    await this.props.store.refreshGrid();
  }

  protected setDefaultSortedColumn(): SortingRule {
    return {
      id: 'name',
      desc: false
    };
  }

  protected setNoDataText(): string | React.ReactNode {
    return 'No upload history available';
  }

  private async handleOrganizationChange(organizationId: number): Promise<void> {
    this._store.organizationId = organizationId;
    this.props.store.organizationId = organizationId;
    await this.props.store.refreshGrid();
  }

  private async fetchOrganizations(): Promise<Organization[]> {
    try {
      const response = await apiService.get<Organization[]>('/organization/listActive');
      return response.data;
    } catch (e) {
      NotificationToast.showNetworkError();
      return [];
    }
  }
}
