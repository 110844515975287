import * as React from 'react';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { observer } from 'mobx-react';
import { RouteComponentProps, StaticContext, withRouter } from 'react-router';
import { CreateUserOrganizationSectionStore } from './CreateUserOrganizationSectionStore';
import { makeRef } from '../../../../helpers/Ref';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import FormDataTypeSelector from '../../../controls/formSelect/dataTypeSelector/FormDataTypeSelector';
import FormInput from '../../../controls/formInput/FormInput';
import FormSectionContainer from '../../../layout/formContainer/sectionContainer/FormSectionContainer';
import FormStateSelector from '../../../controls/formSelect/stateSelector/FormStateSelector';
import ButtonWithSpinner from '../../../controls/buttons/ButtonWithSpinner';
import styles from './CreateUserOrganizationSection.scss';
import CreateUserOrganizationList from './OrganizationList/CreateUserOrganizationList';
import { State } from '../../../../model/address/State';

@observer
export class CreateUserOrganizationSection extends React.Component<CreateUserOrganizationSectionProps, CreateUserOrganizationSectionState> {
  private _store: CreateUserOrganizationSectionStore = this.props.store;

  public constructor(props: CreateUserOrganizationSectionProps) {
    super(props);

    this.state = {
      isOrgFetching: false,
      shouldFetchDataTypes: false
    };
  }

  public render(): React.ReactNode {
    const customSearchOrgInput = (
      <InputGroup>
        <Input
          placeholder="Enter Organization name..."
          onChange={e => { this._store.organizationSearchName = e.target.value; }}
        />
        <InputGroupAddon addonType="append">
          <ButtonWithSpinner
            id="searchOrgButton"
            color="primary"
            buttonText="Find"
            block
            className="findOrgButton"
            isBusy={this.state.isOrgFetching}
            onClick={() => this.fetchOrganizations()}
          />
        </InputGroupAddon>
      </InputGroup>
    );

    return (
      <FormSectionContainer className={styles.organizationSection}>
        <div className="row">
          <FormStateSelector
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'organizationSearchState')}
            id="stateSearch"
            labelText="What state is your organization located in?"
            onValueChangedFunc={(state: number) => this.onStateChanged(state)}
            fetchOptionsFunc={() => this.fetchStates()}
          />
          <FormDataTypeSelector
            id="dataTypeSearch"
            className="col-12 col-sm-6"
            storeRef={makeRef(this._store, 'organizationSearchDataSubmissionType')}
            labelText="What data type are you submitting?"
            onValueChangedFunc={(dataType: number) => this.onDataTypeChanged(dataType)}
            shouldFetchOptions={this.state.shouldFetchDataTypes}
            fetchOptionsFunc={() => this.fetchDataTypes()}
          />
        </div>
        <div className="row mb-3">
          <FormInput
            id="organizationSearchName"
            className="col-12 col-sm-6"
            labelText="Organization"
            storeRef={makeRef(this._store, 'organizationSearchName')}
            customInput={customSearchOrgInput}
          />
        </div>
        <div className="row m-0">
          <em className="mb-2">{this.renderGuideMessage()}</em>
        </div>
        <CreateUserOrganizationList store={this._store} />
      </FormSectionContainer>
    );
  }

  private renderGuideMessage = () => {
    if (!this._store.existingOrganizations) {
      return 'Enter your organization name or add a new one.';
    } if (this._store.existingOrganizations!.length > 0) {
      return 'We found the following organizations that match your search criteria. Please make a selection or create a new organization.';
    }

    return 'There are no organizations that match your name criteria. Please try again or adminCreate a new organization.';
  };

  private async fetchOrganizations(): Promise<void> {
    this._store.selectedOrganization = undefined;
    const isStateValid = await this._store.validate('organizationSearchState');
    const isDataTypeValid = await this._store.validate('organizationSearchDataSubmissionType');
    if (!isStateValid || !isDataTypeValid) {
      return;
    }

    this._store.resetValidationState();
    this.setState({ isOrgFetching: true });
    const locationStateOrgId = this.props.location.state?.organizationId;

    await this._store.fetchOrganizations();

    if (locationStateOrgId && locationStateOrgId > 0) {
      this._store.selectedOrganization = this._store.existingOrganizations?.find(org => org.id === locationStateOrgId);
    }

    this.setState({ isOrgFetching: false });
  }

  private async fetchStates(): Promise<State[]> {
    return this._store.fetchStates();
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private onStateChanged(state: State): void {
    this.setState(prevState => ({ shouldFetchDataTypes: !prevState.shouldFetchDataTypes }));
    this._store.organizationSearchDataSubmissionType = undefined;
    this._store.selectedOrganization = undefined;
    this._store.existingOrganizations = undefined;
    this._store.resetValidationState();
  }

  private async fetchDataTypes(): Promise<DataSubmissionType[]> {
    return this._store.fetchDataTypes(this._store.organizationSearchState!);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  private onDataTypeChanged(submissionType: DataSubmissionType): void {
    this._store.resetValidationState('organizationSearchDataSubmissionType');
    this._store.selectedOrganization = undefined;
    this._store.existingOrganizations = undefined;
  }
}

interface OrganizationIdLocationState {
  organizationId?: number;
}

type CreateUserOrganizationSectionProps = {
  store: CreateUserOrganizationSectionStore;
} & RouteComponentProps<{}, StaticContext, OrganizationIdLocationState>;

interface CreateUserOrganizationSectionState {
  isOrgFetching: boolean;
  shouldFetchDataTypes: boolean;
}

export default withRouter(CreateUserOrganizationSection);
