import React from 'react';
import { Progress, Row, Spinner } from 'reactstrap';
import { AxiosResponse } from 'axios';
import styles from './FileItem.scss';
import { combineClassNames } from '../../../../helpers/reactHelpers';
import apiService from '../../../../services/ApiService';
import notificationToast from '../../../layout/notificationToast/NotificationToast';
import { DataSubmissionType } from '../../../../model/dataSubmission/DataSubmissionType';
import { DataSubmissionResponse } from '../../../../model/data/submission/DataSubmissionResponse';
import routePaths from '../../../../constants/routePaths';
import { FileProcessStatus } from '../../../../helpers/enums/fileProcessStatus';
import { SubmitterType } from '../../../../helpers/enums/submitterType';

export type FileItemParams = {
  file: File;
  organizationId: number;
  submissionType: DataSubmissionType;
  submitterType: SubmitterType;
  onRemoveButtonClick: (file: File) => void;
};

export type FileItemState = {
  percentUploaded: number;
  uploadStatus: FileProcessStatus;
};

export class FileItem extends React.Component<FileItemParams, FileItemState> {
  constructor(props: FileItemParams) {
    super(props);

    this.state = {
      percentUploaded: 0,
      uploadStatus: FileProcessStatus.Pending
    };
  }

  get fileSize(): string {
    const fileKb = Math.round(this.props.file.size / 1000);
    const maxKbForDisplay = 999;
    if (fileKb > maxKbForDisplay) {
      const fileMb = (fileKb / 1000).toFixed(1);
      return `${fileMb}MB`;
    }

    return `${fileKb}KB`;
  }

  get file(): File {
    return this.props.file;
  }

  get fileExtension() {
    return this.file.name.split('.').pop();
  }

  get mimeType(): string {
    switch (this.fileExtension) {
      case 'csv':
        return 'text/csv';
        break;
      case 'dat':
        return 'application/octet-stream';
        break;
      case 'tsv':
        return 'text/tab-separated-values';
        break;
      case 'txt':
        return 'text/plain';
        break;
      case 'xls':
        return 'application/vnd.ms-excel';
        break;
      default:
        return '';
    }
  }

  public render() {
    return (
      <li className={combineClassNames(styles.fileItem, 'container-fluid mb-3')}>
        <Row>
          <div className="col-11 pt-2 pb-2">
            <div className="container p-0">
              <div className="col-12 p-0">
                <span className="fileName"> {this.file.name}</span>({this.fileSize})
              </div>

              {this.state.percentUploaded > 0 && (
                <div className="pb-1">
                  <span>
                    {this.state.uploadStatus === FileProcessStatus.InProgress && (
                      <span className="pr-2"> <Spinner color="primary" /></span>
                    )}
                    <span className="pr-2">{FileProcessStatus[this.state.uploadStatus]}</span>
                    ({this.state.percentUploaded}%)
                  </span>
                  <Progress value={this.state.percentUploaded} />
                </div>
              )}
            </div>
          </div>

          <div className="col-1 p-0 d-flex align-items-center">
            <div className="fileControls">
              <span
                role="button"
                aria-label="file item"
                tabIndex={0}
                className="remove"
                onClick={() => this.props.onRemoveButtonClick(this.file)}
                onKeyDown={() => null}
              />
            </div>
          </div>
        </Row>
      </li>
    );
  }

  public async upload() {
    if (!this.readyForUpload()) {
      return;
    }

    const fileBlob = new Blob([this.file], { type: this.mimeType });
    const formData = new FormData();
    formData.append('file', fileBlob);
    formData.append('fileName', this.file.name); // because blobs have no name
    formData.append('organizationId', this.props.organizationId.toString());
    formData.append('submissionType', this.props.submissionType.toString());
    formData.append('submitterType', this.props.submitterType.toString());

    let response: AxiosResponse<DataSubmissionResponse> | void;

    try {
      response = await apiService.post(
        'dataSubmission/uploadFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentComplete = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
            this.setState({ uploadStatus: FileProcessStatus.InProgress, percentUploaded: percentComplete - 1 });
          }
        }
      );

      if (response && response.status === 200 && response.data.success) {
        this.setState({ uploadStatus: FileProcessStatus.Success, percentUploaded: 100 });
        notificationToast.showSuccess(
          this.buildSuccessMessage(),
          { html: true }
        );
      }
    } catch (error) {
      this.setState({ uploadStatus: FileProcessStatus.Error });
      notificationToast.showError(`Upload failed for ${this.file.name}: ${error.response?.data.error}`);
    }
  }

  public readyForUpload(): boolean {
    return this.state.uploadStatus === FileProcessStatus.Pending;
  }

  private buildSuccessMessage(): string {
    return `<span>
        Upload complete for ${this.file.name}.
        Navigate to <a href="#${routePaths.data.history}" target="_blank">Upload History</a> to view the current status of your upload.
      </span>`;
  }
}
