import * as React from 'react';
import RouteManager, { RouteSettings } from './RouteManager';

const defaultSettings: RouteSettings = {
  path: '',
  needsLayout: true,
  needsAuthentication: true,
  permitActiveUsersOnly: true,
  hasPermission: '',
  exact: true
};

export function page(routeSettings: RouteSettings | string) {
  return (cls: React.ComponentClass<any, any> | React.FC<any>) => {
    const settings = buildRouteSettings(routeSettings);
    RouteManager.registerPage(settings, cls);
  };
}

export function registerPage(routeSettings: RouteSettings | string, cls: React.FC<any>): React.FC<any> {
  const settings = buildRouteSettings(routeSettings);
  RouteManager.registerPage(settings, cls);

  return cls;
}

function buildRouteSettings(settings: RouteSettings | string): Required<RouteSettings> {
  let result: Partial<RouteSettings>;
  if (typeof settings === 'string') {
    result = {
      path: settings
    };
  } else {
    result = settings;
  }

  return ({ ...defaultSettings, ...result }) as Required<RouteSettings>;
}
