import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CustomInput, Form } from 'reactstrap';
import { observer } from 'mobx-react';
import routePaths from '../../../constants/routePaths';
import { combineClassNames } from '../../../helpers/reactHelpers';
import ButtonWithSpinner from '../../../shared/controls/buttons/ButtonWithSpinner';
import MessageBox, { MessageType } from '../../../shared/layout/messageBox/MessageBox';
import styles from './LoginForm.scss';
import loginStore from './LoginStore';
import FormInput from '../../../shared/controls/formInput/FormInput';
import { makeRef } from '../../../helpers/Ref';

@observer
export default class LoginForm extends React.Component<{}> {
  private _store = loginStore;

  public constructor(props: Readonly<{}>) {
    super(props);
  }

  public render(): React.ReactNode {
    if (this._store.loginSuccessful) {
      return <Redirect to={routePaths.dashboard.root} />;
    }

    return (
      <div className={combineClassNames(styles.loginForm, 'mb-5')}>
        <h5 className="title mb-5">Secure Log In</h5>
        <Form>
          <FormInput
            storeRef={makeRef(this._store, 'login')}
            placeholder="Email Address"
            id="email"
          />
          <FormInput type="password" storeRef={makeRef(this._store, 'password')} placeholder="Password" id="password" />
          <CustomInput
            type="switch"
            name="rememberMe"
            label="Remember me"
            id="rememberMe"
            onChange={e => { this._store.rememberMe = e.target.checked; }}
          />
          <div className="mb-3 d-flex justify-content-end">
            <Link className="small" to={routePaths.user.forgotPassword}>
              Forgot password?
            </Link>
          </div>
          {this.getErrorArea()}
          <ButtonWithSpinner
            id="loginButton"
            type="submit"
            className="text-uppercase"
            block
            color="primary"
            buttonText="Login"
            isBusy={this._store.isBusy}
            onClick={(e: React.MouseEvent<any>) => this.submitButtonClicked(e)}
          />
        </Form>
      </div>
    );
  }

  public componentWillUnmount(): void {
    this._store.reset();
  }

  private getErrorArea(): React.ReactNode {
    return this._store.error !== ''
      ? (
        <MessageBox
          id="loginFormErrorBox"
          isVisible={this._store.error !== ''}
          message={this._store.error}
          type={MessageType.Error}
        />
      )
      : <div className="separator" />;
  }

  private async submitButtonClicked(e: React.MouseEvent<any>): Promise<void> {
    e.preventDefault();

    if (await this._store.validate()) {
      await this._store.doLogin();
    }
  }
}
