import { action, observable } from 'mobx';
import ValidatableObject from '../../../../shared/validation/ValidatableObject';
import { validate } from '../../../../shared/validation/helpers';
import NotEmpty from '../../../../shared/validation/validators/NotEmpty';
import apiService from '../../../../services/ApiService';

export default class OrganizationDeactivationRejectionStore extends ValidatableObject {
  @observable
  public isBusy = false;

  @observable
  @validate(() => new NotEmpty())
  public reason = '';

  @action
  public async disableOrganization(organizationId: number) {
    await this.postRequest('organization/deactivate', organizationId);
  }

  @action
  public async rejectOrganization(organizationId: number) {
    await this.postRequest('organization/reject', organizationId);
  }

  private async postRequest(endpoint: string, organizationId: number) {
    this.isBusy = true;

    const request: OrganizationDeactivationRejection = {
      organizationId,
      reason: this.reason
    };

    try {
      await apiService.post(endpoint, request);
    } finally {
      this.isBusy = false;
    }
  }
}

interface OrganizationDeactivationRejection {
  organizationId: number;
  reason: string;
}
