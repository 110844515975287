import ProfilePageStore from '../../../shared/user/profile/ProfilePageStore';
import apiService from '../../../services/ApiService';
import UserProfile from '../../../model/user/UserProfile';
import { EditProfileUserNameStore } from './EditProfileUserNameStore';
import { EditProfilePhoneStore } from './EditProfilePhoneStore';
import { EditProfilePasswordStore } from './EditProfilePasswordStore';
import { EditProfileEmailStore } from './EditProfileEmailStore';
import { EditProfileOrganizationStore } from './EditProfileOrganizationStore';

export class UserProfilePageStore extends ProfilePageStore<EditProfileUserNameStore,
  EditProfilePhoneStore,
  EditProfilePasswordStore,
  EditProfileEmailStore,
  EditProfileOrganizationStore> {
  public constructor(editUserNameStore: EditProfileUserNameStore,
    editProfilePhoneStore: EditProfilePhoneStore,
    editProfilePasswordStore: EditProfilePasswordStore,
    editProfileEmailStore: EditProfileEmailStore,
    editProfileOrganizationStore: EditProfileOrganizationStore) {
    super(editUserNameStore, editProfilePhoneStore, editProfilePasswordStore, editProfileEmailStore, editProfileOrganizationStore);
  }

  public async getUserProfile(): Promise<void> {
    this.isBusy = true;
    try {
      const response = await apiService.get<UserProfile>('user/profile');
      this.userProfile = response.data;
    } finally {
      this.isBusy = false;
    }
  }
}
