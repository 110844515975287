import * as React from 'react';
import styles from './Steps.scss';
import { combineClassNames } from '../../../helpers/reactHelpers';

export interface StepOption {
  id: number;
  description: string;
}

export interface StepsProps {
  activeStepId: number;
  steps: StepOption[];
}

export default class Steps extends React.Component<StepsProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.steps}>
        <ul>
          {this.props.steps.map(step => {
            const pastStepClass = step.id < this.props.activeStepId ? 'past' : '';
            const activeStepClass = step.id === this.props.activeStepId ? 'active' : '';

            return (
              <li key={step.id} className={combineClassNames(activeStepClass, pastStepClass)}>
                <span className="step-id">
                  {step.id}
                </span>
                <span className="step-description">{step.description}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
