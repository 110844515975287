import { AuditChangeType } from '../../model/audit/AuditChangeType';

const ADD = 'CREATE';
const UPDATE = 'UPDATE';
const DELETE = 'DELETE';

export const AuditChangeTypeDisplayName: { [index: number]: string } = [];
AuditChangeTypeDisplayName[AuditChangeType.Add] = ADD;
AuditChangeTypeDisplayName[AuditChangeType.Update] = UPDATE;
AuditChangeTypeDisplayName[AuditChangeType.Delete] = DELETE;
