import * as React from 'react';
import styles from '../CreateNaloxoneEventReviewSection.scss';
import InlineEntry from '../../../../../../shared/layout/inlineEntry/InlineEntry';
import { State } from '../../../../../../model/address/State';
import { combineClassNames } from '../../../../../../helpers/reactHelpers';
import CreateNaloxoneEventStore from '../../CreateNaloxoneEventStore';
import { CreateNaloxoneEventPageSection } from '../../CreateNaloxoneEventPage';
import { GenderDisplayName } from '../../../../../../helpers/enums/gender';
import {
  ScnaPatientInformationSectionStore
} from '../../patientInformationSection/scnaPatientInformationForm/ScnaPatientInformationSectionStore';
import {
  ScnaAdministerInformationSectionStore
} from '../../administerInformationSection/scnaAdministerInformationForm/ScnaAdministerInformationSectionStore';
import {
  SubmitterType as SubmitterTypeEnum,
  SubmitterTypeDisplayName
} from '../../../../../../helpers/enums/submitterType';

interface ScnaCreateNaloxoneEventReviewSectionProps {
  mainStore: CreateNaloxoneEventStore;
}

export default class ScnaCreateNaloxoneEventReviewSection extends React.Component<ScnaCreateNaloxoneEventReviewSectionProps> {
  constructor(props: ScnaCreateNaloxoneEventReviewSectionProps) {
    super(props);
  }
  private readonly _mainStore = this.props.mainStore;
  private readonly _administerInformationStore = this.props.mainStore.administerInformationSectionStore;
  private _patientInformationStore = this.props.mainStore.patientInformationSectionStore;

  public render(): React.ReactNode {
    const {
      FirstName,
      LastName,
      MiddleName,
      DateOfBirth,
      Patient_Address1,
      Patient_Address2,
      Patient_City,
      Patient_State,
      Patient_ZipCode,
      Patient_Gender,
    } = this._patientInformationStore! as ScnaPatientInformationSectionStore;

    const {
      SubmitterType,
      FirstResponder_Type,
      FirstResponder_FirstAndLastName,
      Incident_MedicationAdministeredDate,
      Incident_Zipcode,
      Incident_County
    } = this._administerInformationStore.AdministerInformationFormStore! as ScnaAdministerInformationSectionStore;

    return (
      <div className={combineClassNames(styles.reviewSection, 'd-flex', 'flex-column')}>
        <div className="subsection_header">
          <span>Administer Information</span>
          <button type="button" className="link" onClick={e => this.goToPage(e, CreateNaloxoneEventPageSection.AdministerInformation)}>Edit</button>
        </div>
        <div className="subsection_content">
          <InlineEntry value={SubmitterTypeDisplayName[SubmitterType]} id="SubmitterType" labelText="Submitter Type" />
          {SubmitterType === SubmitterTypeEnum.FirstResponder && (
            <>
              <InlineEntry value={FirstResponder_Type} id="FirstResponder_Type" labelText="First Responder Type" />
              <InlineEntry value={FirstResponder_FirstAndLastName} id="FirstResponder_FirstAndLastName" labelText="First Responder Name" />
            </>
          )}
          <InlineEntry value={Incident_MedicationAdministeredDate} id="Incident_MedicationAdministeredDate" labelText="Administered Date" />
          <>
            <InlineEntry value={Incident_Zipcode} id="Incident_Zipcode" labelText="Incident Zipcode" />
            <InlineEntry value={Incident_County} id="Incident_County" labelText="Incident County" />
          </>
        </div>
        <div className="subsection_header">
          <span>Patient Information</span>
          <button type="button" className="link" onClick={e => this.goToPage(e, CreateNaloxoneEventPageSection.PatientInformation)}>Edit</button>
        </div>
        <div className="subsection_content">
          <InlineEntry value={FirstName} id="patientFirstName" labelText="Patient First Name" />
          <InlineEntry value={MiddleName} id="patientMiddleName" labelText="Patient Middle Name" />
          <InlineEntry value={LastName} id="patientLastName" labelText="Patient Last Name" />
          <InlineEntry value={DateOfBirth} id="patientDateOfBirth" labelText="Patient Date Of Birth" />
          <>
            <InlineEntry value={GenderDisplayName[Patient_Gender]} id="patientGender" labelText="Patient Gender" />
          </>
          <InlineEntry
            id="patientAddress"
            labelText="Address"
            value={`${Patient_Address1}, ${Patient_Address2}, ${Patient_City}, ${State[Patient_State]} ${Patient_ZipCode}`}
          />
        </div>
      </div>
    );
  }

  private goToPage(e: React.MouseEvent<HTMLButtonElement>, page: CreateNaloxoneEventPageSection): void {
    e.preventDefault();
    this._mainStore.pageSection = page;
  }
}
