import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { SimpleOperationModal } from '../../../shared/layout/modal/SimpleOperationModal';
import notificationToast from '../../../shared/layout/notificationToast/NotificationToast';
import apiService from '../../../services/ApiService';
import { OpenModal } from '../../../shared/layout/modal/OpenModal';

export type UserDeletionModalProps = {
  openModal: OpenModal.UserDelete;
  modalTitle?: string;
  id: number;
  closeModalFunc: () => void;
  onSubmitFunc?: () => void;
};

const UserDeletionModal:
  React.FC<UserDeletionModalProps> = (props: UserDeletionModalProps) => {
    const [isBusy, setIsBusy] = useState(false);
    const modalTitle = props.modalTitle || 'Delete User';
    const deleteUser = async () => {
      setIsBusy(true);

      try {
        await apiService.post('user/delete', { id: props.id });
      } finally {
        setIsBusy(false);
      }

      notificationToast.showSuccess('User has been deleted.');

      props.closeModalFunc();
      if (props.onSubmitFunc) props.onSubmitFunc!();

      return true;
    };

    return (
      <SimpleOperationModal
        isOpen={props.openModal === OpenModal.UserDelete}
        headerTitle={modalTitle}
        onSubmitButtonClicked={deleteUser}
        onCancelButtonClicked={props.closeModalFunc}
        isSubmitButtonBusy={isBusy}
      >
        <p>Are you sure you want to delete this user?</p>
      </SimpleOperationModal>
    );
  };

export default observer(UserDeletionModal);
