import { hot } from 'react-hot-loader';
import * as React from 'react';
import {
  Redirect, Route, RouteComponentProps, Switch, withRouter
} from 'react-router';
import Alert from 'react-s-alert';
import { Spinner } from 'reactstrap';
import { observer } from 'mobx-react';
import routePaths from './constants/routePaths';
import NotFoundPage from './pages/notFound/NotFoundPage';
import userStore, { UserStoreState } from './data/UserStore';
import Layout from './shared/layout/Layout';
import RouteManager from './services/route/RouteManager';
import NotificationToast from './shared/layout/notificationToast/NotificationToast';
import { AccountStatus } from './model/user/AccountStatus';

// COMMENT(tomek): recursively include each files from ./pages ending with "Page.tsx"
const req = (require as any).context('./pages', true, /Page\.tsx$/);
req.keys().forEach((key: string) => req(key));

@observer
export class App extends React.Component<RouteComponentProps<any>, {}> {
  public componentDidMount(): void {
    this.checkUserStore();

    // Newrelic instrumentation
    // const head = document.querySelector('head');
    // const script = this.buildNewRelicScriptTag();
    //
    // head!.appendChild(script);
  }

  public componentDidUpdate(): void {
    this.checkUserStore();
  }

  private buildNewRelicScriptTag() {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    switch (ENVIRONMENT) {
      case 'Development':
        script.src = 'assets/monitoring/newrelic.dev.js';
        break;
      case 'Qa':
        script.src = 'assets/monitoring/newrelic.qa.js';
        break;
      case 'Prep':
        script.src = 'assets/monitoring/newrelic.prep.js';
        break;
      case 'Production':
        script.src = 'assets/monitoring/newrelic.prod.js';
        break;
      default:
        break;
    }

    return script;
  }

  private checkUserStore(): void {
    // COMMENT(tomek): I actually consider this to be kind of a hack,
    // but in every case if there's no need for layout, there's no need for userStore
    // and in case of callbacks, if you do initialize it, it might break things.
    if (RouteManager.needsLayout(this.props.location.pathname) && userStore.loadingState === UserStoreState.NotInitialized) {
      userStore.initialize();
    }
  }

  public render(): React.ReactNode {
    if (!RouteManager.needsLayout(this.props.location.pathname)) {
      return this.renderMainRouter();
    }

    return (
      <>
        <Layout>
          {this.renderContent()}
        </Layout>
        <Alert stack={{ limit: 3 }} />

      </>
    );
  }

  private renderContent(): React.ReactNode {
    if (userStore.loadingState === UserStoreState.NotInitialized || userStore.loadingState === UserStoreState.Initializing) {
      return (
        <div className="container align-self-stretch d-flex align-items-center justify-content-center">
          <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
        </div>
      );
    }

    if (!RouteManager.isPublicPath(this.props.location.pathname) && userStore.loadingState !== UserStoreState.LoggedIn) {
      switch (userStore.loadingState) {
        case UserStoreState.SessionExpired:
          NotificationToast.showInfo('Your session has expired. Please log back in');
          break;
        case UserStoreState.NotLoggedIn:
          NotificationToast.showError('Unauthorized access. Please log in.');
          break;
        default:
          NotificationToast.showNetworkError();
          break;
      }

      return <Redirect to={routePaths.general.root} />;
    }

    if (!RouteManager.isPublicPath(this.props.location.pathname)
      && !RouteManager.hasPermissionForPath(this.props.location.pathname, userStore.session?.role)) {
      NotificationToast.showError('You do not have permission to view this page.');
      return <Redirect to={routePaths.dashboard.root} />;
    }

    if (!RouteManager.isPublicPath(this.props.location.pathname)
      && RouteManager.isActiveUsersOnlyPath(this.props.location.pathname)
      && userStore.session?.status !== AccountStatus.Active) {
      let errorMessage;

      switch (userStore.session?.status) {
        case AccountStatus.Disabled:
          errorMessage = 'Your account has been deactivated. Please contact your administrator if you need to reactivate your account.';
          break;
        default:
          errorMessage = 'Unauthorized access. Please contact your account administrator to activate your account.';
      }

      NotificationToast.showError(errorMessage);
      return <Redirect to={routePaths.dashboard.root} />;
    }

    return this.renderMainRouter();
  }

  private renderMainRouter(): React.ReactNode {
    return (
      <Switch>
        {RouteManager.getRedirects().map(entry => <Redirect key={entry.from} exact={entry.exact} from={entry.from} to={entry.to} />)}
        {RouteManager.getEntries().map(entry => (
          <Route
            key={entry.settings.path}
            exact={entry.settings.exact}
            path={entry.settings.path}
            component={entry.component}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default hot(module)(withRouter(App));
