import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router';
import OverviewContext from './OverviewContext';
import { FormContainer } from '../../shared/layout/formContainer/FormContainer';
import { LicenseeContextProvider } from './LicenseeContext';
import { registerPage } from '../../services/route/decorators';
import routePaths from '../../constants/routePaths';
import { RouteSettings } from '../../services/route/RouteManager';
import { ViewMode } from './ViewMode';
import permissions from '../../model/user/permissions';
import { InfoSection } from './_components/OverviewTab/_components/InfoSection/InfoSection';

export const pageSettings: RouteSettings = {
  path: routePaths.license.overview,
  needsAuthentication: true,
  hasPermission: permissions.license.overview
};
const LicenseeOverviewPage: React.FC<RouteComponentProps> = () => {
  const [mode] = useState<ViewMode>(ViewMode.preview);

  return (
    <OverviewContext.Provider value={mode}>
      <LicenseeContextProvider>
        <FormContainer>
          <InfoSection />
        </FormContainer>
      </LicenseeContextProvider>
    </OverviewContext.Provider>
  );
};
export default registerPage(pageSettings, LicenseeOverviewPage);
