import { observable } from 'mobx';
import ProfilePageStore from '../../../shared/user/profile/ProfilePageStore';
import apiService from '../../../services/ApiService';
import UserProfile from '../../../model/user/UserProfile';
import { EditDetailsUserNameStore } from './EditDetailsUserNameStore';
import { EditDetailsPhoneStore } from './EditDetailsPhoneStore';
import { EditDetailsPasswordStore } from './EditDetailsPasswordStore';
import { EditDetailsEmailStore } from './EditDetailsEmailStore';
import { EditDetailsOrganizationStore } from './EditDetailsOrganizationStore';

export class UserDetailsPageStore extends ProfilePageStore<EditDetailsUserNameStore,
  EditDetailsPhoneStore,
  EditDetailsPasswordStore,
  EditDetailsEmailStore,
  EditDetailsOrganizationStore> {
  public constructor(editUserNameStore: EditDetailsUserNameStore,
    editDetailsPhoneStore: EditDetailsPhoneStore,
    editDetailsPasswordStore: EditDetailsPasswordStore,
    editProfileEmailStore: EditDetailsEmailStore,
    editDetailsOrganizationStore: EditDetailsOrganizationStore) {
    super(editUserNameStore, editDetailsPhoneStore, editDetailsPasswordStore, editProfileEmailStore, editDetailsOrganizationStore);
  }
  @observable
  public userId = '';

  public async getUserProfile(): Promise<void> {
    this.isBusy = true;
    try {
      const response = await apiService.get<UserProfile>(`user/${this.userId}`);
      this.userProfile = response.data;
    } finally {
      this.isBusy = false;
    }
  }
}
