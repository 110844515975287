import Validator, { ValidationResult } from './Validator';

export default class NotApprissEmail implements Validator<string | undefined> {
  private readonly _message: string;

  public constructor(message = 'Email cannot contain Appriss domain') {
    this._message = message;
  }

  public validate(value: string | undefined): Promise<ValidationResult> {
    if (value?.endsWith('@appriss.com') || value?.endsWith('@apprisshealth.com')) {
      return Promise.resolve({ isValid: false, message: this._message });
    }
    return Promise.resolve({ isValid: true });
  }
}
